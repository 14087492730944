import {useViewer} from './auth';
import {useEffect} from 'react';
import {DashboardOrderT, OrderT} from '../types/orders';
import {createReviewPageData, createStartData} from '../helpers/customerIO';
import {PurchaseResT} from '../types/purchase';
import {getTZdate} from '../helpers/orders';
import {formats} from '../helpers/helpers';
import {ReferralsDataT} from '../types/referrals';
import {Modal} from 'antd';

export const analyticsTrackFN = (key: string, object: any) => {
  // eslint-disable-next-line
  // @ts-ignore
  analytics.track(key, object);
};
export const analyticsIdentifyFN = (id: string, user_hash: string) => {
  // eslint-disable-next-line
  // @ts-ignore
  analytics.identify(id, {user_hash});
};

export const loadDashboard = (orders?: number) => {
  const viewer = useViewer();
  const track = () =>
    analyticsTrackFN('My Orders', {
      userId: viewer?.id,
      userName: viewer?.firstName,
      total_orders: orders,
      email: viewer?.email,
      phone: viewer?.phoneNumber,
    });
  useEffect(() => {
    if (orders) track();
  }, [orders]);
};

export const useSegmentVip = (order?: OrderT) => {
  const viewer = useViewer();
  const startPageData = createStartData(viewer, order);
  const toUpgradePageClick = () => analyticsTrackFN('VIP Upgrade Started', startPageData);

  const onUpgradeReview = (purchaseRes?: PurchaseResT) => {
    const reviewPageData = createReviewPageData(viewer, order, purchaseRes);
    analyticsTrackFN('VIP Upgrade Review', reviewPageData);
  };
  const onUpgradeComplete = (purchaseRes?: PurchaseResT) => {
    const completePageData = createReviewPageData(viewer, order, purchaseRes);
    analyticsTrackFN('VIP Upgrade Complete', completePageData);
  };

  return {toUpgradePageClick, onUpgradeReview, onUpgradeComplete};
};

export const useSegmentAddon = (order?: OrderT) => {
  const viewer = useViewer();

  const toAddonPageClick = () => {
    const startPageData = createStartData(viewer, order);
    analyticsTrackFN('Add On Started', startPageData);
  };

  const onAddonReview = (purchaseRes?: PurchaseResT) => {
    const reviewPageData = createReviewPageData(viewer, order, purchaseRes);
    analyticsTrackFN('Add On Reviewed', reviewPageData);
  };

  const onAddonComplete = (purchaseRes?: PurchaseResT) => {
    const completePageData = createReviewPageData(viewer, order, purchaseRes);
    analyticsTrackFN('Add On Completed', completePageData);
  };

  return {toAddonPageClick, onAddonReview, onAddonComplete};
};

export const useSegmentChangeDate = (order?: OrderT) => {
  const viewer = useViewer();

  const toChangeDatePageClick = () => {
    const startPageData = createStartData(viewer, order);
    analyticsTrackFN('Date Change Started', startPageData);
  };

  const onChangeDateReview = (purchaseRes?: PurchaseResT) => {
    const reviewPageData = createReviewPageData(viewer, order, purchaseRes);
    analyticsTrackFN('Date Change Reviewed', reviewPageData);
  };

  const onChangeDateComplete = (purchaseRes?: PurchaseResT) => {
    const completePageData = createReviewPageData(viewer, order, purchaseRes);
    analyticsTrackFN('Date Change Completed', completePageData);
  };

  return {toChangeDatePageClick, onChangeDateReview, onChangeDateComplete};
};

export const useSegmentCancellation = (order?: OrderT) => {
  const viewer = useViewer();

  const toChangeDatePageClick = () => {
    const startPageData = createStartData(viewer, order);
    analyticsTrackFN('Order Cancellation Started', startPageData);
  };

  const onCancelReview = (purchaseRes?: PurchaseResT) => {
    const reviewPageData = createReviewPageData(viewer, order, purchaseRes);
    analyticsTrackFN('Order Cancellation Reviewed', reviewPageData);
  };
  const onCancelComplete = (purchaseRes?: PurchaseResT) => {
    const completePageData = createReviewPageData(viewer, order, purchaseRes);
    analyticsTrackFN('Order Cancellation Completed', completePageData);
  };

  return {toChangeDatePageClick, onCancelReview, onCancelComplete};
};

export const useSegmentPurchaseGA = (order?: OrderT) => {
  const viewer = useViewer();

  const toAddonPageClick = () => {
    const startPageData = createStartData(viewer, order);
    analyticsTrackFN('Add Ticket Started', startPageData);
  };

  const onAddonReview = (purchaseRes?: PurchaseResT) => {
    const reviewPageData = createReviewPageData(viewer, order, purchaseRes);
    analyticsTrackFN('Add Ticket Reviewed', reviewPageData);
  };
  const onAddonComplete = (purchaseRes?: PurchaseResT) => {
    const completePageData = createReviewPageData(viewer, order, purchaseRes);
    analyticsTrackFN('Add Ticket Completed', completePageData);
  };

  return {toAddonPageClick, onAddonReview, onAddonComplete};
};

export const useUserLoginAnalytics = () => {
  const onLogin = (res: any) => {
    const userEmail = res?.email;
    const uid = res?.id;
    const ticketureIdentityId = res?.ticketureIdentityId;
    const user_hash = res?.hmac;
    const userName = `${res.firstName} ${res.lastName}`;
    if (!uid) return;
    const data = {
      uid: uid,
      name: userName,
      email: userEmail,
    };
    analyticsIdentifyFN(ticketureIdentityId, user_hash);
    analyticsTrackFN('User Login', data);
  };

  return onLogin;
};

export const useUserLogoutAnalytics = () => {
  const user = useViewer();
  const onLogout = () => {
    const userEmail = user?.email;
    const userName = `${user?.firstName} ${user?.lastName}`;

    const data = {
      name: userName,
      email: userEmail,
    };
    analyticsTrackFN('User Logout', data);
  };

  return onLogout;
};

export const useUserAccountAnalytics = () => {
  const user = useViewer();

  const onAccountVisited = (events: {totalEvents: number; upcomingEvents: number; pastEvents: number}) => {
    const userEmail = user?.email;
    const userName = `${user?.firstName} ${user?.lastName}`;

    const data = {
      ...events,
      name: userName,
      email: userEmail,
    };
    analyticsTrackFN('My Account Visited', data);
  };

  const onOrdersVisited = () => {
    const uid = user?.id;
    const userEmail = user?.email;
    const userName = `${user?.firstName} ${user?.lastName}`;

    const data = {
      uid: uid,
      name: userName,
      email: userEmail,
    };
    analyticsTrackFN('My Orders Visited', data);
  };

  const onReferralsVisited = (code?: string) => {
    const uid = user?.id;
    const userEmail = user?.email;
    const userName = `${user?.firstName} ${user?.lastName}`;

    const data = {
      uid: uid,
      name: userName,
      email: userEmail,
      referralCode: code,
    };
    analyticsTrackFN('My Referrals Visited', data);
  };

  const onProfileVisited = () => {
    const uid = user?.id;
    const userEmail = user?.email;
    const userName = `${user?.firstName} ${user?.lastName}`;

    const data = {
      uid: uid,
      name: userName,
      email: userEmail,
    };
    analyticsTrackFN('My Profile Visited', data);
  };

  const onProfileUpdated = (updateData: any) => {
    const uid = user?.id;

    const data = {
      uid: uid,
      ...updateData,
    };
    analyticsTrackFN('User Updated', data);
  };

  return {onOrdersVisited, onReferralsVisited, onProfileVisited, onAccountVisited, onProfileUpdated};
};

export const useIntercomAnalytics = () => {
  const user = useViewer();
  const userEmail = user?.email;
  const userName = `${user?.firstName} ${user?.lastName}`;

  const data = {
    name: userName,
    email: userEmail,
  };

  useEffect(() => {
    if (typeof window?.Intercom !== 'undefined' && userEmail) {
      if (userEmail) return window.Intercom('onShow', () => analyticsTrackFN('Guest Services Contacted', data));
      window.Intercom('onShow', () => null);
    }
  }, [userEmail]);
};

export const useOrderClickAnalytics = (order: DashboardOrderT) => {
  const user = useViewer();
  const data = {
    name: `${user?.firstName} ${user?.lastName}`,
    email: user?.email,
    location: order.sellerName,
    venue: order.sellerVenue,
    date: getTZdate(order.eventDate, {sellerTimezone: order.sellerTimezone}).format(formats.calendarDate),
    time: getTZdate(order.eventDate, {sellerTimezone: order.sellerTimezone}).format(formats.time),
    orderNumber: order?.ticketureOrderNumber,
    source: 'My Account', // Funnel, My Account, Email etc
  };

  const onViewTicketClick = () => {
    analyticsTrackFN('Ticket Viewed', data);
  };
  const onManageWaiversClick = () => {
    analyticsTrackFN('Waivers Viewed', data);
  };
  return {onViewTicketClick, onManageWaiversClick};
};

export const useReferralShareAnalytics = (order?: OrderT, referrals?: ReferralsDataT) => {
  const user = useViewer();
  const data = {
    name: `${user?.firstName} ${user?.lastName}`,
    email: user?.email,
    location: order?.sellerName,
    venue: order?.sellerVenue,
    date: getTZdate(order?.eventDate, order).format(formats.calendarDate),
    time: getTZdate(order?.eventDate, order).format(formats.time),
    orderNumber: order?.ticketureOrderNumber,
    source: 'My Account',
    referralCode: user?.referralCode,
    creditsTotal: referrals?.totalEarned,
    creditsUsed: referrals?.totalSpent,
    creditsRemaing: referrals?.remaining,
    // referredPeople: 0,
  };
  const onShareClick = () => {
    analyticsTrackFN('Referral Link Shared', data);
  };
  return {onShareClick};
};

export const intercomActions = () => {
  const show = () => {
    if (typeof window?.Intercom === 'undefined') return;
    window.Intercom('show');
  };
  const hide = () => {
    if (typeof window?.Intercom === 'undefined') return;
    window.Intercom('hide');
  };
  const update = (data: any) => {
    if (typeof window?.Intercom === 'undefined') return;
    window.Intercom('update', data);
  };
  const launch = (data: any) => {
    if (typeof window?.Intercom === 'undefined') return;
    window.Intercom('boot', data);
  };
  return {show, hide, launch, update};
};

type useOnRequestsErrorPropsT = {
  order?: OrderT;
};

export type onRequestErrorT = (error?: any, fc?: string, req?: any) => void;

export const useOnRequestsError = ({order}: useOnRequestsErrorPropsT) => {
  const user = useViewer();
  const {launch, update, show} = intercomActions();
  const onRequestError = (error?: any, fc?: string, req?: any) => {
    const data = {
      user_id: user?.id,
      email: user?.email,
      order_number: order?.ticketureOrderNumber,
      location: window?.location?.href,
      error: error,
      request: JSON.stringify({
        uri: fc,
        body: req,
      }),
    };
    const handleChat = () => {
      if (error) {
        launch(data);
        update(data);
        show();
      }
    };

    Modal.confirm({
      title: 'Oops! The system encountered a technical issue...',
      okText: 'Chat with Guest Services',
      cancelText: 'close',
      onOk: handleChat,
    });
  };
  return {onRequestError};
};

export const useHideIntercomLauncher = () => {
  useEffect(() => {
    if (typeof window?.Intercom === 'undefined') return;
    const launcher = document.querySelector<HTMLElement>('.intercom-launcher');
    if (launcher?.style) {
      launcher.style.display = 'none';
    }
    return () => {
      if (launcher?.style) {
        launcher.style.display = 'block';
      }
    };
  }, []);
};
