import React, {PropsWithChildren, useEffect, useState} from 'react';
import {LinkButton, LinkButtonView, DashboardCardButton} from '../../ui-kit/Button/LinkButton';
import {Label, VipLabel, VisitProtectionLabel, WeatherProtectionLabel} from '../../ui-kit/Label';
import {Tab, Tabs} from '../../ui-kit/Tabs';
import {BoldText1, Text1, Text5} from '../../ui-kit/Typography';
import {useRecoilState} from 'recoil';
import {browserDetector} from '../../states/tickets';
import {capitalize} from '../../helpers/helpers';
import {
  BoldDetailText,
  ContentWrapper,
  DescriptionPastZero,
  LinkButtonsContainer,
  PageWrapper,
  PrimaryDetailCell,
  TabContent,
  TabDetailsWrapper,
  TabPastZeroContent,
  TabPastZeroContentWrapper,
  TabTitle,
  TabTitleWrapper,
  TitleAdds,
  TitleDescr,
  TitlePastZero,
  TitleWrapper,
  DashboardButtonsWrapper,
  OptionSection,
  OptionsWrapper,
  OptionsTitle,
  BadgeWrapper,
  SurveyWrapper,
} from './styles';
import {IconSvg} from '../../ui-kit/Icon';
import {TitleMedium} from '../styles';
import {route, surveyLink} from '../../constants/routes';
import {canBuyVipT, DashboardOrderT, OrderT} from '../../types/orders';
import {PageLogoLoader} from '../../ui-kit/Loader';
import {ModalLvl} from '../../ui-kit/types/common';
import {WarningModal} from '../../ui-kit/AlertModal';
import {intercomActions, useOrderClickAnalytics} from '../../hooks/customerIO';
import {getAppleWalletURL, getGoogleWalletURL} from '../../hooks/orders';
import {Popover} from 'antd';

type DashboardPagePropsT = {
  upcomingOrders?: DashboardOrderT[];
  pastOrders?: DashboardOrderT[];
  viewerDetails: {
    name?: string;
  };
  loading?: boolean;
  canBuyVip?: canBuyVipT[];
  orders?: OrderT[];
  currentTab?: number;
  onSelect?: (index: number) => void;
};

export const OrderPage: React.FC<DashboardPagePropsT> = ({
  upcomingOrders,
  pastOrders,
  viewerDetails,
  loading,
  canBuyVip,
  currentTab,
  onSelect,
}) => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <TitleDescr>{capitalize(`Welcome, ${viewerDetails.name?.toLocaleLowerCase()}!`)}</TitleDescr>
          <TitleMedium>{'Manage Orders'}</TitleMedium>
        </TitleWrapper>
        {/* <br /> */}
        {loading ? (
          <PageLogoLoader height="50vh" />
        ) : (
          <Tabs preSelectedTabIndex={currentTab === -1 ? 0 : currentTab} onSelect={onSelect}>
            <Tab title="Upcoming" buttonTab={true}>
              {upcomingOrders?.length ? (
                <>
                  {upcomingOrders?.map((order) => (
                    <Upcoming key={order.ticketureOrderId} order={order} canBuyVip={canBuyVip} />
                  ))}
                  <SurveyLink />
                </>
              ) : (
                <UpcomingZeroPage />
              )}
            </Tab>
            <Tab title="Past" buttonTab={true}>
              {pastOrders?.length ? (
                <>
                  {pastOrders?.map((order) => (
                    <Past key={order.ticketureOrderId} order={order} />
                  ))}
                  <SurveyLink />
                </>
              ) : (
                <PastZeroPage />
              )}
            </Tab>
          </Tabs>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};

type TabsPropsT = {
  order: DashboardOrderT;
  canBuyVip?: canBuyVipT[];
  orderData?: OrderT;
};

const modals = {
  vip: {
    title: 'VIP is sold out.',
    content: 'We are sorry, VIP is sold out for your date of visit. ',
    okText: 'back',
    cancelText: 'back',
  },
  changeDateSuite: {
    title: 'Change Date - Suite',
    content:
      'Changing dates is not available for suites purchases in My Account. Please contact our guest services team for support in rebooking your suites visit.',
    okText: 'back',
    cancelText: 'back',
  },
  changeDate: {
    title: 'Change Date',
    content: 'Changing dates is not available 1 hour or less prior to your Enchant visit.',
    okText: 'back',
    cancelText: 'back',
  },
  cancel: {
    title: 'Cancel Your Visit',
    content:
      'Order cancellation is not available for your order as we only allow cancellations earlier than 24 hours from your event time.',
    okText: 'back',
    cancelText: 'back',
  },
};

const DeactivatedPopover: React.FC<PropsWithChildren> = ({children}) => (
  <Popover title={'This order is not eligible for certain My Account functions.'}>{children}</Popover>
);
const EmptyTag: React.FC<PropsWithChildren> = ({children}) => <>{children}</>;

const Upcoming: React.FC<TabsPropsT> = ({order, canBuyVip}) => {
  const vipAvailable = canBuyVip?.find((el) => el?.orderId === order?.id);
  const vipEnabled = !!vipAvailable && vipAvailable?.canBuy && !!canBuyVip?.length;
  const vipLink = vipEnabled ? route.upgradeVipPage.get({id: order.ticketureOrderId}) : '#';
  const cancelLink = order?.cancelDisabled ? '#' : route.cancellationPage.get({id: order.ticketureOrderId});
  const changeDateLink = order?.dateChangeDisabled
    ? '#'
    : route.changeDate.get({id: order.ticketureOrderId}) + '?step=1';
  const {onManageWaiversClick, onViewTicketClick} = useOrderClickAnalytics(order);
  const [vipModalLvl, setVipModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [cdSuiteModalLvl, setCDSuiteModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [cdModalLvl, setCDModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [cancelModalLvl, setCancelModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const eventMap = (order?.sellerMeta as {event_map?: any})?.event_map || '';
  const guest_info_link = (order?.sellerMeta as {guest_info_link?: any})?.guest_info_link;
  const importantInfo = `https://${guest_info_link}` || 'https://help.enchantchristmas.com/en/';
  const {show} = intercomActions();
  const onSetSuccess = () => {
    setVipModalLvl(ModalLvl.success);
    setCDSuiteModalLvl(ModalLvl.success);
    setCDModalLvl(ModalLvl.success);
    setCancelModalLvl(ModalLvl.success);
  };
  const onSetClose = () => {
    setVipModalLvl(ModalLvl.closed);
    setCDSuiteModalLvl(ModalLvl.closed);
    setCDModalLvl(ModalLvl.closed);
    setCancelModalLvl(ModalLvl.closed);
  };
  const onOpenModal = () => {
    if (!vipAvailable) return;
    if (!vipEnabled) setVipModalLvl(ModalLvl.confirm);
  };

  const openCDModal = () => {
    if (!order?.dateChangeDisabled) return;
    if (order?.isSuite) {
      setCDSuiteModalLvl(ModalLvl.confirm);
      show();
      return;
    }
    setCDModalLvl(ModalLvl.confirm);
  };
  const openCancelModal = () => {
    if (!order?.cancelDisabled) return;
    setCancelModalLvl(ModalLvl.confirm);
  };

  useEffect(() => {
    if (vipModalLvl === ModalLvl.confirm) {
      WarningModal(
        modals.vip.title,
        modals.vip.content,
        modals.vip.okText,
        modals.vip.cancelText,
        onSetSuccess,
        onSetClose,
      );
    }
    if (cdSuiteModalLvl === ModalLvl.confirm) {
      WarningModal(
        modals.changeDateSuite.title,
        modals.changeDateSuite.content,
        modals.changeDateSuite.okText,
        modals.changeDateSuite.cancelText,
        onSetSuccess,
        onSetClose,
      );
    }
    if (cdModalLvl === ModalLvl.confirm) {
      WarningModal(
        modals.changeDate.title,
        modals.changeDate.content,
        modals.changeDate.okText,
        modals.changeDate.cancelText,
        onSetSuccess,
        onSetClose,
      );
    }
    if (cancelModalLvl === ModalLvl.confirm) {
      WarningModal(
        modals.cancel.title,
        modals.cancel.content,
        modals.cancel.okText,
        modals.cancel.cancelText,
        onSetSuccess,
        onSetClose,
      );
    }
  }, [vipModalLvl, cdModalLvl, cancelModalLvl, cdSuiteModalLvl]);
  const browserType = useRecoilState(browserDetector);
  const appleWallet = getAppleWalletURL(order?.ticketureOrderId);
  const googleWallet = getGoogleWalletURL(order?.ticketureOrderId);

  const handleCopyOrderNum = () => {
    navigator.clipboard.writeText(order?.ticketureOrderNumber || '');
  };
  const ButtonWrapper = !order?.hasGA ? DeactivatedPopover : EmptyTag;
  return (
    <>
      {order.isCancel && (
        <TabContent>
          <TabTitleWrapper>
            <TabDetailsWrapper>
              <TitleAdds>
                <Label label={'Cancelled'} type={'error'} icon={true} />
              </TitleAdds>
              <TabTitle>The Great Search</TabTitle>
              <PrimaryDetailCell>
                <BoldDetailText>{order.ticketureOrderNumber}</BoldDetailText>
              </PrimaryDetailCell>
              <PrimaryDetailCell>
                <Text1>
                  {order.sellerVenue} - {order.sellerName}
                </Text1>
              </PrimaryDetailCell>
              <PrimaryDetailCell>
                <Text1>
                  {order.eventDateFormat} &mdash; {order.arrivalDateFormat}
                </Text1>
              </PrimaryDetailCell>
              <PrimaryDetailCell>
                <Text1>{order.refundedAmount} USD (Refunded)</Text1>
              </PrimaryDetailCell>
            </TabDetailsWrapper>
          </TabTitleWrapper>
        </TabContent>
      )}
      {!order.isCancel && (
        <TabContent>
          <TabTitleWrapper>
            <TabDetailsWrapper>
              <BadgeWrapper>
                {order?.weatherProtection && <WeatherProtectionLabel label={'Free date change available'} />}
                {order.isVip && !order.isFreeChangeDate && <VipLabel />}
                {order.isProtection && !order.isFreeChangeDate && <VisitProtectionLabel label={'Visit Protection'} />}
              </BadgeWrapper>
              <TabTitle>Enchant {order.sellerName}</TabTitle>
              <PrimaryDetailCell onClick={handleCopyOrderNum}>
                <BoldDetailText>
                  {order.ticketureOrderNumber}
                  <IconSvg type="copy" viewBox="0 -2 24 24" />
                </BoldDetailText>
              </PrimaryDetailCell>
              {order.isFreeChangeDate && <Label label={'Free Date Change Available'} type={'secondary'} icon={true} />}
              {/* <TabDetails> */}
              <PrimaryDetailCell>
                <Text1>{order.sellerVenue}</Text1>
              </PrimaryDetailCell>
              <PrimaryDetailCell>
                <Text1>
                  {order.eventDateFormat} &mdash; {order.arrivalDateFormat} arrival
                </Text1>
              </PrimaryDetailCell>
              {/* </TabDetails> */}
              {/* <TabDetails> */}
              <PrimaryDetailCell>
                <Text1>${order.totalPayments} USD</Text1>
              </PrimaryDetailCell>
            </TabDetailsWrapper>
            <DashboardButtonsWrapper>
              <DashboardCardButton
                value="View Ticket"
                onClick={onViewTicketClick}
                toLink={order.viewTicketLink}
                Icon={
                  <IconSvg type="ticket" stroke="none" fill="black" width="24px" height="24px" viewBox="0 -2 24 24" />
                }
                blank
              />
              <DashboardCardButton
                value="Important Information"
                toLink={importantInfo}
                Icon={
                  <IconSvg
                    type="examination"
                    stroke="none"
                    fill="black"
                    width="24px"
                    height="24px"
                    viewBox="0 -2 24 24"
                  />
                }
                blank
              />
              <DashboardCardButton value="Event Map" toLink={eventMap} Icon={<IconSvg type="map" />} blank />
              {browserType?.[0].includes('safari') && (
                <DashboardCardButton
                  value="Apple"
                  toLink={appleWallet}
                  walletButton={true}
                  walletType="safari"
                  blank></DashboardCardButton>
              )}
              {browserType?.[0].includes('chrome') && (
                <DashboardCardButton
                  value="Chrome"
                  toLink={googleWallet}
                  walletButton={true}
                  walletType="chrome"
                  blank></DashboardCardButton>
              )}
            </DashboardButtonsWrapper>
            {/* </TabDetails> */}
          </TabTitleWrapper>
          {!order.isFreeChangeDate ? (
            <OptionSection>
              <OptionsWrapper>
                <OptionsTitle>Purchase</OptionsTitle>
                <ButtonWrapper>
                  <LinkButton
                    to={route.purchaseAdmission.get({id: order.ticketureOrderId}) + '?step=1'}
                    disabled={!order?.hasGA}>
                    Purchase Tickets
                  </LinkButton>
                </ButtonWrapper>
                <ButtonWrapper>
                  <LinkButton
                    to={route.addPurchase.get({id: order.ticketureOrderId}) + '?step=1'}
                    disabled={!order?.hasGA}>
                    Purchase Add Ons
                  </LinkButton>
                </ButtonWrapper>

                {!order.isVip && (
                  <ButtonWrapper>
                    <LinkButton to={vipLink} onClick={onOpenModal} disabled={!vipAvailable || !order?.hasGA}>
                      Upgrade to VIP
                    </LinkButton>
                  </ButtonWrapper>
                )}
              </OptionsWrapper>
              <OptionsWrapper>
                <OptionsTitle>Manage</OptionsTitle>
                {order.isIceSkating && (
                  <LinkButtonView onClick={onManageWaiversClick} toLink={order.manageWaiversLink} blank>
                    Manage Waivers
                  </LinkButtonView>
                )}
                <ButtonWrapper>
                  <LinkButton
                    to={changeDateLink}
                    onClick={openCDModal}
                    disabled={order?.dateChangeDisabled || !order?.hasGA}>
                    Change Date
                  </LinkButton>
                </ButtonWrapper>
                {(order.isProtection || order?.allowRefund) && (
                  <ButtonWrapper>
                    <LinkButton to={cancelLink} onClick={openCancelModal} disabled={order?.cancelDisabled}>
                      Cancel Your Visit
                    </LinkButton>
                  </ButtonWrapper>
                )}
              </OptionsWrapper>
            </OptionSection>
          ) : (
            <LinkButtonsContainer>
              <ButtonWrapper>
                <LinkButton
                  to={changeDateLink}
                  onClick={openCDModal}
                  disabled={order?.dateChangeDisabled || !order?.hasGA}>
                  Change Date
                </LinkButton>
              </ButtonWrapper>
              <LinkButtonView toLink={order.viewTicketLink} blank>
                View Ticket
              </LinkButtonView>
            </LinkButtonsContainer>
          )}
        </TabContent>
      )}
    </>
  );
};

const Past: React.FC<TabsPropsT> = ({order}) => {
  const cancelLink =
    order?.allowRefund || order?.weatherProtection ? route.cancellationPage.get({id: order.ticketureOrderId}) : '';
  const changeDateLink = order?.weatherProtection ? route.changeDate.get({id: order.ticketureOrderId}) + '?step=1' : '';
  const {onViewTicketClick} = useOrderClickAnalytics(order);

  const [cdModalLvl, setCDModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [cancelModalLvl, setCancelModalLvl] = useState<ModalLvl>(ModalLvl.closed);

  const onSetSuccess = () => {
    setCDModalLvl(ModalLvl.success);
    setCancelModalLvl(ModalLvl.success);
  };

  const onSetClose = () => {
    setCDModalLvl(ModalLvl.closed);
    setCancelModalLvl(ModalLvl.closed);
  };
  const openCDModal = () => {
    if (!order?.dateChangeDisabled) return;
    setCDModalLvl(ModalLvl.confirm);
  };
  const openCancelModal = () => {
    if (!order?.cancelDisabled) return;
    setCancelModalLvl(ModalLvl.confirm);
  };
  useEffect(() => {
    if (cdModalLvl === ModalLvl.confirm) {
      WarningModal(
        modals.changeDate.title,
        modals.changeDate.content,
        modals.changeDate.okText,
        modals.changeDate.cancelText,
        onSetSuccess,
        onSetClose,
      );
    }
    if (cancelModalLvl === ModalLvl.confirm) {
      WarningModal(
        modals.cancel.title,
        modals.cancel.content,
        modals.cancel.okText,
        modals.cancel.cancelText,
        onSetSuccess,
        onSetClose,
      );
    }
  }, [cdModalLvl, cancelModalLvl]);
  const handleCopyOrderNum = () => {
    navigator.clipboard.writeText(order?.ticketureOrderNumber || '');
  };
  return (
    <>
      {order.isCancel && (
        <TabContent>
          <TabTitleWrapper>
            <TabDetailsWrapper>
              <TitleAdds>
                <Label label={'Cancelled'} type={'error'} icon={true} />
              </TitleAdds>
              <TabTitle>The Great Search</TabTitle>
              <PrimaryDetailCell>
                <Text1>
                  {order.sellerVenue} - {order.sellerName}
                </Text1>
              </PrimaryDetailCell>
              <PrimaryDetailCell onClick={handleCopyOrderNum}>
                <BoldDetailText>
                  {order.ticketureOrderNumber}
                  <IconSvg type="copy" viewBox="0 -2 24 24" />
                </BoldDetailText>
              </PrimaryDetailCell>
              <PrimaryDetailCell>
                <Text1>
                  {order.eventDateFormat} &mdash; {order.arrivalDateFormat}
                </Text1>
              </PrimaryDetailCell>
              <PrimaryDetailCell>
                <Text1>{order.refundedAmount} USD (Refunded)</Text1>
              </PrimaryDetailCell>
            </TabDetailsWrapper>
          </TabTitleWrapper>
        </TabContent>
      )}
      {!order.isCancel && (
        <TabContent>
          <TabTitleWrapper>
            <TabDetailsWrapper>
              <TitleAdds>
                {order.isVip && <VipLabel />}
                {order?.weatherProtection && order?.allowDateChange && (
                  <WeatherProtectionLabel label={'Free date change available'} />
                )}
                {order?.weatherProtection && order?.allowRefund && (
                  <WeatherProtectionLabel label={'Refund available'} />
                )}
                {order.isProtection && <Label label={'Visit Protection'} type={'primary'} icon={true} />}
              </TitleAdds>
              <TabTitle>Enchant {order.sellerName}</TabTitle>
              <PrimaryDetailCell onClick={handleCopyOrderNum}>
                <BoldDetailText>
                  {order.ticketureOrderNumber}
                  <IconSvg type="copy" viewBox="0 -2 24 24" />
                </BoldDetailText>
              </PrimaryDetailCell>
              <PrimaryDetailCell>
                <Text1>{order.sellerVenue}</Text1>
              </PrimaryDetailCell>
              <PrimaryDetailCell>
                <Text1>
                  {order.eventDateFormat} &mdash; {order.arrivalDateFormat}
                </Text1>
              </PrimaryDetailCell>
              <PrimaryDetailCell>
                <Text1>${order.totalPrice} USD</Text1>
              </PrimaryDetailCell>
            </TabDetailsWrapper>
          </TabTitleWrapper>
          <OptionSection>
            <OptionsWrapper>
              <OptionsTitle>Manage</OptionsTitle>
              <LinkButtonView onClick={onViewTicketClick} toLink={order.viewTicketLink} blank>
                View Ticket
              </LinkButtonView>
              {changeDateLink && (
                <LinkButton to={changeDateLink} onClick={openCDModal}>
                  Change Date
                </LinkButton>
              )}
              {cancelLink && (
                <LinkButton to={cancelLink} onClick={openCancelModal}>
                  Cancel Your Visit
                </LinkButton>
              )}
            </OptionsWrapper>
          </OptionSection>
        </TabContent>
      )}
    </>
  );
};

const PastZeroPage = () => {
  return (
    <TabPastZeroContent>
      <TabPastZeroContentWrapper>
        <TitlePastZero>no Past Orders</TitlePastZero>
        <DescriptionPastZero>
          You have no past orders at this time, please go back to the “Upcoming” tab to view all your upcoming orders.
        </DescriptionPastZero>
      </TabPastZeroContentWrapper>
    </TabPastZeroContent>
  );
};

const UpcomingZeroPage = () => {
  return (
    <TabPastZeroContent>
      <TabPastZeroContentWrapper>
        <TitlePastZero>No upcoming orders</TitlePastZero>
        <DescriptionPastZero>
          You have no upcoming orders at this time, please go to the “Past” tab to view all your past orders.
        </DescriptionPastZero>
      </TabPastZeroContentWrapper>
    </TabPastZeroContent>
  );
};

const SurveyLink = () => {
  return (
    <SurveyWrapper href={surveyLink} target="_blank">
      <Text5>We want your feedback! Help us create more magic in My Account.</Text5>
      <BoldText1>Take Survey</BoldText1>
    </SurveyWrapper>
  );
};
