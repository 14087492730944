import React, {useState} from 'react';
import social_share from '../../ui-kit/videos/social_share.mp4';
import ShareThis from '../ShareThis';
import {
  ConfirmOrderTitleDescr,
  ShareTitle,
  SocialSharingWrapper,
  SharingSection,
  ShareButton,
  VideoSection,
  VideoPlayer,
  Video,
} from './styles';
import {capitalize} from '../../helpers/helpers';
import {useViewer} from '../../hooks/auth';
import {REFERRAL_URL} from '../../constants/env';

export const SocialSharing: React.FC = () => {
  const viewer = useViewer();
  const referralLink = `${REFERRAL_URL}${viewer?.referralCode || ''}`;
  const [shareTo, setShareTo] = useState(false);
  // Handle Share
  const handleShare = () => {
    setShareTo(!shareTo);
  };
  return (
    <SocialSharingWrapper>
      <VideoSection>
        <VideoPlayer>
          <Video autoPlay loop muted>
            <source src={social_share} type="video/mp4" />
          </Video>
        </VideoPlayer>
      </VideoSection>
      <SharingSection>
        {
          <>
            <ShareTitle>{capitalize('Encourage your friends to')}</ShareTitle>
            <ShareTitle>{capitalize('join you at Enchant!')}</ShareTitle>
            <ConfirmOrderTitleDescr>Share with your friends & family!</ConfirmOrderTitleDescr>
          </>
        }
        <div className="icon-section">
          {shareTo ? <ShareThis dataUrl={referralLink} /> : <ShareButton onClick={handleShare}>SHARE</ShareButton>}
        </div>
      </SharingSection>
    </SocialSharingWrapper>
  );
};
