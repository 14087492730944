import {commonOrderInfo} from './../states/common';
import {useEffect, useState, useMemo} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
// import {addonSession} from '../states/session';
import {purchaseState, purchaseAddOneNavFooterState, purchaseError, creditsAppliedState} from '../states/purchase';
import {ButtonType} from '../ui-kit/Button/types';
import {useGetOrderWithDetails} from './orders';
import {checkoutState, confirmPaymentInfo} from '../states/order';

import {
  formatHiddenSessions,
  formatPurchaseError,
  GAtimeT,
  getExitingOrderInfoWithAddons,
  getTZdate,
  isGACat,
} from '../helpers/orders';
import {EventSessionT, EventsT, EventTemplateT, TicketTypeT} from '../types/events';
import {setPurchaseAddOns} from '../queries/orderGroup';
import {
  calcAddonsState,
  checkAllDatesAdded,
  createPurchaseOrderInfo,
  filterAddOns,
  getPossibleTimes,
  getPurchaseTicketsIds,
  toCreatePurchaseTicket,
  useGetAvailableAddons,
} from '../helpers/purchaseAddOns';
import {useGetAvailableEvents} from './events';
import {
  getAddonsGroupConnection,
  useGetAddons,
  useGetAddonsGroup,
  useGetTicketTypesFromGroups,
} from '../helpers/purchaseAddOns';
import {addonsChangeStateT, calcAddonsStateResT, PurchaseResT} from '../types/purchase';
import {route} from '../constants/routes';
import {checkoutCart, createCartQuery} from '../queries/payment';
import {CartResT, OrderT} from '../types/orders';
import {AvailableEventSessionsAndPriceItemT} from '../queries/types/changeDate';
import {getEventSessionsForOneDate} from '../queries/eventsGroup';
import {onRequestErrorT, useOnRequestsError, useSegmentAddon} from './customerIO';
import {formats, scrollUp} from '../helpers/helpers';
import {checkoutCartReq} from '../queries/types/payment';
import {CartQueryList, OrdersQueryList} from '../constants/api';
import {useViewer} from './auth';

export const usePurchaseState = (calcData: calcAddonsStateResT, purchaseRes?: PurchaseResT) => {
  const [purchase, setPurchaseState] = useRecoilState(purchaseState);
  const setProgress = (val: number) => {
    setPurchaseState((prev) => ({...prev, progress: val}));
  };
  const setPayment = (val: number) => {
    setPurchaseState((prev) => ({...prev, payment: val}));
  };
  const setTotalPrice = (val: number) => {
    setPurchaseState((prev) => ({...prev, totalPrice: val}));
  };
  const setOrderNumber = (val: string | undefined) => {
    setPurchaseState((prev) => ({...prev, orderNumber: val}));
  };
  const setIsConfirm = (val: boolean) => {
    setPurchaseState((prev) => ({...prev, isConfirm: val}));
  };
  const subTotal = purchaseRes?.cart_fees?._data?.[0]?.grand_totals?.total_outstanding;
  useEffect(() => {
    const selected = calcData.prices.filter((el) => !!el?.price);
    if (selected.length === 0) {
      setProgress(15);
    }
    if (selected.length === 1) {
      setProgress(35);
    }
    if (selected.length > 1) {
      setProgress(65);
    }
  }, [calcData.total, subTotal]);

  useEffect(() => {
    setTotalPrice(Number(calcData?.total || 0));
    if (calcData.total) setPayment(Number(calcData.total || 0));
    if (subTotal) setPayment(Number(subTotal || 0));
  }, [calcData.total, subTotal]);

  return {purchase, setProgress, setOrderNumber, setIsConfirm};
};

export const usePurchaseNav = (
  calcData: calcAddonsStateResT,
  onPurchase: () => Promise<boolean>,
  steps: purchaseStepsStateT,
  redirect: () => void,
  ticketsToPurchase: string[],
  cartCheckout: (source?: string, creditsRedeemed?: boolean) => Promise<boolean>,
  cartId?: string,
  stripeAccountId?: string,
  allDatesAdded?: boolean,
  addonsTime?: addonsIdsWithDates,
) => {
  const setCheckout = useSetRecoilState(checkoutState);
  const [footerNav, setFooterNav] = useRecoilState(purchaseAddOneNavFooterState);
  const creditsApplied = useRecoilValue(creditsAppliedState);
  const setShowButton = (val: boolean) => {
    setFooterNav((prev) => ({...prev, showButton: val}));
  };
  const resetPaymentFunc = () => {
    setFooterNav((prev) => ({...prev, onClickPayment: undefined}));
  };
  const setButtonTitle = (val: string) => {
    setFooterNav((prev) => ({...prev, buttonTitle: val}));
  };
  const setButtonType = (val: ButtonType) => {
    setFooterNav((prev) => ({...prev, buttonType: val}));
  };
  const setConditionButton = (val: boolean) => {
    setFooterNav((prev) => ({...prev, conditionButton: val}));
  };
  const setOnClickNext = (func: () => void) => {
    setFooterNav((prev) => ({...prev, onClick: func}));
  };
  const setCartId = (val?: string) => {
    setFooterNav((prev) => ({...prev, cartId: val}));
  };
  const setStripeAccountId = (val: string) => {
    setFooterNav((prev) => ({...prev, stripeAccountId: val}));
  };

  const step2Click = async () => {
    steps.setStep2();
    window.scrollTo(0, 0);
  };
  const step3Click = async () => {
    const success = await onPurchase();
    console.log('success:', success);
    if (success) {
      steps.setStep3();
      setButtonTitle('Confirm Purchase');
    }
  };
  const step4Click = async (source?: string, creditsRedeemed?: boolean) => {
    const success = await cartCheckout(source, creditsRedeemed);
    success && redirect();
  };

  useEffect(() => {
    const selected = calcData.prices.filter((el) => !!el?.price);
    if (selected.length === 0) {
      if (steps.step !== '1') steps.setStep1();
      setConditionButton(false);
      setButtonType('tertiary');
      setButtonTitle('Next');
      setShowButton(false);
    }
    if (selected.length >= 1) {
      setConditionButton(false);
      setButtonType('rounded');
      setButtonTitle('Next');
      setShowButton(true);
    }
  }, [calcData.total, calcData.prices?.length]);

  useEffect(() => {
    if (steps.step === '2') {
      if (allDatesAdded) {
        setConditionButton(false);
        return;
      }
      setConditionButton(true);
    }
  }, [allDatesAdded, steps.step]);

  useEffect(() => {
    if (steps.step === '1') {
      setOnClickNext(step2Click);
      resetPaymentFunc();
      return;
    }
    if (steps.step === '2') {
      setButtonTitle('Next');
      setOnClickNext(step3Click);
      setCartId(undefined);
      setStripeAccountId('');
      setCheckout(undefined);
      resetPaymentFunc();
      return;
    }
    if (steps.step === '3') setOnClickNext(step4Click);
    setCartId(cartId);
    if (stripeAccountId) setStripeAccountId(stripeAccountId);
  }, [steps.step, ticketsToPurchase?.length, cartId, stripeAccountId, addonsTime?.length, creditsApplied]);

  return {footerNav, setButtonTitle, setButtonType, setConditionButton, setOnClickNext};
};

export type purchaseAddonsValuesT = Record<string, Record<string, number>>;
export type addonsInfoValuesT = Record<string, TicketTypeT[]>;
export type addonsIdsWithDates = {eventTempId?: string; sessionId?: string}[];
export const usePurchaseAddons = (events?: EventsT, addons?: EventTemplateT[]) => {
  const [addonsState, setAddonsState] = useState<purchaseAddonsValuesT>({});
  const [addonsInfo, setAddonsInfo] = useState<addonsInfoValuesT>({});
  const [addonsTime, setAddonsTime] = useState<addonsIdsWithDates>([]);

  const addonsGroups = useGetAddonsGroup(events);
  const addonTicketTypes = useGetTicketTypesFromGroups(events, addonsGroups);

  const AGconnection = getAddonsGroupConnection(addonsGroups, addons);

  useEffect(() => {
    const newObjState: purchaseAddonsValuesT = {};
    addonTicketTypes?.forEach((el) => {
      const isParking = el?.name.includes('Parking');
      const parkingGroups = AGconnection?.filter((el) => el?.addonName?.includes('Parking'));
      let addonId;
      if (isParking) {
        addonId = parkingGroups?.find((pg) => pg?.addonName?.includes(el?.name))?.addonId;
      } else {
        addonId = AGconnection?.find((ag) => ag?.groupId === el?.ticket_group_id)?.addonId;
      }

      if (addonId && el?.id) newObjState[addonId] = {...newObjState?.[addonId], [el.id]: 0};
    });

    const newObjInfo: addonsInfoValuesT = {};
    addonTicketTypes?.forEach((el) => {
      const isParking = el?.name.includes('Parking');
      const parkingGroups = AGconnection?.filter((el) => el?.addonName?.includes('Parking'));
      let addonId;
      if (isParking) {
        addonId = parkingGroups?.find((pg) => pg?.addonName?.includes(el?.name))?.addonId;
      } else {
        addonId = AGconnection?.find((ag) => ag?.groupId === el?.ticket_group_id)?.addonId;
      }

      if (addonId && el?.id) {
        const arr = newObjInfo?.[addonId] ? newObjInfo?.[addonId] : [];
        arr.push(el);
        newObjInfo[addonId] = arr;
      }
    });

    setAddonsState(newObjState);
    setAddonsInfo(newObjInfo);
  }, [addonTicketTypes?.length]);

  const addAddonSession = (eventTempId?: string, sessionId?: string) =>
    setAddonsTime((prev) => [...prev.filter((el) => el?.eventTempId !== eventTempId), {eventTempId, sessionId}]);
  const changeState: addonsChangeStateT = (parent: string) => (value?: number, name?: string) => {
    name &&
      (value || value === 0) &&
      setAddonsState((prev) => ({...prev, [parent]: {...prev?.[parent], [name]: value}}));
  };
  const calcData = calcAddonsState(addonsState, addonTicketTypes);
  const ticketsToPurchase = getPurchaseTicketsIds(addonsState);
  const allDatesAdded = checkAllDatesAdded(addonsState, addonsTime);
  const resetSelectedTime = () => setAddonsTime([]);
  return {
    addonsState,
    AGconnection,
    addonsInfo,
    changeState,
    calcData,
    addonsGroups,
    addonTicketTypes,
    ticketsToPurchase,
    addAddonSession,
    addonsTime,
    allDatesAdded,
    resetSelectedTime,
  };
};

export const usePurchase = (steps: purchaseStepsStateT) => {
  // const getAddonSession = useRecoilState(addonSession);
  const navigate = useNavigate();
  const viewer = useViewer();
  const {id} = useParams();
  const setOrderInfo = useSetRecoilState(commonOrderInfo);
  const [purchaseRes, setPurchaseRes] = useState<PurchaseResT>();
  const creditsApplied = useRecoilValue(creditsAppliedState);
  const {order, loading: orderLoading} = useGetOrderWithDetails(id);
  const {onAddonComplete, onAddonReview, toAddonPageClick} = useSegmentAddon(order);
  const {events, loading: eventsLoading} = useGetAvailableEvents(order?.sellerId);
  const addonsNonFiltered = useGetAddons(events);
  const addons = filterAddOns(addonsNonFiltered, order);
  const {onRequestError} = useOnRequestsError({order});
  const {
    addonsState,
    addonsInfo,
    changeState,
    calcData,
    addonsGroups,
    ticketsToPurchase,
    addAddonSession,
    addonsTime,
    allDatesAdded,
    resetSelectedTime,
  } = usePurchaseAddons(events, addons);

  const {ticketsAvSessions, avLoading} = getAddonsSoldOutInfo(addonsState, addonsInfo, order, events);
  const {purchase, setIsConfirm, setOrderNumber, setProgress} = usePurchaseState(calcData, purchaseRes);
  const availableAddons = useGetAvailableAddons(order);
  const {info, parkingItems} = getExitingOrderInfoWithAddons(
    addonsState,
    addonsInfo,
    addonsGroups,
    order,
    purchaseRes,
    events,
  );
  //purchase
  const redirect = () => {
    navigate(route.confirmOrderPurchaseOnePage.get({id}));
  };
  const onSuccessPurchase = (result: any) => {
    setPurchaseRes({...result, created: new Date()});
    onAddonReview(result);
  };
  const checkoutSuccess = () => onAddonComplete(purchaseRes);
  const {handlePurchase, error} = usePurchaseAddOns(
    order?.ticketureOrderId,
    addonsTime,
    addonsState,
    onSuccessPurchase,
    onRequestError,
  );
  const onPurchase = async () => await handlePurchase(ticketsToPurchase);

  const cartId = purchaseRes?.cart?._data?.[0]?.id || '';
  const identityId = purchaseRes?.cart?._data?.[0]?.identity_id || '';
  const stripe_fees = purchaseRes?.cart_fees?._data?.find((el) => el.gateway_id === 'stripe');
  const stripeAccountId = stripe_fees?.account_id;
  const {cartPurchase} = useCartPurchase(
    cartId,
    identityId,
    order?.sellerId,
    checkoutSuccess,
    onRequestError,
    false,
    creditsApplied,
  );
  //footernav
  const {footerNav} = usePurchaseNav(
    calcData,
    onPurchase,
    steps,
    redirect,
    ticketsToPurchase,
    cartPurchase,
    cartId,
    stripeAccountId,
    allDatesAdded,
    addonsTime,
  );
  const orderDetailsInfo = steps.step === '3' ? createPurchaseOrderInfo(order, events, purchaseRes, viewer) : undefined;
  // const orderDetailsInfo =
  //   steps.step === '3' ? createPurchaseOrderInfo(order, events, purchaseRes, viewer, getAddonSession) : undefined;
  useEffect(() => {
    setProgress(15);
  }, []);

  useEffect(() => {
    setOrderNumber(order?.ticketureOrderNumber);
  }, [order]);

  useEffect(() => {
    order?.id && toAddonPageClick();
    if (order?.id)
      setOrderInfo({orderId: order?.id, orderNumber: order?.ticketureOrderNumber, sellerName: order?.sellerName});
  }, [order?.id]);

  useEffect(() => {
    //reset selected times card on goBack action
    if (steps.step === '2') resetSelectedTime();
  }, [steps.step]);
  const hiddenSesions = useMemo(() => formatHiddenSessions(events), [!!events]);

  return {
    setProgress,
    purchase,
    footerNav,
    order,
    setIsConfirm,
    orderItems: info,
    paymentInfo: {
      payment: purchase?.totalPrice,
    },
    addons,
    orderDetailsInfo,
    addonsState,
    addonsInfo,
    changeState,
    onPurchase,
    availableAddons,
    orderLoading,
    eventsLoading,
    orderCreatedAt: purchaseRes?.created,
    purchaseError: error,
    parkingItems,
    addAddonSession,
    addonsTime,
    ticketsAvSessions,
    avLoading,
    hiddenSesions,
    gateway_audit: purchaseRes,
  };
};

export const usePurchaseAddOns = (
  orderId?: string,
  addonsTime?: addonsIdsWithDates,
  addonsState?: purchaseAddonsValuesT,
  onSuccess?: (value: any) => void,
  onRequestError?: onRequestErrorT,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const handlePurchase = async (ticketTypeIds?: string[]) => {
    if (!orderId || !ticketTypeIds?.length || !addonsTime?.length || !addonsState) return false;
    setLoading(true);
    const tickets = ticketTypeIds
      ?.map((id) => toCreatePurchaseTicket(addonsTime, addonsState, id))
      .filter((el) => !!el?.ticket_type_id && el !== null);
    if (!tickets.length) return false;
    const body = {tickets};
    try {
      const res = await setPurchaseAddOns({orderId, body});
      setLoading(false);
      scrollUp();
      res?.body && onSuccess?.(res?.body);
      return true;
    } catch (e) {
      onRequestError?.(JSON.stringify(e), OrdersQueryList.purchaseAddOns(orderId), body);
      setError(
        'Sorry! Something unexpected has happened on our end. Our engineers have been notified. Please try again later.',
      );
      setLoading(false);
      return false;
    }
  };
  return {handlePurchase, loading, error};
};

export const usePurchaseCart = () => {
  const [purchase, setPurchaseState] = useRecoilState(purchaseState);
  const setCart = (cart: CartResT) => {
    setPurchaseState((prev) => ({...prev, cart: cart}));
  };
  let fetching = false;
  const createCart = async () => {
    if (fetching) return;
    try {
      fetching = true;
      const res = await createCartQuery();
      if (res?.body) setCart(res?.body as CartResT);
      if (!res) fetching = false;
    } catch (e) {
      fetching = false;
    }
  };

  useEffect(() => {
    if (!purchase?.cart?.id) createCart();
  }, [purchase?.cart?.id]);
};

export type purchaseStepsStateT = {
  step: string | null;
  setStep1: () => void;
  setStep2: () => void;
  setStep3: () => void;
};

export const usePurchaseSteps = (): purchaseStepsStateT => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setCreditsApplied = useSetRecoilState(creditsAppliedState);
  const step = searchParams.get('step');
  const setStep1 = () => {
    searchParams.set('step', '1');
    setSearchParams(searchParams);
  };
  const setStep2 = () => {
    searchParams.set('step', '2');
    setCreditsApplied(false);
    setSearchParams(searchParams);
  };
  const setStep3 = () => {
    searchParams.set('step', '3');
    setSearchParams(searchParams);
  };

  return {step, setStep1, setStep2, setStep3};
};

export const useCartPurchase = (
  cartId?: string,
  identityId?: string,
  sellerId?: string,
  onSuccess?: () => void,
  onRequestError?: onRequestErrorT,
  useIntercom?: boolean,
  creditsApplied?: boolean,
) => {
  const [, setTicketContext] = useRecoilState(confirmPaymentInfo);
  const setError = useSetRecoilState(purchaseError);
  const cartPurchase = async (source?: string, creditsRedeemed = creditsApplied) => {
    setError('');
    if (!source || !cartId) return false;
    const body: checkoutCartReq['body'] =
      source === 'skip'
        ? {gateway_data: {}, gateway_id: 'free', guest_identity_id: identityId, send_email: true}
        : {
            gateway_data: {
              source: source,
            },
          };
    if (creditsRedeemed) {
      body.apply_credits = true;
    }
    try {
      await checkoutCart({cartId, body}).then((res) => {
        setTicketContext(res?.body?.ticketureOrderContext);
        // dispatch(setGatewayAudit(res?.body?.ticketureOrderContext));
      });
      // useEffect(() => {
      //   const fetchCheckoutCart = async () => {
      //     try {
      //       const res = await checkoutCart({cartId, body});
      //       const paymentInfo = res?.body?.ticketureOrderContext?.payments;
      //       setPayments(paymentInfo);
      //     } catch (error) {
      //       formatPurchaseError(error, setError);
      //     }
      //   };

      //   fetchCheckoutCart();
      // }, []);
      await onSuccess?.();
      scrollUp();
      return true;
    } catch (e) {
      if (useIntercom) onRequestError?.(JSON.stringify(e), CartQueryList.checkoutCart(cartId), body);
      console.log('card error:', e);
      formatPurchaseError(e, setError);
      return false;
    }
  };
  return {cartPurchase};
};

export const useGetAddonTime = (
  skip?: boolean,
  tickets?: Record<string, number>,
  item?: Partial<EventTemplateT>,
  gaTime?: GAtimeT,
  isParking?: boolean,
) => {
  const [times, setTimes] = useState<EventSessionT[]>();
  const [fetching, setFetching] = useState(false);
  const templateId = item?.id;
  const req_tickets: AvailableEventSessionsAndPriceItemT[] = tickets
    ? Object.keys(tickets)?.map((el) => ({ticket_type_id: el, quantity: tickets[el]}))
    : [];

  const fs = () => setFetching(true);
  const fe = () => setFetching(false);
  const startDate = gaTime?.start
    ? getTZdate(gaTime?.start, {sellerTimezone: gaTime?.timeZone}).format(formats.onDate)
    : '';
  const fetch = async () => {
    if (skip || fetching) {
      return false;
    }
    fs();
    try {
      if (!templateId || !req_tickets || !startDate) {
        fe();
        return false;
      }
      const res1 = await getEventSessionsForOneDate(templateId, startDate);
      const formated = getPossibleTimes(gaTime, res1?.body?.event_session?._data, isParking);
      setTimes(formated);
      fe();
      return true;
    } catch (e) {
      fe();
      return false;
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  return {times, loading: fetching};
};

export type ticketsAvSessionsT = Record<string, EventSessionT[]>;
export const getAddonsSoldOutInfo = (
  addonsState: purchaseAddonsValuesT,
  addonsInfo: addonsInfoValuesT,
  order?: OrderT,
  events?: EventsT,
) => {
  const [ticketsAvSessions, setTicketsAvSessions] = useState<ticketsAvSessionsT>();
  const [loading, setLoading] = useState<boolean>(true);
  const ready = !!Object.values(addonsState).length && !!Object.values(addonsInfo).length;
  const tasLength = Object.values(ticketsAvSessions || {}).length;
  const ticketTypes = events?.ticket_type?._data;
  const groups = events?.ticket_group?._data;
  // const eventTemplates = events?.event_template?._data;

  useEffect(() => {
    if (order && events && ready && !ticketsAvSessions) {
      const ticketTemplatesMap: Record<string, any> = {};
      const gaTicket = order?.tickets?.find((el) => isGACat(el?.ticketGroupName));
      const onDate = getTZdate(gaTicket?.eventStartDate, order).format(formats.onDate);
      Object.keys(addonsState).forEach((key_1) => {
        Object.keys(addonsState[key_1]).forEach((key) => {
          const tt = ticketTypes?.find((el) => el?.id === key);
          const group = groups?.find((el) => el?.id === tt?.ticket_group_id);
          ticketTemplatesMap[key] = {
            groupId: tt?.ticket_group_id,
            name: tt?.name,
            eventTemplateId: group?.event_template_id,
          };
        });
      });
      Object.keys(ticketTemplatesMap).forEach(async (key) => {
        if (!ticketTemplatesMap[key]?.eventTemplateId) return;
        setLoading(true);
        const res = await getEventSessionsForOneDate(ticketTemplatesMap[key].eventTemplateId, onDate);
        setTicketsAvSessions((prev) => {
          return {...(prev || {}), [key]: res?.body?.event_session?._data};
        });
      });
    }
  }, [!!order, !!events, ready]);

  useEffect(() => {
    if (!ready) return;
    let tt_count = 0;
    Object.keys(addonsState).forEach((k) => Object.keys(addonsState[k]).forEach(() => tt_count++));
    if (tt_count === tasLength) setLoading(false);
  }, [tasLength, ready]);
  return {ticketsAvSessions, avLoading: loading};
};
