import styled from 'styled-components';
import {Logo} from '../../ui-kit/Logos/Logo';
import {Heading1} from '../../ui-kit/Typography';

export const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 336px;
`;

export const LogoStyled = styled(Logo).attrs({variant: 'silver'})`
  width: 150px;
  height: 35px;
  margin-bottom: ${({theme}) => theme.spacer._3};
`;

export const Title = styled(Heading1)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  text-transform: capitalize;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
