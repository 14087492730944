import React from 'react';
import {BoldTextBody1, TextSmallBold} from '../../../../Typography/styles';
import {OrderDetailsProps, ItemsArray} from '../../types';
import {
  StyledWrapper,
  StyledDetails,
  StyledText,
  StyledGeneralInfo,
  VisitLabel,
  StyledCard,
  WrapperDetail,
} from '../../styles';
import {Label} from '../../../../Label';
import {Separator} from '../../../../Separators';
export const OrderDetailsHeader: React.FC<OrderDetailsProps> = ({data, paymentForm}) => {
  const ticketTypeInfo = (str: string | undefined, type: string) => {
    if (str === undefined) {
      return '';
    }

    const ticketRegex = /^([\w\s]+) (\d{1,2}:\d{2} - \d{1,2}:\d{2} [ap]m)$/;
    const matches = str.match(ticketRegex);

    if (matches) {
      let ticketName = matches[1].trim();
      const ticketTime = matches[2].trim();
      if (type == 'ticket') {
        ticketName = ticketName.includes('General')
          ? 'Event Arrival:'
          : ticketName.includes('Parking')
          ? 'Parking Arrival:'
          : ticketName.includes('Skating')
          ? 'Ice Skating Check-in:'
          : '';
      }
      return type === 'ticket' ? ticketName : ticketTime;
    } else {
      return '';
    }
  };
  const items: ItemsArray = (paymentForm ? paymentForm.items : data.items || []) as ItemsArray;
  // const thePaymentForm = paymentForm ? paymentForm : data;
  // 'need to add ice skating time and parking time here'
  return (
    <WrapperDetail>
      {data.isProtection && (
        <VisitLabel>
          <Label label={'Visit Protection Applied'} type={'primary'} icon={true} />{' '}
        </VisitLabel>
      )}
      <StyledCard>
        <StyledWrapper>
          <BoldTextBody1>Order Details</BoldTextBody1>
          <a href="/">
            <TextSmallBold>Clear Cart</TextSmallBold>
          </a>
        </StyledWrapper>
        <Separator
          vertical={false}
          style={{
            margin: '8px 0',
            borderTop: '0.5px solid #000000',
            height: 0,
            maxWidth: 'none',
          }}
        />
        {data?.orderNumber && (
          <StyledDetails>
            <StyledText>Order Number:</StyledText>
            <StyledGeneralInfo>#{data.orderNumber}</StyledGeneralInfo>
          </StyledDetails>
        )}
        {data?.orderedBy && (
          <StyledDetails>
            <StyledText>Ordered By:</StyledText>
            <StyledGeneralInfo>{data.orderedBy}</StyledGeneralInfo>
          </StyledDetails>
        )}
        {data?.discountName && (
          <StyledDetails>
            <StyledText>Discount Applied:</StyledText>
            <StyledGeneralInfo>{data.discountName}</StyledGeneralInfo>
          </StyledDetails>
        )}
        <StyledDetails>
          <StyledText>Location:</StyledText>
          <StyledGeneralInfo>{data.location}</StyledGeneralInfo>
        </StyledDetails>
        <StyledDetails>
          <StyledText>Event Date:</StyledText>
          <StyledGeneralInfo>{data.eventDate ? data.eventDate : data.arrival}</StyledGeneralInfo>
        </StyledDetails>
        {data.eventArrival && (
          <StyledDetails>
            <StyledText>Event Arrival:</StyledText>
            <StyledGeneralInfo>{data.eventArrival}</StyledGeneralInfo>
          </StyledDetails>
        )}
        {items?.map((item) => (
          <StyledDetails key={item?.ticketType}>
            <StyledText>{ticketTypeInfo(item.ticketType, 'ticket')}</StyledText>
            <StyledGeneralInfo>{ticketTypeInfo(item.ticketType, '')}</StyledGeneralInfo>
          </StyledDetails>
        ))}
        {/* <LabelWrapper>
        <Label label={'Free Date Change Applicable'} type={'secondary'} icon={true} />{' '}
      </LabelWrapper> */}
      </StyledCard>
    </WrapperDetail>
  );
};
