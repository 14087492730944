import {atom} from 'recoil';
import {EventsT} from '../types/events';
import {OrderT} from '../types/orders';

export type PreloadedAvailableEventsT = Record<string, EventsT>;
export type PreloadedOrdersT = OrderT[];
export type PreloadLoadingT = {events?: boolean; orders?: boolean; detailedOrders?: boolean};

export const preloadedAvailableEvents = atom<PreloadedAvailableEventsT | undefined>({
  key: 'preloadedAvailableEvents',
  default: undefined,
});

export const preloadedOrders = atom<PreloadedOrdersT | undefined>({
  key: 'preloadedOrders',
  default: undefined,
});

export const preloadLoading = atom<PreloadLoadingT | undefined>({
  key: 'preloadLoading',
  default: undefined,
});
