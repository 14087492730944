import styled from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {Loader} from '../../ui-kit/Loader';

export const PageWrapper = styled.div`
  width: 100%;
  background-color: transparent;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  min-height: 100vh;
  ${Media.down.l} {
    flex-direction: column;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
`;

export const BigLoader = styled(Loader)`
  &.ant-spin {
    color: ${({theme}) => theme.palette.system.white};
  }
  .ant-spin-dot {
    font-size: 72px;
  }
`;
