import styled from 'styled-components';
import {Logo} from '../../ui-kit/Logos/Logo';
import {Media} from '../../ui-kit/theme/breakpoints';
import {Heading1, Text1, Text3} from '../../ui-kit/Typography';
import {PublicButton} from '../../ui-kit/Button';

export const ConfirmOrderWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: 88px;
  // max-width: 681px;
  min-height: 100vh;
  padding-bottom: 40px;
  display: flex;
  gap: 112px;
  justify-content: center;
  ${Media.down.m} {
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 86px;
  }
`;

export const SocialSharingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  margin: 4px 0 40px 0;
  color: #ffffff;
  font-family: 'Proxima Nova';
`;

export const SocialSharingDesktop = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;

export const SocialSharingMobile = styled.div`
  display: flex;
  @media (min-width: 767px) {
    display: none;
  }
`;

export const VideoSection = styled.div`
  // position: relative;
  // width: 357px;
  // height: 357px;
  // overflow: hidden;
  // border-radius: 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 63px;
`;

export const VideoPlayer = styled.div`
  position: relative;
  width: 357px;
  height: 306px;
  border-radius: 33px;
  overflow: hidden;
  @media (max-width: 767px) {
    padding-bottom: 0;
    height: 300px;
  }
`;

export const Video = styled.video`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  // height: 100%;
  object-fit: cover;
`;

export const SharingSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  color: #ffffff;
  gap: 8px;

  // @media (min-width: 1001px) {
  //   align-items: flex-start;
  //   text-align: left;
  // }

  .icon-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    // @media (min-width: 1001px) {
    //   justify-content: flex-start;
    // }

    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const ShareButton = styled(PublicButton)`
  width: 180px;
  background-color: transparent;
  padding: 12px 24px;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border: none;
  }
`;

export const LogoSilver = styled(Logo).attrs({variant: 'silver'})`
  width: 150px;
  height: 35px;
  ${Media.down.m} {
    margin: 0 auto;
  }
`;

export const ConfirmOrderTitle = styled(Heading1)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  text-transform: capitalize;
  max-width: 500px;
  margin-bottom: ${({theme}) => theme.spacer._1};

  ${Media.down.m} {
    text-align: center;
    max-width: unset;
  }
`;

export const ShareTitle = styled(Heading1)`
  color: ${({theme}) => theme.palette.system.white};
`;

export const ConfirmOrderTitleDescr = styled(Text3)`
  color: ${({theme}) => theme.palette.system.white};
  display: block;
  margin-bottom: ${({theme}) => theme.spacer._9};

  ${Media.down.m} {
    text-align: center;
    margin-bottom: ${({theme}) => theme.spacer._5};
  }
  .anticon {
    font-size: 14px;
    margin-right: 6px;
  }
  cursor: pointer;
`;

export const AssignCardWrapper = styled.div`
  margin-bottom: ${({theme}) => theme.spacer._9};
`;

export const DetailCardWrapper = styled.div`
  margin-bottom: ${({theme}) => theme.spacer._3};
`;

export const DetailedCardFootNote = styled(Text1)`
  color: ${({theme}) => theme.palette.system.white};
`;
