import React, {useEffect} from 'react';
import {ReferralsWrapper} from '../../components/Referrals/ReferralsWrapper';
import {useViewer} from '../../hooks/auth';
import {Tab, Tabs} from '../../ui-kit/Tabs';
import {Credits} from '../../components/Referrals/Credits';
import {Perks} from '../../components/Referrals/Perks';
//import {Activity} from '../../components/Referrals/Activity';
import {useReferrals} from '../../hooks/referral';
import {useGetCurrentTab} from '../../hooks/helpers';
import {useReferralShareAnalytics, useUserAccountAnalytics} from '../../hooks/customerIO';
import {useGetOrders} from '../../hooks/orders';

const tabs = ['myCredits', 'perks', 'myActivity'];

export const Referrals = () => {
  const viewer = useViewer();
  const data = useReferrals();
  const {orders} = useGetOrders();
  const firstOrder = orders?.filter((el) => !el?.orderCancelled)?.[0];

  const {currentTab, onSelect} = useGetCurrentTab(tabs);
  const {onReferralsVisited} = useUserAccountAnalytics();
  const {onShareClick} = useReferralShareAnalytics(firstOrder, data.referrals);

  useEffect(() => {
    if (!data?.generated) return;
    onReferralsVisited(data?.generated);
  }, [data?.generated]);

  return (
    <ReferralsWrapper viewer={viewer}>
      <Tabs onSelect={onSelect} preSelectedTabIndex={currentTab === -1 ? 0 : currentTab}>
        <Tab title="My Credits" key="credits" buttonTab={true}>
          <Credits
            referral={data.generated}
            onShareClick={onShareClick}
            referrals={data.referrals}
            loading={data?.loading1 || data?.loading2}
            orderDetail={firstOrder}
          />
        </Tab>
        <Tab title="Perks" key="perks" buttonTab={true}>
          <Perks />
        </Tab>
        {/* <Tab title="Activity" key="activity" buttonTab={true}>
          <Activity />
        </Tab> */}
      </Tabs>
    </ReferralsWrapper>
  );
};
