import React, {useState, useEffect} from 'react';
import moment from 'moment-timezone';
import {ExclamationCircleFilled} from '@ant-design/icons';
import {toUpper} from '../../../helpers/helpers';
import {
  StyledTimeCardWrapper,
  StyledTimeCardBoard,
  StyledTimeCard,
  StyledWpapperDescription,
  StyledTimeCardWithDescription,
  StyledTimeCardNotTransparent,
  StyledDescriptionTimeCard,
  StyledSoldTicket,
  StylesRed,
  StylesTextRed,
  TimeContain,
  DiffPrice,
  CardTime,
  NightOwlTag,
  NightOwlTagHolder,
  HeaderText,
  PawHeader,
} from './styles';
import {TimeCardPropsT, TypeVariant} from './types';
import {StyledElfAvailable, StyledTextTimes} from '../../Typography/styles';
import {IconSvg} from '../../Icon';

const CardFromTime: React.FC<TimeCardPropsT> = ({
  data,
  onClick,
  isActive,
  elfLabel,
  isMuted,
  nightOwl,
  isPaws,
  priceDiffInfo,
  sellerTimezone,
}) => {
  const [isPriceDiff, setIsPriceDiff] = useState(false);
  const Click = () => {
    if (isMuted) return;
    onClick(data.id);
  };
  if (isMuted) return null;
  useEffect(() => {
    if (priceDiffInfo?.diffPrices && data) {
      const highPriceIndex = priceDiffInfo?.diffPrices.map((price: any) => price?.currency_amount);
      const maxIndex = highPriceIndex.reduce((maxIndex: any, currentPrice: any, currentIndex: number, array: any) => {
        const currentAmount = parseFloat(currentPrice.currency_amount);
        const maxAmount = parseFloat(array[maxIndex].currency_amount);
        return currentAmount > maxAmount ? currentIndex : maxIndex;
      }, 0);
      const filteredSessionWithPrice = priceDiffInfo?.diffPrices[maxIndex];
      const startTime = parseInt(moment.tz(filteredSessionWithPrice?.from, sellerTimezone || '').format('hmm'), 10);
      const endTime = parseInt(moment.tz(filteredSessionWithPrice?.to, sellerTimezone || '').format('hmm'), 10);
      const sessionStartTime = parseInt(moment.tz(data?.start, sellerTimezone || '').format('hmm'), 10);
      const sessionEndTime = parseInt(moment.tz(data?.end, sellerTimezone || '').format('hmm'), 10);
      if (sessionEndTime <= endTime && sessionStartTime >= startTime) {
        setIsPriceDiff(true);
      } else {
        setIsPriceDiff(false);
      }
    }
  }, [priceDiffInfo?.diffPrices, data]);

  return (
    <StyledTimeCardWrapper $isMuted={isMuted} $isActive={isActive} onClick={Click}>
      <StyledTimeCardBoard variant={data.variant} $isActive={isActive}>
        {isPaws && (
          <PawHeader>
            <HeaderText>Paws & Claus</HeaderText>
            <IconSvg type="paw" viewBox="-6 -6 24 24" />
          </PawHeader>
        )}
        {nightOwl && <NightOwlTag>Night Owl</NightOwlTag>}
        {data.variant === TypeVariant.default ? (
          <StyledTimeCard $alignBottom={isPaws}>
            <TimeContain>
              {nightOwl && <NightOwlTagHolder />}
              <CardTime $isElf={!!elfLabel}>{toUpper(data.time)}</CardTime>
              {elfLabel && <StyledElfAvailable>{elfLabel}</StyledElfAvailable>}
            </TimeContain>
          </StyledTimeCard>
        ) : (
          <StyledWpapperDescription $alignBottom={isPaws}>
            <StyledDescriptionTimeCard style={{width: 'auto', textAlign: 'center'}} {...data}>
              <StyledTextTimes style={{fontSize: '22px'}}>{data.description}</StyledTextTimes>
            </StyledDescriptionTimeCard>
            {isPriceDiff && <DiffPrice>{`+$${priceDiffInfo?.diffPrice}`}</DiffPrice>}
            {data.variant === TypeVariant.fast ? (
              <>
                {!data.vipPlaces ? (
                  <StyledTimeCardWithDescription>{toUpper(data.time)}</StyledTimeCardWithDescription>
                ) : (
                  <StyledSoldTicket>
                    <StyledTimeCardWithDescription>{toUpper(data.time)}</StyledTimeCardWithDescription>
                    <StylesRed>
                      <ExclamationCircleFilled />
                      <StylesTextRed>VIP Sold Out</StylesTextRed>
                    </StylesRed>
                  </StyledSoldTicket>
                )}
              </>
            ) : (
              <StyledTimeCardNotTransparent>{toUpper(data.time)}</StyledTimeCardNotTransparent>
            )}
          </StyledWpapperDescription>
        )}
      </StyledTimeCardBoard>
    </StyledTimeCardWrapper>
  );
};
export default CardFromTime;
