import React from 'react';
import {PurchaseAdmissionPage} from '../../components/PurchaseAdmission';
import {usePurchaseSteps} from '../../hooks/purchase';
import {useAdmission} from '../../hooks/admission';
import {useHideIntercomLauncher} from '../../hooks/customerIO';

export const PurchaseAdmission = () => {
  const steps = usePurchaseSteps();
  const {
    orderLoading,
    eventsLoading,
    ticketsLoading,
    tickets,
    order,
    changeTickets,
    valuesState,
    orderInfo,
    paymentInfo,
    orderCreatedAt,
    paymentForm,
    dataOrderDetails,
    vipInfo,
    error,
    hasOwl,
  } = useAdmission(steps);
  useHideIntercomLauncher();
  return (
    <PurchaseAdmissionPage
      tickets={tickets}
      orderLoading={orderLoading}
      eventsLoading={eventsLoading}
      ticketsLoading={ticketsLoading}
      step={steps.step}
      order={order}
      valueState={valuesState}
      paymentInfo={paymentInfo}
      orderInfo={orderInfo}
      changeTickets={changeTickets}
      orderCreatedAt={orderCreatedAt}
      paymentForm={paymentForm}
      dataOrderDetails={dataOrderDetails}
      vipInfo={vipInfo}
      error={error}
      hasOwl={hasOwl}
    />
  );
};
