import React from 'react';
import {
  PerkCardDescription,
  PerkCardImage,
  PerkCardTitle,
  PerkCardWrapper,
  PerkCards,
  TabDescr,
  TabTitle,
} from './styles';
import vipImg from '../../ui-kit/assets/upgrade-vip-perk.png';
import dateImg from '../../ui-kit/assets/change-date-perk.png';
import addonsImg from '../../ui-kit/assets/addons-perk.png';

const dataMap = {
  vip: {
    title: 'UPGRADE TO VIP',
    description:
      'Get the most out of your Enchant experience - upgrade to VIP. Add-on includes Enchant Express Entry, exclusive VIP Lounge access, complimentary beverage, souvenir gift, plus so much more.',
    image: vipImg,
  },
  addons: {
    title: 'PURCHASE ADD ONS',
    description:
      "Add-ons are specific to your Enchant location. They can include: Enchant's one-of-a-kind illuminated ice skating trail, convenient site parking, access to a dinner or brunch seating, etc.",
    image: addonsImg,
  },
  date: {
    title: 'CHANGE DATE',
    description:
      'Last-minute change of plans? We know the holiday season is busy. Use our self-serve option to change the date and time of your Enchant visit, and apply credits toward the exchange fee.',
    image: dateImg,
  },
};
const perks = ['vip', 'addons', 'date'];
const getKey = (key: string) => {
  if (key?.includes('vip')) return 'vip';
  if (key?.includes('addons')) return 'addons';
  if (key?.includes('date')) return 'date';
  return null;
};
export const Perks = () => {
  return (
    <>
      <TabTitle>What can I use my credits for?</TabTitle>
      <TabDescr>See below for perks and incentives.</TabDescr>
      <PerkCards>
        {perks.map((el) => {
          const key = getKey(el);
          if (!key) return null;
          return (
            <PerkCard
              ticketType={el}
              title={dataMap[key].title}
              description={dataMap[key].description}
              image={dataMap[key].image}
              key={el}
            />
          );
        })}
      </PerkCards>
    </>
  );
};

const PerkCard: React.FC<{
  ticketType: string;
  title: string;
  description: string;
  image: string;
}> = ({image, title, description}) => {
  return (
    <PerkCardWrapper>
      <PerkCardImage src={image} />
      <PerkCardTitle>{title}</PerkCardTitle>
      <PerkCardDescription>{description}</PerkCardDescription>
    </PerkCardWrapper>
  );
};
