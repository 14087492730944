import React from 'react';
import {BigLoader, LoaderWrapper, PageWrapper} from './styles';

export const MagicLinkReceived = () => {
  return (
    <PageWrapper>
      <LoaderWrapper>
        <BigLoader />
      </LoaderWrapper>
    </PageWrapper>
  );
};
