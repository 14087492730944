import React from 'react';
import {BillingInfo} from './Form';
import {useSetRecoilState} from 'recoil';
import {getPaymentSkipping} from '../../states/general';
import {SelectDate, SelectArrival} from './SelectCards';
import {StyledTitle} from '../PurchaseOne/styles';
import {
  ChangeDateContentContainer,
  ContentDescr,
  ContentDescrSmall,
  PageWrapper,
  TitleContainer,
  TitleWrapper,
  DatesWrapper,
  ArrivalWrapper,
  LineSeparator,
} from './styles';
import {ChangeDatePageProps} from './types';
import {DesktopView, MobileView} from '../../ui-kit/AppLayout/View';
import {steps} from '../../types/helpers';
import {OrderInfoComponent} from '../OrdersInfo';
import {Payment} from '../../containers/Payment';
import {Label} from '../../ui-kit/Label';
import {OrderCardWithLogic} from '../../ui-kit/Order/OrderCardWithLogic';
import {PNCInformModal, PawsNClausModal, SpecialNightModal} from '../EventModals';

export const ChangeDatePage: React.FC<ChangeDatePageProps> = ({
  changeDate,
  setIsDateSelected,
  setIsTimeSelected,
  orderInfo,
  dataTicketCard,
  orderData,
  setDateCardId,
  dataTimeCard,
  onClickTimeCard,
  dataOrderDetails,
  eventsWithPrice,
  order,
  paymentForm,
  loadingSessions,
  orderCreatedAt,
  error,
  bookedSessions,
  selectedGA,
  urlSteps,
  sessions,
  showPromo,
  nightOwl,
  sellerHasOwl,
  hiddenSesions,
  bookedTemplates,
  orderInfoBar,
  subTotal,
  addons,
  modals,
  specialNights,
  hasPNC,
  onDate,
  removedTickets,
}) => {
  const paymentInfo = {payment: orderData ? orderData.payment : 0};
  const pickArrival = urlSteps?.step === '2';
  const paymentSkipping = changeDate?.step === steps.four && (Number(subTotal) === 0 || Number(subTotal) < 0);
  const confirmedPaymentSkipping = orderInfo?.weatherProtection;
  const setPaymentSkipping = useSetRecoilState(getPaymentSkipping);
  const isPaymentSkipping = confirmedPaymentSkipping ? confirmedPaymentSkipping : paymentSkipping;
  if (confirmedPaymentSkipping) {
    setPaymentSkipping(confirmedPaymentSkipping);
  }
  return (
    <PageWrapper>
      <TitleContainer>
        <TitleWrapper>
          <StyledTitle style={{whiteSpace: 'pre-line'}}>
            {changeDate?.step === steps.four
              ? 'Review your updated order'
              : `Select a new date and time for your order below.`}
          </StyledTitle>
          {changeDate?.step === steps.four && (
            <>
              <DesktopView>
                <ContentDescrSmall>
                  <p style={{whiteSpace: 'pre-line'}}>
                    Please review your order to ensure all the details are correct. Visit protection will be added if
                    you originally added it to your order.
                  </p>
                </ContentDescrSmall>
              </DesktopView>
              <MobileView>
                <ContentDescr>
                  Please review your order to ensure all the details are correct. Visit protection will be added if you
                  originally added it to your order.
                </ContentDescr>
              </MobileView>
            </>
          )}
        </TitleWrapper>
        <DesktopView>{changeDate?.step !== steps.four && <OrderInfoComponent orderInfo={orderInfo} />}</DesktopView>
      </TitleContainer>

      {changeDate?.step !== steps.four && (
        <OrderCardWithLogic paymentInfo={paymentInfo} data={orderInfoBar} show={true} loading={false} />
      )}

      <ChangeDateContentContainer $reverse={changeDate?.step === steps.four}>
        {changeDate?.step !== steps.four ? (
          <DatesWrapper $hide={pickArrival}>
            {dataTicketCard !== undefined && (
              <SelectDate
                dataTicketCard={dataTicketCard}
                setIsDateSelected={setIsDateSelected}
                setDateCardId={setDateCardId}
                sellerTimezone={orderInfo?.sellerTimezone}
                weatherProtection={orderInfo?.weatherProtection}
                bookedSessions={bookedSessions}
                modals={modals}
                specialNights={specialNights}
              />
            )}
          </DatesWrapper>
        ) : (
          changeDate?.stripeAccountId !== undefined && (
            <Payment
              order={order}
              orderCreatedAt={orderCreatedAt}
              stripeAccount={changeDate?.stripeAccountId}
              paymentSkipping={isPaymentSkipping}
            />
          )
        )}
        {pickArrival && changeDate?.step !== steps.four && changeDate?.step !== steps.one ? (
          <LineSeparator vertical={true} />
        ) : (
          <></>
        )}
        {changeDate?.step !== steps.four ? (
          changeDate?.step !== steps.one ? (
            <ArrivalWrapper $hide={!pickArrival}>
              <SelectArrival
                onClickTimeCard={onClickTimeCard}
                setIsTimeSelected={setIsTimeSelected}
                dataTimeCard={dataTimeCard}
                loading={loadingSessions}
                sellerTimezone={orderInfo?.sellerTimezone}
                selectedGA={selectedGA}
                sessions={sessions}
                eventsWithPrice={eventsWithPrice}
                nightOwl={nightOwl}
                sellerHasOwl={sellerHasOwl}
                hiddenSesions={hiddenSesions}
                bookedTemplates={bookedTemplates}
                removedTickets={removedTickets}
              />
            </ArrivalWrapper>
          ) : (
            <></>
          )
        ) : (
          <BillingInfo
            dataOrderDetails={dataOrderDetails}
            paymentForm={paymentForm}
            showPromo={showPromo}
            showTime
            paymentSkipping={isPaymentSkipping}
          />
        )}
      </ChangeDateContentContainer>
      {error && <Label type={'error'} label={error} />}

      <PawsNClausModal
        isVisible={modals?.pnc.visible}
        onClose={modals?.pnc?.close}
        data={addons?.pnc}
        onAddTickets={modals?.pnc?.onAdd}
        specialNights={specialNights}
        minValue={modals?.pnc?.minValue}
      />
      <SpecialNightModal
        modalKey={modals?.specialNight.visible}
        onClose={modals?.specialNight?.close}
        specialNights={specialNights}
      />
      <PNCInformModal hasPnc={hasPNC} date={onDate} sellerTimezone={orderInfo?.sellerTimezone} />
    </PageWrapper>
  );
};
