import styled from 'styled-components';
import {Text1, Heading6} from '../Typography';
import {InfoCircleOutlined} from '@ant-design/icons';

export const TooltipIcon = styled(InfoCircleOutlined)`
  margin-left: 10px;
`;

export const TooltipWrapper = styled.div`
  background-color: #becdcc;
  padding: 16px;
  border: 1px solid #ac9175;
  border-radius: 12px;
`;

export const TooltipDescription = styled(Text1)`
  color: #042c3b;
`;

export const VisitProtectionTooltipWrapper = styled.div`
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  padding: 16px;
  border: 1px solid ${({theme}) => theme.palette.system.darkBlack};
  border-radius: 12px;
`;

export const VisitProtectionTooltipTitle = styled(Heading6)`
  margin-bottom: ${({theme}) => theme.spacer._3};
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spacer._3};
`;

export const VisitProtectionTooltipDescription = styled(Text1)``;
