import {OrderT, VipSetT} from '../types/orders';
import {OrderDataT} from '../components/ChangeDate/types';
import {useState, useEffect} from 'react';
import {cancelVisit, setChangeDates, upgradeToVipReq} from '../queries/orderGroup';
// import {checkoutCart} from '../queries/payment';
import {toCreateChangeDateReqBody} from '../helpers/changeDate';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {purchaseAddOneNavFooterState, purchaseError, purchaseState} from '../states/purchase';
import {changeDateState, checkoutState, upgradeVipState} from '../states/order';
import {purchaseVipTicketsT} from '../queries/types/vip';
import {useNavigate} from 'react-router-dom';
import {route} from '../constants/routes';
import {EventTemplateIdsT} from '../queries/types/changeDate';
import {EventsT} from '../types/events';
import {scrollUp} from '../helpers/helpers';
import {formatVipError} from '../helpers/upgradeToVip';
import {purchaseAdmission} from '../states/tickets';
import {onRequestErrorT} from './customerIO';
import {OrdersQueryList} from '../constants/api';
import {preloadLoading, preloadedOrders} from '../states/preload';
import {APPLE_WALLET_URL, GOOGLE_WALLET_URL} from '../constants/env';

export const useCancelVisit = (onSuccess?: () => void) => {
  const cancelVisitInvoke = async (orderId: string) => {
    try {
      const res = await cancelVisit(orderId);
      res?.body && onSuccess?.();
    } catch (error) {
      console.log(error);
    }
  };

  return {cancelVisitInvoke};
};

export const useGetOrders = () => {
  const orders = useRecoilValue(preloadedOrders);
  const loading = useRecoilValue(preloadLoading);
  return {orders, loading: loading?.orders};
};

// export const useGetOrders = () => {
//   const [orders, setOrders] = useState<OrderT[]>([]);
//   const [loading, setLoading] = useState<boolean>(false);

//   const fetch = async () => {
//     try {
//       setLoading(true);
//       const response = await getOrders();
//       setOrders(response.body);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       console.log(error);
//     }
//   };
//   useEffect(() => {
//     fetch();
//   }, []);

//   return {orders, refetch: fetch, loading};
// };
// export const checkoutInfo = () => {
//   const [checkout, setCheckout] = useState<any[]>([]);
//   const [loading, setLoading] = useState<boolean>(false);

//   // const fetch = async () => {
//   //   try{
//   //     setLoading(true);
//   //     const response = await checkoutCart();
//   //     setCheckout(response.body);
//   //     setLoading(false);
//   //   } catch (error) {
//   //     setLoading(false);
//   //     console.log(error);
//   //   }
//   //   }
//   // }
// }
export const useGetOrderWithDetails = (orderId?: string) => {
  const [order, setOrder] = useState<OrderT>();
  const orders = useRecoilValue(preloadedOrders);
  const loader = useRecoilValue(preloadLoading);
  const navigate = useNavigate();

  const getOrder = async (orderId: string) => {
    if (!orderId || order) return false;
    const detailedOrder = orders?.find((el) => el?.ticketureOrderId === orderId || String(el?.id) === String(orderId));
    if (detailedOrder) return setOrder(detailedOrder);
    if (!detailedOrder && loader?.orders === false && orders?.length) navigate(route.orderNotFoundPage.path);
  };

  useEffect(() => {
    orderId && getOrder(orderId);
  }, [orderId, orders?.length]);
  return {order, getOrder, loading: !order};
};

export const useChangeDateReq = (
  orderId?: string,
  orderData?: OrderDataT,
  order?: OrderT,
  events?: EventsT,
  eventTemplateIds?: EventTemplateIdsT[],
  onSuccess?: (values: any) => void,
  vipTicket?: VipSetT,
  onRequestError?: onRequestErrorT,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const changeDateReq = async () => {
    if (!orderId) return false;
    setLoading(true);
    setError('');
    const tickets = await toCreateChangeDateReqBody(orderData, order, events, eventTemplateIds, vipTicket);
    if (!tickets?.length) return false;
    const body = {tickets};
    try {
      const res = await setChangeDates({orderId, body});
      res?.body && onSuccess?.(res?.body);
      setLoading(false);
      scrollUp();
      return !!res?.body;
    } catch (e) {
      onRequestError?.(JSON.stringify(e), OrdersQueryList.changeDate(orderId), body);
      setError(
        'Sorry! Something unexpected has happened on our end. Our engineers have been notified. Please try again later.',
      );
      setLoading(false);
      return false;
    }
  };
  return {changeDateReq, loading, error};
};

export const useUpgradeToVip = (
  tickets?: purchaseVipTicketsT[],
  orderId?: string,
  onSuccess?: (values: any) => void,
  onRequestError?: onRequestErrorT,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const upgradeToVipInvoke = async () => {
    if (!orderId || !tickets) return false;
    setLoading(true);
    if (!tickets?.length) return false;
    const body = {tickets: tickets};
    try {
      setError('');
      const res = await upgradeToVipReq({orderId, body});
      scrollUp();
      res?.body && onSuccess?.(res?.body);
      setLoading(false);
      return true;
    } catch (e) {
      onRequestError?.(JSON.stringify(e), OrdersQueryList.upgradeToVip(orderId), body);
      setError(formatVipError(e));
      setLoading(false);
      return false;
    }
  };

  return {upgradeToVipInvoke, loading, error};
};

export const useResetStates = () => {
  const setPurchaseFooterNav = useSetRecoilState(purchaseAddOneNavFooterState);
  const setPurchaseState = useSetRecoilState(purchaseState);
  const setUpgradeVip = useSetRecoilState(upgradeVipState);
  const setChangeDate = useSetRecoilState(changeDateState);
  const setError = useSetRecoilState(purchaseError);
  const setCheckout = useSetRecoilState(checkoutState);
  const setPurchaseGA = useSetRecoilState(purchaseAdmission);
  const reset = () => {
    setPurchaseState({});
    setPurchaseFooterNav({});
    setUpgradeVip({});
    setChangeDate({});
    setPurchaseGA({});
    setError('');
    setCheckout(undefined);
  };
  useEffect(() => {
    reset();
  }, []);
  return {reset};
};

export const useOrderTime = (createdAt?: Date) => {
  const [timeLeft, setTimeLeft] = useState<null | number>(null);
  const calcTime = () => {
    if (!createdAt) return;
    const now = new Date();
    const created = new Date(createdAt);
    const difference = now.getTime() - created.getTime();
    const resultInMinutes = 10 - Math.round(difference / 60000);
    if (resultInMinutes > 0) return setTimeLeft(resultInMinutes);
    return setTimeLeft(0);
  };

  useEffect(() => {
    calcTime();
    let interval: NodeJS.Timer | undefined = undefined;
    if (createdAt) {
      interval = setInterval(() => {
        calcTime();
      }, 60000);
    }
    return () => interval && clearInterval(interval);
  }, [createdAt]);

  return {timeLeft};
};

export const useGetBalanceDue = () => {
  const [upgradeVip] = useRecoilState(upgradeVipState);
  const [addons] = useRecoilState(purchaseState);
  const [changeDate] = useRecoilState(changeDateState);
  const [purchaseFooter] = useRecoilState(purchaseAddOneNavFooterState);

  if (upgradeVip?.cartId) return Number(upgradeVip.subtotal || 0);
  if (purchaseFooter?.cartId) return Number(addons?.payment || 0);
  if (changeDate?.cartId) return Number(changeDate?.additionalPayment || 0);
};

export const getAppleWalletURL = (orderId?: string) => {
  if (!orderId) return;
  return APPLE_WALLET_URL?.replace('order_id', orderId);
};
export const getGoogleWalletURL = (orderId?: string) => {
  if (!orderId) return;
  return GOOGLE_WALLET_URL?.replace('order_id', orderId);
};
