import React from 'react';
import {InfoIcon, StyledLabel} from './styles';
import {LabelPropsT} from './types';
import {VisitProtectionTooltip} from '../Tooltips';
import {ClockCircleFilled} from '@ant-design/icons';
import {IconSvg} from '../Icon';

export const Label: React.FC<LabelPropsT> = ({type, label, icon}) => {
  return (
    <StyledLabel variant={type}>
      {type !== 'primary' && icon && (
        <>
          {label}
          <InfoIcon />
        </>
      )}
      {type === 'primary' && icon && <VisitProtectionTooltip>{label}</VisitProtectionTooltip>}
      {type === 'error' && !icon && <>{label}</>}
      {type === 'time' && (
        <>
          <ClockCircleFilled />
          {label}
        </>
      )}
    </StyledLabel>
  );
};

export const VisitProtectionLabel: React.FC<LabelPropsT> = ({label}) => {
  return (
    <StyledLabel variant="primary">
      <IconSvg type="shield" width="13px" height="14px" viewBox="0 0 13 14" />
      {label}
    </StyledLabel>
  );
};

export const VipLabel: React.FC = () => {
  return (
    <StyledLabel variant="gold">
      <IconSvg type="sparkSmall" width="13px" height="14px" viewBox="0 0 13 14" stroke="none" fill="black" />
      VIP
    </StyledLabel>
  );
};

export const WeatherProtectionLabel: React.FC<LabelPropsT> = ({title, label}) => {
  return (
    <StyledLabel variant="secondary" isTitle={!!(title && title?.length > 0)}>
      <p style={{margin: '0'}}>
        {title && <span>{title}</span>}&nbsp;
        {label}
        {!title && <InfoIcon />}
      </p>
    </StyledLabel>
  );
};
