import React from 'react';
import {WarningOutlined} from '@ant-design/icons';
import {Modal} from 'antd';
import {TAlertModal} from './types';

const {confirm, warning} = Modal;

export const AlertModal: TAlertModal = (title, content, okText, cancelText, onOkClose, onCancelClose) => {
  return confirm({
    title: title,
    content: content,
    okText: okText,
    cancelText: cancelText,
    autoFocusButton: null,
    icon: <WarningOutlined />,
    onOk: onOkClose,
    onCancel: onCancelClose,
  });
};

export const WarningModal: TAlertModal = (title, content, okText, cancelText, onOkClose, onCancelClose) => {
  return warning({
    title: title,
    content: content,
    okText: okText,
    cancelText: cancelText,
    autoFocusButton: null,
    icon: <WarningOutlined />,
    onOk: onOkClose,
    onCancel: onCancelClose,
  });
};
