import styled from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {StyledHeading6, StyledHeadingMediumTitle, TextBody5, TextCaption} from '../../ui-kit/Typography/styles';
import {scrollStyles} from '../../ui-kit/theme/scroll';

export const StyledPageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-bottom: 60px;
  ${Media.down.s} {
    padding-bottom: 80px;
  }
`;

export const StyledContentWrapper = styled.div`
  padding-top: 120px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  ${Media.down.m} {
    padding-top: 48px;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
`;

export const StyledTitleWrapper = styled.div`
  max-width: 700px;
  ${Media.down.l} {
    width: 90%;
  }
  ${Media.down.m} {
    padding-top: calc(${({theme}) => theme.spacer._15} - 10px);
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: ${({theme}) => theme.spacer._1};
    width: 100%;
  }
  margin-bottom: 15px;
`;

export const StyledTitle = styled(StyledHeadingMediumTitle)`
  margin-bottom: ${({theme}) => theme.spacer._1};
  text-transform: capitalize;
  font-size: 28px;
  color: ${({theme}) => theme.palette.secondary.brightGold};
  ${Media.down.l} {
    font-size: 24px !important;
    line-height: 26px;
  }
`;

export const StyledDescription = styled(TextBody5)`
  color: ${({theme}) => theme.palette.system.white};
  ${Media.down.l} {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const TicketsList = styled.div`
  max-width: 50%;
  overflow-y: auto;
  padding: 10px 0;
  ${scrollStyles};
  ${Media.down.m} {
    max-width: 100%;
  }
`;

export const TicketsInputsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

export const TicketPrice = styled(StyledHeading6)`
  display: block;
  font-size: 16px;
  ${Media.down.m} {
    font-size: 14px;
  }
`;

export const TicketDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
  align-items: center;
`;

export const SoldoutInfo = styled(TextCaption)`
  color: ${({theme}) => theme.palette.primary.alarmColor};
`;

export const NotAvailableLine = styled(TextBody5)`
  display: block;
  padding-top: 10px;
  font-size: 14px;
  color: ${({theme}) => theme.palette.primary.alarmColor};
`;

export const ErrorLine = styled.div`
  padding: 10px 0;
`;
