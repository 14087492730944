import {DashboardOrderT, OrderT} from '../types/orders';
import 'moment-timezone';
import {User} from '../types/auth';
import {formatCurrency, formats} from './helpers';
import {getTZdate, isGA, isGACat} from './orders';

export const toCreateOrderData = (orders?: OrderT[]) => {
  const ordersData: DashboardOrderT[] | undefined = orders?.map((order) => {
    const ticketsCategoryGA = order?.tickets?.filter((el) => isGACat(el?.eventTemplateCategory));
    const eventStartDate = ticketsCategoryGA ? ticketsCategoryGA[0]?.eventStartDate : '';
    const eventEndDate = ticketsCategoryGA ? ticketsCategoryGA[0]?.eventEndDate : '';
    const ticketsCategoryVip = order?.tickets?.filter((el) => el.eventTemplateCategory === 'VIP');
    const ticketsCategoryIceSkating = order?.tickets?.filter((el) =>
      el.eventTemplateName?.toLowerCase().includes('ice skating'),
    );
    const ticketsCategorySuite = order?.tickets?.filter((el) => el.eventTemplateName?.toLowerCase().includes('suite'));
    const hasSuiteTickets = order?.tickets?.some((ticket) => ticket?.ticketGroupName?.includes('Suite'));
    const isIceSkating = ticketsCategoryIceSkating?.length ? true : false;
    const isSuite = ticketsCategorySuite?.length ? true : false;
    return {
      eventDate: order.eventDate,
      eventDateFormat: getTZdate(order.eventDate, order).format('MMM DD'),
      ticketureOrderId: order.ticketureOrderId,
      sellerName: order.sellerName,
      sellerVenue: order.sellerVenue,
      sellerMeta: order.sellerMeta,
      ticketureOrderNumber: order.ticketureOrderNumber,
      arrivalDateFormat: `
      ${getTZdate(eventStartDate, order).format(formats.timeStart)} - 
      ${getTZdate(eventEndDate, order).format(formats.timeEnd)}`,
      isVip: ticketsCategoryVip && ticketsCategoryVip?.length > 0 ? true : false,
      isProtection: order.visitProtection,
      weatherProtection: order.weatherProtection,
      viewTicketLink: order?.metadata?.viewTicketLink,
      totalPrice: order.totalPrice,
      totalPayments: order.totalPayments,
      isFreeChangeDate: false,
      manageWaiversLink: order?.metadata?.manageWaiversLink,
      canPurchaseAddOns: order?.metadata?.canPurchaseAddOns,
      allowRefund: order?.metadata?.allowRefund,
      allowDateChange: order?.metadata?.allowDateChange,
      isCancel: order.orderCancelled,
      refundedAmount: formatCurrency(order?.refundedAmount ? order?.refundedAmount : 0),
      countTickets: `${order?.tickets?.length} ${order?.tickets?.length === 1 ? 'ticket' : 'tickets'}`,
      id: order.id,
      isIceSkating: isIceSkating,
      isSuite: isSuite,
      sellerTimezone: order?.sellerTimezone,
      dateChangeDisabled: !order?.metadata?.canChangeDate || hasSuiteTickets || isSuite,
      cancelDisabled: !order?.metadata?.canCancelVisit,
      isDateChange: order?.dateChange,
      hasGA: order?.tickets?.some((el) => isGA(el)),
    };
  });
  return ordersData;
};

export const toCreateViewerDetails = (viewer?: User | null) => {
  const name = viewer?.firstName;
  const viewerDetails = {name: name};
  return viewerDetails;
};
