import React, {useEffect, useState} from 'react';
import {PageSpinLoader} from '../../ui-kit/Loader';
import {usePreloadOrders} from '../../hooks/preload';
import {useCancelConfirmOrder} from '../../hooks/cancellation';
import {toCreateCancellConfirm} from '../../helpers/orders';
import {CancelConfirmOrderPage} from '../../components/CancelConfirmOrder';
import {getOrders} from '../../queries/orderGroup';

export const CancelConfirmOrder = () => {
  const {order, loading, onClickToBack} = useCancelConfirmOrder();
  const [dataOrder, setDataOrder] = useState<any>();
  const [pageLoaded, setPageLoaded] = useState(true);

  useEffect(() => {
    const fetchDataOrder = async () => {
      const orders = await getOrders();
      const theOrder = orders?.body?.find(
        (orderDetail: any) => orderDetail?.ticketureOrderNumber === order?.ticketureOrderNumber,
      );
      const newDataOrder = toCreateCancellConfirm(theOrder);
      if (theOrder) {
        setDataOrder(newDataOrder);
        setPageLoaded(false);
        return;
      }
    };
    fetchDataOrder();
  }, [order]);

  usePreloadOrders();

  if (pageLoaded || loading) return <PageSpinLoader />;
  return <CancelConfirmOrderPage dataOrder={dataOrder} onClickToBack={onClickToBack} />;
};
