import styled, {css} from 'styled-components';

import {DescriptionProps, timeCardVariant, TimeElementsWrapperProps} from './types';
import {Button} from '../../ui-kit/Button';
import {ButtonPropsT} from '../../ui-kit/Button/types';
import {Media} from '../../ui-kit/theme/breakpoints';
import {newSoldOutGradient, sellingFastGradient, whiteShadow} from '../../ui-kit/theme/palette';
import {Heading5, Heading6, SmallBold, Text1, Text2, Text3} from '../../ui-kit/Typography';
import {fadeIn} from '../animations';
import {
  StyledHeading6,
  StyledHeadingMediumTitle,
  TextBody1,
  TextBody5,
  TextSixteenBodyBold,
  TextSmallBold,
} from '../../ui-kit/Typography/styles';
import {scrollStyles} from '../../ui-kit/theme/scroll';
import {mixins} from '../../ui-kit/theme/mixins';
import {fontSecondaryBold} from '../../ui-kit/theme/font';

export const StyledPageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-bottom: 75px;
`;

export const StyledContentWrapper = styled.div`
  padding-top: 120px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  ${Media.down.m} {
    padding-top: 48px;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
`;

// export const StyledWrapperTimeRemaining = styled.div`
//   background-color: ${({theme}) => theme.palette.system.gray1};
//   width: 100%;
//   display: flex;
//   align-content: center;
//   justify-content: space-between;
//   padding: 7px 16px;
//   border-radius: 4px;
// `;
// color: ${({theme}) => theme.palette.system.white};

// export const StyledWrapperHelp = styled.div`
//   display: flex;
//   color: ${({theme}) => theme.palette.system.white};
// `;
// export const StyledTextTimeRemaining = styled(TextBody2)`
//   color: ${({theme}) => theme.palette.system.white};
// `;

// export const StyledParamIcon = styled.div`
//   width: 12px;
//   height: 12px;
// `;

export const StyledTitleWrapper = styled.div`
  ${Media.down.l} {
    width: 90%;
  }
  ${Media.down.m} {
    padding-top: calc(${({theme}) => theme.spacer._15} - 10px);
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: ${({theme}) => theme.spacer._1};
    width: 100%;
  }
`;

export const StyledTitle = styled(StyledHeadingMediumTitle)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  margin-bottom: ${({theme}) => theme.spacer._1};
  text-transform: capitalize !important;
  font-size: 28px !important;
  ${Media.down.l} {
    font-size: 24px !important;
    line-height: 26px;
  }
`;

export const StyledDescription = styled(TextBody5)`
  color: ${({theme}) => theme.palette.system.white};
  ${Media.down.l} {
    font-size: 16px;
    line-height: 18px;
  }
`;

// export const StyledDescriptionPlaceOrder = styled.div`
//   max-width: 500px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-end;
// `;

// export const StyledStateLocation = styled(StyledHeading5)`
//   color: ${({theme}) => theme.palette.system.white};
//   font-size: 26px;
// `;

// export const StyledOrderNumber = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
// `;

// export const StyledOrderNumberInfo = styled(TextBody1)`
//   color: ${({theme}) => theme.palette.system.white};
// `;

// export const StyledOrderCipher = styled(TextSixteenBodyBold)`
//   color: ${({theme}) => theme.palette.system.white};
//   margin-left: 4px;
// `;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTitleAddText = styled(StyledHeading6)`
  margin-top: ${({theme}) => theme.spacer._5};
  color: ${({theme}) => theme.palette.secondary.brightGold};
`;

export const StyledChooseTicket = styled(TextBody1)`
  color: ${({theme}) => theme.palette.system.white};
  display: flex;
  margin-bottom: ${({theme}) => theme.spacer._3};
`;

export const StyledCard = styled.div`
  max-width: 396px;
`;

export const AddOnsCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  animation: ${fadeIn} 0.5s;
  -webkit-animation: ${fadeIn} 0.5s;
  -moz-animation: ${fadeIn} 0.5s;
  -o-animation: ${fadeIn} 0.5s;
  -ms-animation: ${fadeIn} 0.5s;

  ${Media.down.l} {
    width: 100%;
    margin-bottom: 20px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const AdvContainer = styled.div<{advImg?: string}>`
  height: 180px;
  width: 100%;
  background-image: ${({advImg}) => (advImg ? `url('../../../../ui-kit/assets/${advImg}.png')` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px 8px 0 0;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

export const AdvVideo = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
`;

export const AdvImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
`;

export const AddOnsCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: stretch;
  // margin-top: 24px;
  gap: 16px;
  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    .ant-typography {
      margin-bottom: 14px;
    }
  }
  ${Media.down.s} {
    width: 100%;
    justify-content: center;
  }
`;

export const AddOnsList = styled.div`
  max-width: 396px;
  max-height: 500px;
  overflow-y: auto;
  padding: 0 5px 5px 0;
  ${scrollStyles};
`;
// PaymentForm

export const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 46%;
  padding-right: 30px;
  ${Media.down.xl} {
    padding-right: 33px;
  }
  ${Media.down.l} {
    padding-right: 30px;
    width: 100%;
    padding: 0;
  }
`;

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 54%;
  padding-left: 20px;
  ${Media.down.xl} {
    padding-left: 20px;
  }
  ${Media.down.l} {
    padding-left: 20px;
    width: 100%;
    padding: 0;
  }
`;

export const ContentTitle = styled(Heading5)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
`;

export const ContentTitleSmall = styled(Heading6)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  margin-top: ${({theme}) => theme.spacer._5};
`;

export const ContentTitleSmallSecondary = styled(Heading6)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  margin-top: ${({theme}) => theme.spacer._7};
`;

export const ContentDescrWrapper = styled.div<DescriptionProps>`
  width: ${({width}) => width || '100%'};
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const ContentDescrSmallWrapper = styled.div`
  margin-top: 4px;
`;

export const ContentDescr = styled(Text3)`
  color: ${({theme}) => theme.palette.system.white};
`;

export const ContentDescrSmall = styled(Text1)`
  color: ${({theme}) => theme.palette.system.white};
`;

export const SwitchWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const TimeElementsWrapper = styled.div<TimeElementsWrapperProps>`
  opacity: ${({selected}) => (selected ? 1 : 0.5)};
`;

export const UpperTimeCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0 16px 0;
  border-bottom: 1px solid ${({theme}) => theme.palette.system.gray3};
`;

export const TimeCardsContainer = styled(UpperTimeCardsContainer)`
  border: none;
`;

export const TimeCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ValueItem = styled(Text1)``;

export const TimeCardsSwitchWrapper = styled.div`
  margin-right: 16px;
  margin-top: 24px;
`;

export const ButtonWrapper = styled.div`
  display: block;
`;

export const TransparentButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border: none;
  }
`;

export const FormElementsWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.palette.secondary.nightBlue};
  ${Media.down.m} {
    border-bottom: 1px solid ${({theme}) => theme.palette.state.darkGray};
  }
`;

export const PaymentFormTitleWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const PaymentFormTitle = styled(SmallBold)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputsColumnLeft = styled(InputsContainer)`
  padding-right: 16px;
`;

export const InputsColumnRight = styled(InputsContainer)`
  padding-left: 16px;
`;

export const PaymentButtonWrapperLeft = styled(InputsContainer)`
  padding-right: 8px;
`;

export const PaymentButtonWrapperRight = styled(InputsContainer)`
  padding-left: 8px;
`;

export const PaymentButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid ${({theme}) => theme.palette.secondary.nightBlue};
  ${Media.down.m} {
    border-bottom: 1px solid ${({theme}) => theme.palette.state.darkGray};
  }
`;

export const PaymentButton = styled(Button)<ButtonPropsT>`
  width: 100%;
  color: ${({theme}) => theme.palette.system.white};
  background: ${({theme}) => theme.palette.primary.darkBlack};
  border: 1px solid ${({theme}) => theme.palette.system.white};
  border-radius: 8px;
  &:hover,
  &:active,
  &:focus {
    color: ${({theme}) => theme.palette.system.white};
    background: ${({theme}) => theme.palette.primary.darkBlack};
    border: 1px solid ${({theme}) => theme.palette.system.white};
  }
`;

export const CheckBoxWrapper = styled.div`
  padding-top: 4px;
`;

export const CheckboxText = styled(Text2)`
  color: ${({theme}) => theme.palette.system.white};
  font-size: 16px;
`;

export const CheckboxTextUnderlined = styled(SmallBold)`
  color: ${({theme}) => theme.palette.system.white};
  text-decoration: underline;
  font-size: 16px;
`;

// PAYMENT DETAIL
export const ChangeDateContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 38px;
  position: relative;
  ${Media.down.l} {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 24px;
    margin-bottom: 20px;
    padding-top: 40px;
  }
`;

export const PickAddonDateBlock = styled.div`
  margin-bottom: ${({theme}) => theme.spacer._6};
  margin-top: ${({theme}) => theme.spacer._6};
`;

export const AddonDateTextHead = styled.div`
  margin-bottom: ${({theme}) => theme.spacer._2};
`;

export const AddonDateSubTitle = styled(Heading5)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  font-size: 20px;
`;

export const AddonDateSubDescr = styled(TextBody1)`
  color: ${({theme}) => theme.palette.system.white};
  max-width: 500px;
  display: block;
`;

export const TimeCardLines = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  ${Media.down.l} {
    gap: 6px;
  }
`;

const souldoutCss = css`
  display: flex;
  justify-content: space-between;
  ${newSoldOutGradient};
`;
const fastCss = css`
  ${sellingFastGradient};
`;
const defaultCardCss = css`
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
`;
const unavailableCardCss = css`
  opacity: 0.85;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
`;
const pawCardCss = css`
  justify-content: flex-start;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  padding: 0;
  gap: 10px;
`;
const owlCardCss = css`
  justify-content: flex-start;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  padding: 0;
  gap: 10px;
`;

export const AddonTimeCardWrapper = styled.div<{variant?: timeCardVariant; $isActive?: boolean; $isDisabled?: boolean}>`
  width: 190px;
  height: 56px;
  padding: 7px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;

  ${({$isActive, variant}) => {
    if ($isActive && variant !== 'paw' && variant !== 'owl') return '';
    switch (variant) {
      case 'soldout':
        return souldoutCss;
      case 'fast':
        return fastCss;
      case 'unavailable':
        return unavailableCardCss;
      case 'paw':
        return pawCardCss;
      case 'owl':
        return owlCardCss;
      default:
        return defaultCardCss;
    }
  }};
  ${({$isActive, theme}) => $isActive && `background-color: ${theme.palette.secondary.brightGold};`};
  ${({$isActive}) => $isActive && `${whiteShadow}`};
  ${({$isDisabled}) => $isDisabled && `opacity: 0.7; pointer-events: none;`};

  ${Media.down.m} {
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 43px;
    width: 100%;
    padding: 1px 24px;
  }
`;

export const TimeText1 = styled(TextBody1)`
  ${mixins.font.primary.bold};
  font-size: 18px;
`;

export const TextSoldOut = styled(TextSixteenBodyBold)`
  width: 100%;
  text-align: center;
  ${fontSecondaryBold};
  font-size: 20px;

  ${Media.down.m} {
    text-align: end;
  }
`;

export const PawHeader = styled.div`
  background-color: ${({theme}) => theme.palette.secondary.brightGold};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  top: 0px;
  gap: 12px;

  ${Media.down.m} {
    background-color: transparent;
    justify-content: flex-end;
  }
`;

export const OwlHeader = styled(PawHeader)`
  background-color: ${({theme}) => theme.palette.secondary.skyBlue};

  ${Media.down.m} {
    background-color: transparent;
    justify-content: flex-end;
  }
`;

export const HeaderText = styled(TextSixteenBodyBold)`
  ${fontSecondaryBold};
  display: contents;
  font-size: 12px;
  line-height: 12px;
  height: 100%;
  text-transform: uppercase;
  white-space: nowrap;
  ${Media.down.m} {
    font-size: 20px;
    text-transform: capitalize;
  }
`;

export const TextTime = styled(TextSmallBold)`
  width: 100%;
  white-space: nowrap;
  text-align: center;
  ${Media.down.m} {
    font-size: 20px;
    text-align: left;
  }
`;

export const PopoverWrapper = styled.div``;
export const PopoverTitle = styled(Text3)`
  ${mixins.font.primary.bold};
  text-transform: capitalize;
  display: flex;
  align-items: center;
  & > .anticon {
    margin-left: 6px;
  }
  & > svg {
    width: 14px;
  }
`;
export const PopoverDescr = styled(Text1)`
  color: ${({theme}) => theme.palette.secondary.roseRed};
  display: block;
  text-transform: capitalize;
  margin-top: 4px;
`;

export const UnavailableIcon = styled.div`
  position: absolute;
  right: 8px;
  top: 2px;
`;
