import {atom} from 'recoil';

import {ButtonType} from '../ui-kit/Button/types';
import {CardT, CartResT} from '../types/orders';

export type PurchaseStateT = {
  progress?: number;
  payment?: number;
  orderNumber?: string;
  isConfirm?: boolean;
  totalPrice?: number;
  cart?: CartResT;
  cardToken?: CardT;
};
export const purchaseState = atom<PurchaseStateT | undefined>({
  key: 'purchaseState',
  default: undefined,
});

export type PurchaseAddOneUpdateFooterState = {
  progress?: number;
  step?: number;
  buttonType?: ButtonType;
  buttonTitle?: string;
  conditionButton?: boolean;
  onClick?: (id?: string, creditRedeemed?: boolean) => void;
  onClickPayment?: () => Promise<boolean | string>;
  showButton?: boolean;
  cartId?: string;
  stripeAccountId?: string;
};
export const purchaseAddOneNavFooterState = atom<PurchaseAddOneUpdateFooterState | undefined>({
  key: 'purchaseAddOneNavFooterState',
  default: undefined,
});

export const purchaseError = atom<string | undefined>({
  key: 'purchaseError',
  default: undefined,
});

export const creditsAppliedState = atom<boolean>({
  key: 'creditsAppliedState',
  default: false,
});

export const deductionAmount = atom<number | string | undefined>({
  key: 'deductionAmount',
  default: 0,
});
