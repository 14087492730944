import React from 'react';

import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {CounterWrapper, CounterButton, NumValue} from './styles';
import {ICounterProps} from './types';

export const NumericInput: React.FC<ICounterProps> = ({
  changeNumberStep,
  parent,
  name,
  step = 1,
  value = 0,
  availableQuantity = 0,
  onChange,
  disableIncrease,
  min,
  disabled,
  ...props
}) => {
  const determineValue = (el: boolean): void => {
    el ? onChange((value -= step), name) : !disableIncrease && onChange((value += step), name);
    if (changeNumberStep && parent) {
      el ? changeNumberStep(false, parent) : changeNumberStep(true, parent);
    }
  };

  return (
    <CounterWrapper {...props} $disabled={disabled}>
      <CounterButton disabled={value <= (min || 0)} onClick={() => determineValue(true)}>
        <MinusOutlined />
      </CounterButton>
      <NumValue>{value <= availableQuantity ? value : availableQuantity}</NumValue>
      {/*<StyledInput value={value <= availableQuantity ? value : availableQuantity} onChange={handleChange} />*/}
      <CounterButton disabled={value >= availableQuantity || disableIncrease} onClick={() => determineValue(false)}>
        <PlusOutlined />
      </CounterButton>
    </CounterWrapper>
  );
};
