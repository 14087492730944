import React from 'react';
import {
  LogoFooterDesktopStyled,
  LogoFooterMobileStyled,
  TextFooterStyled,
  TextFooterBold,
  WebsiteLink,
  TextWebsiteStyled,
  SocialLinksWrapper,
  SocialLink,
  IconStyled,
} from './styles';
import {DesktopView, MobileView} from '../../ui-kit/AppLayout/View';

type TicketConfirmationFooterPropsT = {
  reservedDate?: string;
};

export const TicketConfirmationFooter: React.FC<TicketConfirmationFooterPropsT> = ({reservedDate}) => {
  return (
    <>
      <DesktopView>
        <LogoFooterDesktopStyled />
      </DesktopView>
      <MobileView>
        <LogoFooterMobileStyled />
      </MobileView>
      <TextFooterStyled>
        Questions? please contact us at <TextFooterBold>support@enchantchristmas.com</TextFooterBold> or vist our
        <TextFooterBold> FAQs</TextFooterBold>.
      </TextFooterStyled>
      <WebsiteLink href="https://www.enchantchristmas.com" target="_blank" rel="nofollow noopener noreferer">
        <TextWebsiteStyled>www.enchantchristmas.com</TextWebsiteStyled>
      </WebsiteLink>
      <SocialLinksWrapper>
        <SocialLink href="https://www.instagram.com" target="_blank" rel="nofollow noopener noreferer">
          <IconStyled type={'instagram'} />
        </SocialLink>
        <SocialLink href="https://www.twitter.com" target="_blank" rel="nofollow noopener noreferer">
          <IconStyled type={'twitter'} viewBox="0 0 20 15" />
        </SocialLink>
        <SocialLink href="https://www.facebook.com" target="_blank" rel="nofollow noopener noreferer">
          <IconStyled type={'facebook'} />
        </SocialLink>
      </SocialLinksWrapper>
      <TextFooterStyled>Reserved on: {reservedDate}</TextFooterStyled>
    </>
  );
};
