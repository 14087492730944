import {useEffect, useState} from 'react';
import {checkReferralsUsage, generateReferral, getReferrals} from '../queries/referrals';
import {useViewer} from './auth';
import {ReferralsDataT} from '../types/referrals';

export const useReferrals = () => {
  const viewer = useViewer();
  const [eligible, setEligible] = useState();
  const [generated, setGenerated] = useState('');
  const [referrals, setReferrals] = useState<ReferralsDataT>();
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [eligibleLoading, setEligibleLoading] = useState(false);
  const fetchGenerate = async () => {
    try {
      setLoading1(true);
      const res = await generateReferral();
      setGenerated(res?.body?.referralCode);
    } catch (error) {
      console.log('error:', error);
    } finally {
      setLoading1(false);
    }
  };

  const fetchReferrals = async () => {
    try {
      setLoading2(true);
      const res = await getReferrals();
      setReferrals(res?.body);
    } catch (error) {
      console.log('error:', error);
    } finally {
      setLoading2(false);
    }
  };

  const fetchUsage = async () => {
    if (!viewer?.email) return;
    try {
      setEligibleLoading(true);
      const res = await checkReferralsUsage(viewer?.email);
      setEligible(res?.body);
    } catch (error) {
      console.log('error:', error);
    } finally {
      setEligibleLoading(false);
    }
  };
  useEffect(() => {
    fetchGenerate();
    fetchReferrals();
    fetchUsage();
  }, []);
  return {generated, referrals, eligible, loading1, loading2, eligibleLoading};
};
