import React from 'react';
import {TLogoEmbleme} from './types';
import {StyledLogoEmblemGold, StyledLogoEmblemWhite, StyledLogoEmblemBlack} from './styles';

export const LogoEmblem: React.FC<TLogoEmbleme> = ({variant = 'gold', ...props}) => {
  {
    switch (variant) {
      case 'gold':
        return <StyledLogoEmblemGold {...props} />;
      case 'white':
        return <StyledLogoEmblemWhite {...props} />;
      case 'black':
        return <StyledLogoEmblemBlack {...props} />;
      default:
        return <StyledLogoEmblemGold {...props} />;
    }
  }
};
