import {OrderT} from '../types/orders';
import {format} from 'date-fns';
import {User} from '../types/auth';
import {CurrentProps} from '../ui-kit/Order/types';
import {getTZdate, isGACat} from './orders';
import {formats} from './helpers';

export const toCreateOrderDetails = (order?: OrderT, user?: User | null) => {
  const eventDate = order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '';
  const eventArrival = order?.tickets?.find((el) => isGACat(el.eventTemplateName));
  const eventArrivalStart =
    eventArrival?.eventStartDate && getTZdate(eventArrival?.eventStartDate, order).format(formats.timeStart);
  const eventArrivalEnd =
    eventArrival?.eventEndDate && getTZdate(eventArrival?.eventEndDate, order).format(formats.timeEnd);

  const items: CurrentProps[] = [];
  const upgrades = order?.tickets?.filter(
    (el) => el?.eventTemplateName !== 'Parking' && el?.eventTemplateCategory === 'Addon',
  );
  upgrades?.forEach((el, i) => {
    const label = `${el?.eventTemplateName}, Check In Time`;
    const id = el?.id || 100 + i;
    const value = `${el?.eventStartDate && getTZdate(el?.eventStartDate, order).format(formats.timeStart)} - ${
      el?.eventEndDate && getTZdate(el?.eventEndDate, order).format(formats.timeEnd)
    }`;
    if (!items?.some((item) => item?.label === label)) items?.push({label, id, value});
  });

  const dataOrderDetails = [
    {label: 'Order Number', value: order?.ticketureOrderNumber || '', id: '1'},
    {label: 'Location', value: `${order?.sellerName || ''} - ${order?.sellerVenue || ''}`, id: '2'},
    {label: 'Event date', value: eventDate || '', id: '3'},
    {
      label: 'Event Arrival Window',
      value: eventArrivalStart && eventArrivalEnd ? `${eventArrivalStart} - ${eventArrivalEnd}` : '',
      id: '4',
    },
    ...items,
  ];
  dataOrderDetails.push({label: 'Ordered by:', value: `${user?.firstName} ${user?.lastName}`, id: '9999'});

  const countWaivers = order?.waivers?.length;
  const viewTicketLink = order?.metadata?.viewTicketLink;

  return {dataOrderDetails, countWaivers, viewTicketLink};
};

export const toCreateFooterDate = (order?: OrderT) => {
  const reservedDate = order?.createdAt && format(new Date(order?.createdAt), 'eee, LLLL do y - p');
  return reservedDate;
};
