import {useRecoilState, useSetRecoilState} from 'recoil';
import {preloadLoading, preloadedAvailableEvents, preloadedOrders} from '../states/preload';
import {getOrders} from '../queries/orderGroup';
import {useEffect} from 'react';
import {getEventsAvailable} from '../queries/eventsGroup';
import {useViewer} from './auth';
import {uniqArray} from '../helpers/helpers';

export const usePreloadEvents = () => {
  const viewer = useViewer();
  const setLoading = useSetRecoilState(preloadLoading);
  const [orders] = useRecoilState(preloadedOrders);
  const [, setEvents] = useRecoilState(preloadedAvailableEvents);

  const sellerIds = orders?.map((el) => el.sellerId).filter(uniqArray);
  const fetch = async () => {
    if (!sellerIds?.length) return;
    setLoading((prev) => ({...prev, events: true}));
    try {
      await Promise.all(
        sellerIds?.map(async (sellerId) => {
          if (!sellerId) return;
          const res = await getEventsAvailable(sellerId);
          setEvents((prev) => ({...prev, [sellerId as string]: {...res?.body, sellerId}}));
        }),
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({...prev, events: false}));
    }
  };
  useEffect(() => {
    if (!viewer) return;
    fetch();
  }, [orders?.length, viewer?.id]);
};

export const usePreloadOrders = () => {
  const viewer = useViewer();
  const setLoading = useSetRecoilState(preloadLoading);
  const [, setOrders] = useRecoilState(preloadedOrders);
  const fetch = async () => {
    setLoading((prev) => ({...prev, orders: true}));
    try {
      const response = await getOrders();
      setOrders(response.body);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({...prev, orders: false}));
    }
  };
  useEffect(() => {
    if (!viewer) return;
    fetch();
  }, [viewer?.id]);
  useEffect(() => {
    setInterval(() => {
      fetch(); //update orders each 10 minutes
    }, 600000);
  }, []);
};
