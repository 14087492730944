import React, {useEffect} from 'react';
import {DashboardPage} from '../../components/Dashboard';
import {useGetOrders, useResetStates} from '../../hooks/orders';
import {toCreateOrderData, toCreateViewerDetails} from '../../helpers/dashboard';
import {useViewer} from '../../hooks/auth';
import {useUserAccountAnalytics} from '../../hooks/customerIO';
import {sortOrders} from '../../helpers/orders';

export const Dashboard = () => {
  useResetStates();
  const viewer = useViewer();
  const viewerDetails = toCreateViewerDetails(viewer);

  const {orders} = useGetOrders();
  const ordersData = toCreateOrderData(orders);
  const {upcomingOrders, pastOrders} = sortOrders(ordersData);
  const {onAccountVisited} = useUserAccountAnalytics();

  useEffect(() => {
    if (!orders?.length) return;
    onAccountVisited({
      pastEvents: pastOrders?.length || 0,
      upcomingEvents: upcomingOrders?.length || 0,
      totalEvents: orders?.length,
    });
  }, [orders?.length]);

  return <DashboardPage loading={false} viewerDetails={viewerDetails} />;
};
