import {ExclamationCircleFilled} from '@ant-design/icons';
import React from 'react';
import {AssignCardWrapper, AssignCardTextWrapper, AssignCardText, AssignButton} from './styles';
import {AssignCardProps} from './types';

export const AssignCard: React.FC<AssignCardProps> = ({value, buttonTitle, waiversLink}) => {
  return (
    <AssignCardWrapper>
      <AssignCardTextWrapper>
        <ExclamationCircleFilled />
        <AssignCardText>{`You have ${value} pending waivers that need to be assigned.`}</AssignCardText>
      </AssignCardTextWrapper>
      <AssignButton href={waiversLink} target={'_blank'}>
        {buttonTitle}
      </AssignButton>
    </AssignCardWrapper>
  );
};
