export const spacer = {
  _0: '4px',
  _1: '8px',
  _2: '12px',
  _3: '16px',
  _4: '20px',
  _5: '24px',
  _6: '28px',
  _7: '32px',
  _8: '36px',
  _9: '40px',
  _10: '44px',
  _11: '48px',
  _12: '52px',
  _13: '56px',
  _14: '60px',
  _15: '80px',
  _16: '120px',
};
