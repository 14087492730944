import {EventsT} from '../types/events';
import {ErrorCodes, OrderT} from '../types/orders';
import {TicketsVipPassT, TicketVipElfProps} from '../ui-kit/Cards/VipElf/types';
import {format} from 'date-fns';
import {OrderDetailsCardProps} from '../ui-kit/Cards/OrderDetailsCard/types';
import {purchaseVipTicketsT} from '../queries/types/vip';
import {useState} from 'react';
import {purchaseOrderTicketTypeT, PurchaseResT} from '../types/purchase';
import {adult_key, child_key, formatCurrencyPrice, formats, infant_key} from './helpers';
import {cartTicketsToOrderInfo, getErrorResponse, getTZdate, isGA, strToArray} from './orders';
import {User} from '../types/auth';

export type VipSelectedStateT = Record<string, boolean>;
export type setVipT = (index: string) => void;

export const toCreateVipPassData = (order?: OrderT, events?: EventsT) => {
  const [vipSelected, setVipSelected] = useState<VipSelectedStateT>({vipPass: false});
  const vipTicketAdult = events?.ticket_type?._data.find(
    (el) => el.gl_code === 'VIP Pass' && el.name.includes(adult_key),
  );
  const vipTicketChild = events?.ticket_type?._data.find(
    (el) => el.gl_code === 'VIP Pass' && el.name.includes(child_key),
  );
  const ticketsCategoryGA = order?.tickets?.filter((el) => isGA(el));
  const countAdult = ticketsCategoryGA?.filter(
    (el) => el.ticketTypeName !== child_key && el.ticketTypeName !== infant_key,
  ).length;
  const countChild = ticketsCategoryGA?.filter((el) => el.ticketTypeName === child_key).length;
  const ticketsVipPass: TicketsVipPassT[] = [];

  //<--- get ticket type ids & event sessions ids
  const vipPassTicketTypeIds = [vipTicketAdult?.id, vipTicketChild?.id].filter((el) => !!el);
  const vipPassGroupsIds = [vipTicketAdult?.ticket_group_id, vipTicketChild?.ticket_group_id].filter((el) => !!el);
  const vipPassEventTemplateIds = events?.ticket_group?._data
    ?.filter((el) => vipPassGroupsIds.includes(el?.id))
    .map((el) => el?.event_template_id);
  const vipPassEventSessions = events?.event_session?._data?.filter((el) =>
    vipPassEventTemplateIds?.includes(el?.event_template_id),
  )?.[0];
  const vipPassToPurchase: purchaseVipTicketsT[] = vipPassTicketTypeIds
    ?.map((el) => ({
      ticket_type_id: el || '',
      event_session_id: vipPassEventSessions?.id || '',
    }))
    .filter((el) => el?.ticket_type_id && el?.event_session_id);
  //----->

  if (countAdult && countAdult > 0) {
    ticketsVipPass.push({
      type: `VIP Access Adult ($${Number(vipTicketAdult?.currency_amount || 0).toFixed(2)})`,
      name: `VIP Access Adult`,
      price: Number(vipTicketAdult?.currency_amount || 0),
      id: '1',
      count: countAdult,
      index: 'vipPass',
    });
  }
  if (countChild && countChild > 0) {
    ticketsVipPass.push({
      type: `VIP Access ${child_key} ($${Number(vipTicketChild?.currency_amount || 0).toFixed(2)})`,
      name: `VIP Access ${child_key}`,
      price: Number(vipTicketChild?.currency_amount || 0),
      id: '2',
      count: countChild,
      index: 'vipPass',
    });
  }
  const vipPassData: TicketVipElfProps[] = [
    {
      type: 'VIP Pass',
      description: 'Save time and head to the VIP line for The Maze, ice Skating and Santa!',
      ticketType: ticketsVipPass,
      isActive: true,
      id: `${order?.id}`,
      index: 'vipPass',
    },
  ];
  const payment = ticketsVipPass.reduce((acc, el) => {
    return acc + el.count * el.price;
  }, 0);

  const setVip = (index: string) => setVipSelected((prev) => ({...prev, [index]: !prev[index]}));
  return {vipPassData, ticketsVipPass, payment, vipPassToPurchase, setVip, vipSelected};
};

export const toCreatedataOrderDetails = (
  order?: OrderT,
  events?: EventsT,
  purchaseRes?: PurchaseResT,
  viewer?: User | null,
) => {
  const {payment, vipPassData} = toCreateVipPassData(order, events);
  // const ticketsCategories: string[] = [];
  // order?.tickets?.forEach((el) => {
  //   if (el.ticketGroupName && ticketsCategories.includes(el.ticketGroupName)) {
  //     return;
  //   } else {
  //     el.ticketGroupName && ticketsCategories.push(el.ticketGroupName);
  //   }
  // });
  // console.log(ticketsCategories);

  const ticketsVip = vipPassData[0].ticketType.map((el) => `${el.count} x ${el.type}`);

  const currentTickets = [
    {
      name: 'VIP',
      id: '1',
      ticket: ticketsVip,
      amount: payment,
    },
  ];
  const currentSubtotal = currentTickets.reduce((acc, el) => {
    return acc + el.amount;
  }, 0);
  const currentTax = 0;
  const currentFacilityFree = 0;
  const currentTotal = currentSubtotal + currentTax + currentFacilityFree;

  const dataOrderDetails: OrderDetailsCardProps = {
    location: `${order?.sellerName} - ${order?.sellerVenue}`,
    eventDate: order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '',
    id: `${order?.ticketureOrderId}`,
    ticketType: currentTickets,
    subtotal: `$${currentSubtotal.toFixed(2)}`,
    tax: `$${currentTax.toFixed(2)}`,
    facilityFree: `$${currentFacilityFree.toFixed(2)}`,
    total: `$${currentTotal.toFixed(2)}`,
    isProtection: order?.visitProtection,
    orderedBy: `${viewer?.firstName} ${viewer?.lastName}`,
    orderNumber: order?.ticketureOrderNumber,
    discountName: purchaseRes?.cartmod?._data?.[0]?.name || '',
  };

  return {dataOrderDetails, currentTotal};
};

export const toCreateEventTemplateId = (events?: EventsT) => {
  // const eventVipPassI = events?.event_template?._data.find((el) => el.name === 'VIP Pass');
  // const eventVipPass = events?.event_template?._data.find((el) => el.name?.includes('VIP Express'));
  const eventVipPass = events?.event_template?._data.find((el) => el.name?.includes('VIP Experience'));
  const eventTemplateId = eventVipPass && eventVipPass?.id;
  return eventTemplateId;
};

export const toCreateCurrentDate = (order?: OrderT) => {
  const onDate = order?.eventDate && format(new Date(order?.eventDate), 'yyyy-LL-dd');
  return onDate;
};

export const calcVipPayment = (vipSelected: VipSelectedStateT, tickets: TicketsVipPassT[]) => {
  const isSelected = Object.values(vipSelected).filter((el) => el).length;
  const payment_vipPass = isSelected
    ? tickets
        .filter((el) => el.index === 'vipPass')
        .reduce((prev, current) => prev + current?.count * Number(current.price || 0), 0)
    : 0;
  const payment_elfPass = isSelected
    ? tickets
        .filter((el) => el.index === 'elfPass')
        .reduce((prev, current) => prev + current?.count * Number(current.price || 0), 0)
    : 0;

  return {payment: payment_vipPass + payment_elfPass};
};

export type getPaymentFormInfoT = {
  total?: string;
  subTotal?: string;
  facilityFee?: string;
  tax?: string;
  subtotal_numeric?: number;
  balance_numeric?: number;
  balance?: string;
  totalPaid?: string;
  items?: purchaseOrderTicketTypeT[];
  discount?: string;
  discountName?: string;
  stripeAccountId?: string;
  eventArrival?: string;
  iceSkatingArrival?: string;
  parkingArrival?: string;
};
export const getPaymentFormInfo = (
  order?: OrderT,
  purchaseState?: PurchaseResT,
  events?: EventsT,
  changeFee?: purchaseOrderTicketTypeT,
): getPaymentFormInfoT => {
  if (!purchaseState) return {};
  const _prices = purchaseState?.cart_fees?._data?.[0]?.grand_totals;
  const currency = purchaseState?.cart_fees?._data?.[0]?.currency_code || 'USD';
  const tax = Number(_prices?.total_fee_percent_outside);
  const facilityFee = Number(_prices?.total_fee_fixed_outside);
  const total = Number(_prices?.total_after_fees_price);
  const subtotal = Number(_prices?.total_before_cartmod_price);
  const balance = Number(_prices?.total_outstanding) > 0 ? Number(_prices?.total_outstanding) : 0;
  const totalPaid = Number(_prices?.total_payments);
  const discount =
    purchaseState?.cartmod?._data?.map((el) => Number(el?.discount || 0))?.reduce((p, c) => p + c, 0) || 0;
  const tickets = purchaseState?.ticket?._data?.filter((el) => el?.handler === 'tickets');
  const items = cartTicketsToOrderInfo(events, tickets, order?.sellerTimezone);
  changeFee && items.push(changeFee);
  const stripe_fees = purchaseState?.cart_fees?._data?.find((el) => el.gateway_id === 'stripe');
  const stripeAccountId = stripe_fees?.account_id;
  return {
    total: formatCurrencyPrice(currency).format(total),
    subTotal: formatCurrencyPrice(currency).format(subtotal),
    facilityFee: formatCurrencyPrice(currency).format(facilityFee),
    tax: formatCurrencyPrice(currency).format(tax),
    balance: formatCurrencyPrice(currency).format(balance),
    totalPaid: formatCurrencyPrice(currency).format(totalPaid),
    subtotal_numeric: subtotal,
    balance_numeric: balance,
    items,
    discount: discount ? formatCurrencyPrice(currency).format(discount * -1) : '',
    discountName: purchaseState?.cartmod?._data?.[0]?.name || '',
    stripeAccountId,
  };
};

export const formatVipError = (e: unknown) => {
  const response = getErrorResponse(e);
  const error = response.details?.data?._data?.[0];
  if (error?._code === ErrorCodes.ticket_group_capacity_exceeded) return error?._description;
  return 'Sorry! Something unexpected has happened on our end. Our engineers have been notified. Please try again later.';
};

export const checkHiddenSessionsVip = (order?: OrderT, events?: EventsT) => {
  if (!events) return {available: false, evLoading: !events};
  let canUpgrade = true;
  const gaTicket = order?.tickets?.find((el) => isGA(el));
  const arrivalTime = getTZdate(gaTicket?.eventStartDate, order).format(formats.timeStart);
  const arrivalDate = getTZdate(gaTicket?.eventStartDate, order).format(formats.onDate);

  const vipTemplate = events?.event_template?._data?.find((el) => el?.name?.toLowerCase()?.includes('vip'));
  const hiddenSessionsByTemplate = events?.meta?._data?.find(
    (el) => el?.resource_id === vipTemplate?.id && el?.metakey === 'hide_session',
  );
  const sessions: string[] = strToArray(hiddenSessionsByTemplate?.value);
  sessions.forEach((session) => {
    const session_ = session?.replaceAll('pm', '');
    if (session_.includes(arrivalDate) && session_.includes(arrivalTime)) canUpgrade = false;
  });

  return {available: canUpgrade, evLoading: !events};
};
