import React, {PropsWithChildren} from 'react';
import {
  TooltipWrapper,
  TooltipDescription,
  TooltipIcon,
  VisitProtectionTooltipWrapper,
  VisitProtectionTooltipTitle,
  DescriptionWrapper,
  VisitProtectionTooltipDescription,
} from './styles';
import {Tooltip} from 'antd';
import {CheckCircleFilled} from '@ant-design/icons';

const facilityFeeText = (
  <TooltipWrapper>
    <TooltipDescription>
      Facility fee covers third party and other associated ticket expenses. Thank you for your support in bringing
      Enchant to you.
    </TooltipDescription>
  </TooltipWrapper>
);

export const FacilityFeeTooltip = () => {
  return (
    <Tooltip placement="bottom" title={facilityFeeText} overlayInnerStyle={{padding: '0px', borderRadius: '12px'}}>
      <TooltipIcon />
    </Tooltip>
  );
};

const visitProtectionText = (
  <VisitProtectionTooltipWrapper>
    <VisitProtectionTooltipTitle>Visit Protection Ticket</VisitProtectionTooltipTitle>
    <DescriptionWrapper>
      <VisitProtectionTooltipDescription>
        Flexibility to change the date and time of your event or cancel and request a refund of your order up to a hour
        in advance of your scheduled event time.
      </VisitProtectionTooltipDescription>
      {/* <VisitProtectionTooltipDescription>
        Ticket protection allows you to move your current tickets to an exact ticket type and price without incurring
        any additional charges.
      </VisitProtectionTooltipDescription>
      <VisitProtectionTooltipDescription>
        Should there be a price difference between your original ticket and the new ticket the ticket protection will
        only cover the original ticket price and the change fee, you will be responsible for any additional charges.
      </VisitProtectionTooltipDescription> */}
    </DescriptionWrapper>
  </VisitProtectionTooltipWrapper>
);

export const VisitProtectionTooltip: React.FC<PropsWithChildren> = ({children}) => {
  return (
    <Tooltip placement="top" title={visitProtectionText} overlayInnerStyle={{padding: '0px', borderRadius: '12px'}}>
      {children} <CheckCircleFilled />
    </Tooltip>
  );
};
