import React from 'react';
import {FooterNavigation, FooterLeftMobileComponent} from '../../../ui-kit/AppLayout/FooterNavigation';
import {DesktopView, MobileView} from '../../../ui-kit/AppLayout/View';
import {useUpgradeVipNavigation} from '../../../hooks/navigation';
import {formatCurrencyPrice} from '../../../helpers/helpers';
import {useRecoilState} from 'recoil';
import {deductionAmount} from '../../../states/purchase';

export const UpgradeVipFooter = () => {
  const [netTotal] = useRecoilState(deductionAmount);
  const navigation = useUpgradeVipNavigation();
  const showLabel = !!navigation?.subtotal;

  const paymentLabelDesktop = navigation?.cartId ? 'Balance Due:' : 'Additional Payment:';
  const paymentLabel = navigation?.cartId ? 'Balance Due:' : 'Subtotal:';
  const paymentCount = netTotal
    ? netTotal
    : showLabel
    ? `+${formatCurrencyPrice('USD').format(navigation?.subtotal || 0)}`
    : undefined;
  const checkoutDisabled = navigation?.checkout ? !navigation.checkout.cardFilled : false;
  const buttonLabel = navigation?.cartId ? 'Confirm Purchase' : 'Next';
  return (
    <>
      <DesktopView>
        <FooterNavigation
          component={showLabel ? <FooterLeftMobileComponent title={paymentLabelDesktop} value={paymentCount} /> : null}
          buttonType={navigation?.buttonType}
          buttonTitle={navigation?.buttonTitle}
          onClick={navigation?.onNextClick}
          conditionButton={checkoutDisabled || navigation?.conditionButton}
          colorLineTop="gold"
        />
      </DesktopView>
      <MobileView>
        <FooterNavigation
          buttonType={'primary'}
          buttonTitle={buttonLabel}
          component={showLabel ? <FooterLeftMobileComponent title={paymentLabel} value={paymentCount} /> : null}
          onClick={navigation?.onNextClick}
          conditionButton={checkoutDisabled || navigation?.conditionButton}
          colorLineTop="grey"
        />
      </MobileView>
    </>
  );
};
