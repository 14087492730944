import styled from 'styled-components';
import {Checkbox} from 'antd';

export const StyledCheckbox = styled(Checkbox)`
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({theme}) => theme.palette.secondary.lightRose};
    border-color: ${({theme}) => theme.palette.secondary.seaBlue};
  }
  .ant-checkbox-input {
    :hover + .ant-checkbox-inner {
      border-color: ${({theme}) => theme.palette.secondary.seaBlue};
    }
    :focus + .ant-checkbox-inner {
      border-color: ${({theme}) => theme.palette.secondary.seaBlue};
    }
  }
  .ant-checkbox-checked:after {
    border-color: ${({theme}) => theme.palette.secondary.seaBlue};
  }
`;
