import React, {useState} from 'react';
import {CheckBoxFieldWrapper, ContentWrapper, ErrorWrapper, PageWrapper, TabContent, TitleDescr} from './styles';
import {TitleMedium} from '../styles';
import {TitleWrapper} from '../Cancellation/styles';
import {capitalize} from '../../helpers/helpers';
import {CenteredLoader} from '../../ui-kit/Loader';
import {User, UserMetadata} from '../../types/auth';
import {Tab, Tabs} from '../../ui-kit/Tabs';
import {SaveUserValuesT} from '../../hooks/user';
import {MyProfile} from './MyProfile';
import {CustomCheckbox} from '../../ui-kit/Checkbox';
import {SmallBold} from '../../ui-kit/Typography';
import {TextBody2} from '../../ui-kit/Typography/styles';
import {Button} from '../../ui-kit/Button';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';

interface ProfileProps {
  loading?: boolean;
  viewer?: User;
  currentTab?: number;
  onSelect?: (index: number) => void;
  handleUpdate: (values: SaveUserValuesT) => void;
  handlePictureUpload: (values: any) => void;
  updateLoading?: boolean;
  updatePreferences?: (values: UserMetadata['promotionsConsent']) => Promise<void>;
  isError?: boolean;
}

const Profile: React.FC<ProfileProps> = ({
  loading,
  viewer,
  currentTab,
  onSelect,
  handlePictureUpload,
  handleUpdate,
  updateLoading,
  updatePreferences,
  isError,
}) => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <TitleDescr>{capitalize(`Welcome, ${viewer?.firstName?.toLocaleLowerCase()}!`)}</TitleDescr>
          <TitleMedium>{'Manage Profile'}</TitleMedium>
        </TitleWrapper>
        {loading ? (
          <CenteredLoader variant={'light'} />
        ) : (
          <Tabs preSelectedTabIndex={currentTab === -1 ? 0 : currentTab} onSelect={onSelect}>
            <Tab key={1} buttonTab={true} title="My Profile">
              <MyProfile
                viewer={viewer}
                handlePictureUpload={handlePictureUpload}
                handleUpdate={handleUpdate}
                updateLoading={updateLoading}
                isError={isError}
              />
            </Tab>
            <Tab key={2} buttonTab={true} title="Preferences">
              <Preference updatePreferences={updatePreferences} viewer={viewer} isError={isError} />
            </Tab>
          </Tabs>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Profile;

interface PreferenceProps {
  updatePreferences?: (values: UserMetadata['promotionsConsent']) => Promise<void>;
  viewer?: User;
  isError?: boolean;
}

const Preference: React.FC<PreferenceProps> = ({viewer, updatePreferences, isError}) => {
  const [isChanged, setIsChanged] = useState(false);
  const [fields, setFields] = useState({
    sms: viewer?.metadata.promotionsConsent?.sms,
    email: viewer?.metadata.promotionsConsent?.email,
  });
  const onChange = (e: CheckboxChangeEvent) => {
    setFields((prev) => ({...prev, [e.target.name as string]: e.target.checked}));
    setIsChanged(true);
  };
  const onSubmit = () => {
    updatePreferences?.(fields);
    setIsChanged(false);
  };
  return (
    <>
      <TabContent>
        <SmallBold>Promotions</SmallBold>
        <CheckBoxFieldWrapper>
          <CustomCheckbox id={'email'} name="email" onChange={onChange} checked={fields.email} />
          <label htmlFor={'email'}>
            <TextBody2>I would like to receive marketing emails and all the good things at Enchant.</TextBody2>
          </label>
        </CheckBoxFieldWrapper>
        <CheckBoxFieldWrapper>
          <CustomCheckbox id={'sms'} name="sms" onChange={onChange} checked={fields.sms} />
          <label htmlFor={'sms'}>
            <TextBody2>I would like to receive SMS messages with good updates and promotions.</TextBody2>
          </label>
        </CheckBoxFieldWrapper>
        <CheckBoxFieldWrapper>
          <TextBody2>
            NOTE: Transactional messages (receipts, password reset, etc.). will be sent to keep you informed about your
            Enchant purchase.
          </TextBody2>
        </CheckBoxFieldWrapper>
        {isError && (
          <ErrorWrapper>
            <TextBody2>
              We could not update your profile at this time. Please try again or contact us for support.
            </TextBody2>
          </ErrorWrapper>
        )}
        <Button variant={'tertiary'} width={'100%'} onClick={onSubmit} disabled={!isChanged}>
          UPDATE
        </Button>
      </TabContent>
      {/* <TabContent>
        <SmallBold>Account Access</SmallBold>
        <TextBody2>
          Your account can be accessed through magic link, which you can receive on your email or SMS.
        </TextBody2>
        <CheckBoxFieldWrapper>
          <IconSvg type="check" />
          <TextBody2>Magic Link Access</TextBody2>
        </CheckBoxFieldWrapper>
        <CheckBoxFieldWrapper>
          <IconSvg type="close" />
          <TextBody2>Password Access</TextBody2>
        </CheckBoxFieldWrapper>
        <Button variant={'tertiary'} width={'100%'}>
          Setup password
        </Button>
      </TabContent> */}
    </>
  );
};
