import React from 'react';
import {FormItemProps, Tooltip} from 'antd';
import {StyledFormItem, TooltipWrapper} from './styles';
import {ItemLabelProps} from './types';
import {Typography} from 'antd';
import {palette} from '../theme/palette';
const {Text} = Typography;

export const FormItem: React.FC<FormItemProps> = ({...props}) => <StyledFormItem {...props} />;

export const ItemLabel: React.FC<ItemLabelProps> = ({label, title}) => {
  return (
    <>
      <span>{label}</span>
      <TooltipWrapper>
        <Tooltip title={<Text>{title}</Text>} color={palette.system.white} />
      </TooltipWrapper>
    </>
  );
};
