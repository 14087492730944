import styled, {css} from 'styled-components';

import {ActiveTicketsCardProps, StyledPriceCoastProps, DatesTicketsCardProps} from './types';
import {
  sellingFastGradient,
  linearGoldGradient,
  soldOutGradient,
  whiteShadow,
  noShadow,
  newSellingFastGradient,
  newSoldOutGradient,
} from '../../theme/palette';
import {StyledHeading6, TextCaption} from '../../Typography/styles';
// import {Text3} from '../../Typography';
import {Media} from '../../theme/breakpoints';

export const StyledTicketCardShy = css`
  ${whiteShadow};
`;

export const StyledTicketCard = css`
  ${noShadow}
`;

const activeTicketCardWrapper = (isActive: boolean | undefined) => {
  switch (isActive) {
    case true:
      return StyledTicketCardShy;
    default:
      return StyledTicketCard;
  }
};

export const StyledTicketCardWrapper = styled.div<ActiveTicketsCardProps>`
  ${({$isActive}) => activeTicketCardWrapper($isActive)}
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  ${({$hasAddon}) => $hasAddon && ` margin-top: 20px;`}
`;

export const AddonNameLine = styled.div`
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 8px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.secondary.brightGold};
  color: ${({theme}) => theme.palette.system.darkBlack};
  position: absolute;
  top: -20px;
  z-index: 5;
  border-radius: 6px 6px 0 0;
  text-transform: uppercase;
  font-weight: bold;

  img {
    width: 14px;
    height: 14px;
    margin-right: 12px;
  }
`;

// OLD STYLE //
export const primaryBackground = css`
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
`;

export const secondaryBackground = css`
  background-color: ${({theme}) => theme.palette.secondary.greyBackground};
`;
// ============ //

const defaultBackground = css`
  background: ${({theme}) => theme.palette.secondary.lightGold};
`;

const fastBackground = css`
  ${newSellingFastGradient}
`;

const soldBackground = css`
  ${newSoldOutGradient}
`;

const activeBackground = css`
  background-color: ${({theme}) => theme.palette.system.active};
`;

const activeTicketCardBoard = (variant: 'default' | 'fast' | 'sold' | undefined, isActive: boolean | undefined) => {
  switch (variant) {
    case 'default':
      if (isActive) return activeBackground;
      return defaultBackground;
    case 'fast':
      if (isActive) return activeBackground;
      return fastBackground;
    case 'sold':
      return soldBackground;
    default:
      return defaultBackground;
  }
};

export const StyledTicketCardBoard = styled.div<DatesTicketsCardProps>`
  ${({variant, $isActive}) => activeTicketCardBoard(variant, $isActive)};
  position: relative;
  width: 100%;
  min-height: 64px;
  border-radius: 8px;
  padding: 8px 24px;
  display: flex;
  gap: 10px;
  align-items: center;
  ${Media.down.m} {
    padding: 8px 10px;
    padding: 6px 2px 6px 18px;
  }
`;

export const StyledCardFromTicketTitle = styled.div<StyledPriceCoastProps>`
  opacity: ${({variant}) => (variant !== 'sold' ? 1 : 0.5)};
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background: linear-gradient(142deg, #041c2c 0%, #1b3e55 31.09%, #133145 58.5%, #041c2c 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;
export const StyledCardFromTicketDate = styled.div<StyledPriceCoastProps>`
  font-size: 30px;
  line-height: 24px;
  font-family: Poppins Bold;
`;
export const StyledCardFromTicketDay = styled.div<StyledPriceCoastProps>`
  font-size: 15px;
  line-height: 15px;
  font-family: Poppins Bold;
  text-transform: uppercase;
`;
export const DateInnerSeparator = styled.div`
  width: 0.5px;
  height: 57px;
  background: #e4d19d;
  margin: 0 16px;
`;

export const StyledPriceCoast = styled.div<StyledPriceCoastProps>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  opacity: ${({variant}) => (variant !== 'sold' ? 1 : 0.5)};
`;

export const StyledPrice = styled(StyledHeading6)`
  line-height: 22px;
  font-size: 20px;
  background: linear-gradient(142deg, #041c2c 0%, #1b3e55 31.09%, #133145 58.5%, #041c2c 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

export const StyledCountTicket = styled(TextCaption)`
  // margin-left: ${({theme}) => theme.spacer._1};
  text-transform: capitalize;
`;

export const StyledInfo = styled(TextCaption)`
  margin-left: 10px;
  color: ${({theme}) => theme.palette.primary.alarmColor};
`;
export const StyledRed = styled(StyledInfo)`
  margin-left: 0px;
`;

export const chooseStyleCardGold = css`
  ${linearGoldGradient}
  color: ${({theme}) => theme.palette.primary.darkBlack};
  &:hover,
  &:active,
  &:focus {
    color: ${({theme}) => theme.palette.primary.darkBlack};
    ${linearGoldGradient}
  }
`;

export const anotherStyleCardGold = css`
  ${sellingFastGradient}
  color: ${({theme}) => theme.palette.primary.darkBlack};
  &:hover,
  &:active,
  &:focus {
    color: ${({theme}) => theme.palette.primary.darkBlack};
    ${sellingFastGradient}
  }
`;
export const newAnotherStyleCardGold = css``;

const activeStyleTickedCardWrapper = ($isActive: boolean | undefined) => {
  switch ($isActive) {
    case true:
      return newAnotherStyleCardGold;
    default:
      return newAnotherStyleCardGold;
  }
};

export const StyledTickedCardWrapper = styled.div<ActiveTicketsCardProps>`
  ${({$isActive}) => activeStyleTickedCardWrapper($isActive)}
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 100%;
  border-radius: 0px 8px 8px 0px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// OLD STYLE //
export const goldStyledButton = css`
  ${sellingFastGradient}
  color: ${({theme}) => theme.palette.primary.darkBlack};
  &:hover,
  &:active,
  &:focus {
    ${sellingFastGradient}
    color: ${({theme}) => theme.palette.primary.darkBlack};
  }
  &:disabled {
    color: ${({theme}) => theme.palette.system.white};
    background: ${({theme}) => theme.palette.primary.brownBtn};
    opacity: 0.5;
  }
`;

export const redStyledButton = css`
  ${soldOutGradient}
  color: ${({theme}) => theme.palette.primary.darkBlack};
  &:hover,
  &:active,
  &:focus {
    color: ${({theme}) => theme.palette.primary.darkBlack};
    ${soldOutGradient}
  }
`;

export const noStyledBackground = css`
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  color: ${({theme}) => theme.palette.primary.darkBlack};
  &:hover,
  &:active,
  &:focus {
    color: ${({theme}) => theme.palette.primary.darkBlack};
    background-color: ${({theme}) => theme.palette.secondary.lightGold};
  }
`;
// ============ //

const defaultButton = css`
  background: transparrent;
`;

const fastButton = css`
  background: transparrent;
`;

const soldButton = css``;

const activeTickedCardButton = (variant: 'default' | 'fast' | 'sold') => {
  switch (variant) {
    case 'fast':
      return fastButton;
    case 'sold':
      return soldButton;
    case 'default':
      return defaultButton;
    default:
      return defaultButton;
  }
};

export const StyledTickedCardButton = styled.div<DatesTicketsCardProps>`
  ${({variant}) => activeTickedCardButton(variant)};
  position: absolute;
  height: 100%;
  top: 0;
  right: 24px;
  width: 140px;
  border-radius: 0px 8px 8px 0px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${Media.down.s} {
    width: 70px;
    right: 10px;
  }
`;

export const StyledLeftWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const SoldoutLine = styled.div``;
