import React from 'react';

import CardFromTicket from './CardFromTicket';
import {TicketCardPropsT} from './types';
import {formatCurrencyInteger} from '../../../helpers/helpers';
import {DateSeparator} from '../../Separators';
import moment from 'moment-timezone';
import {MonthTag} from '../../../components/ChangeDate/styles';

export const TicketCardsSwitch: React.FC<TicketCardPropsT> = ({
  prevData,
  data,
  onClick,
  isActiveTickedCard,
  onMonthChange,
  sellerTimezone,
  weatherProtection,
  bookedSessions,
  addons,
  modals,
  specialNights,
  // showMonthLabel,
}) => {
  let isWeekdays = false;
  let isWeekend = false;
  const addonName = addons?.[0]?.template?.name;

  const isActive = isActiveTickedCard === data.id;
  const totalCoast = formatCurrencyInteger(data.price);

  const calendarDate = moment.tz(data?.title, sellerTimezone ?? '').format('L');
  const specialNight = specialNights?.find((el) => el?.dates?.includes(calendarDate));
  const cardClick = () => {
    onClick(data.id);
    if (addonName?.toLocaleLowerCase()?.includes('paws')) return modals?.pnc?.open();
    if (specialNight?.name) return modals?.specialNight?.open(specialNight.name);
  };
  if (data.variant) {
    const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
    const weekends = ['Sat', 'Sun'];
    const theDay = moment.tz(data?.title, sellerTimezone ?? '').format('ddd');
    const prevDay = moment.tz(prevData?.title, sellerTimezone ?? '').format('ddd');

    const month = moment.tz(data?.title, sellerTimezone ?? '').format('MMMM');
    const prevMonth = moment.tz(prevData?.title, sellerTimezone ?? '').format('MMMM');

    const monthLabel = month !== prevMonth ? month : '';
    const handleMonthChange = (newVal: number) => {
      if (newVal) {
        onMonthChange?.(newVal);
      }
    };
    if (!prevData) {
      if (weekdays.includes(theDay)) {
        isWeekdays = true;
      }
      if (weekends.includes(theDay)) {
        isWeekend = true;
      }
    } else {
      const endOfWeekdays = weekends.includes(prevDay) && weekdays.includes(theDay);
      const endOfWeekend = weekdays.includes(prevDay) && weekends.includes(theDay);
      isWeekdays = endOfWeekdays;
      isWeekend = endOfWeekend;
    }
    return (
      <>
        {monthLabel && <MonthTag>{monthLabel}</MonthTag>}
        {isWeekdays && <DateSeparator date={'WEEKDAYS'} />}
        {isWeekend && <DateSeparator date={'WEEKEND'} />}
        <CardFromTicket
          totalCoast={totalCoast}
          isActive={isActive}
          key={data.id}
          onClick={cardClick}
          data={data}
          onMonthChange={handleMonthChange}
          isActiveTickedCard={isActiveTickedCard}
          sellerTimezone={sellerTimezone}
          weatherProtection={weatherProtection}
          bookedSessions={bookedSessions}
          addonName={addonName}
          modals={modals}
          specialNight={specialNight}
        />
      </>
    );
  }
  return null;
};

export default TicketCardsSwitch;
