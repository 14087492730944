import React from 'react';
import {Route, Routes} from 'react-router-dom';

import {ConfirmChangeDate} from './containers/ConfirmChangeDate';
import {route} from './constants/routes';
import {AddOnePurchase} from './containers/AddOnePurchase';
import {Cancellation} from './containers/Cancellation';
import {ChangeDate} from './containers/ChangeDate';
import {ConfirmPurchaseOne} from './containers/ConfirmPurchaseOne';
import {ConfirmUpgradeVip} from './containers/ConfirmUpgradeVip';
import {Dashboard} from './containers/Dashboard';
import {ManageOrder} from './containers/ManageOrder';
import {PrivateRoute} from './containers/PrivateRoute';
import {UpgradeVip} from './containers/UpgradeVip';
import {CancelConfirmOrder} from './containers/CancelConfirmOrder';
import {MagicLinkAccess} from './containers/MagicLink';
import {OrderNotFound} from './containers/OrderNotFound';
import {PurchaseAdmission} from './containers/PurchaseAdmission';
import ProfileContainer from './containers/Profile';
import {Referrals} from './containers/Referrals';
import {OrdersContainer} from './containers/Orders';

const PublicRoutes = [
  <Route key="magic-link-access" path={route.magicLinkAccess.path} element={<MagicLinkAccess />} />,
  <Route key="manageOrder" path={route.manageOrder.path} element={<ManageOrder />} />,
  <Route key="order-not-found" path={route.orderNotFoundPage.path} element={<OrderNotFound />} />,
];

const PrivateRoutes = [
  <Route
    key="dashboard"
    path={route.dashboard.path}
    element={
      <PrivateRoute path={route.dashboard.path}>
        <Dashboard />
      </PrivateRoute>
    }
  />,
  <Route
    key="orders"
    path={route.orders.path}
    element={
      <PrivateRoute path={route.dashboard.path}>
        <OrdersContainer />
      </PrivateRoute>
    }
  />,

  <Route
    key="referrals"
    path={route.referrals.path}
    element={
      <PrivateRoute path={route.referrals.path}>
        <Referrals />
      </PrivateRoute>
    }
  />,

  <Route
    key="profile"
    path={route.profile.path}
    element={
      <PrivateRoute path={route.profile.path}>
        <ProfileContainer />
      </PrivateRoute>
    }
  />,

  <Route
    key="changeDate"
    path={route.changeDate.path}
    element={
      <PrivateRoute path={route.changeDate.path}>
        <ChangeDate />
      </PrivateRoute>
    }
  />,
  <Route
    key="confirmOrder"
    path={route.confirmOrder.path}
    element={
      <PrivateRoute path={route.confirmOrder.path}>
        <ConfirmChangeDate />
      </PrivateRoute>
    }
  />,
  <Route
    key="cancel-visit"
    path={route.cancellationPage.path}
    element={
      <PrivateRoute path={route.cancellationPage.path}>
        <Cancellation />
      </PrivateRoute>
    }
  />,
  <Route
    key="add-ons-Purchase"
    path={route.addPurchase.path}
    element={
      <PrivateRoute path={route.addPurchase.path}>
        <AddOnePurchase />
      </PrivateRoute>
    }
  />,

  <Route
    key="upgrade-vip"
    path={route.upgradeVipPage.path}
    element={
      <PrivateRoute path={route.upgradeVipPage.path}>
        <UpgradeVip />
      </PrivateRoute>
    }
  />,
  <Route
    key="confirm-order-upgrade-vip"
    path={route.confirmOrderVipPage.path}
    element={
      <PrivateRoute path={route.confirmOrderVipPage.path}>
        <ConfirmUpgradeVip />
      </PrivateRoute>
    }
  />,
  <Route
    key="confirm-order-purchase-one"
    path={route.confirmOrderPurchaseOnePage.path}
    element={
      <PrivateRoute path={route.confirmOrderPurchaseOnePage.path}>
        <ConfirmPurchaseOne />
      </PrivateRoute>
    }
  />,
  <Route
    key="cancel-confirm-order"
    path={route.cancelConfirmOrderPage.path}
    element={
      <PrivateRoute path={route.cancelConfirmOrderPage.path}>
        <CancelConfirmOrder />
      </PrivateRoute>
    }
  />,
  <Route
    key="purchase-admission"
    path={route.purchaseAdmission.path}
    element={
      <PrivateRoute path={route.purchaseAdmission.path}>
        <PurchaseAdmission />
      </PrivateRoute>
    }
  />,
  <Route
    key="confirm-order-purchase-admission"
    path={route.confirmPurchaseAdmission.path}
    element={
      <PrivateRoute path={route.confirmPurchaseAdmission.path}>
        <ConfirmPurchaseOne />
      </PrivateRoute>
    }
  />,
];

const RoutesSwitch: React.FC = () => {
  return (
    <Routes>
      {PublicRoutes}
      {PrivateRoutes}
    </Routes>
  );
};

export default RoutesSwitch;
