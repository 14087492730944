import {useRecoilState} from 'recoil';
import {userState} from '../states/session';
import {useEffect, useState} from 'react';
import {User, UserMetadata} from '../types/auth';
import {removeEmptyFields} from '../helpers/helpers';
import {updateUserQuery, uploadUserImageQuery} from '../queries/user';
import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {useUserAccountAnalytics} from './customerIO';
import {notification} from 'antd';

export type SaveUserValuesT = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  gender?: string;
  dateOfBirth?: string;
  relationshipStatus?: string;
  address?: any;
};

export const useUpdateUser = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useRecoilState(userState);
  const {onProfileUpdated} = useUserAccountAnalytics();
  const [error, setError] = useState<boolean>(false);

  const handleUpdate = async (values: SaveUserValuesT) => {
    const {email, firstName, phoneNumber, lastName, ...metadata} = values;
    const userMetadata = user?.metadata || {};
    const body = removeEmptyFields({email, firstName, lastName, phoneNumber, metadata: {...userMetadata, ...metadata}});
    setLoading(true);
    try {
      setError(false);
      const res = await updateUserQuery(body);
      if (res?.body?.email) setUser(res.body);
      onProfileUpdated(values);
    } catch (error) {
      console.error('update error:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePictureUpload = async (values: any) => {
    const avatar = values.target?.files?.[0];
    if (!avatar) return;
    try {
      setError(false);
      const res = await uploadUserImageQuery({avatar});
      if (res?.body) setUser(res.body);
    } catch (e) {
      setError(true);
      console.error(e);
    }
  };

  const updatePreferences = async (promotions: UserMetadata['promotionsConsent']) => {
    const body = {metadata: {...(user?.metadata || {}), promotionsConsent: promotions}};
    setLoading(true);
    try {
      setError(false);
      const res = await updateUserQuery(body);
      if (res?.body?.email) setUser(res.body);
      notification.success({
        message: 'Preferences updated',
        duration: 1.5,
        placement: 'bottomRight',
      });
    } catch (error) {
      console.error('update error:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  return {handleUpdate, handlePictureUpload, loading, updatePreferences, error};
};

const toInitialState = (initial?: User): SaveUserValuesT => ({
  firstName: initial?.firstName,
  lastName: initial?.lastName,
  dateOfBirth: initial?.metadata?.dateOfBirth,
  address: initial?.metadata?.address,
  gender: initial?.metadata?.gender,
  relationshipStatus: initial?.metadata?.relationshipStatus,
  phoneNumber: initial?.phoneNumber,
  email: initial?.email,
});

const requiredFields: {n: keyof SaveUserValuesT; l: string}[] = [
  {n: 'firstName', l: 'Please enter your FirstName'},
  {n: 'firstName', l: 'Please enter your LastName'},
  {n: 'email', l: 'Please enter your Email'},
  {n: 'phoneNumber', l: 'Please enter your Phone Number'},
];
export const useProfileForm = (initial?: User) => {
  const [error, setError] = useState('');
  const [address, setAddress] = useState(null);
  const [values, setValues] = useState<SaveUserValuesT>({});
  const handleChange = (name?: keyof SaveUserValuesT, value?: string | UserMetadata['address']) => {
    if (!name) return;
    setValues((prev) => ({...prev, [name]: value}));
  };
  const handleChangeAddress = (name?: keyof SaveUserValuesT, value?: string | UserMetadata['address']) => {
    if (!name) return;
    setValues((prev) => ({...prev, address: {...prev?.address, [name]: value}}));
  };

  useEffect(() => {
    setValues(toInitialState(initial));
  }, [!!initial]);

  //check required
  useEffect(() => {
    const field = requiredFields?.find((el) => values[el.n] === '');
    if (field) {
      setError(field.l);
    } else {
      setError('');
    }
  }, [values]);

  const selectAddress = (newValue: any) => {
    try {
      geocodeByPlaceId(newValue?.value?.place_id).then((res) => {
        const findComponent = (type: string) => res?.[0]?.address_components?.find((el) => el?.types?.includes(type));

        const country = findComponent('country')?.short_name || '';
        const streetNumber = findComponent('street_number')?.long_name || '';
        const streetAddress = findComponent('route')?.long_name || '';
        const city = findComponent('locality')?.long_name;
        const state = findComponent('administrative_area_level_1')?.long_name;
        const zipCode = findComponent('postal_code')?.long_name;
        const postalCodeSuffix = findComponent('postal_code_suffix')?.long_name;

        const obj = removeEmptyFields({
          streetAddress: `${streetNumber} ${streetAddress}`,
          city,
          state,
          zipCode: `${zipCode || ''}${postalCodeSuffix || ''}`,
          country,
        });
        setValues((prev) => ({...prev, address: obj}));
      });
      setAddress(newValue);
    } catch (error) {
      console.error('Address error:', error);
    }
  };
  const resetAddress = () => {
    setAddress(null);
    setValues((prev) => ({...prev, address: null}));
  };
  return {values, handleChange, handleChangeAddress, error, address, selectAddress, resetAddress};
};
