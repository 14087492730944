import {EventsModals} from '../../../hooks/changeDate';
import {BookedSessionsT, EventTemplateT, TicketGroupT, TicketTypeT} from '../../../types/events';
import {SpecialNightRowT} from '../../../types/orders';

export interface CurrentTicketsCardProps {
  variant: TicketType;
  title: string;
  price: number;
  countTickets: number;
  warningLabel: string;
  id: string;
  event: string;
  description?: string;
}

export type StyledPriceCoastProps = {
  variant?: TicketType;
};

export type TicketType = 'default' | 'fast' | 'sold';

export enum TypeVariant {
  default = 'default',
  fast = 'fast',
  sold = 'sold',
}

export type DateCardAddons = {
  template?: EventTemplateT;
  group?: TicketGroupT;
  ticket_type?: TicketTypeT;
};

export type TicketCardPropsT = {
  totalCoast?: string;
  isActive?: boolean;
  key: string;
  onClick: (id: string) => void;
  prevData?: DatesTicketsCardProps | undefined;
  data: DatesTicketsCardProps;
  isActiveTickedCard: string | null;
  sellerTimezone?: string;
  weatherProtection?: boolean;
  bookedSessions?: BookedSessionsT;
  onMonthChange?: (position: number) => void;
  addons?: DateCardAddons[];
  addonName?: string;
  modals?: EventsModals;
  specialNights?: SpecialNightRowT[];
  specialNight?: SpecialNightRowT;
  showMonthLabel?: boolean;
};

export type ActiveTicketsCardProps = {
  $isActive?: boolean;
  variant?: string;
  $hasAddon?: boolean;
};

export interface DatesTicketsCardProps {
  variant: TicketType;
  title?: string;
  price: number;
  countTickets: number;
  warningLabel?: string;
  id: string;
  event?: string;
  description?: string;
  capacity: number;
  soldQuantity: number;
  currentPrice?: number;
  datePrice?: number;
  $isActive?: boolean;
  addons?: DateCardAddons[];
}
