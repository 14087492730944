import superagent from 'superagent';
import * as superagentIntercept from 'superagent-intercept';
import {SERVER_URL} from '../constants/env';
import {getToken, removeToken} from '../hooks/auth';

const AuthIntercept = superagentIntercept((err: any, res: any) => {
  if (res.status == 401) {
    removeToken();
  }
});

export const postQuery = (query: string, server?: string) => {
  const bearerToken = getToken();
  if (bearerToken)
    return superagent.post(`${server || SERVER_URL}${query}`).set('Authorization', 'Bearer ' + bearerToken);

  return superagent.post(`${server || SERVER_URL}${query}`);
};

export const getQuery = (query: string, server?: string) => {
  const bearerToken = getToken();
  if (bearerToken) {
    return superagent
      .get(`${server || SERVER_URL}${query}`)
      .set('Authorization', 'Bearer ' + bearerToken)
      .use(AuthIntercept);
  }

  return superagent.get(`${server || SERVER_URL}${query}`).use(AuthIntercept);
};

export const putQuery = (query: string) => {
  const bearerToken = getToken();
  if (bearerToken) return superagent.put(`${SERVER_URL}${query}`).set('Authorization', 'Bearer ' + bearerToken);

  return superagent.put(`${SERVER_URL}${query}`);
};

export const deleteQuery = (query: string) => {
  const bearerToken = getToken();
  if (bearerToken) return superagent.delete(`${SERVER_URL}${query}`).set('Authorization', 'Bearer ' + bearerToken);

  return superagent.delete(`${SERVER_URL}${query}`);
};
