import styled from 'styled-components';
import {TextBody1} from '../../ui-kit/Typography/styles';
import {Media} from '../../ui-kit/theme/breakpoints';

export const OptionCard = styled.div<{$ghost?: boolean}>`
  width: 350px;
  box-sizing: border-box;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #fff;
  justify-content: center;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.9;
  }
  ${Media.down.m} {
    padding: 12px 6px 12px 16px;
  }
  border: 1px solid #fff;
  ${({$ghost}) =>
    $ghost &&
    `
    background: transparent;
    span {
      color: #fff;
    }
    svg {
      fill: white;
    }
  `}
`;
export const LinkWrapper = styled.a``;

export const OptTitleLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const OptDescription = styled.p`
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

export const OptTitle = styled(TextBody1)`
  font-weight: 700;
`;

export const OptionCardWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 760px;
  flex-wrap: wrap;
  gap: 10px;
  ${Media.down.m} {
    gap: 8px;
  }
`;

export const OptLogo = styled.img`
  width: 24px;
  height: 24px;
  margin: 0;
  margin-right: 8px;
`;
