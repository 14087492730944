import styled from 'styled-components';
import {Media} from '../theme/breakpoints';

export const DateSeparatorWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  font-family: 'Proxima Nova';
  font-weight: 700;
  font-style: normal;
  font-size: 15px;
  color: #ffffff;
  line-height: 24px;
`;

export const MonthSeparatorWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  font-family: 'Proxima Nova';
  font-weight: 700;
  font-style: normal;
  font-size: 15px;
  color: #ffffff;
  line-height: 24px;
`;

export const SeparatorLine = styled.div`
  width: 100%;
  height: 100%;
  max-width: 608px;
  margin: 18px 0;
  border-top: 0.5px solid #ffffff;
  ${Media.down.l} {
    display: none;
  }
`;
export const SeparatorVerticalLine = styled.div`
  width: 0;
  // height: 82vh;
  margin: 0 6%;
  border-left: 0.5px solid #ffffff;
  ${Media.down.l} {
    display: none;
  }
`;
