import React, {useState} from 'react';

import {
  // CardInfoColumn,
  CardInfoLabel,
  CardInfoTitle,
  CardInfoValue,
  OrderCardWrapperLogic,
  ValueWrapper,
  CardInfoLabelMobile,
  ValueWrapperMobile,
  CardInfoColumnWithLogic,
  CurrentCard,
  SelectedCard,
  MidIcon,
  CardInfoBody,
  Inline,
  DDicon,
  CardInfoPlaceholder,
} from './styleswithLogic';
import {OrderProps} from './typesOrderCardWithLogic';
import {viewInfoTicketDesktop} from '../../helpers/helpers';
import {DesktopView, MobileView} from '../../ui-kit/AppLayout/View';
import {CenteredLoader} from '../Loader';
import {PlusOutlined} from '@ant-design/icons';
import {IconSvg} from '../Icon';

export const OrderCardWithLogic: React.FC<OrderProps> = ({data, loading, show, icon}) => {
  const [expanded, setExpanded] = useState(false);
  const onExpand = () => setExpanded((prev) => !prev);
  const current = data?.[0];
  const selected = data?.[1];
  const colon = expanded ? '' : ':';
  return (
    <OrderCardWrapperLogic $show={show}>
      <CurrentCard>
        <DesktopView>
          <CardInfoColumnWithLogic key={current?.label}>
            <CardInfoLabel>{current?.label}</CardInfoLabel>
            <CardInfoTitle>{current?.title}</CardInfoTitle>
            <CardInfoValue>
              {!current?.value?.length && loading && <CenteredLoader />}
              {current?.value?.map((item, i) => (
                <ValueWrapper key={i}>
                  <>{Number(item?.count || 0) > 0 && viewInfoTicketDesktop(item)}</>
                </ValueWrapper>
              ))}
            </CardInfoValue>
          </CardInfoColumnWithLogic>
        </DesktopView>

        <MobileView>
          <CardInfoColumnWithLogic key={current?.label}>
            <Inline>
              <CardInfoLabelMobile>
                {current?.label}
                {colon}
              </CardInfoLabelMobile>
              {!expanded && <CardInfoTitle>{current?.mobTitle}</CardInfoTitle>}
              <DDicon $expanded={expanded} onClick={onExpand}>
                <IconSvg type="chevronDown" width="12px" height="8px" viewBox="0 0 12 8" />
              </DDicon>
            </Inline>
            {expanded && current?.expandedMobTitle && (
              <CardInfoTitle $isBold={current?.selectedBold}>{current?.expandedMobTitle}</CardInfoTitle>
            )}
            {expanded && (
              <ValueWrapperMobile>
                {current?.value?.map((item, i) => (
                  <div key={i}>{(item?.count || 0) > 0 && viewInfoTicketDesktop(item)}</div>
                ))}
              </ValueWrapperMobile>
            )}
          </CardInfoColumnWithLogic>
        </MobileView>
      </CurrentCard>
      <MidIcon>
        {icon === 'plus' ? (
          <PlusOutlined />
        ) : (
          <IconSvg type="arrowRight" width="14px" height="14px" viewBox="0 0 12 14" />
        )}
      </MidIcon>
      <SelectedCard>
        <DesktopView>
          <CardInfoColumnWithLogic key={selected?.label}>
            <CardInfoLabel>{selected?.label}</CardInfoLabel>
            <CardInfoTitle>{selected?.title}</CardInfoTitle>
            {selected?.bodyText && <CardInfoBody>{selected?.bodyText}</CardInfoBody>}
            <CardInfoValue>
              {!selected?.value?.length && loading && <CenteredLoader />}
              {selected?.value?.map((item, i) => (
                <ValueWrapper key={i}>
                  <>{Number(item?.count || 0) > 0 && viewInfoTicketDesktop(item)}</>
                </ValueWrapper>
              ))}
            </CardInfoValue>
          </CardInfoColumnWithLogic>
        </DesktopView>
        <MobileView>
          <CardInfoColumnWithLogic key={selected?.label}>
            <Inline>
              <CardInfoLabelMobile>
                {selected?.label}
                {colon}
              </CardInfoLabelMobile>
              {!expanded && <CardInfoTitle>{selected?.mobTitle}</CardInfoTitle>}
            </Inline>
            {expanded && selected?.expandedMobTitle && !selected?.mobPlaceholder && (
              <CardInfoTitle $isBold={selected?.selectedBold}>{selected.expandedMobTitle}</CardInfoTitle>
            )}
            {expanded && selected?.mobPlaceholder && (
              <CardInfoPlaceholder>{selected.mobPlaceholder}</CardInfoPlaceholder>
            )}
            {expanded && (
              <ValueWrapperMobile>
                {selected?.value?.map((item, i) => (
                  <div key={i}>{(item?.count || 0) > 0 && viewInfoTicketDesktop(item)}</div>
                ))}
              </ValueWrapperMobile>
            )}
          </CardInfoColumnWithLogic>
        </MobileView>
      </SelectedCard>
      {/* <OrderCardContentWrapperLogic $show={show}>
        {data?.map((el, i) => (
          <div key={i}>
            <DesktopView>
              <CardInfoColumnWithLogic key={el.label}>
                <CardInfoLabel>{el.label}</CardInfoLabel>
                <CardInfoTitle>{el.title}</CardInfoTitle>
                <CardInfoValue>
                  {!el?.value?.length && loading && <CenteredLoader />}
                  {el?.value?.map((item, i) => (
                    <ValueWrapper key={i}>
                      <>{Number(item?.count || 0) > 0 && viewInfoTicketDesktop(item)}</>
                    </ValueWrapper>
                  ))}
                </CardInfoValue>
              </CardInfoColumnWithLogic>
            </DesktopView>

            <MobileView>
              <CardInfoColumnWithLogic key={el.label}>
                <CardInfoLabelMobile>{el.label}</CardInfoLabelMobile>
                <ValueWrapperMobile>
                  {el?.value?.map((item, i) => (
                    <div key={i}>{(item?.count || 0) > 0 && viewInfoTicketMobile(item)}</div>
                  ))}
                </ValueWrapperMobile>
              </CardInfoColumnWithLogic>
            </MobileView>
          </div>
        ))} */}
      {/* {Number(paymentInfo?.payment || 0) >= 0 && show && (
          <DesktopView>
            <PaymentColumnWithLogic>
              <PaymentTitle>Additional Payment</PaymentTitle>
              <PaymentValue>{`+${formatCurrency(paymentInfo?.payment || 0)}`}</PaymentValue>
            </PaymentColumnWithLogic>
          </DesktopView>
        )} */}
    </OrderCardWrapperLogic>
  );
};
