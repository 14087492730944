import React, {useEffect, ChangeEvent} from 'react';
// import React from 'react';
import {TicketsInfoList} from './components/TicketInfo/TicketInfoWithLogic';
import {OrderDetailsHeader} from './components/Header';
import {useReferrals} from '../../../hooks/referral';
import InputPromo from './components/InputPromo/InputPromo';
import {PublicButtonStyled} from '../../AppLayout/FooterNavigation/styles';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {creditsAppliedState, deductionAmount} from '../../../states/purchase';
import {
  StyledCard,
  StyledText,
  StyledWrapperDescription,
  StylesDataDiscount,
  StylesRedeemCredits,
  StylesTicketType,
  FacilityFeeWrapper,
  StyledTotal,
  StyledBoldTotal,
  StyledBalance,
  Spacer,
  BalanceInfoText,
  PaidInfoText,
  DetailWrapper,
} from './styles';
import {OrderDetailsWithLogicProps} from './types';
import {TextSixteenBodyBold} from '../../Typography/styles';
import {FacilityFeeTooltip} from '../../Tooltips';
import gift from '../../assets/icons/ref_gift.png';

export const OrderDetailsCardWithLogic: React.FC<OrderDetailsWithLogicProps> = ({
  data,
  onClick,
  valueInput,
  setValueInput,
  paymentSkipping,
}) => {
  const [creditsRedeemed, setcreditsRedeemed] = React.useState(false);
  const creditsApplied = useRecoilValue(creditsAppliedState);
  const setCreditsApplied = useSetRecoilState(creditsAppliedState);
  const netTotal = useRecoilValue(deductionAmount);
  const setNetTotal = useSetRecoilState(deductionAmount);
  // const [netTotal, setNetTotal] = React.useState<netAmount>(data?.balance);
  const referralPoints = useReferrals();
  const remainingCredits = referralPoints?.referrals?.remaining;
  const click = () => onClick();
  const takeInfo = (e: ChangeEvent<HTMLInputElement>) => {
    setValueInput(e.target.value);
  };
  useEffect(() => {
    setCreditsApplied(creditsRedeemed);
    let deduction: any = '';
    const theTotal = data?.balance && parseFloat(data?.balance.replace('$', ''));
    if (creditsApplied && remainingCredits && theTotal) {
      deduction = (theTotal - remainingCredits).toFixed(2);
    } else {
      deduction = typeof theTotal === 'number' ? theTotal && theTotal.toFixed(2) : theTotal;
    }
    setNetTotal(`$${deduction}`);
  }, [creditsRedeemed, creditsApplied, deductionAmount, netTotal]);

  const applyCredits = () => {
    setcreditsRedeemed(!creditsRedeemed);
  };

  return (
    <DetailWrapper>
      {data && <OrderDetailsHeader data={data}></OrderDetailsHeader>}
      {!paymentSkipping && (
        <StyledCard>
          <StyledWrapperDescription>
            <TextSixteenBodyBold>Ticket Type</TextSixteenBodyBold>
            {/* <TextSixteenBodyBold>QTY</TextSixteenBodyBold> */}
            <TextSixteenBodyBold>Amount</TextSixteenBodyBold>
          </StyledWrapperDescription>
          <TicketsInfoList items={data?.items} />
          <Spacer />
          <InputPromo takeInfo={takeInfo} valueInput={valueInput} onClick={click} />
          {(remainingCredits as number) > 0 && (
            <StylesTicketType>
              <StylesDataDiscount>
                <StylesRedeemCredits>
                  <PublicButtonStyled variant={'plainBanner'}>
                    <img src={gift} alt="" style={{margin: '0 12px 4px 0'}} />
                    {`Enchant Credits: $${referralPoints?.referrals?.remaining}`}
                  </PublicButtonStyled>
                  <PublicButtonStyled
                    variant={creditsRedeemed ? 'plainTrans' : 'plainTransDark'}
                    onClick={applyCredits}>
                    {creditsRedeemed ? 'Remove' : 'Apply'}
                  </PublicButtonStyled>
                </StylesRedeemCredits>
              </StylesDataDiscount>
            </StylesTicketType>
          )}
          <StylesTicketType>
            <StylesDataDiscount>
              <StyledText>Subtotal</StyledText> <StyledText>{data?.subTotal}</StyledText>
            </StylesDataDiscount>
            {data?.discount && (
              <StylesDataDiscount>
                <StyledText>Discount</StyledText> <StyledText>{data?.discount}</StyledText>
              </StylesDataDiscount>
            )}
            <StylesDataDiscount>
              <StyledText>Tax</StyledText> <StyledText>{data?.tax}</StyledText>
            </StylesDataDiscount>
            {data?.isPromo && (
              <StylesDataDiscount>
                <StyledText>Promo</StyledText> <StyledText>{data?.isPromo}</StyledText>
              </StylesDataDiscount>
            )}
            <StylesDataDiscount>
              <FacilityFeeWrapper>
                <StyledText>Facility Fee</StyledText>
                <FacilityFeeTooltip />
              </FacilityFeeWrapper>
              <StyledText>{data?.facilityFee}</StyledText>
            </StylesDataDiscount>
          </StylesTicketType>
          <StyledTotal>
            <StyledBoldTotal>Total</StyledBoldTotal>
            <StyledBoldTotal>{data?.total}</StyledBoldTotal>
          </StyledTotal>
          <Spacer />
          <StyledBalance>
            <PaidInfoText>Total Paid</PaidInfoText>
            <PaidInfoText>{data?.totalPaid}</PaidInfoText>
          </StyledBalance>
          {creditsRedeemed && (
            <StyledBalance>
              <PaidInfoText>Enchant Credits</PaidInfoText>
              <PaidInfoText>{`-$${Number(referralPoints?.referrals?.remaining).toFixed(2)}`}</PaidInfoText>
            </StyledBalance>
          )}
          <StyledBalance>
            <BalanceInfoText>Balance Due</BalanceInfoText>
            <BalanceInfoText>{netTotal}</BalanceInfoText>
          </StyledBalance>
        </StyledCard>
      )}
      {/* <StyledCard>
        <StyledWrapper>
          <BoldTextBody1>Order Details1231</BoldTextBody1>
        </StyledWrapper>
        <StyledDetails>
          <StyledText>Location:</StyledText>
          <StyledGeneralInfo>{data?.location}</StyledGeneralInfo>
        </StyledDetails>
        <StyledDetails>
          <StyledText>Event Date:</StyledText>
          <StyledGeneralInfo>{data?.arrival}</StyledGeneralInfo>
        </StyledDetails>
        {data?.isProtection && (
          <LabelWrapper>
            <Label label={'Visit Protection Applicable'} type={'primary'} icon={true} />
          </LabelWrapper>
        )}
        <StyledWrapperDescription>
          <TextBody2>Ticket Type</TextBody2>
          <TextBody2>Amount</TextBody2>
        </StyledWrapperDescription>
        <TicketsInfoList items={data?.items} />
        <Spacer />
        <InputPromo takeInfo={takeInfo} valueInput={valueInput} onClick={click} />
        <StylesTicketType>
          <StylesDataDiscount>
            <StyledText>Subtotal</StyledText> <StyledText>{data?.subTotal}</StyledText>
          </StylesDataDiscount>
          <StylesDataDiscount>
            <StyledText>Tax</StyledText>
            <StyledText>{data?.tax}</StyledText>
          </StylesDataDiscount>
          {data?.isPromo && (
            <StylesDataDiscount>
              <StyledText>Promo</StyledText> <StyledText>{data?.isPromo}</StyledText>
            </StylesDataDiscount>
          )}
          <StylesDataDiscount>
            <FacilityFeeWrapper>
              <StyledText>Facility Fee</StyledText>
              <FacilityFeeTooltip />
            </FacilityFeeWrapper>
            <StyledText>{data?.facilityFee}</StyledText>
          </StylesDataDiscount>
        </StylesTicketType>
        <StyledTotal>
          <StyledBoldTotal>Total</StyledBoldTotal>
          <StyledBoldTotal>{data?.total}</StyledBoldTotal>
        </StyledTotal>
        <Spacer />
        <StyledBalance>
          <PaidInfoText>Total Paid</PaidInfoText>
          <PaidInfoText>{data?.totalPaid}</PaidInfoText>
        </StyledBalance>
        <StyledBalance>
          <BalanceInfoText>Balance Due</BalanceInfoText>
          <BalanceInfoText>{data?.balance}</BalanceInfoText>
        </StyledBalance>
      </StyledCard> */}
    </DetailWrapper>
  );
};
