import React from 'react';

import {BillingInfoWithLogic} from './PaymentForm';
import {ChangeDateContentContainer} from './styles';
import {AddPurchaisePaymentDetailProps} from './types';
import {Payment} from '../../containers/Payment';

const AddPurchaisePaymentDetail: React.FC<AddPurchaisePaymentDetailProps> = ({
  orderDetailsInfo,
  orderCreatedAt,
  stripeAccount,
  paymentSkipping,
}) => {
  return (
    <ChangeDateContentContainer>
      {orderDetailsInfo?.items?.length && (
        <Payment orderCreatedAt={orderCreatedAt} stripeAccount={stripeAccount} paymentSkipping={paymentSkipping} />
      )}
      {orderDetailsInfo?.items?.length && (
        <BillingInfoWithLogic orderDetailsInfo={orderDetailsInfo} paymentSkipping={paymentSkipping} />
      )}
    </ChangeDateContentContainer>
  );
};

export default AddPurchaisePaymentDetail;
