import React from 'react';
import {
  HeaderLayout,
  HeaderTop,
  HeaderTopLeft,
  HeaderTopRight,
  IconStarWrapper,
  HeaderLogoEmblem,
  LogoutButton,
  HeaderMobileWrapper,
  HeaderMobileTitle,
  HeaderMobileCaption,
  HeaderDesktopTitle,
  HeaderDesktopWrapper,
  HeaderDesktopCaption,
  LinkHeader,
  LinkHeaderWrapper,
} from './styles';
import {Cart} from './Cart';
import {IconSvg} from '../../Icon';
import {ProgressBar} from './ProgressBar';
import {DropDown} from './DropDown';
import {IHeaderNavigationProps} from './types';
import {DesktopView, MobileView} from '../View';
import {HomeLink} from '../../Button/LinkButton';
import {route} from '../../../constants/routes';
import {useLocation} from 'react-router-dom';

export const HeaderNavigation: React.FC<IHeaderNavigationProps> = ({
  titleDropDown,
  menuDropDown,
  onClickDropDown,
  status = false,
  count,
  percent,
  title,
  caption,
  logout,
  onLogOutClick,
  hideLogoMobile,
  centeredLogo,
  location,
  hideLinks,
}) => {
  const {pathname} = useLocation();
  return (
    <HeaderLayout>
      <HeaderTop>
        {pathname !== '/manage-order' && (
          <>
            <HeaderTopLeft $centeredLogo={centeredLogo}>
              <HomeLink>
                <HeaderLogoEmblem $showMobile={!hideLogoMobile} />
              </HomeLink>
              <DesktopView>
                {titleDropDown !== undefined && (
                  <DropDown onClick={onClickDropDown} titleDropDown={titleDropDown} menuDropDown={menuDropDown} />
                )}
              </DesktopView>
              {/* <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}> */}
              <MobileView>
                <HeaderMobileWrapper>
                  <HeaderMobileTitle>{title}</HeaderMobileTitle>
                  <HeaderMobileCaption>{caption ? caption : ''}</HeaderMobileCaption>
                </HeaderMobileWrapper>
              </MobileView>
              <DesktopView>
                <HeaderDesktopWrapper>
                  <HeaderDesktopTitle>{title}</HeaderDesktopTitle>
                </HeaderDesktopWrapper>
              </DesktopView>
              {!hideLinks && (
                <LinkHeaderWrapper>
                  <LinkHeader to={route.orders.path} isActive={pathname === route.orders.path}>
                    <MobileView>{'Orders'}</MobileView>
                    <DesktopView>{'Manage Orders'}</DesktopView>
                  </LinkHeader>
                  <LinkHeader to={route.referrals.path} isActive={pathname === route.referrals.path}>
                    Referrals
                  </LinkHeader>
                  <LinkHeader to={route.profile.path} isActive={pathname === route.profile.path}>
                    Profile
                  </LinkHeader>
                </LinkHeaderWrapper>
              )}
            </HeaderTopLeft>
          </>
        )}
        <DesktopView>
          <HeaderTopRight>
            {Boolean(location && caption) && (
              <HeaderMobileWrapper>
                <HeaderDesktopCaption>{location}</HeaderDesktopCaption>
                <HeaderDesktopCaption>{caption}</HeaderDesktopCaption>
              </HeaderMobileWrapper>
            )}
            {status && (
              <IconStarWrapper>
                <IconSvg type={'sparkGold'} width={'41'} height={'41'} viewBox="0 0 41 41" />
              </IconStarWrapper>
            )}
            {count !== undefined && <Cart count={count} />}
            {logout && (
              <LogoutButton onClick={onLogOutClick}>
                Logout
                <IconSvg type="logout" />
              </LogoutButton>
            )}
          </HeaderTopRight>
        </DesktopView>
        <MobileView>
          <HeaderTopRight>
            {logout && (
              <LogoutButton onClick={onLogOutClick}>
                <IconSvg type="logout" />
              </LogoutButton>
            )}
          </HeaderTopRight>
        </MobileView>
      </HeaderTop>
      {percent && <ProgressBar percent={percent} />}
    </HeaderLayout>
  );
};
