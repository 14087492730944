import React from 'react';
import {useGetCurrentProgress} from '../../../hooks/navigation';
import {HeaderNavigation} from '../../../ui-kit/AppLayout/HeaderNavigation';
import {commonOrderInfo} from '../../../states/common';
import {useRecoilValue} from 'recoil';

export const UpgradeVipHeader = () => {
  const progress = useGetCurrentProgress();
  const orderInfo = useRecoilValue(commonOrderInfo);
  return (
    <HeaderNavigation
      percent={progress}
      title={'Purchase Add Ons'}
      caption={`Order # ${orderInfo?.orderNumber}`}
      location={orderInfo?.sellerName}
      hideLinks
    />
  );
};
