import styled, {css} from 'styled-components';
import {ActiveTicketsCardProps, CurrentTimeCardProps} from './types';
import {whiteShadow, noShadow, newSellingFastGradient, newSoldOutGradient} from '../../theme/palette';
import {StyledHeading6, TextBody2} from '../../Typography/styles';
import {SmallBold} from '../../Typography';
import {Media} from '../../theme/breakpoints';
import {fontPrimaryBold} from '../../theme/font';

export const StyledTicketCardShy = css`
  ${whiteShadow};
`;

export const StyledTicketCard = css`
  ${noShadow}
`;

export const StyledTimeCardShy = css`
  ${whiteShadow};
`;

export const StyledTimeCardNotSelect = css`
  ${noShadow}
`;

const activeTimeCardWrapper = (isActive: boolean | undefined) => {
  switch (isActive) {
    case true:
      return StyledTimeCardShy;
    default:
      return StyledTimeCardNotSelect;
  }
};

export const StyledTimeCardWrapper = styled.div<ActiveTicketsCardProps>`
  ${({$isActive}) => activeTimeCardWrapper($isActive)};
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  ${({$isMuted}) => $isMuted && ` display: none;`};
  ${Media.down.m} {
    width: calc(50% - 4px);
  }
`;

export const StyledSoldTicket = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StylesRed = styled.div`
  color: ${({theme}) => theme.palette.primary.alarmColor};
`;

export const StylesTextRed = styled.span`
  margin-left: 6px;
`;

export const ligthNoBorderBackground = css`
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  border: 3px solid ${({theme}) => theme.palette.secondary.lightGold};
`;

export const ligthBorderBackground = css`
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  border: 3px solid ${({theme}) => theme.palette.secondary.yellowGold};
`;
export const lightBackground = css`
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  border: 3px solid ${({theme}) => theme.palette.secondary.yellowGold};
`;

export const greyBackground = css`
  background-color: ${({theme}) => theme.palette.secondary.greyBackground};
  border: 3px solid ${({theme}) => theme.palette.primary.alarmColor};
`;

export const fastBackground = css`
  ${newSellingFastGradient}
`;

export const soldBackground = css`
  ${newSoldOutGradient}
`;

export const defaultBackground = css`
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
`;

const activeBackground = css`
  background-color: ${({theme}) => theme.palette.system.active};
`;

const activeTimeCardBoard = (variant: 'default' | 'fast' | 'sold' | undefined, isActive: boolean | undefined) => {
  switch (variant) {
    case 'fast':
      if (isActive) return activeBackground;
      return fastBackground;
    case 'sold':
      return soldBackground;
    case 'default':
      if (isActive) return activeBackground;
      return defaultBackground;
    default:
      return defaultBackground;
  }
};

export const StyledTimeCardBoard = styled.div<ActiveTicketsCardProps>`
  ${({variant, $isActive}) => activeTimeCardBoard(variant, $isActive)};
  border-radius: 8px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 50px;
  position: relative;
`;

export const ligthNoBorderDefault = css`
  // border: 3px solid ${({theme}) => theme.palette.secondary.yellowGold};
`;

export const noneStyle = css``;

export const StyledBoardToDefault = styled.div<CurrentTimeCardProps>`
  ${({variant, $isActiveState}) => {
    switch (variant && $isActiveState) {
      case variant === 'default' && $isActiveState:
        return ligthNoBorderDefault;
      default:
        return noneStyle;
    }
  }};
`;

export const StyledTimeCard = styled.div<{$alignBottom?: boolean}>`
  width: 208px;
  height: 50px;
  display: flex;
  align-items: ${({$alignBottom}) => ($alignBottom ? 'end' : 'center')};
  justify-content: center;
  font-size: 17px;
`;
export const CardTime = styled(StyledHeading6)<{$isElf?: boolean}>`
  font-size: ${({$isElf}) => ($isElf ? '16px' : '18px')};
  margin-top: ${({$isElf}) => ($isElf ? '-6px' : 0)};
`;

export const TimeContain = styled.div`
  position: relative;
`;

export const DiffPrice = styled.div`
  position: absolute;
  top: 3px;
  right: 4px;
  width: 100%;
  max-width: 32px;
  font-family: 'Proxima Nova Bold';
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  display: flex;
  padding: 3px 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  box-sizing: border-box;
  color: #ffffff;
  z-index: 2;
  background: #1f4762;
`;

export const StyledWpapperDescription = styled.div<{$alignBottom?: boolean}>`
  display: flex;
  align-items: ${({$alignBottom}) => ($alignBottom ? 'end' : 'center')};
  flex-direction: column;
  justify-content: center;
  width: 208px;
  height: 100%;
  min-height: 50px;
  align-items: center;
  padding: 0 16px;
`;

export const StyledTimeCardWithDescription = styled(SmallBold)``;

export const StyledTimeCardNotTransparent = styled(StyledTimeCardWithDescription)`
  // opacity: 0.3;
`;

export const lightBottomBackground = css`
  background: ${({theme}) => theme.palette.secondary.yellowGold};
`;

export const greyBottomBackground = css`
  background: ${({theme}) => theme.palette.state.red};
`;

export const NightOwlTagHolder = styled.div`
  width: 100%;
  height: 14px;
  margin-bottom: 8px;
`;

export const NightOwlTag = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  font-family: 'Proxima Nova Bold';
  padding: 0 16px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  color: #020e16;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  width: 100%;
  height: 24px;
  gap: 8px,
  font-size: 12px,
  line-height: 14px,
  margin-bottom: 2px;
  background: #becdcc;
`;

export const transparrentBackground = css`
  background-color: transparrent;
`;

const actionDescriptionTimeCard = (variant: 'default' | 'fast' | 'sold') => {
  switch (variant) {
    case 'fast':
      return transparrentBackground;
    case 'sold':
      return transparrentBackground;
    default:
      return transparrentBackground;
  }
};

export const StyledDescriptionTimeCard = styled.div<CurrentTimeCardProps>`
  // ${({variant}) => actionDescriptionTimeCard(variant)};
  // padding-top: 3px;
  // border-bottom-left-radius: 8px;
  // border-bottom-right-radius: 8px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  width: 60px;
  text-align: right;
`;

export const PawHeader = styled.div`
  background-color: ${({theme}) => theme.palette.secondary.brightGold};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  top: 0px;
  gap: 12px;

  ${Media.down.m} {
    background-color: transparent;
    justify-content: center;
  }
`;

export const HeaderText = styled(TextBody2)`
  ${fontPrimaryBold};
  display: contents;
  font-size: 12px;
  line-height: 12px;
  height: 100%;
  text-transform: uppercase;
  white-space: nowrap;
  ${Media.down.m} {
    font-size: 16px;
    text-transform: capitalize;
  }
`;
