import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import {blueLinearCenterGradient} from '../theme/palette';
import {Heading1, Heading6} from '../Typography';
import {defaultTheme} from '../theme/theme';
import {MainWrapper} from './styles';
import {Background} from '../../containers/Layout/Background';

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledTitle = styled(Heading1)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
`;

export const StyledSubTitle = styled(Heading6)`
  color: ${defaultTheme.palette.system.white};
  font-weight: 400;
`;

export const HeaderLayout = styled.div`
  min-width: 100%;
  min-height: 198px;
  display: flex;
  flex-direction: column;
  padding: 44px 110px 44px 110px;
  gap: 24px;
  ${blueLinearCenterGradient};
`;

export type WithChildren = {
  children?: React.ReactNode;
};

type AppLayoutProps = WithChildren & {
  hideHeader?: boolean;
  hideFooter?: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
};

export const AppLayout: React.FC<AppLayoutProps> = ({children, header, footer, hideHeader, hideFooter, ...props}) => {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <StyledLayout {...props}>
      {!hideHeader && header}
      <Background pathname={pathname}>
        <MainWrapper>{children}</MainWrapper>
      </Background>
      {!hideFooter && footer}
    </StyledLayout>
  );
};
