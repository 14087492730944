import React, {useMemo} from 'react';

import {InfoCircleOutlined} from '@ant-design/icons/lib/icons';
import {
  StyledCard,
  StyledLocation,
  StyledWrapper,
  StyledWrapperTicket,
  StyledSvg,
  TicketDescription,
  NoteRow,
} from './styles';
import {AddonFunnelCardProps, FunnelCardProps} from './types';
import {formatCurrency, formatCurrencyPrice} from '../../../helpers/helpers';
import {NumericInput} from '../../NumericInput';
import {TextBody2} from '../../Typography/styles';
import {AdvContainer, AdvImage} from '../../../components/PurchaseOne/styles';
import {
  checkIsAddonSoldOut,
  checkIsAllAvailableTimes,
  getAddonImage,
  getAvailableCount,
  getAvailableToAdd,
  getMetaForSpecialEvents,
} from '../../../helpers/purchaseAddOns';
import {cutTicketName, GAtimeT} from '../../../helpers/orders';
import {TicketTypeT} from '../../../types/events';
import {ticketsAvSessionsT, useGetAddonTime} from '../../../hooks/purchase';
import {getSoldoutText} from '../../../helpers/admission';
import {NotAvailableLine} from '../../../components/PurchaseAdmission/styles';
import {CenteredLoader} from '../../Loader';

const CardFunnelWithLogic: React.FC<FunnelCardProps> = ({
  handleChangePurchaiseData,
  changeNumberStep,
  name,
  description,
  data,
  availableQuantity,
}) => {
  return (
    <StyledCard>
      <StyledWrapper>
        <StyledLocation>{name}</StyledLocation>
        &nbsp;
        <StyledSvg>
          <InfoCircleOutlined />
        </StyledSvg>
      </StyledWrapper>
      <TextBody2>{description}</TextBody2>
      {data?.value.map(
        (item) =>
          item.menuParent === name && (
            <StyledWrapperTicket key={item.name}>
              <StyledLocation>{`${item.name} ${item.price && formatCurrency(item.price)}`}</StyledLocation>
              <NumericInput
                changeNumberStep={changeNumberStep}
                name={item.name}
                parent={item.menuParent}
                value={item.count}
                availableQuantity={availableQuantity}
                onChange={handleChangePurchaiseData}
              />
            </StyledWrapperTicket>
          ),
      )}
    </StyledCard>
  );
};

export const AddonCardFunnelWithLogic: React.FC<AddonFunnelCardProps> = ({
  name,
  description,
  addonTickets,
  addonId,
  addonsState,
  changeState,
  availableAddons,
  parkingItems,
  ticketsAvSessions,
  gaTime,
  templateId,
  hiddenSesions,
}) => {
  if (!addonId) return null;
  const values = addonId ? addonsState?.[addonId] : undefined;
  const isGoldenFrame = values && Object.values(values)?.some((value) => value > 0);

  const onChange = changeState(addonId);
  const availableItem = availableAddons?.find((el) =>
    el?.groupName?.toLowerCase().includes(cutTicketName(name)?.toLowerCase() || ''),
  );
  const hiddenSessionsById = hiddenSesions?.find((el) => el?.resource_id === templateId)?.arrValue;
  const metaData = getMetaForSpecialEvents(name);
  return (
    <StyledCard golden_frame={isGoldenFrame} style={{position: 'relative', maxWidth: '500px', height: '100%'}}>
      <AdvContainer advImg="ic-hd">
        <AdvImage src={getAddonImage(name)} />
      </AdvContainer>
      <div style={{height: 180}}></div>
      <StyledWrapper>
        <StyledLocation>{name}</StyledLocation>
      </StyledWrapper>
      {metaData ? (
        <>
          <TicketDescription>
            {metaData.description}
            <span>{metaData.note_secondary}</span>
          </TicketDescription>
          <NoteRow>{metaData.note && `Note: ${metaData.note}`}</NoteRow>
        </>
      ) : (
        <TicketDescription>{description} </TicketDescription>
      )}
      {/* <TextBody1>{description}</TextBody1> */}
      {addonTickets?.map((item) => (
        <InputPart
          item={item}
          addonId={addonId}
          availableQuantity={getAvailableCount(availableItem, item)}
          disableIncrease={getAvailableToAdd(availableItem, item, parkingItems)}
          onChange={onChange}
          value={values?.[item.id]}
          key={item.name}
          ticketsAvSessions={ticketsAvSessions}
          gaTime={gaTime}
          templateId={templateId}
          hiddenSessions={hiddenSessionsById}
        />
      ))}
    </StyledCard>
  );
};

const InputPart: React.FC<{
  item: TicketTypeT;
  addonId: string;
  value?: number;
  availableQuantity: number;
  disableIncrease?: boolean;
  onChange: (value?: number, name?: string) => void;
  ticketsAvSessions?: ticketsAvSessionsT;
  gaTime?: GAtimeT;
  templateId?: string;
  hiddenSessions?: string[];
}> = ({
  item,
  addonId,
  value,
  availableQuantity,
  disableIncrease,
  onChange,
  ticketsAvSessions,
  gaTime,
  templateId,
  hiddenSessions,
}) => {
  const isSoldOut = useMemo(() => checkIsAddonSoldOut(ticketsAvSessions, item.id), []);

  const {times, loading} = useGetAddonTime(
    false,
    {},
    {id: templateId},
    gaTime,
    item?.name?.toLowerCase().includes('parking'),
  );
  const isAllAvailable = useMemo(
    () => checkIsAllAvailableTimes(times, hiddenSessions, gaTime?.timeZone),
    [times?.length],
  );
  const dinnerAvailable = item?.gl_code?.toLocaleLowerCase().includes('dinner');
  const dinnerNotAvailable = availableQuantity == 0 && dinnerAvailable;
  const skatingAvailable = item?.gl_code?.toLocaleLowerCase().includes('skating');
  const soldOutTicket =
    dinnerAvailable && isSoldOut
      ? 'Dinner tickets are not available or sold out for this date'
      : dinnerNotAvailable
      ? `Maximum tickets reached for your order. Please purchase additional General Admission tickets before you can add more dinner tickets.`
      : `${item?.name} is sold out and unavailable`;
  if (loading) return <CenteredLoader />;
  if (item.name.toLocaleLowerCase().includes('parking') || item.name.toLocaleLowerCase().includes('snow tubing')) {
    if (!isAllAvailable) {
      return <NotAvailableLine>{soldOutTicket}</NotAvailableLine>;
    }
  } else if ((!isAllAvailable && !dinnerAvailable) || (!dinnerAvailable && !skatingAvailable) || dinnerNotAvailable) {
    return item?.name == 'Junior' || item?.name == 'Child' ? (
      <NotAvailableLine></NotAvailableLine>
    ) : (
      <NotAvailableLine>{soldOutTicket}</NotAvailableLine>
    );
  }
  const itemName = dinnerAvailable ? `Dinner` : skatingAvailable ? `Ice Skating` : item?.name;
  if (isSoldOut)
    return <>{item?.name !== 'Junior' && <NotAvailableLine>{getSoldoutText(itemName)}</NotAvailableLine>}</>;
  const description = (item.description?.length || 0) <= 20 ? `${item.description} ` : '';
  return (
    <StyledWrapperTicket>
      <StyledLocation>
        {item.name.toLocaleLowerCase().includes('santa') ? '' : item.name} &nbsp;
        {description}
        {formatCurrencyPrice(item?.currency_code).format(Number(item?.currency_amount || 0) || 0)}
      </StyledLocation>
      {isSoldOut && <div>soldout</div>}
      <NumericInput
        name={item.id}
        parent={addonId}
        value={value}
        availableQuantity={availableQuantity}
        onChange={onChange}
        disableIncrease={disableIncrease}
      />
    </StyledWrapperTicket>
  );
};

export default CardFunnelWithLogic;
