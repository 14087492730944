import styled from 'styled-components';

import {TextBody1} from '../../../../Typography/styles';

export const StylesTicketType = styled.div<{$noTopOffset?: boolean; $noBotOffset?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({$noTopOffset}) => ($noTopOffset ? '0' : '10px')};
  margin-bottom: ${({$noBotOffset}) => ($noBotOffset ? '0' : '10px')};
`;

export const StyledInfoTicket = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled(TextBody1)<{$isAmount?: boolean}>`
  line-height: 20px;
  ${({$isAmount}) =>
    $isAmount &&
    ` text-align: right;
    min-width: 66px;`}
`;
