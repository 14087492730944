import {TextAreaProps} from 'antd/lib/input';
import {InputProps} from 'antd/lib/input/Input';
import React from 'react';
import {Tooltip} from '../Tooltip';
import {StyledInput, StyledTextArea} from './styles';
import {TooltipInputProps} from './types';

const Input: React.FC<InputProps & TooltipInputProps> = ({tooltip, ...props}) => (
  <StyledInput suffix={tooltip && <Tooltip title={tooltip} />} {...props} />
);

export const TextArea: React.FC<TextAreaProps> = ({...props}) => <StyledTextArea {...props} />;

export default Input;
