import {useLocation} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {paths} from '../constants/routes';
import {cancellationState, changeDateState, checkoutState, upgradeVipState} from '../states/order';
import {purchaseAddOneNavFooterState, purchaseState} from '../states/purchase';
import {useEffect, useState} from 'react';
import {purchaseAdmission} from '../states/tickets';

export const useGetCurrentProgress = () => {
  const [cancellation] = useRecoilState(cancellationState);
  const [changeDate] = useRecoilState(changeDateState);
  const [upgradeVip] = useRecoilState(upgradeVipState);
  const [purchase] = useRecoilState(purchaseState);

  const {pathname} = useLocation();

  if (pathname.includes(paths.cancellationPage)) {
    return cancellation?.progress;
  }
  if (pathname.includes(paths.changeDate)) {
    return changeDate?.progress;
  }
  if (pathname.includes(paths.upgradeVipPage)) {
    return upgradeVip?.progress;
  }
  if (pathname.includes(paths.addPurchase)) {
    return purchase?.progress;
  }
  return null;
};

export const useGetCurrentOrderNumber = () => {
  const [cancellation] = useRecoilState(cancellationState);
  const [upgradeVip] = useRecoilState(upgradeVipState);
  const [changeDate] = useRecoilState(changeDateState);
  const [purchase] = useRecoilState(purchaseState);

  const {pathname} = useLocation();

  if (pathname.includes(paths.cancellationPage)) {
    return cancellation?.orderNumber;
  }
  if (pathname.includes(paths.upgradeVipPage)) {
    return upgradeVip?.orderNumber;
  }
  if (pathname.includes(paths.changeDate)) {
    return changeDate?.orderNumber;
  }
  if (pathname.includes(paths.addPurchase)) {
    return purchase?.orderNumber;
  }
  return null;
};

export const useUpgradeVipNavigation = () => {
  const [upgradeVip] = useRecoilState(upgradeVipState);
  const [checkout] = useRecoilState(checkoutState);
  const onNextClick = upgradeVip?.onNextClick;

  const {pathname} = useLocation();
  if (pathname.includes(paths.upgradeVipPage)) {
    const onClick = upgradeVip?.onClickPayment
      ? async () => {
          const res = await upgradeVip?.onClickPayment?.();
          const id = typeof res === 'string' ? res : '';
          await onNextClick?.(id);
        }
      : onNextClick;
    return {
      progress: upgradeVip?.progress,
      step: upgradeVip?.step,
      buttonType: upgradeVip?.buttonType,
      buttonTitle: upgradeVip?.buttonTitle,
      conditionButton: upgradeVip?.conditionButton,
      onNextClick: onClick,
      subtotal: upgradeVip?.subtotal,
      cartId: upgradeVip?.cartId,
      checkout,
    };
  }
  return null;
};

export const usePurchaiseAddOneNavigation = () => {
  const [footerNav] = useRecoilState(purchaseAddOneNavFooterState);
  const [checkout] = useRecoilState(checkoutState);

  const {pathname} = useLocation();
  if (pathname.includes(paths.addPurchase)) {
    const onNextClick = footerNav?.onClickPayment
      ? async () => {
          const res = await footerNav?.onClickPayment?.();
          const id = typeof res === 'string' ? res : '';
          res && (await footerNav?.onClick?.(id));
        }
      : footerNav?.onClick;

    return {
      progress: footerNav?.progress,
      step: footerNav?.step,
      buttonType: footerNav?.buttonType,
      buttonTitle: footerNav?.buttonTitle,
      conditionButton: footerNav?.conditionButton,
      showButton: footerNav?.showButton,
      onNextClick: onNextClick,
      cartId: footerNav?.cartId,
      checkout,
    };
  }
  return null;
};

export const useChangeDateNavigation = () => {
  const [changeDate] = useRecoilState(changeDateState);
  const [checkout] = useRecoilState(checkoutState);
  const onNextClick = changeDate?.onNextClick;

  const {pathname} = useLocation();
  if (pathname.includes(paths.changeDate)) {
    const onClick = changeDate?.onClickPayment
      ? async () => {
          const res = await changeDate?.onClickPayment?.();
          const id = typeof res === 'string' ? res : '';
          await onNextClick?.(id);
        }
      : onNextClick;
    return {
      step: changeDate?.step,
      conditionButton: changeDate?.conditionButton,
      conditionMobileButton: changeDate?.conditionMobileButton,
      buttonType: changeDate?.buttonType,
      buttonTitle: changeDate?.buttonTitle,
      onNextClick: onClick,
      onNextMobileClick: onClick,
      additionalPayment: changeDate?.additionalPayment,
      checkout,
    };
  }
  return null;
};

export const useScrollPosition = () => {
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
    if (bottom) return setIsBottom(true);
    return setIsBottom(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return {isBottom};
};

export const useAdmissionNavigation = () => {
  const [admission] = useRecoilState(purchaseAdmission);
  const [checkout] = useRecoilState(checkoutState);
  const onNextClick = admission?.onNextClick;

  const {pathname} = useLocation();
  if (pathname.includes(paths.admission)) {
    const onClick = admission?.onClickPayment
      ? async () => {
          const res = await admission?.onClickPayment?.();
          const id = typeof res === 'string' ? res : '';
          await onNextClick?.(id);
        }
      : onNextClick;
    return {
      progress: admission?.progress,
      step: admission?.step,
      buttonType: admission?.buttonType,
      buttonTitle: admission?.buttonTitle,
      conditionButton: admission?.conditionButton,
      onNextClick: onClick,
      subtotal: admission?.subtotal,
      cartId: admission?.cartId,
      showButton: admission?.showButton,
      checkout,
    };
  }
  return null;
};
