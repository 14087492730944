import React, {useState} from 'react';
// import {SearchKey} from '../../constants/routes';
import {PublicButton} from '../../ui-kit/Button';
import {
  PageWrapper,
  ContentWrapper,
  EnchantLogo,
  TitleWrapper,
  ManageOrderTitle,
  ManageOrderText,
  RetrieveContainer,
  StyledRetrieveInput,
  SuccessContainer,
  SuccessTitle,
  StyledTextNote,
  StyledTextError,
  TextErrorWrapper,
  InlineFormWrapper,
  ItemFormWrapper,
  ContentHeader,
  PossibilitiesWrapper,
  PossibilitiesTitle,
  PossibilitiesCards,
  PossibilitiesCard,
  PurchaseLinkOut,
  PurchaseLinkText,
  PurchaseLink,
  HelpLink,
} from './styles';
import {useSendMagicLink} from '../../hooks/auth';
import {Form} from 'antd';
import {FormItem} from '../../ui-kit/Input/Form';
import {validateEmail} from '../../helpers/helpers';
import {TICKETS_URL} from '../../constants/env';
import {IconSvg} from '../../ui-kit/Icon';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {intercomActions} from '../../hooks/customerIO';

type ManageOrderPageProps = {
  searchParams: URLSearchParams;
  setSearchParams: (searchParams: any) => void;
  removeOptions?: (val: boolean) => void;
};

// const manageText = `Provide us with your email that you used to buy your Enchant tickets, and we’ll send you a magic link to give
// you access to purchase extra options, or change your date of your visit.`;

// const retrieveText = `Welcome to Enchant My Account, your hub for Enchant order management and event details.
//   You can manage your tickets, purchase upgrades, track referrals, and connect with our support team.
//   Please enter your email below to access My Account. We'll send you a link to your email to log in to your account.`;

export const ManageOrderPage: React.FC<ManageOrderPageProps> = ({searchParams, setSearchParams}) => {
  const [removedOptions, setRemovedOptions] = useState(false);
  // const paramType = searchParams.get(SearchKey.type) || OrderType.retrieve;
  const {show} = intercomActions();
  return (
    <PageWrapper>
      <HelpLink onClick={show}>
        <QuestionCircleOutlined />
      </HelpLink>
      <ContentWrapper>
        <ContentHeader>
          <EnchantLogo />
          <TitleWrapper>
            <ManageOrderTitle>My Account</ManageOrderTitle>
          </TitleWrapper>
          <ManageOrderText>Manage your orders all in one place.</ManageOrderText>
        </ContentHeader>
        <Retrieve searchParams={searchParams} setSearchParams={setSearchParams} removeOptions={setRemovedOptions} />
        {/* 
        {paramType === OrderType.retrieve || paramType === OrderType.success ? (
          <Retrieve searchParams={searchParams} setSearchParams={setSearchParams} />
        ) : (
          <Manage searchParams={searchParams} setSearchParams={setSearchParams} />
        )} */}
        <Possibilities removed={removedOptions} />
      </ContentWrapper>
    </PageWrapper>
  );
};

const Retrieve: React.FC<ManageOrderPageProps> = ({removeOptions}) => {
  const [form] = Form.useForm();
  const {onSend, success, error, loading} = useSendMagicLink(form.resetFields);
  const [stateButton, setStateButton] = useState(false);

  const onFinish = async (values: any) => {
    await onSend(values);
  };

  const handleChange = (inputValue: {email: string}): void => {
    removeOptions?.(true);
    setStateButton(!validateEmail.test(inputValue?.email.toLowerCase()));
  };

  if (success) return <Success />;
  return (
    <RetrieveContainer>
      <Form form={form} onFinish={onFinish} onValuesChange={handleChange}>
        <InlineFormWrapper>
          <ItemFormWrapper>
            <FormItem
              rules={[
                {required: true, message: 'Invalid Email, please enter a valid email.'},
                {
                  type: 'email',
                  message: 'Invalid Email, please enter a valid email.',
                },
              ]}
              name={['email']}>
              <StyledRetrieveInput placeholder="Enter your email address" name="email" />
            </FormItem>
          </ItemFormWrapper>
          <ItemFormWrapper>
            <FormItem>
              <PublicButton disabled={stateButton} variant={'tertiary'} htmlType="submit" loading={loading}>
                Continue
              </PublicButton>
            </FormItem>
          </ItemFormWrapper>
          {error && (
            <TextErrorWrapper>
              <StyledTextError>{error}</StyledTextError>
            </TextErrorWrapper>
          )}
        </InlineFormWrapper>
      </Form>
    </RetrieveContainer>
  );
};

const Success: React.FC = () => {
  return (
    <SuccessContainer>
      <SuccessTitle>We’ve sent you the Magic Link!</SuccessTitle>
      <StyledTextNote>
        Your Magic Link should arrive in your inbox momentarily. Please keep an eye out for it!
      </StyledTextNote>
    </SuccessContainer>
  );
};

const Possibilities: React.FC<{removed?: boolean}> = ({removed}) => {
  return (
    <PossibilitiesWrapper>
      {!removed && (
        <>
          <PossibilitiesTitle>Things you can do with My Account</PossibilitiesTitle>
          <PossibilitiesCards>
            <PossibilitiesCard>
              Add Tickets &amp; Add-Ons
              <IconSvg type="lightTicket" fill="none" stroke="white" viewBox="0 2 26 26" width="27px" height="27px" />
            </PossibilitiesCard>
            <PossibilitiesCard>
              Upgrade to VIP
              <IconSvg type="lightStar" fill="none" stroke="white" viewBox="0 2 26 26" width="27px" height="27px" />
            </PossibilitiesCard>
            <PossibilitiesCard>
              Manage Waivers <IconSvg type="lightMark" fill="none" stroke="white" />
            </PossibilitiesCard>
            <PossibilitiesCard>
              Change Date <IconSvg type="lightCalendar" fill="none" stroke="white" />
            </PossibilitiesCard>
            <PossibilitiesCard>
              Connect with Guest Services <IconSvg type="lightChat" fill="none" stroke="white" />
            </PossibilitiesCard>
            <PossibilitiesCard>
              Manage Referrals &amp; Credits <IconSvg type="lightGift" fill="none" stroke="white" />
            </PossibilitiesCard>
          </PossibilitiesCards>
        </>
      )}
      <PurchaseLinkOut>
        <PurchaseLinkText>Don’t have an order?</PurchaseLinkText>
        <PurchaseLink href={TICKETS_URL}>Get Tickets to Enchant</PurchaseLink>
      </PurchaseLinkOut>
    </PossibilitiesWrapper>
  );
};
