import {Button} from 'antd';
import styled, {css} from 'styled-components';
import {RightOutlined} from '@ant-design/icons';
import {ButtonPropsT} from './types';
import {linearGoldGradient, linearWhiteGradient} from '../theme/palette';
import {buttonTextStyles, linkButtonTextStyles} from '../Typography/styles';
import {Link} from 'react-router-dom';
import {Media} from '../theme/breakpoints';

const publicPrimaryStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  text-transform: uppercase;
  outline: none;
  ${linearGoldGradient};
  cursor: pointer;
  color: ${({theme}) => theme.palette.system.darkBlack};
  &:disabled {
    color: ${({theme}) => theme.palette.system.darkBlack};
    ${linearGoldGradient};
    opacity: 0.7;
  }
  &:hover,
  &:active,
  &:focus {
    ${linearWhiteGradient}
    color: ${({theme}) => theme.palette.system.darkBlack};
    opacity: 1;
  }
  & i > svg {
    fill: ${({theme}) => theme.palette.system.darkBlack};
  }
`;

const publicSecondaryStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.palette.system.white};
  outline: none;
  cursor: pointer;
  background-color: transparent;
  color: ${({theme}) => theme.palette.system.white};
  &:disabled {
    color: ${({theme}) => theme.palette.system.white};
    background-color: transparent;
    opacity: 0.7;
  }
  &:hover,
  &:active,
  &:focus {
    ${linearWhiteGradient}
    color: ${({theme}) => theme.palette.system.darkBlack};
    opacity: 1;
  }
  & i > svg {
    fill: ${({theme}) => theme.palette.system.white};
  }
`;

const publicTertiaryStyles = css`
  ${publicPrimaryStyles};
  border-radius: 4px;
`;

const publicRoundedStyles = css`
  ${publicPrimaryStyles};
  border-radius: 4px;
  text-transform: uppercase;
  min-width: 200px;
`;
const publicPlainTrans = css`
  ${publicPrimaryStyles};
  border-radius: 4px;
  background: transparent;
  border: 1px solid #000;
  text-transform: uppercase;
  min-width: 95px !important;
  width: 98px;
`;

const publicPlainDark = css`
  ${publicPlainTrans};
  background: #020e16;
  color: #ffffff;
`;

const publicBannerButton = css`
  ${publicPlainTrans};
  cursor: text;
  &:hover,
  &:active,
  &:focus {
  }
  width: 100%;
`;

export const StyledPublicButton = styled(Button)<ButtonPropsT>`
  ${buttonTextStyles};
  ${({variant}) => {
    switch (variant) {
      case 'primary':
        return publicPrimaryStyles;
      case 'secondary':
        return publicSecondaryStyles;
      case 'tertiary':
        return publicTertiaryStyles;
      case 'rounded':
        return publicRoundedStyles;
      case 'plainTrans':
        return publicPlainTrans;
      case 'plainTransDark':
        return publicPlainDark;
      case 'plainBanner':
        return publicBannerButton;
      default:
        return publicPrimaryStyles;
    }
  }};
  & i {
    margin-right: 10px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  & {
    padding: ${(props) => props.padding || '12px 24px'};
    height: ${(props) => props.height || '43px'};
  }
`;

const corporatedPrimaryStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  outline: none;
  background: ${({theme}) => theme.palette.primary.darkGold};
  cursor: pointer;
  color: ${({theme}) => theme.palette.system.white};
  &:hover,
  &:active,
  &:focus {
    color: ${({theme}) => theme.palette.system.white};
    background: ${({theme}) => theme.palette.primary.enchantGold};
  }
  &:disabled {
    color: ${({theme}) => theme.palette.system.white};
    background: ${({theme}) => theme.palette.primary.darkGold};
    opacity: 0.5;
  }
  &:disabled:hover {
    background: ${({theme}) => theme.palette.primary.darkGold};
    color: ${({theme}) => theme.palette.system.white};
  }
  & i > svg {
    fill: ${({theme}) => theme.palette.system.white};
  }
`;

export const corporatedSecondaryStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid ${({theme}) => theme.palette.primary.darkGold};
  outline: none;
  cursor: pointer;
  background: ${({theme}) => theme.palette.system.white};
  color: ${({theme}) => theme.palette.system.darkBlack};
  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${({theme}) => theme.palette.primary.white};
    color: ${({theme}) => theme.palette.system.darkBlack};
    opacity: 1;
  }
  &:disabled {
    color: ${({theme}) => theme.palette.system.darkBlack};
    opacity: 0.5;
  }
  &:hover:disabled {
    color: ${({theme}) => theme.palette.system.darkBlack};
  }
  & i > svg {
    fill: ${({theme}) => theme.palette.system.darkBlack};
  }
`;

export const corporatedTertiaryStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  background: ${({theme}) => theme.palette.system.gray4};
  color: ${({theme}) => theme.palette.system.darkBlack};
  &:hover,
  &:active,
  &:focus {
    border: none;
    color: ${({theme}) => theme.palette.system.darkBlack};
    background: ${({theme}) => theme.palette.system.gray3};
    opacity: 1;
  }
  &:disabled {
    color: ${({theme}) => theme.palette.system.darkBlack};
    opacity: 0.5;
  }
  &:hover:disabled {
    color: ${({theme}) => theme.palette.system.darkBlack};
  }
  & i > svg {
    fill: ${({theme}) => theme.palette.system.darkBlack};
  }
`;

export const StyledCorporateButton = styled(Button)<ButtonPropsT>`
  ${buttonTextStyles};
  ${({variant}) => {
    switch (variant) {
      case 'primary':
        return corporatedPrimaryStyles;
      case 'secondary':
        return corporatedSecondaryStyles;
      case 'tertiary':
        return corporatedTertiaryStyles;
      default:
        return corporatedPrimaryStyles;
    }
  }};
  & i {
    margin-right: 10px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  & {
    padding: ${(props) => props.padding || '12px 24px'};
    height: ${(props) => props.height || '43px'};
    ${(props) => props.width && `width: ${props.width}`};
  }
`;

export const StyledLink = styled(Link)<{$isDisabled?: boolean}>`
  // max-width: 166px;
  & > button {
    position: static;
  }
  ${({$isDisabled}) => $isDisabled && ` opacity: 0.7;`};
`;

export const ChevronIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin: 0;
`;

export const StyledLinkButton = styled(Button)<ButtonPropsT>`
  ${linkButtonTextStyles};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: ${({theme}) => theme.palette.system.darkBlack};
  background: transparent;
  width: 100%;
  height: 24px;
  line-height: 18px;
  border: none;
  padding: 0;
  text-align: left;
  box-sizing: border-box;
  box-shadow: none;
  svg {
    font-size: 13px;
    margin-left: 8px;
  }
  &:hover,
  &:active,
  &:focus {
    opacity: 0.5;
    background: transparent;
    color: ${({theme}) => theme.palette.system.darkBlack};
  }
  ${Media.down.s} {
    width: 100%;
    border-radius: 4px;
    & > span {
      white-space: normal;
    }
  }
`;

export const LinkButtonArrow = styled(RightOutlined)`
  ${Media.down.l} {
    // display: none;
  }
`;

export const StyledLinkView = styled.div`
  & > button {
    position: static;
  }
`;

export const VipOptButton = styled.a<{add?: boolean}>`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  box-sizing: border-box;
  background: ${({theme, add}) =>
    add === undefined ? 'transparent' : add ? 'transparent' : theme.palette.primary.midnightBlue};
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #020e16;
  ${linkButtonTextStyles};
  color: ${({theme, add}) =>
    add === undefined ? theme.palette.system.white : add ? theme.palette.system.darkBlack : theme.palette.system.white};
  &:hover {
    color: ${({theme, add}) =>
      add === undefined
        ? theme.palette.system.white
        : add
        ? theme.palette.system.darkBlack
        : theme.palette.system.white};
    opacity: 0.9;
  }
  img {
    width: 24px;
  }
  p {
    display: flex;
    height: auto;
    align-items: center;
    margin: 0;
  }
`;

export const StyledLinkButtonView = styled.a`
  display: flex;
  width: 100%;
  height: 24px;
  background: transparent;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  text-align: left;
  box-sizing: border-box;
  padding: 0;
  text-align: left;
  box-sizing: border-box;
  ${linkButtonTextStyles};
  border: none;
  svg {
    font-size: 13px;
    margin-left: 8px;
  }
  color: ${({theme}) => theme.palette.system.darkBlack};
  background: transparent;
  box-shadow: none;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.5;
    background: transparent;
    color: ${({theme}) => theme.palette.system.darkBlack};
  }
  ${Media.down.s} {
    width: 100%;
    // border-radius: 4px;
    & > span {
      white-space: normal;
    }
  }
  // ${Media.down.xs} {
  //   width: 146px;
  // }
  // ${Media.down.xxs} {
  //   width: 120px;
  // }
`;

export const ButtonPrimaryLink = styled.a`
  width: 100%;
  height: 100%;
  ${buttonTextStyles};
  ${publicPrimaryStyles};
`;

export const DashboardButton = styled.a`
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  width: auto;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #041c2c;
  color: #020e16;
  padding: 0 16px;
  font-size: 14px;
  height: 28px;
  &:hover {
    color: ${({theme}) => theme.palette.system.white};
    background: ${({theme}) => theme.palette.primary.midnightBlue};
  }
  ${buttonTextStyles};
  ${Media.down.m} {
    height: 38px;
    font-size: 16px;
    border-radius: 6px;
    justify-content: space-between;
    align-items: center;
  }
`;

export const AppleWalletButton = styled.a`
  width: 100%;
  height: 32px;
  background-color: #1f1e22;
  border-radius: 8px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  gap: 8px;
  ${Media.down.m} {
    height: 42px;
    font-size: 14px;
  }
  :hover {
    color: white;
  }
`;
export const GoogleWalletButton = styled.a`
  width: 100%;
  height: 32px;
  background-color: #1f1e22;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  border-radius: 8px;
  gap: 8px;
  font-size: 13px;
  ${Media.down.m} {
    height: 42px;
    font-size: 14px;
  }
  :hover {
    color: white;
  }
`;
