import {useSetRecoilState} from 'recoil';
import {purchaseAddOneNavFooterState, purchaseState} from '../states/purchase';
import {changeDateState, upgradeVipState} from '../states/order';
import {purchaseAdmission} from '../states/tickets';
import {applyCode, checkPromoCode} from '../queries/payment';
import {useState} from 'react';
import {getErrorResponse} from '../helpers/orders';
import {applyCodeBody} from '../queries/types/payment';

export type usePaymentDataT = {
  onSubmit: (values: any) => Promise<boolean>;
  onSetSuccessCard: (values: any) => void;
  setPurchaseFunc: (func: () => Promise<boolean | string>) => void;
};

export const usePayment = (): usePaymentDataT => {
  const setFooterNav = useSetRecoilState(purchaseAddOneNavFooterState);
  const setPurchaseState = useSetRecoilState(purchaseState);
  const setUpdateVipState = useSetRecoilState(upgradeVipState);
  const setChangeDateState = useSetRecoilState(changeDateState);
  const setPurchaseAdmission = useSetRecoilState(purchaseAdmission);

  const setPurchaseFunc = (func: () => Promise<boolean | string>) => {
    setUpdateVipState((prev) => ({...prev, onClickPayment: func}));
    setFooterNav((prev) => ({...prev, onClickPayment: func}));
    setChangeDateState((prev) => ({...prev, onClickPayment: func}));
    setPurchaseAdmission((prev) => ({...prev, onClickPayment: func}));
  };
  const onSubmit = async (values: any) => {
    try {
      if (!values?.terms) return false;
      return true;
    } catch (e) {
      return false;
    }
  };
  const onSetSuccessCard = (tokenInfo: any) => setPurchaseState((prev) => ({...prev, cardToken: tokenInfo}));

  return {onSubmit, setPurchaseFunc, onSetSuccessCard};
};

export type usePromoCodesT = {
  available: number;
  success: string | null;
  error: string | null;
  check: (code?: string) => Promise<boolean>;
  apply: (code?: string) => Promise<boolean>;
};

export const usePromoCodes = (cartId?: string, sellerId?: string): usePromoCodesT => {
  const [available, setAvailable] = useState<number>(0);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [promocode, setPromocode] = useState<string>('');
  const check = async (code?: string) => {
    setError(null);
    setSuccess(null);
    setAvailable(0);

    if (!code || !sellerId) return false;
    try {
      const res = await checkPromoCode({code, sellerId});
      // console.log(res);
      if (res?.body) {
        setAvailable(1);
        setPromocode(code);
      }
      return true;
    } catch (e) {
      // setError(getErrorResponse(e)?.message || 'Promo code not found or not available');
      setError('Promo code not found or not available');
      return false;
    }
  };

  const apply = async () => {
    setError(null);
    if (!promocode || !sellerId || !cartId) return false;
    try {
      const body: applyCodeBody = {codes: [promocode], require_benefit: false};
      const res = await applyCode({cartId, sellerId, body});
      // console.log(res);
      if (res?.body) {
        setSuccess(`${promocode} was applied succesfully`);
        setAvailable(0);
      }
      return true;
    } catch (e) {
      setError(typeof e === 'object' ? getErrorResponse(e)?.message : e || 'Error');
      setSuccess(null);
      return false;
    }
  };

  return {check, success, error, available, apply};
};
