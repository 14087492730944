import React from 'react';
import {LinkProps} from 'react-router-dom';

import {
  StyledHeading1,
  StyledHeading3,
  StyledHeading4,
  StyledHeading5,
  StyledHeading6,
  StyledHeading2,
  StyledHeadingMedium,
  TextBody1,
  TextBody2,
  TextBody3,
  TextBody5,
  TextCaption,
  TextSmall,
  TextSmallBold,
  TextVerySmallBold,
  TextSixteenBodyBold,
  LinkBody,
  LinkBody2,
  StyledTextNote,
  BoldTextBody1,
  StyledHeadingMedium2,
  StyledHeadingMedium3,
  StyledHeadingMediumTitle,
} from './styles';
import {WithChildren} from '../../types/helpers';

export const Heading1: React.FC<WithChildren> = (props) => <StyledHeading1 {...props} />;
export const Heading2: React.FC<WithChildren> = (props) => <StyledHeading2 {...props} />;
export const Heading3: React.FC<WithChildren> = (props) => <StyledHeading3 {...props} />;
export const Heading4: React.FC<WithChildren> = (props) => <StyledHeading4 {...props} />;
export const Heading5: React.FC<WithChildren> = (props) => <StyledHeading5 {...props} />;
export const Heading6: React.FC<WithChildren> = (props) => <StyledHeading6 {...props} />;
export const HeadingMedium: React.FC<WithChildren> = (props) => <StyledHeadingMedium {...props} />;
export const HeadingMedium2: React.FC<WithChildren> = (props) => <StyledHeadingMedium2 {...props} />;
export const HeadingMedium3: React.FC<WithChildren> = (props) => <StyledHeadingMedium3 {...props} />;
export const HeadingMediumTitle: React.FC<WithChildren> = (props) => <StyledHeadingMediumTitle {...props} />;

export const Text1: React.FC<WithChildren> = (props) => <TextBody1 {...props} />;
export const Text2: React.FC<WithChildren> = (props) => <TextBody2 {...props} />;
export const Text3: React.FC<WithChildren> = (props) => <TextBody3 {...props} />;
export const Text5: React.FC<WithChildren> = (props) => <TextBody5 {...props} />;
export const Text4: React.FC<WithChildren> = (props) => <TextSixteenBodyBold {...props} />;

export const Caption: React.FC<WithChildren> = (props) => <TextCaption {...props} />;
export const Small: React.FC<WithChildren> = (props) => <TextSmall {...props} />;
export const SmallBold: React.FC<WithChildren> = (props) => <TextSmallBold {...props} />;
export const VerySmallBold: React.FC<WithChildren> = (props) => <TextVerySmallBold {...props} />;
export const BoldText1: React.FC<WithChildren> = (props) => <BoldTextBody1 {...props} />;

export const TextLink1: React.FC<LinkProps> = (props) => <LinkBody {...props} />;
export const TextLink2: React.FC<LinkProps> = (props) => <LinkBody2 {...props} />;

export const TextNote: React.FC<WithChildren> = (props) => <StyledTextNote {...props} />;
