import {User} from '../types/auth';
import {createTicketUserReq} from '../queries/types/payment';

export type PaymentFormInitialT = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};

export const toPaymentFormInitial = (user?: User | null): PaymentFormInitialT => {
  return {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phone: user?.phoneNumber || '',
    email: user?.email || '',
  };
};

export const toTicketUserData = (values: any): createTicketUserReq['body'] => {
  return {
    first_name: values?.firstName || '',
    last_name: values?.lastName || '',
    email: values?.email || '',
    additional_info: {
      phone_number: values?.phone || '',
      zip_code: values?.zip || '',
      country: 'US',
      newsletter: false,
      send_marketing_email: false,
    },
  };
};
