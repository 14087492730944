import React, {useMemo} from 'react';
import {EventSessionT} from '../../types/events';
import {getTimeCardTitle, getTimeCardVariant} from '../../helpers/purchaseAddOns';
import {
  AddonTimeCardWrapper,
  PopoverWrapper,
  TextSoldOut,
  TimeText1,
  PopoverTitle,
  PopoverDescr,
  UnavailableIcon,
  TextTime,
  PawHeader,
  HeaderText,
  OwlHeader,
} from './styles';
import {checkIsHiddenSession, GAtimeT, getTZdate} from '../../helpers/orders';
import {Popover} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {formats} from '../../helpers/helpers';
import {IconSvg} from '../../ui-kit/Icon';

type AddonTimeCardPropsT = {
  onChose?: (id?: string) => void;
  isParking?: boolean;
  chosen?: string;
  gaTime?: GAtimeT;
  selected: number;
  ticketName?: string;
  hiddenSesions?: string[];
} & Partial<EventSessionT>;

export const AddonTimeCard: React.FC<AddonTimeCardPropsT> = ({
  isParking,
  start_datetime,
  end_datetime,
  sold_out,
  onChose,
  id,
  chosen,
  gaTime,
  sold_quantity,
  capacity,
  selected,
  hiddenSesions,
  ticketName,
}) => {
  const title = getTimeCardTitle(ticketName, isParking, start_datetime, end_datetime, gaTime?.timeZone);
  const canPurchase = selected + Number(sold_quantity || 0) <= Number(capacity || 0);
  const variant = getTimeCardVariant({sold_out, canPurchase});
  const isHidden = useMemo(
    () =>
      checkIsHiddenSession(
        getTZdate(gaTime?.start, {sellerTimezone: gaTime?.timeZone}).format(formats.onDate),
        title,
        hiddenSesions,
      ),
    [!!start_datetime],
  );
  const chose = () => {
    if (!canPurchase || sold_out || isHidden) return;
    onChose?.(id);
  };
  if (isHidden) return null;
  return (
    <AddonTimeCardWrapper variant={variant} onClick={chose} $isActive={id === chosen} $isDisabled={isHidden}>
      {variant === 'default' && <TextTime>{title}</TextTime>}
      {(variant === 'soldout' || variant === 'unavailable') && (
        <>
          <TextSoldOut>Sold Out</TextSoldOut>
          <TextTime>{title}</TextTime>
        </>
      )}
      {variant === 'fast' && (
        <>
          <TextSoldOut>Selling Fast!</TextSoldOut>
          <TextTime>{title}</TextTime>
        </>
      )}
      {variant === 'paw' && <PawsCard time={title} />}
      {variant === 'owl' && (
        <>
          <OwlHeader>
            <HeaderText>Night Owl</HeaderText>
          </OwlHeader>
          <TextTime>{title}</TextTime>
        </>
      )}
    </AddonTimeCardWrapper>
  );
};

export const Unavailable: React.FC<{title?: string; name?: string}> = ({name, title}) => {
  return (
    <>
      <Popover
        placement="topLeft"
        content={
          <PopoverWrapper>
            <PopoverTitle>
              {name} <ExclamationCircleOutlined />
            </PopoverTitle>
            <PopoverDescr>{name} is sold out and unavailable</PopoverDescr>
          </PopoverWrapper>
        }>
        <UnavailableIcon>
          <ExclamationCircleOutlined />
        </UnavailableIcon>
        <TimeText1>{title}</TimeText1>
      </Popover>
    </>
  );
};

export const PawsCard: React.FC<{time?: string}> = ({time}) => {
  return (
    <>
      <PawHeader>
        <HeaderText>Paws & Claus</HeaderText>
        <IconSvg type="paw" viewBox="0 -6 26 26" />
      </PawHeader>
      <TextTime>{time}</TextTime>
    </>
  );
};
