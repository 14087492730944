import {postQuery, getQuery} from './hooks';
import {OrdersQueryList} from '../constants/api';
import {orderIdT} from './types/groups';
import {PurchaseAddOnsReqBody} from './types/purchaseAddOns';
import {ChangeDateReqBody} from './types/changeDate';
import {upgradeVipReq} from './types/vip';

export const cancelVisit = async (orderId: orderIdT) => await postQuery(OrdersQueryList.cancelVisit(orderId));

export const getOrders = async () => await getQuery(OrdersQueryList.getOrders());

export const getOrderWithDetails = async (orderId: orderIdT) =>
  await getQuery(OrdersQueryList.getOrderWithDetails(orderId));

export const setChangeDates = async ({orderId, body}: ChangeDateReqBody) =>
  await postQuery(OrdersQueryList.changeDate(orderId)).send(body);

export const setPurchaseAddOns = async ({orderId, body}: PurchaseAddOnsReqBody) =>
  await postQuery(OrdersQueryList.purchaseAddOns(orderId)).send(body);

export const upgradeToVipReq = async ({orderId, body}: upgradeVipReq) =>
  await postQuery(OrdersQueryList.upgradeToVip(orderId)).send(body);
