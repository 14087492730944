import React, {useState, useEffect} from 'react';
import {
  PageWrapper,
  ContentWrapper,
  TitleWrapper,
  Title,
  Description,
  CancelCard,
  CancelCardTitle,
  CancelCardWrapperSelect,
  CancelCardDescription,
  //ButtonToChangeDateStyled,
  CancelCardButton,
  ChangeDateButton,
} from './styles';
import {AlertModal} from '../../ui-kit/AlertModal/index';
import {ModalLvl} from '../../ui-kit/types/common';
import Select from '../../ui-kit/Select';

type CancellationPagePropsT = {
  reasonCancel: string;
  onChangeReason: (val: string) => void;
  setProgress: (val: number) => void;
  onCancel: () => void;
  onCancelBack: () => void;
  onClickToChangeDate: () => void;
  onCancelReview: () => void;
};

const selectOptions = [
  {label: 'Illness / Injury (including Covid-19)', value: 'Illness / Injury (including Covid-19)'},
  {label: 'Death of Immediate Family', value: 'Death of Immediate Family'},
  {label: 'Armed Forces & Emergency Services Recall', value: 'Armed Forces & Emergency Services Recall'},
  {label: 'Unable to Attend', value: 'Unable to Attend'},
];

export const modals = {
  title: 'Are you sure you want to cancel your visit?',
  content: 'This action cannot be undone.',
  okText: 'cancel ticket',
  cancelText: 'back',
};

export const CancellationPage: React.FC<CancellationPagePropsT> = ({
  reasonCancel,
  onChangeReason,
  onCancel,
  onCancelBack,
  onClickToChangeDate,
  onCancelReview,
}) => {
  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);

  const onSetSuccess = () => {
    setDelModalLvl(ModalLvl.success);
    onCancel();
  };

  const onSetClose = () => {
    setDelModalLvl(ModalLvl.closed);
    onCancelBack();
  };

  const onOpenModal = () => {
    onCancelReview();
    setDelModalLvl(ModalLvl.confirm);
  };

  useEffect(() => {
    if (delModalLvl === ModalLvl.confirm) {
      AlertModal(modals.title, modals.content, modals.okText, modals.cancelText, onSetSuccess, onSetClose);
    }
  }, [delModalLvl]);

  return (
    <PageWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <Title>Cancel your Visit</Title>
          <Description>
            By cancelling your ticket you will receive a refund on the original form of payment. You refund can be
            expected in 7-10 business days.
          </Description>
        </TitleWrapper>
        <CancelCard>
          <CancelCardTitle>Reason for Cancellation</CancelCardTitle>
          <CancelCardDescription>Please let us know why you cannot attend Enchant.</CancelCardDescription>
          <CancelCardWrapperSelect>
            <Select
              onChange={onChangeReason}
              placeholder="Choose reason for the cancellation"
              options={selectOptions}
            />
          </CancelCardWrapperSelect>
          <CancelCardButton disabled={reasonCancel.length === 0} onClick={onOpenModal}>
            Cancel Visit
          </CancelCardButton>
        </CancelCard>
        <CancelCard>
          <CancelCardTitle>Change Your Date</CancelCardTitle>
          <CancelCardDescription>
            If you still wish to attend Enchant, try looking for another date and time (subject to availability). Note
            that prices may differ from your original order.
          </CancelCardDescription>
          <ChangeDateButton onClick={onClickToChangeDate}>Change Date</ChangeDateButton>
        </CancelCard>
      </ContentWrapper>
    </PageWrapper>
  );
};
