const antiAliasing = {
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
};

const font = {
  primary: {
    light: {
      fontFamily: 'Proxima Nova',
      fontWeight: 400,
      ...antiAliasing,
    },
    semibold: {
      fontFamily: 'Proxima Nova Semibold',
      fontWeight: 500,
      ...antiAliasing,
    },
    bold: {
      fontFamily: 'Proxima Nova Bold',
      fontWeight: 700,
      ...antiAliasing,
    },
  },
  secondary: {
    light: {
      fontFamily: 'Majesti Banner',
      fontWeight: 400,
      ...antiAliasing,
    },
    bold: {
      fontFamily: 'Majesti Banner',
      fontWeight: 700,
      ...antiAliasing,
    },
  },
  tertiary: {
    light: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      ...antiAliasing,
    },
    bold: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      ...antiAliasing,
    },
  },
};

export const mixins = {
  font,
};
