import React, {useState} from 'react';

import {RightSideWrapper} from '../ChangeDate/styles';
import {OrderDetailsCard} from '../../ui-kit/Cards/OrderDetailsCard/OrderDetailsCardUpgraideToVip';

import {BillingInfoPropsT} from './types';

export const BillingInfo: React.FC<BillingInfoPropsT> = ({
  dataOrderDetails,
  paymentForm,
  paymentSkipping,
  showFreePurchase,
}) => {
  const [valueInput, setValueInput] = useState<string>('');
  const onClickOrder = () => {
    setValueInput('');
  };

  return (
    <RightSideWrapper $half>
      <OrderDetailsCard
        onClick={onClickOrder}
        data={dataOrderDetails}
        valueInput={valueInput}
        setValueInput={setValueInput}
        paymentForm={paymentForm}
        paymentSkipping={paymentSkipping}
        showFreePurchase={showFreePurchase}
      />
    </RightSideWrapper>
  );
};
