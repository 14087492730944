import {Button} from 'antd';
import styled, {css} from 'styled-components';

import {FooterLayoutPropsType} from './types';
import {PublicButton} from '../../Button';
import {Media} from '../../theme/breakpoints';
import {fontPrimaryBold} from '../../theme/font';
import {Caption, Heading6} from '../../Typography';

// Footer

export const viewGoldLine = css`
  width: 100%;
  padding: ${({theme}) => theme.spacer._3} 0;
  background-color: ${({theme}) => theme.palette.primary.midnightBlue};
  border-top: 2px solid;
  border-image-source: linear-gradient(
    263.17deg,
    #a58569 -1.06%,
    #c2a06a 23.03%,
    #dfc999 48.41%,
    #c2a06a 72.7%,
    #a58569 95.28%
  );
  border-image-slice: 1;
`;

export const viewGreyLine = css`
  width: 100%;
  border-top: 2px solid ${({theme}) => theme.palette.system.gray1};
  padding: ${({theme}) => theme.spacer._3} 0;
  background-color: ${({theme}) => theme.palette.primary.midnightBlue};
`;

const viewColorLine = (color: string) => {
  switch (color) {
    case 'gold':
      return viewGoldLine;
    case 'grey':
      return viewGreyLine;
    default:
      return viewGoldLine;
  }
};

export const FooterLayout = styled.div<FooterLayoutPropsType>`
  ${({colorLineTop}) => viewColorLine(colorLineTop)};
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 80px;
  z-index: 3;
`;

export const FooterBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PublicButtonStyled = styled(PublicButton)`
  min-width: 200px;
`;

export const FooterLeft = styled.div``;

// FooterScroll
export const FooterScrollLayout = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: ${({theme}) => theme.spacer._6} 0;
  height: 60px;
  z-index: 3;
  background-color: ${({theme}) => theme.palette.primary.midnightBlue};
`;

export const FooterScrollBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterScrollTitle = styled(Button)`
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 50%;
  ${fontPrimaryBold};
  font-size: 16px;
  line-height: 19px;
  bottom: 12px;
  color: ${({theme}) => theme.palette.system.white};
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    color: ${({theme}) => theme.palette.system.white};
  }
  ${Media.down.m} {
    /* text-transform: uppercase; */
  }
`;

// Footer Mobile component

export const FooterLeftMobileWrapper = styled.div``;

export const FooterLeftMobileTitle = styled(Caption)`
  color: ${({theme}) => theme.palette.system.white};
`;

export const FooterLeftMobileValue = styled(Heading6)`
  color: ${({theme}) => theme.palette.system.white};
`;

// Footer Ticket Confirmation //

export const FooterTicketConfirmationWrapper = styled.div`
  padding-top: 156px;
  padding-bottom: 306px;
  background-color: ${({theme}) => theme.palette.primary.midnightBlue};
`;
