type EventsRowT = {_count: number; _total: number};
export type EventsT = {
  event_session: EventsRowT & {_data: EventSessionT[]};
  event_template: EventsRowT & {_data: EventTemplateT[]};
  meta: EventsRowT & {_data: MetaT[]};
  ticket_group: EventsRowT & {_data: TicketGroupT[]};
  ticket_type: EventsRowT & {_data: TicketTypeT[]};
  venue: EventsRowT & {_data: VenueT[]};
  seller?: any;
};
export type SessionsT = {
  limit: [number, number];
  _total: number;
  event_session: EventsRowT & {_data: EventSessionT[]};
};
// export type EventTicketGroupT = {
//   additional_info_spec?: string;
//   admission_end_offset?: string;
//   admission_start_offset?: string;
//   capacity?: number;
//   description?: string;
//   duration?: string;
//   duration_new?: string;
//   event_template_id?: string;
//   exclude_from_event_capacity?: string;
//   handler?: string;
//   hidden_type?: string;
//   id?: string;
//   max_redemptions?: number;
//   max_tickets_per_order?: number;
//   member_role_id?: string;
//   min_tickets_per_order?: number;
//   name?: string;
//   oversell_capacity?: number;
//   portal_id?: string;
//   sales_end_offset?: string;
//   sales_start_offset?: string;
//   sells_out_event?: string;
//   start_policy?: string;
//   summary?: string;
//   _rank: number;
// };

export type EventTemplateT = {
  capacity?: number;
  category?: string;
  dedicated_order?: boolean;
  description?: string;
  event_type?: string;
  hidden_type?: string;
  id?: string;
  name?: string;
  oversell_capacity?: number;
  portal_id?: string;
  release_sessions_until?: string;
  scan_code_type?: string;
  seller_id?: string;
  stage_size?: number;
  subtitle?: string;
  summary?: string;
  ticket_template_id?: string;
  venue_id?: string;
  _rank: number;
};

export type EventSessionT = {
  capacity: number;
  end_datetime?: string;
  event_template_id?: string;
  id?: string;
  largest_stage?: number;
  oversell_capacity?: number;
  oversold_out?: boolean;
  portal_id?: string;
  redeemed_count?: number;
  seller_id?: string;
  sold_out?: boolean;
  sold_quantity: number;
  start_datetime?: string;
  used_capacity?: number;
};

export type VenueT = {
  address?: string;
  country?: string;
  description?: string;
  email?: string;
  id?: string;
  latitude?: string;
  longitude?: string;
  name?: string;
  phone?: string;
  portal_id?: string;
  timezone?: string;
  website?: string;
};

export type TicketGroupT = {
  _rank?: number;
  additional_info_spec?: null | boolean | string;
  admission_end_offset?: string;
  admission_start_offset?: string;
  capacity?: number;
  description?: string;
  duration?: null | boolean | string;
  duration_new?: null | boolean | string;
  event_template_id?: string;
  exclude_from_event_capacity?: boolean;
  handler?: string;
  hidden_type?: string;
  id?: string;
  max_redemptions?: number;
  max_tickets_per_order?: number;
  member_role_id?: null | boolean | string;
  min_tickets_per_order?: number;
  name?: string;
  oversell_capacity?: number;
  portal_id?: string;
  sales_end_offset?: null | boolean | string;
  sales_start_offset?: null | boolean | string;
  sells_out_event?: boolean;
  start_policy?: string;
  summary?: string;
};

export type MetaT = {
  metakey?: string;
  resource?: string;
  resource_id?: string;
  value?: string;
};

export type CustomHideSessionT = MetaT & {name?: string; arrValue: string[]};

export type BookedTemplateIds = {
  ga?: string;
  parking?: string;
  skating?: string;
  dinner?: string;
  voucher?: string;
};

export enum DataTimeCard {
  id = 'id',
  variant = 'variant',
  description = 'description',
  time = 'time',
  warningLabel = 'warningLabel',
  eventGroupName = 'eventGroupName',
  ticketTypeId = 'eventTypeId',
  start = 'start',
  end = 'end',
  addons = 'addons',
  removing = 'removing',
}

export type CustomSessionsT = {
  name: string;
  isSpecial?: boolean;
  limit: [number, number];
  _total: number;
  event_session: EventsRowT & {_data: EventSessionT[]};
};

export type TicketTypeT = {
  currency_amount: string;
  currency_amount_max: string;
  currency_amount_min: string;
  currency_code: string;
  description: string;
  gl_code?: string;
  id: string;
  name: string;
  portal_id: string;
  summary: string;
  ticket_group_id: string;
  _rank: number;
};

export type PriceScheduleItemT = {
  available: boolean;
  currency_amount: string;
  currency_amount_max: null;
  currency_amount_min: null;
  from: string;
  to: string;
};

export type PriceScheduleT = {
  _count: number;
  _total: number;
  _data: PriceScheduleItemT[];
};

export type DateEventsT = {
  limit: [number, number];
  _total: number;
  event_session: EventsRowT & {_data: EventSessionT[]};
  price_schedule: PriceScheduleT;
};

export type BookedSessionT = {
  start?: string;
  capacity?: number;
  sold?: number;
  soldout?: boolean;
};

export type BookedSessionsT = {
  vipSessions?: BookedSessionT[] | null;
  elfSessions?: BookedSessionT[] | null;
  gaSessions?: BookedSessionT[] | null;
  skatingSessions?: BookedSessionT[] | null;
  dinnerSessions?: BookedSessionT[] | null;
  voucherSessions?: BookedSessionT[] | null;
  parkingSessions?: BookedSessionT[] | null;
  parkingGCSessions?: BookedSessionT[] | null;
  parkingGGSessions?: BookedSessionT[] | null;
};
