import React from 'react';

import {StyledSwitchButton} from './styles';
import {SwitchPropsT} from './types';
import {ButtonPropsT} from '../Button/types';

export const SwitchButton: React.FC<ButtonPropsT> = ({children, disabled, ...props}) => {
  return (
    <StyledSwitchButton {...props} disabled={disabled}>
      {children}
    </StyledSwitchButton>
  );
};

export const Switch: React.FC<SwitchPropsT> = ({options, value, onChange}) => {
  return options.map((type: string) => (
    <SwitchButton
      key={type}
      onClick={() => {
        onChange(type);
      }}
      variant={type === value ? 'primary' : 'secondary'}>
      {type}
    </SwitchButton>
  ));
};
