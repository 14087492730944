import React, {useEffect, useRef} from 'react';
import {ShareThisWrapper} from './style';

const ShareThis: React.FC<{dataUrl: string; onShareClick?: () => void}> = ({dataUrl, onShareClick}) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const st = window.__sharethis__;
    if (!st) {
      const script = document.createElement('script');
      script.src = 'https://platform-api.sharethis.com/js/sharethis.js#property=646f96448b790100199498ef&product=sop';
      document.body.appendChild(script);
    } else if (typeof st.initialize === 'function') {
      st.href = window.location.href;
      st.initialize();
    }

    return () => {
      const existingScript = document.querySelector(
        `script[src='https://platform-api.sharethis.com/js/sharethis.js#property=646f96448b790100199498ef&product=sop']`,
      );
      if (existingScript && existingScript.parentNode) {
        existingScript.parentNode.removeChild(existingScript);
      }
    };
  }, []);
  useEffect(() => {
    if (!ref?.current) return;
    const onButtonClick = (e: any) => {
      if (e.target?.classList.contains('st-btn')) return onShareClick?.();
      if (e?.target?.alt?.includes('sharing')) return onShareClick?.();
    };

    ref?.current.addEventListener('click', onButtonClick);
    return () => ref?.current?.removeEventListener('click', onButtonClick);
  }, [!!ref?.current]);

  return (
    <ShareThisWrapper>
      <div
        className="sharethis-inline-share-buttons"
        data-url={dataUrl}
        data-title=" I’m going to Enchant! Join me and experience the magic:"
        ref={ref}></div>
    </ShareThisWrapper>
  );
};

export default ShareThis;
