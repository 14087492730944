import {Media} from './../../ui-kit/theme/breakpoints';
import styled, {css} from 'styled-components';
import {Text3} from '../../ui-kit/Typography';

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 143px;
  max-width: 1100px;
  ${Media.down.l} {
    width: 100%;
    padding: 110px 10px 10px 10px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  ${Media.down.l} {
    gap: 0px;
    margin-bottom: 12px;
  }
`;

export const TitleDescr = styled(Text3)`
  color: ${({theme}) => theme.palette.system.white};
  font-weight: 700;
  ${Media.down.l} {
    margin-top: 6px;
    font-size: 18px;
    line-height: 20px;
  }
  margin-bottom: 8px;
`;

export const TabContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
  padding: 40px;
  width: 100%;
  height: 100%;
  max-width: 700px;
  margin-top: 20px;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.system.white};
  ${Media.down.s} {
    padding: 25px 15px;
    display: flex;
  }
  span {
    color: ${({theme}) => theme.palette.secondary.seaBlue};
  }
  button:last-child {
    margin-top: 15px;
  }
`;

export const ResetButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  color: rgb(31, 71, 98);
`;

export const FieldWrapper = styled.div<{$isPair?: boolean; $separate?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 280px;
  position: relative;
  ${({$separate}) => $separate && ` margin: 10px 0;`};
  ${({$isPair}) => $isPair && ` flex-direction: row; gap: 15px;`};
  ${Media.down.s} {
    max-width: 100%;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-picker {
    width: 100%;
  }
  span:last-child {
    color: #020e16;
  }
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  span {
    color: ${({theme}) => theme.palette.system.red};
  }
`;

export const AddressWrapper = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  span {
    color: ${({theme}) => theme.palette.system.white};
  }
`;

export const CheckBoxFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
`;

const EditingAvatarStyles = css`
  position: relative;
  top: unset;
  right: unset;
  margin: 0 auto;
  margin-bottom: 10px;
`;
export const AvatarWrapper = styled.div<{$isEditing?: boolean}>`
  position: absolute;
  top: 20px;
  right: 20px;
  ${Media.up.m} {
    top: 30px;
    right: 50px;
    display: block;
  }
  ${Media.down.s} {
    ${({$isEditing}) => $isEditing && EditingAvatarStyles}
  }
`;

export const AvatarInput = styled.input`
  width: 120px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: 0 auto;
  opacity: 0.01;
  cursor: pointer;
`;
