import styled from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {Text3, Heading6, BoldText1, HeadingMediumTitle, Text5} from '../../ui-kit/Typography';

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 143px;
  max-width: 1100px;
  ${Media.down.l} {
    width: 100%;
    padding: 110px 10px 10px 10px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // margin-bottom: ${({theme}) => theme.spacer._5};
  margin-bottom: 16px;
  gap: 8px;
  ${Media.down.l} {
    gap: 0px;
    margin-bottom: 12px;
  }
`;

export const TitleDescr = styled(Text3)`
  color: ${({theme}) => theme.palette.system.white};
  font-weight: 700;
  ${Media.down.l} {
    margin-top: 6px;
    font-size: 18px;
    line-height: 20px;
  }
  margin-bottom: 8px;
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 40px; */
  padding: 40px;
  width: 100%;
  height: 100%;
  max-width: 700px;
  margin-top: 20px;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  ${Media.down.s} {
    flex-direction: column;
    gap: 18px;
    padding: 16px;
    // margin-top: ${({theme}) => theme.spacer._5};
    // padding: ${({theme}) => theme.spacer._3};
  }
`;

export const TabTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 320px;
  border-right: 1px solid #9a9fa2;
  & > div {
    max-width: 300px;
  }
  ${Media.down.s} {
    padding: 0;
    border: none;
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
    // flex-direction: column-reverse;
    row-gap: 6px;
    & > div {
      max-width: 100%;
    }
  }
`;

export const TitleAdds = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  ${Media.down.m} {
    justify-content: space-between;
  }
`;

export const BadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
`;

export const TabTitle = styled(Heading6)`
  margin-right: ${({theme}) => theme.spacer._3};
  ${Media.down.l} {
    margin-right: ${({theme}) => theme.spacer._1};
  }
`;

export const TabDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  // ${Media.down.l} {
  //   margin-top: ${({theme}) => theme.spacer._0};
  // }
`;

export const TabDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({theme}) => theme.spacer._0};
  ${Media.down.l} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  ${Media.down.m} {
    gap: 12px;
  }
`;

export const OptionsTitle = styled(Heading6)`
  color: #9a9fa2;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid #9a9fa2;
  padding-bottom: 4px;
  ${Media.down.s} {
    display: none;
  }
`;
export const PrimaryDetailCell = styled.div`
  padding-right: ${({theme}) => theme.spacer._3};
  ${Media.down.l} {
    border: none;
    padding-right: 0;
  }
`;

export const SecondaryDetailCell = styled.div`
  border-left: 1px solid ${({theme}) => theme.palette.system.gray2};
  padding: 0 ${({theme}) => theme.spacer._3};
  ${Media.down.l} {
    padding-left: 0;
    border-left: none;
  }
`;

export const BoldDetailText = styled(BoldText1)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  ${Media.down.m} {
    font-size: 14px;
    color: ${({theme}) => theme.palette.secondary.seaBlue};
  }
  :active {
    filter: brightness(1.5);
    color: ${({theme}) => theme.palette.secondary.nightBlue};
  }
`;

export const OptionSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 40px;
  padding-left: 40px;
  box-sizing: border-box;
  max-width: 300px;
  ${Media.down.s} {
    gap: 0;
    border-left: none;
    max-width: 100%;
    padding-left: 0;
    padding-top: 0;
    justify-content: space-around;
    align-items: stretch;
    row-gap: ${({theme}) => theme.spacer._2};
  }
`;

export const LinkButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({theme}) => theme.spacer._2};
  gap: ${({theme}) => theme.spacer._2};
  width: 100%;
  box-sizing: border-box;
  padding-left: 40px;
  border-left: 1px solid #9a9fa2;
  max-width: 300px;
  ${Media.down.s} {
    border-left: none;
    max-width: 100%;
    border-top: 1px solid #9a9fa2;
    padding-left: 0;
    padding-top: 24px;
    justify-content: space-around;
    align-items: stretch;
    row-gap: ${({theme}) => theme.spacer._2};
    column-gap: ${({theme}) => theme.spacer._2};
  }
`;

export const DashboardButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 24px;
  ${Media.down.s} {
    margin-top: 12px;
    gap: 8px;
  }
`;

export const IconStarWrapper = styled.div`
  margin-right: ${({theme}) => theme.spacer._5};
`;

export const TabPastZeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: -webkit-fill-available;
  padding-top: 155px;
  position: absolute;
  ${Media.down.m} {
    padding-top: 80px;
    position: static;
    width: 100%;
  }
`;

export const TabPastZeroContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 671px;
`;

export const TitlePastZero = styled(HeadingMediumTitle)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  text-transform: capitalize;
  margin-bottom: ${({theme}) => theme.spacer._1};
`;

export const DescriptionPastZero = styled(Text5)`
  text-align: center;
  color: ${({theme}) => theme.palette.system.white};
`;

export const AvailableButtons = styled.div`
  margin-top: 24px;
  display: flex;
  width: 100%;
`;
export const NotAvailableButtons = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${({theme}) => theme.palette.system.gray2};
  padding-top: 24px;
  width: 100%;
  & > div {
    margin-top: 24px;
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const SurveyWrapper = styled.a`
  max-width: 700px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #9a9fa2;
  padding: 10px 15px;
  margin-top: 30px;

  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.down.m} {
    padding: 8px 15px;
  }
  span {
    color: #fff !important;
    &:first-of-type {
      font-size: 14px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      ${Media.down.m} {
        font-size: 12px;
        max-width: 70%;
      }
    }
    &:last-of-type {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      ${Media.down.m} {
        font-size: 14px;
      }
    }
  }
`;
