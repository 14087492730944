import {CustomSessionsT, EventSessionT} from '../../../types/events';

export interface CurrentTicketsCardProps {
  variant: TicketType;
  title: string;
  price: number;
  countTickets: number;
  warningLabel: string;
  id: string;
  event: string;
  description?: string;
}
export type PossibleAddonSession = EventSessionT & {name?: string};
export interface CurrentTimeCardProps {
  variant: TicketType;
  id: string;
  time: string;
  description: string;
  vipPlaces?: boolean;
  isActive?: boolean;
  isActiveState?: boolean;
  $isActiveState?: boolean;
  warningLabel?: string;
  eventGroupName?: string;
  ticketTypeId?: string;
  eventTypeId?: string;
  start?: string;
  end?: string;
  addons?: PossibleAddonSession[];
}

export type TicketType = 'default' | 'fast' | 'sold';

export enum TypeVariant {
  default = 'default',
  fast = 'fast',
  sold = 'sold',
}

export type TicketCardPropsT = {
  totalCoast?: string;
  isActive?: boolean;
  key: string;
  onClick: (id: string) => void;
  data: CurrentTicketsCardProps;
  isActiveTickedCard: string | null;
};

export type TimeCardPropsT = {
  isActive?: boolean;
  key: string;
  onClick: (id: string) => void;
  data: CurrentTimeCardProps;
  isActiveTickedCard: boolean | string | null;
  sessions?: CustomSessionsT[];
  sellerTimezone?: string;
  elfLabel?: string;
  nightOwl?: boolean;
  isMuted?: boolean;
  hideLast?: boolean;
  hiddenSesions?: string[];
  isLastGA?: boolean;
  priceDiffInfo?: any;
  addons?: PossibleAddonSession[];
  isPaws?: boolean;
  preventSkip?: boolean;
};

export type ActiveTicketsCardProps = {
  $isActive?: boolean;
  $isMuted?: boolean;
  variant?: TicketType;
};
