import React from 'react';
import {WithChildren} from '.';
import {MobileViewWrapper, DesktopViewWrapper} from './styles';

export const MobileView: React.FC<WithChildren> = ({children}) => {
  return <MobileViewWrapper>{children}</MobileViewWrapper>;
};

export const DesktopView: React.FC<WithChildren> = ({children}) => {
  return <DesktopViewWrapper>{children}</DesktopViewWrapper>;
};
