import React, {useEffect, useState} from 'react';
import {DateCardAddons} from '../../ui-kit/Cards/CardFromTicket/types';
import {
  EventModalWrapper,
  InformButtons,
  NoteRow,
  PNCImage,
  PNCImageSide,
  PNCSide,
  PNCWrapper,
  PurchaseButton,
  PurchaseRow,
  RemoveModalWrapper,
  RemoveWrapper,
  TicketDescription,
  TicketName,
  TicketTitle,
} from './styles';
import pncImage from '../../ui-kit/assets/paws_claus.734c1ec9.png';
import {NumericInput} from '../../ui-kit/NumericInput';
import {SpecialNightRowT} from '../../types/orders';
import {MAX_PNC_TICKETS, PNC_date, formats} from '../../helpers/helpers';
import {getTZdate} from '../../helpers/orders';
import {useNavigate} from 'react-router-dom';
import {route} from '../../constants/routes';

type PawsNClausModalPropsT = {
  isVisible?: boolean;
  onClose?: () => void;
  data?: DateCardAddons | null;
  onAddTickets?: (count?: number) => void;
  specialNights?: SpecialNightRowT[];
  minValue?: number;
};

const pnc_description =
  'Celebrate the holiday season with your furry friends! Join us for a festive gathering of pets and their owners, featuring pet-friendly activities and a chance to meet Santa Claus. Dog tickets are only available for 5:30pm and 7:30pm time slots and require waivers.';
const pnc_max_tickets = 2;

export const PawsNClausModal: React.FC<PawsNClausModalPropsT> = ({
  isVisible,
  onClose,
  onAddTickets,
  data,
  specialNights,
  minValue,
}) => {
  const metaData = specialNights?.find((el) => el?.name?.toLocaleLowerCase().includes('paws & claus'));
  const [value, setValue] = useState(minValue || 0);
  const onChange = (value: any) => setValue(value);
  const onPurchase = () => (!value ? onClose?.() : onAddTickets?.(value));
  return null;
  return (
    <EventModalWrapper visible={isVisible} onOk={onClose} onCancel={onClose}>
      <PNCWrapper>
        <PNCImageSide>
          <PNCImage src={pncImage} />
        </PNCImageSide>
        <PNCSide>
          <TicketTitle>{metaData?.name}</TicketTitle>
          <TicketDescription>
            {metaData?.description || pnc_description}
            <br />
            <span>{metaData?.note_secondary}</span>
          </TicketDescription>
          <NoteRow>{metaData?.note && `Note: ${metaData?.note}`}</NoteRow>
          <PurchaseRow>
            <TicketName>
              {data?.ticket_type?.name} ${data?.ticket_type?.currency_amount}
            </TicketName>
            <NumericInput
              name={''}
              parent={''}
              value={value}
              availableQuantity={pnc_max_tickets - (minValue || 0)}
              onChange={onChange}
              disableIncrease={value >= 2}
              min={minValue}
              disabled={minValue === MAX_PNC_TICKETS}
            />
          </PurchaseRow>
          <PurchaseButton
            onClick={onPurchase}
            variant={'secondary'}
            $filled={!!value}
            disabled={minValue === MAX_PNC_TICKETS}>
            {!value ? 'Skip' : 'Add ticket'}
          </PurchaseButton>
        </PNCSide>
      </PNCWrapper>
    </EventModalWrapper>
  );
};

type SpecialNightModalPropsT = {
  onClose?: () => void;
  modalKey?: string;
  specialNights?: SpecialNightRowT[];
};

export const SpecialNightModal: React.FC<SpecialNightModalPropsT> = ({onClose, modalKey, specialNights}) => {
  const data = specialNights?.find((el) => el?.name === modalKey);
  return (
    <EventModalWrapper visible={!!modalKey} onOk={onClose} onCancel={onClose}>
      <PNCWrapper>
        <PNCImageSide>
          <PNCImage src={data?.image} />
        </PNCImageSide>
        <PNCSide>
          <TicketTitle>{data?.name}</TicketTitle>
          <p>{data?.description}</p>
          <NoteRow>{data?.note}</NoteRow>
          <PurchaseButton onClick={onClose} variant={'secondary'} $filled={true}>
            Continue
          </PurchaseButton>
        </PNCSide>
      </PNCWrapper>
    </EventModalWrapper>
  );
};

type PNCInformModalPropsT = {
  date?: string;
  hasPnc?: boolean;
  sellerTimezone?: string;
};

export const PNCInformModal: React.FC<PNCInformModalPropsT> = ({hasPnc, date, sellerTimezone}) => {
  const [informVisible, setInformVisible] = useState(false);
  const [removeVisible, setRemoveVisible] = useState(false);

  const inappropriateDate = date && getTZdate(new Date(date), {sellerTimezone}).format(formats.date5) !== PNC_date;
  const navigate = useNavigate();
  useEffect(() => {
    if (hasPnc && !date) setInformVisible(true);
  }, [!!hasPnc]);
  useEffect(() => {
    if (hasPnc && date && inappropriateDate) setRemoveVisible(true);
  }, [!!hasPnc, inappropriateDate]);
  if (!hasPnc) return null;

  const onOk = () => {
    setInformVisible(false);
    setRemoveVisible(false);
  };
  const onCancel = () => navigate(route.dashboard.path);
  return (
    <>
      <EventModalWrapper visible={informVisible} onOk={onOk} onCancel={onOk}>
        <PNCWrapper>
          <PNCImageSide>
            <PNCImage src={pncImage} />
          </PNCImageSide>
          <PNCSide>
            <TicketTitle>Paws & Claus</TicketTitle>
            <TicketDescription>
              <span>
                Paws N` Claus is only available on December 3. You can still change your order date, but unfortunately
                we cannot accommodate Pup Tickets outside of supported dates or refund Pup Tickets.
              </span>
            </TicketDescription>
            <InformButtons>
              <PurchaseButton onClick={onCancel} variant={'secondary'} $filled={false} $rounded disabled={false}>
                {'Cancel'}
              </PurchaseButton>
              <PurchaseButton onClick={onOk} variant={'secondary'} $filled={true} disabled={false}>
                {'Continue'}
              </PurchaseButton>
            </InformButtons>
          </PNCSide>
        </PNCWrapper>
      </EventModalWrapper>
      <RemoveModalWrapper visible={removeVisible} onOk={onOk} onCancel={onOk}>
        <PNCWrapper>
          <RemoveWrapper>
            <TicketTitle>Paws & Claus</TicketTitle>
            <TicketDescription>
              <span>
                Changing your date will remove pup tickets from your order as it`s not available on your selected date
              </span>
            </TicketDescription>
            <PurchaseButton onClick={onOk} variant={'secondary'} $filled={true} disabled={false}>
              {'Continue'}
            </PurchaseButton>
          </RemoveWrapper>
        </PNCWrapper>
      </RemoveModalWrapper>
    </>
  );
};
