import React from 'react';
import {PageWrapper, ContentWrapper, LogoStyled, TitleWrapper, Title} from './styles';

export const OrderNotFoundPage = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <LogoStyled />
          <Title>Order not Found</Title>
        </TitleWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
};
