import React from 'react';
import {DesktopView} from '../../../ui-kit/AppLayout/View';
import {FooterTicketConfirmation} from '../../../ui-kit/AppLayout/FooterNavigation';
import {useTicketConfirmation} from '../../../hooks/ticketConfirmation';

export const TicketConfirmationFooter = () => {
  const {ticketConfirmation} = useTicketConfirmation();

  return (
    <DesktopView>
      <FooterTicketConfirmation reservedDate={ticketConfirmation?.reservedDate} />
    </DesktopView>
  );
};
