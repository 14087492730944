import React from 'react';
import {StyledCard, StyledLocation} from '../../ui-kit/Cards/CardFunnelWithLogic/styles';
import {TextBody2} from '../../ui-kit/Typography/styles';
import {adult_key_l, child_key, child_key_l, formatCurrencyPrice} from '../../helpers/helpers';
import {NumericInput} from '../../ui-kit/NumericInput';
import {TicketCardPropsT} from './types';
import {TicketPrice, TicketsInputsWrapper, SoldoutInfo, TicketDetails} from './styles';
import {getSoldoutText} from '../../helpers/admission';
import {PurchaseAdmissionItemT} from '../../types/purchase';

export const TicketCard: React.FC<TicketCardPropsT> = ({ticket, changeTickets, valueState, vipInfo, hasOwl}) => {
  const value = valueState?.[ticket.id].count || 0;
  const isChild = valueState?.[ticket.id]?.type === child_key_l;
  const isAdult = valueState?.[ticket.id]?.type === adult_key_l;
  const vipChildSoldout = !!(vipInfo.hasVip && isChild && vipInfo?.childSoldOut);
  const adultVipSoldout = !!(vipInfo.hasVip && isAdult && vipInfo?.adultSoldOut);

  const isOwlTicket = ticket?.name?.toLocaleLowerCase().includes('owl');
  const disabledByOwl = hasOwl ? !isOwlTicket : isOwlTicket;
  const isDisabled = !!valueState?.[ticket.id].disabled || ticket.isSoldOut || vipChildSoldout || adultVipSoldout;
  return (
    <StyledCard $isPicked={value > 0} $disabled={disabledByOwl}>
      <TicketsInputsWrapper>
        <div>
          <StyledLocation>{ticket?.name}</StyledLocation>
          <TicketDetails>
            <TicketPrice>
              From {formatCurrencyPrice(ticket?.currency_code).format(Number(ticket?.currency_amount || 0) || 0)} |
              &nbsp;
            </TicketPrice>
            <Description ticket={ticket} adultSoldout={adultVipSoldout} childSoldout={vipChildSoldout} />
          </TicketDetails>
        </div>
        <NumericInput
          name={ticket.id}
          value={value}
          availableQuantity={15}
          onChange={changeTickets}
          disableIncrease={isDisabled || disabledByOwl}
        />
      </TicketsInputsWrapper>
    </StyledCard>
  );
};

const Description: React.FC<{ticket: PurchaseAdmissionItemT; childSoldout: boolean; adultSoldout: boolean}> = ({
  ticket,
  childSoldout,
  adultSoldout,
}) => {
  if (ticket.isSoldOut) return <SoldoutInfo>{getSoldoutText(ticket.name)}</SoldoutInfo>;
  if (childSoldout) return <SoldoutInfo>{getSoldoutText(`VIP Pass ${child_key}`)}</SoldoutInfo>;
  if (adultSoldout) return <SoldoutInfo>{getSoldoutText('VIP Pass Adult')}</SoldoutInfo>;

  return <TextBody2>{ticket?.description}</TextBody2>;
};
