import styled, {css, keyframes} from 'styled-components';

import {Button} from '../Button';
import {ButtonPropsT} from '../Button/types';
import {Media} from '../theme/breakpoints';
import {Text1, Heading6, SmallBold, Text2} from '../Typography';
import {TextBody1, TextSixteenBodyBold, TextSmallBold} from '../Typography/styles';

export const StyledCard = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  padding: 24px;
  gap: 16px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const CardLabel = styled(TextBody1)``;
export const CardValue = styled(TextSixteenBodyBold)`
  ${Media.down.l} {
    line-height: 19px;
  }
`;

export const showPaymentDetailPurchaise = css`
  display: flex;
  justify-content: space-between;
  ${Media.down.m} {
    flex-direction: column;
    padding: 16px 0;
  }
`;

export const notShowPaymentDetailPurchaise = css`
  display: flex;
  justify-content: flex-start;
  margin-left: 30px;
  ${Media.down.m} {
    flex-direction: column;
    padding: 16px 0;
    margin-left: 0;
  }
`;
const showPaymentDetail = (show: boolean) => {
  switch (show) {
    case true:
      return showPaymentDetailPurchaise;
    default:
      return notShowPaymentDetailPurchaise;
  }
};
interface Props {
  $show: boolean;
}
export const OrderCardContentWrapperLogic = styled.div<Props>`
  ${({$show}) => showPaymentDetail($show)};
`;

export const viewTicketPositionShow = css`
  padding-left: 30px;
  ${Media.down.l} {
    padding-left: 14px;
  }
`;

export const viewTicketPositionNotShow = css`
  padding-left: 0px;
  ${Media.down.l} {
    padding-left: 24px;
  }
`;

export const OrderCardWrapperLogic = styled.div<Props>`
  width: 100%;
  /* border-radius: 8px; */
  /* background-color: ${({theme}) => theme.palette.secondary.lightGold}; */
  display: flex;
  justify-content: space-between;
  position: relative;
  ${Media.down.m} {
    flex-direction: column;
  }
`;

export const MobileOrderCardWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  padding: 16px 18px;
`;

export const CardInfoColumnWithLogic = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  padding: 24px 0;
  margin-right: 30px;
  ${Media.down.xl} {
    padding-right: 10px;
    margin-right: 0;
    flex-wrap: wrap;
  }
  ${Media.down.m} {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 0;
  }
`;

export const Inline = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  & > span:nth-child(2) {
    font-size: 14px;
    ${Media.down.s} {
      font-size: 12px;
      padding-right: 12px;
    }
  }
`;

export const DDicon = styled.div<{$expanded?: boolean}>`
  position: absolute;
  right: 0;
  cursor: pointer;
  transform: rotate(${({$expanded}) => ($expanded ? '180deg' : '0deg')});
  transition: 0.15s all;
`;

export const CardInfoLabel = styled(Text1)`
  color: ${({theme}) => theme.palette.system.gray1};
`;

export const CardInfoLabelMobile = styled(TextSmallBold)`
  margin-right: 5px;
  font-weight: 400;
  ${Media.down.s} {
    font-size: 11px;
    white-space: nowrap;
  }
`;

export const CardInfoTitle = styled(Heading6)<{$isBold?: boolean}>`
  margin: 8px 0;
  ${Media.down.m} {
    font-size: 13px;
    padding: 0;
    margin: 0;
    color: ${({theme}) => theme.palette.state.darkGray};
    font-weight: 600;
    ${({$isBold, theme}) =>
      $isBold &&
      `
    color: ${theme.palette.system.darkBlack};
    font-weight: 700;
    font-size: 16px;

    `}
  }
`;

export const CardInfoPlaceholder = styled(TextBody1)`
  margin: 4px 0;
  font-size: 12px;
  color: ${({theme}) => theme.palette.system.darkBlack};
  font-weight: 400;
`;

export const CardInfoBody = styled(Text1)`
  color: ${({theme}) => theme.palette.system.darkBlack};
  display: block;
  text-align: center;
  padding-top: 30px;
`;

export const MobileCardInfoTitle = styled(SmallBold)`
  color: ${({theme}) => theme.palette.system.darkBlack};
`;

export const CardInfoValue = styled(Text1)``;

export const MobileCardInfoValue = styled(Text2)``;

export const PaymentColumnWithLogic = styled.div`
  background-color: ${({theme}) => theme.palette.secondary.lightRose};
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
`;

export const PaymentTitle = CardInfoValue;

export const PaymentValue = CardInfoTitle;

export const ValueWrapper = styled(Text1)`
  display: flex;
  flex-direction: column;
`;

const cssAnimations = keyframes`
  0% {
    max-height: 0;
  }
   100% {
    max-height: 250px;
  }
`;

export const ValueWrapperMobile = styled.div`
  & > div {
    font-size: 12px;
    padding: 2px 0;
    &:empty {
      display: none;
    }
  }
  animation: ${cssAnimations} 1s;
  overflow-y: hidden;
`;

// Card No Logic

export const MobileCardInfoColumn = styled.div`
  width: 100%;
`;
export const AssignCardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.secondary.lightRed};
  padding: 24px;
  gap: 24px;
  ${Media.down.l} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AssignCardTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AssignCardText = styled(Text1)`
  display: block;
  margin-left: ${({theme}) => theme.spacer._1};
`;

export const AssignButton = styled(Button)<ButtonPropsT>`
  border-radius: 4px;
  border: none;
  text-transform: uppercase;
`;

export const CurrentCard = styled.div`
  width: 49.5%;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  padding: 24px 0 24px 24px;
  padding-top: 0px;
  box-sizing: border-box;
  ${Media.down.m} {
    width: 100%;
    padding: 12px 10px;
    margin-bottom: 2px;
  }
`;

export const SelectedCard = styled.div`
  width: 49.5%;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.system.gray3};
  padding: 24px 0 24px 24px;
  padding-top: 0px;
  box-sizing: border-box;
  ${Media.down.m} {
    width: 100%;
    padding: 12px 10px;
  }
`;

export const MidIcon = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${({theme}) => theme.palette.primary.midnightBlue};

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  color: ${({theme}) => theme.palette.system.white};

  border-radius: 100%;

  ${Media.down.m} {
    transform: rotate(90deg) translateX(-50%);
    position: static;
    margin-bottom: -30px;
  }
`;
