import {useState, useEffect, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useRecoilState, useSetRecoilState, useRecoilValue} from 'recoil';
import {route} from '../constants/routes';
import {upgradeVipState} from '../states/order';
import {ButtonType} from '../ui-kit/Button/types';
import {creditsAppliedState} from '../states/purchase';
import {useGetOrderWithDetails, useUpgradeToVip} from './orders';
import {useGetAvailableEvents} from './events';
import {
  calcVipPayment,
  checkHiddenSessionsVip,
  getPaymentFormInfo,
  toCreateVipPassData,
  VipSelectedStateT,
} from '../helpers/upgradeToVip';
import {toCreatedataOrderDetails} from '../helpers/upgradeToVip';
import {getExitingOrderInfoWithVip, getTZdate, isGA} from '../helpers/orders';
import {PurchaseResT} from '../types/purchase';
import {useCartPurchase} from './purchase';
import {useOnRequestsError, useSegmentVip} from './customerIO';
import {OrderT} from '../types/orders';
import {EventSessionT, EventsT} from '../types/events';
import {purchaseVipTicketsT} from '../queries/types/vip';
import {adult_key, child_key, formats, infant_key} from '../helpers/helpers';
import {getEventSessionsForOneDate} from '../queries/eventsGroup';
import {useViewer} from './auth';
import {commonOrderInfo} from '../states/common';
type useUpgradeVipStateProps = {
  orderId?: string;
  purchaseVip?: () => Promise<boolean>;
  dataStepTwo?: any;
  vipState: VipSelectedStateT;
  cartPurchase: (source?: string, creditsRedeemed?: boolean) => Promise<false | true>;
  redirect: () => void;
  cartId?: string;
  stripeAccountId?: string;
  ticketsToPurchase?: purchaseVipTicketsT[];
};
const useUpgradeVipState = ({
  orderId,
  vipState,
  purchaseVip,
  cartPurchase,
  redirect,
  cartId,
  stripeAccountId,
  ticketsToPurchase,
}: useUpgradeVipStateProps) => {
  const [upgradeVip, setUpgradeVip] = useRecoilState(upgradeVipState);
  const creditsApplied = useRecoilValue(creditsAppliedState);

  const setProgress = (val: number) => {
    setUpgradeVip((prev) => ({...prev, progress: val}));
  };
  const setStep = (val: number) => {
    setUpgradeVip((prev) => ({...prev, step: val}));
  };
  const setButtonTitle = (val: string) => {
    setUpgradeVip((prev) => ({...prev, buttonTitle: val}));
  };
  const setButtonType = (val: ButtonType) => {
    setUpgradeVip((prev) => ({...prev, buttonType: val}));
  };
  const setConditionButton = (val: boolean) => {
    setUpgradeVip((prev) => ({...prev, conditionButton: val}));
  };
  const setOrderNumber = (val?: string) => {
    setUpgradeVip((prev) => ({...prev, orderNumber: val}));
  };
  const setIsConfirm = (val: boolean) => {
    setUpgradeVip((prev) => ({...prev, isConfirm: val}));
  };
  const setSubtotal = (val?: number) => {
    setUpgradeVip((prev) => ({...prev, subtotal: val}));
  };
  const setOrderId = (val?: string) => {
    setUpgradeVip((prev) => ({...prev, orderId: val}));
  };
  const setNextClick = (val?: () => void) => {
    setUpgradeVip((prev) => ({...prev, onNextClick: val}));
  };
  const setCartId = (val?: string) => {
    setUpgradeVip((prev) => ({...prev, cartId: val}));
  };
  const setStripeAccountId = (val?: string) => {
    setUpgradeVip((prev) => ({...prev, stripeAccountId: val}));
  };

  const on1StepClick = async () => {
    const success = await purchaseVip?.();
    success && setStep(2);
  };

  const on2StepClick = async (source?: string, creditsRedeemed?: boolean) => {
    const success = await cartPurchase(source, creditsRedeemed);
    success && redirect();
  };

  useEffect(() => {
    setIsConfirm(false);
    setProgress(10);
    setStep(0);
    setButtonType('rounded');
    setButtonTitle('next');
    setConditionButton(true);
    setSubtotal(0);
    setOrderId(orderId);
  }, []);

  const vipsSelected = Object.values(vipState)?.filter((el) => el).length;
  useEffect(() => {
    if (vipsSelected < 0) setStep(0);
    if (upgradeVip?.step === 0) {
      if (vipsSelected > 0) setStep(1);
      setProgress(10);
      setConditionButton(true);
      setButtonTitle('next');
      setSubtotal(0);
      setNextClick(undefined);
    } else if (upgradeVip?.step === 1) {
      setNextClick(on1StepClick);
      setProgress(60);
      ticketsToPurchase?.length && setConditionButton(false);
      setButtonTitle('next');
    } else if (upgradeVip?.step === 2) {
      setButtonTitle('Confirm Purchase');
      setProgress(90);
    }
  }, [upgradeVip?.step, vipsSelected, ticketsToPurchase?.length, creditsApplied]);
  useEffect(() => {
    if (upgradeVip?.step === 2) setNextClick(on2StepClick);
    setCartId(cartId);
    stripeAccountId && setStripeAccountId(stripeAccountId);
  }, [upgradeVip?.step, cartId, stripeAccountId]);
  return {upgradeVip, setOrderNumber, setSubtotal, setStep, setIsConfirm};
};

export const useUpgradeVip = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const viewer = useViewer();
  const creditsApplied = useRecoilValue(creditsAppliedState);
  const [purchaseVipState, setPurchaseVipState] = useState<PurchaseResT>();
  const setOrderInfo = useSetRecoilState(commonOrderInfo);
  const {order, loading} = useGetOrderWithDetails(id);
  const {onUpgradeComplete, onUpgradeReview, toUpgradePageClick} = useSegmentVip(order);
  const {events, loading: evLoading} = useGetAvailableEvents(order?.sellerId);
  const {dataOrderDetails} = toCreatedataOrderDetails(order, events, purchaseVipState, viewer);
  const {vipPassData, setVip, vipSelected, ticketsVipPass} = toCreateVipPassData(order, events);
  const {ticketsToPurchase} = useVippassTicketsToPurchase(order, events);
  const setPurchase = (values: any) => {
    setPurchaseVipState({...values, created: new Date()});
    onUpgradeReview(values);
  };
  const onPurchaseSuccess = () => onUpgradeComplete(purchaseVipState);
  const {onRequestError} = useOnRequestsError({order});
  const {upgradeToVipInvoke, error: purchaseError} = useUpgradeToVip(
    ticketsToPurchase,
    id,
    setPurchase,
    onRequestError,
  );
  const additionalPayment = calcVipPayment(vipSelected, ticketsVipPass);
  const cartId = purchaseVipState?.cart?._data?.[0]?.id || '';
  const stripe_fees = purchaseVipState?.cart_fees?._data?.find((el) => el.gateway_id === 'stripe');
  const stripeAccountId = stripe_fees?.account_id;
  const identityId = purchaseVipState?.cart?._data?.[0]?.identity_id || '';
  const {cartPurchase} = useCartPurchase(
    cartId,
    identityId,
    order?.sellerId,
    onPurchaseSuccess,
    onRequestError,
    false,
    creditsApplied,
  );
  const redirect = async () =>
    upgradeVip?.orderId && navigate(route.confirmOrderVipPage.get({id: upgradeVip?.orderId}));

  const {upgradeVip, setOrderNumber, setSubtotal, setIsConfirm} = useUpgradeVipState({
    orderId: id,
    purchaseVip: upgradeToVipInvoke,
    vipState: vipSelected,
    cartPurchase,
    redirect,
    cartId,
    stripeAccountId,
    ticketsToPurchase,
  });
  const paymentForm = getPaymentFormInfo(order, purchaseVipState, events);

  useEffect(() => {
    if (order?.id)
      setOrderInfo({orderId: order?.id, orderNumber: order?.ticketureOrderNumber, sellerName: order?.sellerName});
  }, [order?.id]);

  const balance_due = purchaseVipState?.cart_fees?._data?.[0]?.grand_totals?.total_outstanding;
  useEffect(() => {
    setSubtotal(additionalPayment?.payment || 0);
    if (balance_due) setSubtotal(Number(balance_due || 0));
  }, [upgradeVip?.step, additionalPayment?.payment, balance_due]);

  useEffect(() => {
    setOrderNumber(order?.ticketureOrderNumber);
  }, [order?.ticketureOrderNumber]);

  useEffect(() => {
    order?.id && toUpgradePageClick();
  }, [order?.id]);

  const canUpgrade = useMemo(() => checkHiddenSessionsVip(order, events), [!!events]);
  return {
    setIsConfirm,
    upgradeVip,
    order,
    events,
    dataOrderDetails,
    loading: loading || evLoading,
    orderInfo: getExitingOrderInfoWithVip({
      order,
      vipState: vipSelected,
      tickets: ticketsVipPass,
      purchaseRes: purchaseVipState,
      events,
    }),
    vipPassData,
    vipSelected,
    setVip,
    additionalPayment,
    paymentForm,
    orderCreatedAt: purchaseVipState?.created,
    purchaseError,
    canUpgrade,
  };
};

export const useVippassTicketsToPurchase = (order?: OrderT, events?: EventsT) => {
  const [ticketsToPurchase, setTicketsToPurchase] = useState<purchaseVipTicketsT[]>();
  const vipTicketAdult = events?.ticket_type?._data.find(
    (el) => el.gl_code === 'VIP Pass' && el.name.includes(adult_key),
  );
  const vipTicketChild = events?.ticket_type?._data.find(
    (el) => el.gl_code === 'VIP Pass' && el.name.includes(child_key),
  );

  const ticketsCategoryGA = order?.tickets?.filter((el) => isGA(el));
  const countAdult =
    ticketsCategoryGA?.filter((el) => el.ticketTypeName !== child_key && el.ticketTypeName !== infant_key).length || 0;
  const countChild = ticketsCategoryGA?.filter((el) => el.ticketTypeName === child_key).length || 0;
  const vippassTemplateId = events?.event_template?._data?.find((el) =>
    el?.name?.toLowerCase().includes('experience'),
  )?.id;
  const getAvailable = async () => {
    const tickets: purchaseVipTicketsT[] = [];
    if (!vippassTemplateId) return;

    const onDate = getTZdate(ticketsCategoryGA?.[0]?.eventStartDate, order).format(formats.onDate);
    const res = await getEventSessionsForOneDate(vippassTemplateId, onDate);
    const currentTime = getTZdate(ticketsCategoryGA?.[0]?.eventStartDate, order).format(formats.time);
    const session = res?.body?.event_session?._data?.find((s: EventSessionT) => {
      const sessionTime = getTZdate(s?.start_datetime, order).format(formats.time);
      return currentTime === sessionTime;
    });
    if (vipTicketAdult?.id)
      for (let i = 0; i < countAdult || 0; i++)
        tickets.push({
          ticket_type_id: vipTicketAdult.id,
          event_session_id: session?.id,
        });
    if (vipTicketChild?.id)
      for (let i = 0; i < countChild || 0; i++)
        tickets.push({
          ticket_type_id: vipTicketChild.id,
          event_session_id: session?.id,
        });
    setTicketsToPurchase(tickets);
  };

  useEffect(() => {
    order?.id && events && getAvailable();
  }, [order?.id, !!events]);
  return {ticketsToPurchase};
};
