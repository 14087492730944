import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useGetOrderWithDetails} from './orders';
import {useGetAvailableEvents} from './events';
import {ticketConfirmationState} from '../states/order';
import {toCreateFooterDate} from '../helpers/ticketConfirmation';
import {useRecoilState} from 'recoil';
import {userState} from '../states/session';

export const useTicketConfirmation = () => {
  const [ticketConfirmation, setTicketConfirmation] = useRecoilState(ticketConfirmationState);
  const [user] = useRecoilState(userState);
  const {id} = useParams();
  const {order} = useGetOrderWithDetails(id);
  const {events} = useGetAvailableEvents(order?.sellerId);
  const reservedDate = toCreateFooterDate(order);

  const setReservedDate = (val: string) => {
    setTicketConfirmation((prev) => ({...prev, reservedDate: val}));
  };

  useEffect(() => {
    if (!reservedDate) return;
    setReservedDate(reservedDate);
  }, [order]);

  return {order, events, ticketConfirmation, user};
};
