import React from 'react';
import {DropDownWrapper, DownOutlinedStyled, DropDownLink} from './styles';
import {Dropdown, Space} from 'antd';
import {IDropDownProps} from './types';
import {Menu} from 'antd';

export const DropDown: React.FC<IDropDownProps> = ({titleDropDown, menuDropDown, onClick}) => {
  return (
    <DropDownWrapper>
      <Dropdown overlay={<Menu items={menuDropDown} />} trigger={['click']}>
        <DropDownLink onClick={onClick}>
          <Space>
            {titleDropDown}
            <DownOutlinedStyled />
          </Space>
        </DropDownLink>
      </Dropdown>
    </DropDownWrapper>
  );
};
