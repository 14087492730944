import {EventsT, EventTemplateT, TicketGroupT, TicketTypeT} from '../types/events';
import {filterExistingOrder, getExistingOrderFormCart, getShortListOfTickets, getTZdate, isGACat} from './orders';
import {gaValuesStateT, PurchaseAdmissionStateT, PurchaseResT, TicketsOrderDetailsT} from '../types/purchase';
import {OrderInfoT, OrderT, PaymentInfoT} from '../types/orders';
import {
  adult_key_l,
  child_key_l,
  formatCurrencyPrice,
  formats,
  infant_key_l,
  MAX_GA_TICKETS,
  uniqArrayWithObjects,
  vip_key,
  vip_key_l,
} from './helpers';
import {PurchaseTicketItemT} from '../queries/types/purchaseAddOns';
import {OrderDetailsCardProps} from '../ui-kit/Cards/OrderDetailsCard/types';
import {useEffect, useState} from 'react';
import {User} from '../types/auth';

export const getGATemplates = (templates: EventTemplateT[]) => templates.find((el) => isGACat(el?.name));
export const getVipPassTemplates = (templates: EventTemplateT[]) =>
  templates.find((el) => el?.name?.toLowerCase().includes('pass') || el?.name?.toLowerCase().includes(vip_key_l));

export const getGATypes = (types: TicketTypeT[], gaGroup?: TicketGroupT) =>
  types.filter((el) => el?.ticket_group_id === gaGroup?.id);
export const getVipPassTypes = (types: TicketTypeT[], vipGroup?: TicketGroupT) =>
  types.filter((el) => el?.ticket_group_id === vipGroup?.id);

export const initialGAvaluesState = (types: TicketTypeT[], time: string): gaValuesStateT => {
  const obj: gaValuesStateT = {};
  types.forEach((el) => {
    const isInfant = el?.name.toLowerCase().includes(infant_key_l);
    const type = isInfant ? infant_key_l : el?.name.toLowerCase().includes(child_key_l) ? child_key_l : adult_key_l;
    obj[el.id] = {count: 0, price: Number(el?.currency_amount) || 0, fullName: ' General Admission', time, type};
  });
  return obj;
};

export const calcAdmissionPayment = (
  values?: gaValuesStateT,
  vipDetails?: TicketsOrderDetailsT[],
  hasVip?: boolean,
): PaymentInfoT => {
  const total = Object.values(values || {}).reduce((p, n) => n.count * n.price + p, 0);
  const vipTotal = hasVip && vipDetails ? vipDetails?.reduce((p, n) => p + (n?.count || 0) * (n?.price || 0), 0) : 0;
  return {payment: total + vipTotal};
};

export const getExitingOrderInfoGA = ({
  gaState,
  order,
  purchaseRes,
  events,
  hasVip,
  vipTicketsDetails,
}: {
  gaState?: gaValuesStateT;
  order?: OrderT;
  purchaseRes?: PurchaseResT;
  events?: EventsT;
  hasVip: boolean;
  vipTicketsDetails?: TicketsOrderDetailsT[];
}): OrderInfoT[] => {
  const ticketTypesExist = uniqArrayWithObjects(
    order?.tickets?.map((el) => ({
      tt: el.ticketType,
      tn: el?.ticketTypeName,
    })) || [],
  );
  const currentTickets = getShortListOfTickets(order?.tickets?.map((el) => el?.ticketType));
  const ticketTypes = events?.ticket_type?._data;
  const info = purchaseRes
    ? getExistingOrderFormCart(order, purchaseRes, events)
    : [
        {
          label: 'Existing order',
          title: order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '',
          mobTitle: currentTickets,
          expandedMobTitle: order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '',
          selectedBold: true,
          value: ticketTypesExist
            ?.filter((el) => !filterExistingOrder(el?.tn))
            ?.map((el) => {
              const item = order?.tickets?.find(
                (ticket) => ticket?.ticketType === el.tt && ticket?.ticketTypeName === el.tn,
              );

              const ticketName =
                item?.eventTemplateName === item?.ticketTypeName
                  ? item?.eventTemplateName
                  : `${item?.eventTemplateName} ${item?.ticketTypeName}`;
              const time =
                item?.eventStartDate && item?.eventEndDate
                  ? `(${getTZdate(item?.eventStartDate, order).format(formats.timeStart)} - ${getTZdate(
                      item?.eventEndDate,
                      order,
                    ).format(formats.timeEnd)} arrival)`
                  : undefined;
              return {
                name: ticketName,
                count: order?.tickets?.filter(
                  (ticket) => ticket?.ticketType === el.tt && ticket?.ticketTypeName === el.tn,
                ).length,
                infoTicket: time,
              };
            }),
        },
      ];

  const selected = Object.values(gaState || {}).filter((el) => el).length;
  const tickets = Object.keys(gaState || {}).map((key) => ({...gaState?.[key], id: key, name: ''}));
  const withVIP = hasVip && vipTicketsDetails ? [...tickets, ...vipTicketsDetails] : tickets;

  const selectedCount = withVIP?.reduce((prev, current) => prev + (current?.count || 0), 0);
  const selectedShort = getShortListOfTickets(withVIP?.filter((el) => el?.count)?.map((el) => el?.fullName || ''));

  if (selected) {
    info.push({
      label: 'New Tickets',
      title: selectedCount ? '' : 'Select additional tickets',
      mobTitle: selectedCount ? selectedShort : 'N/A',
      mobPlaceholder: selectedCount ? '' : 'Select additional tickets',
      expandedMobTitle: '',
      selectedBold: true,
      value: withVIP?.map((ticket) => ({
        name:
          ticket?.name ||
          `${ticketTypes?.find((el) => el.id === ticket?.id)?.name} ${ticket?.fullName || ''} ${
            ticket?.time?.toLocaleUpperCase()?.replace('PM', 'PM arrival')?.replace('AM', 'AM arrival') || ''
          }`,
        count: ticket.count,
        infoTicket: `| ${formatCurrencyPrice('USD').format((ticket?.count || 0) * Number(ticket.price || 0) || 0)}`,
      })),
    });
  }

  return info;
};

export const addVipTicketsForGA = (vipTickets?: PurchaseAdmissionStateT, gaState?: gaValuesStateT) => {
  const [tickets, setTickets] = useState<TicketsOrderDetailsT[] | undefined>();
  const hasVipText = (str?: string) => str?.toLocaleLowerCase().includes(vip_key_l);
  const formatTickets = () => {
    const adultTickets = Object.values(gaState || {}).filter((el) => el?.type === adult_key_l);
    const childTickets = Object.values(gaState || {}).filter((el) => el?.type === child_key_l);
    const adultVip = vipTickets?.find((el) => el?.name.toLowerCase().includes(adult_key_l));
    const childVip = vipTickets?.find((el) => el?.name.toLowerCase().includes(child_key_l));

    const vipToAdd: TicketsOrderDetailsT[] = [];
    const adultDetail: TicketsOrderDetailsT = {id: '', name: ''};
    const childDetail: TicketsOrderDetailsT = {id: '', name: ''};

    adultTickets.forEach((el) => {
      adultDetail['name'] = hasVipText(adultVip?.name) ? adultVip?.name : `${vip_key} ${adultVip?.name}`;
      adultDetail['count'] = (adultDetail['count'] || 0) + el?.count;
      adultDetail['price'] = Number(adultVip?.currency_amount || 0);
    });
    childTickets.forEach((el) => {
      childDetail['name'] = hasVipText(childVip?.name) ? childVip?.name : `${vip_key} ${childVip?.name}`;
      childDetail['count'] = (childDetail['count'] || 0) + el?.count;
      childDetail['price'] = Number(childVip?.currency_amount || 0);
    });
    if (adultDetail?.count) vipToAdd.push(adultDetail);
    if (childDetail?.count) vipToAdd.push(childDetail);
    setTickets(vipToAdd);
  };

  const selectedCount = Object.values(gaState || {}).reduce((p, n) => p + n.count, 0);
  useEffect(() => {
    formatTickets();
  }, [selectedCount]);

  return tickets;
};

export const toCreatePurchaseGATickets = (
  tickets: PurchaseAdmissionStateT,
  gaState: gaValuesStateT,
  vipTickets?: PurchaseAdmissionStateT,
): PurchaseTicketItemT[] | null => {
  const es_id = tickets?.[0].eventSessionId;
  if (!es_id) return null;
  const ticketsToAdd: PurchaseTicketItemT[] = [];

  Object.keys(gaState).forEach((key) => {
    for (let i = 0; i < gaState[key].count; i++) {
      ticketsToAdd.push({
        ticket_type_id: key,
        event_session_id: es_id,
      });
    }
  });

  if (vipTickets) {
    const childVip = vipTickets?.find((el) => el?.name.toLowerCase().includes(child_key_l));
    const adultVip = vipTickets?.find((el) => el?.name.toLowerCase().includes(adult_key_l));

    Object.keys(gaState).forEach((key) => {
      for (let i = 0; i < gaState[key].count; i++) {
        //remove infant from vip tickets
        if (gaState[key].type === infant_key_l) return;
        const ticket_type_id = gaState[key].type === child_key_l ? childVip?.id : adultVip?.id;
        const event_session_id =
          gaState[key].type === child_key_l ? childVip?.eventSessionId : adultVip?.eventSessionId;
        if (!ticket_type_id) return;
        ticketsToAdd.push({
          ticket_type_id,
          event_session_id,
        });
      }
    });
  }
  return ticketsToAdd;
};

export const createDataOrderDetails = (
  order?: OrderT,
  purchaseRes?: PurchaseResT,
  viewer?: User | null,
): OrderDetailsCardProps => {
  const eventDate = getTZdate(order?.eventDate, order).format(formats.date1);
  return {
    location: `${order?.sellerName} - ${order?.sellerVenue}`,
    eventDate: eventDate,
    id: `${order?.ticketureOrderId}`,
    isProtection: !!order?.visitProtection,
    orderedBy: `${viewer?.firstName} ${viewer?.lastName}`,
    orderNumber: order?.ticketureOrderNumber,
    discountName: purchaseRes?.cartmod?._data?.[0]?.name || '',
  };
};

export const getSoldoutText = (name?: string) => `${name} tickets are not available or sold out for this date`;

export const validateCapacity = (
  selectedCount: number,
  tickets: PurchaseAdmissionStateT,
  hasVip: boolean,
  toggleDisable: (v: boolean, types?: string[]) => void,
  gaState: gaValuesStateT,
  vipTickets?: PurchaseAdmissionStateT,
) => {
  toggleDisable(false);
  const capacity = tickets?.[0]?.capacity || 0;
  const used = tickets?.[0]?.sold_quantity || 0;
  if (used + selectedCount > capacity || selectedCount >= MAX_GA_TICKETS) toggleDisable(true);

  // check vip
  if (hasVip) {
    const selectedVipChild = Object.values(gaState)
      .filter((el) => el?.type === child_key_l)
      .reduce((p, n) => p + n.count, 0);
    const selectedVipAdult = Object.values(gaState)
      .filter((el) => el?.type === adult_key_l)
      .reduce((p, n) => p + n.count, 0);

    const vcapacity_child = vipTickets?.find((el) => el?.name.toLowerCase().includes(child_key_l))?.capacity || 0;
    const vused_child = vipTickets?.find((el) => el?.name.toLowerCase().includes(child_key_l))?.sold_quantity || 0;

    const vcapacity_adult = vipTickets?.find((el) => el?.name.toLowerCase().includes(adult_key_l))?.capacity || 0;
    const vused_adult = vipTickets?.find((el) => el?.name.toLowerCase().includes(adult_key_l))?.sold_quantity || 0;

    if (vused_child + selectedVipChild > vcapacity_child || selectedCount >= MAX_GA_TICKETS)
      toggleDisable(true, [child_key_l]);
    if (vused_adult + selectedVipAdult > vcapacity_adult || selectedCount >= MAX_GA_TICKETS)
      toggleDisable(true, [adult_key_l]);
  }
};
