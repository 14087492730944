import {OrderInfoT, DashboardOrderT, canBuyVipT, TicketT, OrderTicketsT} from '../types/orders';
import {OrderT} from '../types/orders';
import {
  formatCurrencyPrice,
  uniqArrayWithObjects,
  formatCurrency,
  formats,
  child_key_l,
  adult_key_l,
  preferred_key,
  valet_key,
  infant_key_l,
  MAX_PNC_TICKETS,
} from './helpers';
import {addonsInfoValuesT, purchaseAddonsValuesT} from '../hooks/purchase';
import {formatFutureOrderInfo} from './purchaseAddOns';
import {CustomHideSessionT, EventSessionT, EventsT, TicketGroupT} from '../types/events';
import {VipSelectedStateT} from './upgradeToVip';
import {TicketsVipPassT} from '../ui-kit/Cards/VipElf/types';
import {PRTicketT, purchaseErrorT, purchaseOrderTicketTypeT, PurchaseResT} from '../types/purchase';
import {getEventSessionsForOneDate} from '../queries/eventsGroup';
import moment from 'moment-timezone';

export const getExitingOrderInfoWithVip = ({
  vipState,
  order,
  tickets,
  purchaseRes,
  events,
}: {
  vipState: VipSelectedStateT;
  tickets: TicketsVipPassT[];
  order?: OrderT;
  purchaseRes?: PurchaseResT;
  events?: EventsT;
}): OrderInfoT[] => {
  const ticketTypes = uniqArrayWithObjects(
    order?.tickets?.map((el) => ({
      tt: el.ticketType,
      tn: el?.ticketTypeName,
    })) || [],
  );
  const currentTickets = getShortListOfTickets(order?.tickets?.map((el) => el?.ticketType));
  const info = purchaseRes
    ? getExistingOrderFormCart(order, purchaseRes, events)
    : [
        {
          label: 'Existing order',
          title: order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '',
          mobTitle: currentTickets,
          expandedMobTitle: order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '',
          selectedBold: true,
          value: ticketTypes
            ?.filter((el) => !filterExistingOrder(el?.tn))
            ?.map((el) => {
              const item = order?.tickets?.find(
                (ticket) => ticket?.ticketType === el.tt && ticket?.ticketTypeName === el.tn,
              );

              const ticketName =
                item?.eventTemplateName === item?.ticketTypeName
                  ? item?.eventTemplateName
                  : `${item?.eventTemplateName} ${item?.ticketTypeName}`;
              const time =
                item?.eventStartDate && item?.eventEndDate
                  ? `(${getTZdate(item?.eventStartDate, order).format(formats.timeStart)} - ${getTZdate(
                      item?.eventEndDate,
                      order,
                    ).format(formats.timeEnd)} arrival)`
                  : undefined;
              return {
                name: ticketName,
                count: order?.tickets?.filter(
                  (ticket) => ticket?.ticketType === el.tt && ticket?.ticketTypeName === el.tn,
                ).length,
                infoTicket: time,
              };
            }),
        },
      ];

  const selected = Object.values(vipState).filter((el) => el).length;
  const selectedTickets = tickets?.reduce((p, c) => p + c.count, 0) + ' VIP';
  if (selected) {
    info.push({
      label: 'Selected VIP',
      title: 'VIP Applied',
      mobTitle: selectedTickets,
      expandedMobTitle: 'VIP Applied',
      selectedBold: true,
      value: tickets?.map((ticket) => ({
        name: ticket.name,
        count: ticket.count,
        infoTicket: `(${formatCurrencyPrice('USD').format(ticket.count * Number(ticket.price || 0) || 0)})`,
      })),
    });
  } else {
    info.push({
      label: 'Selected VIP',
      title: '',
      mobTitle: 'Select additional tickets below',
      expandedMobTitle: '',
      mobPlaceholder: 'Select additional tickets below',
      selectedBold: true,
      bodyText: 'Select additional tickets below',
      value: [],
    });
  }

  return info;
};

export const getExitingOrderInfoWithAddons = (
  addonsState: purchaseAddonsValuesT,
  addonsInfo: addonsInfoValuesT,
  addonsGroups?: TicketGroupT[],
  order?: OrderT,
  purchaseRes?: PurchaseResT,
  events?: EventsT,
): {info: OrderInfoT[]; parkingItems: number} => {
  const ticketTypes = uniqArrayWithObjects(
    order?.tickets?.map((el) => ({
      tt: el.ticketType,
      tn: el?.ticketTypeName,
    })) || [],
  );
  const currentShort = getShortListOfTickets(order?.tickets?.map((el) => el?.ticketType || ''));

  const info = purchaseRes
    ? getExistingOrderFormCart(order, purchaseRes, events)
    : [
        {
          label: 'Existing order',
          title: order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '',
          mobTitle: currentShort,
          expandedMobTitle: order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '',
          selectedBold: true,
          value: ticketTypes
            ?.filter((el) => !filterExistingOrder(el?.tn))
            ?.map((el) => {
              const item = order?.tickets?.find(
                (ticket) => ticket?.ticketType === el.tt && ticket?.ticketTypeName === el.tn,
              );
              const startTime = item?.eventStartDate
                ? getTZdate(item?.eventStartDate, order).format(formats.timeStart)
                : undefined;
              const endTime = item?.eventEndDate
                ? getTZdate(item?.eventEndDate, order).format(formats.timeEnd)
                : undefined;

              const tmpName = item?.eventTemplateName?.toLowerCase();
              const tmpCat =
                tmpName?.includes('skating') ||
                tmpName?.includes('dinner') ||
                tmpName?.includes('admission') ||
                tmpName?.includes('vip')
                  ? item?.eventTemplateName
                  : '';
              const ticketName =
                item?.eventTemplateName === item?.ticketTypeName
                  ? item?.eventTemplateName
                  : `${tmpCat} ${item?.ticketTypeName}`;
              const time = startTime && endTime ? `(${startTime} - ${endTime} arrival)` : undefined;
              return {
                name: ticketName,
                count: order?.tickets?.filter(
                  (ticket) => ticket?.ticketType === el.tt && ticket?.ticketTypeName === el.tn,
                ).length,
                infoTicket: time,
              };
            }),
        },
      ];
  const {parkingItems, items: futureOrder} = formatFutureOrderInfo(addonsState, addonsInfo, addonsGroups);

  const selectedShort = getShortListOfTickets(futureOrder?.map((el) => el?.name || ''));

  if (futureOrder?.length) {
    info.push({
      label: 'Selected New Add Ons',
      title: 'Add Ons',
      mobTitle: selectedShort,
      selectedBold: true,
      expandedMobTitle: 'Add Ons',
      value: futureOrder?.map((el) => {
        const ticketName = el?.groupName === el?.name ? el?.groupName : ` ${el?.name} ${el?.groupName}`;
        const price = `(${formatCurrencyPrice(el?.currency_code).format(el?.calcPrice)})`;
        return {
          name: `${ticketName}`,
          count: el?.count || 0,
          infoTicket: price,
        };
      }),
    });
  } else {
    info.push({
      label: 'Selected New Add Ons',
      title: '',
      mobTitle: 'N/A',
      selectedBold: false,
      expandedMobTitle: '',
      mobPlaceholder: 'Select additional tickets',
      bodyText: 'Select additional tickets below',
      value: [],
    });
  }
  return {info, parkingItems};
};

export const sortOrders = (orders?: DashboardOrderT[]) => {
  const upcomingOrders = orders
    ?.filter((el) => {
      const today = new Date();
      if (el?.weatherProtection) today.setDate(today.getDate() - 0); //7

      const currentTime = moment.tz(today, el?.sellerTimezone || '').format(formats.onDate);
      const orderTime = moment.tz(el?.eventDate, el?.sellerTimezone || '').format(formats.onDate);
      return dateGreaterEqualThen(orderTime, currentTime);
    })
    .sort((p, n) => (p?.isCancel ? 1 : 0) - (n?.isCancel ? 1 : 0));

  const pastOrders = orders?.filter((el) => {
    const today = new Date();
    if (el?.weatherProtection) today.setDate(today.getDate() - 0); //7

    const currentTime = moment.tz(today, el?.sellerTimezone || '').format(formats.onDate);
    const orderTime = moment.tz(el?.eventDate, el?.sellerTimezone || '').format(formats.onDate);
    return !dateGreaterEqualThen(orderTime, currentTime);
  });

  return {upcomingOrders, pastOrders};
};

export const toCreateCancellConfirm = (order?: OrderT) => {
  const dataOrder = [
    {label: 'Order Number', value: order?.ticketureOrderNumber, id: 1},
    {
      label: 'Refunded Amount',
      value: `${formatCurrency(order?.refundedAmount ? order?.refundedAmount : 0)} USD (Refunded)`,
      id: 2,
    },
  ];

  return dataOrder;
};

export const cartTicketsToOrderInfo = (
  events?: EventsT,
  tickets?: PRTicketT[],
  sellerTimezone?: string,
): purchaseOrderTicketTypeT[] => {
  const groupsMap: Record<string, PRTicketT[]> = {};
  const info: purchaseOrderTicketTypeT[] = [];

  //init
  tickets?.forEach((el) => {
    if (!el?.ticket_group_id) return;
    const exist = groupsMap[el.ticket_group_id] || [];
    groupsMap[el.ticket_group_id] = [...exist, el];
  });
  Object.keys(groupsMap).forEach((gId) => {
    const groupInfo = events?.ticket_group?._data?.find((el) => el?.id === gId);
    const tickets = groupsMap[gId];
    const oneTicket = groupsMap[gId]?.[0];

    // const value_price = tickets.reduce((prev, ticket) => prev + Number(ticket?.adjusted_value || 0), 0);
    const value_price = tickets.reduce((prev, ticket) => prev + Number(ticket?.face_value || 0), 0);
    const code = oneTicket?.currency_code || 'USD';

    const adult: PRTicketT[] = [];
    const child: PRTicketT[] = [];
    const infant: PRTicketT[] = [];
    const military: PRTicketT[] = [];
    const senior: PRTicketT[] = [];
    const vip: PRTicketT[] = [];
    const elf: PRTicketT[] = [];
    const other: PRTicketT[] = [];
    let vip_adult_count = 0;
    let vip_child_count = 0;
    let isGa = false;
    //checking child/adult/vip
    tickets?.forEach((t) => {
      const ticketInfo = events?.ticket_type?._data?.find((el) => el?.id === t?.ticket_type_id);
      const tName = ticketInfo?.name.toLowerCase();
      if (tName === 'military') {
        if (groupInfo?.name?.toLowerCase().includes('admission')) {
          isGa = true;
        }
        return military.push(t);
      }
      if (tName === 'senior') {
        if (groupInfo?.name?.toLowerCase().includes('admission')) {
          isGa = true;
        }
        return senior.push(t);
      }
      if (tName === adult_key_l) {
        if (groupInfo?.name?.toLowerCase().includes('admission')) {
          isGa = true;
        }
        return adult.push(t);
      }
      if (tName === child_key_l) {
        if (groupInfo?.name?.toLowerCase().includes('admission')) {
          isGa = true;
        }
        return child.push(t);
      }
      if (tName === infant_key_l) {
        if (groupInfo?.name?.toLowerCase().includes('admission')) {
          isGa = true;
        }
        return infant.push(t);
      }
      if (ticketInfo?.name.toLowerCase().includes('elf')) return elf.push(t);
      if (ticketInfo?.name.toLowerCase().includes('vip')) {
        ticketInfo?.name.toLowerCase().includes(child_key_l) && vip_child_count++;
        ticketInfo?.name.toLowerCase().includes(adult_key_l) && vip_adult_count++;
        return vip.push(t);
      }
      other.push(t);
    });

    // format string type '1 x Adult (+$10.00) | 1 x Child (+$10.00)'
    const {countInfo, vipPrice} = createCountInfo(
      adult,
      child,
      infant,
      military,
      senior,
      vip,
      elf,
      other,
      code,
      vip_adult_count,
      vip_child_count,
      events,
    );
    const includeTime = false;
    const time = `${getTZdate(oneTicket?.start_datetime, {sellerTimezone}).format(formats.timeStart)} - ${getTZdate(
      oneTicket?.end_datetime,
      {sellerTimezone},
    ).format(formats.timeEnd)}`;
    const isVip = !!vip.length;
    const amount = isVip ? vipPrice : value_price;
    info.push({
      ticketType: !isVip ? `${groupInfo?.name} ${includeTime ? time : ''}` : '',
      countInfo: countInfo,
      amount: formatCurrencyPrice(code).format(amount),
      isVip,
      isGa,
    });
  });
  return info;
};

enum ticketStates {
  booked = 'booked',
  in_cart = 'in_cart',
}

const createCountInfo = (
  adult: PRTicketT[],
  child: PRTicketT[],
  infant: PRTicketT[],
  military: PRTicketT[],
  senior: PRTicketT[],
  vip: PRTicketT[],
  elf: PRTicketT[],
  other: PRTicketT[],
  code: string,
  ga_adult_count: number,
  ga_child_count: number,
  events?: EventsT,
) => {
  let countInfo = '';
  let vipPrice = 0;
  const perLabel = '';

  if (!vip?.length) {
    const adultPrice = formatCurrencyPrice(code).format(Number(adult?.[0]?.adjusted_value || 0)) + perLabel;
    const childPrice = formatCurrencyPrice(code).format(Number(child?.[0]?.adjusted_value || 0)) + perLabel;
    const militaryPrice = formatCurrencyPrice(code).format(Number(military?.[0]?.adjusted_value || 0)) + perLabel;
    const seniorPrice = formatCurrencyPrice(code).format(Number(senior?.[0]?.adjusted_value || 0)) + perLabel;
    const infantPrice = formatCurrencyPrice(code).format(Number(infant?.[0]?.adjusted_value || 0)) + perLabel;

    const booked = {
      a: adult.some((el) => el?.state === ticketStates.in_cart) ? '' : '',
      c: child.some((el) => el?.state === ticketStates.in_cart) ? '' : '',
      m: military.some((el) => el?.state === ticketStates.in_cart) ? '' : '',
      s: senior.some((el) => el?.state === ticketStates.in_cart) ? '' : '',
      i: infant.some((el) => el?.state === ticketStates.in_cart) ? '' : '',
    };
    //CALC GA TICKETS ADULT/CHILD/SENIOR/MILITARY
    let delimetr = '';
    if (adult?.length) countInfo += `${adult?.length} x Adult (${booked.a}${adultPrice})`;
    delimetr = countInfo ? ' <br/> ' : '';
    if (child?.length) countInfo += `${delimetr}${child?.length} x Junior (${booked.c}${childPrice})`;
    delimetr = countInfo ? ' <br/> ' : '';
    if (military?.length) countInfo += `${delimetr}${military?.length} x Military (${booked.m}${militaryPrice})`;
    delimetr = countInfo ? ' <br/> ' : '';
    if (senior?.length) countInfo += `${delimetr}${senior?.length} x Senior (${booked.s}${seniorPrice})`;
    delimetr = countInfo ? ' <br/> ' : '';
    if (infant?.length) countInfo += `${delimetr}${infant?.length} x Infant (${booked.s}${infantPrice})`;
  }
  //calc vip
  if (vip?.length) {
    const vip_adult: PRTicketT[] = [];
    const vip_child: PRTicketT[] = [];
    vip.forEach((v_ticket) => {
      const ticketInfo = events?.ticket_type?._data?.find((el) => el?.id === v_ticket?.ticket_type_id);
      if (ticketInfo?.name?.toLowerCase().includes(child_key_l)) vip_child.push(v_ticket);
      if (ticketInfo?.name?.toLowerCase().includes(adult_key_l)) vip_adult.push(v_ticket);
    });

    const adult_value = Number(vip_adult?.[0]?.adjusted_value || 0);
    const child_value = Number(vip_child?.[0]?.adjusted_value || 0);
    vipPrice = adult_value * ga_adult_count + child_value * ga_child_count;
    const adultPrice = formatCurrencyPrice(code).format(adult_value) + perLabel;
    const childPrice = formatCurrencyPrice(code).format(child_value) + perLabel;

    const booked = {
      a: vip_adult.some((el) => el?.state === ticketStates.in_cart) ? '' : '',
      c: vip_child.some((el) => el?.state === ticketStates.in_cart) ? '' : '',
    };

    if (vip_adult.length && vip_child.length) {
      countInfo = `${ga_adult_count} x Adult (${booked.a}${adultPrice}) | ${ga_child_count} x Junior (${booked.c}${childPrice})`;
    }
    if (!vip_adult.length && vip_child.length) {
      countInfo = `${ga_child_count} x Junior (${booked.c}${childPrice})`;
    }
    if (vip_adult.length && !vip_child.length) {
      countInfo = `${ga_adult_count} x Adult (${booked.a}${adultPrice})`;
    }
  }

  // calc other
  if (other?.length && !vip.length) {
    const booked = other?.some((el) => el?.state === ticketStates.in_cart) ? '' : '';
    const ticketInfo = events?.ticket_type?._data?.find((el) => el?.id === other?.[0]?.ticket_type_id);
    const otherPrice = formatCurrencyPrice(code).format(Number(other?.[0]?.adjusted_value || 0)) + perLabel;
    countInfo = countInfo + ` ${other?.length} x ${ticketInfo?.name} (${booked}${otherPrice})`;
  }
  return {countInfo, vipPrice};
};

export const cutTicketName = (ticketName?: string): string | undefined => {
  if (ticketName?.toLowerCase()?.includes('parking')) return 'Parking';
  if (ticketName?.toLowerCase()?.includes('pup ticket')) return 'Paws n Claus';
  return ticketName;
};

export const calcGATickets = (order?: OrderT) => {
  const gaOnly = order?.tickets?.filter((el) => isGA(el));
  return {
    adult: gaOnly?.filter((el) => el?.ticketType?.toLowerCase().includes(adult_key_l))?.length || 0,
    child: gaOnly?.filter((el) => el?.ticketType?.toLowerCase().includes(child_key_l))?.length || 0,
    senior: gaOnly?.filter((el) => el?.ticketType?.toLowerCase().includes('senior'))?.length || 0,
    military: gaOnly?.filter((el) => el?.ticketType?.toLowerCase().includes('military'))?.length || 0,
  };
};

export const checkVipBuy = (
  events?: (EventsT & {sellerId?: string})[],
  orders?: OrderT[],
  setData?: (value: canBuyVipT) => void,
) => {
  if (!events?.length) return [];

  orders?.forEach(async (order) => {
    const eventData = events?.find((ev) => ev?.sellerId === order?.sellerId);
    const gaDate = order?.tickets?.find((ticket) => isGA(ticket))?.eventStartDate;
    const vipPassEvent = eventData?.event_template?._data?.find((el) =>
      el?.name?.toLowerCase().includes('vip experience'),
    );
    const templateId = vipPassEvent?.id;
    const onDate = getTZdate(gaDate, order).format(formats.onDate);
    const startDate = getTZdate(gaDate, order).format(formats.timeStart);
    if (!templateId || !order?.id) return;

    // OLD LOGIC
    // const res = await getAvailableEventSessionsAndPrice({
    //   eventTemplateId: templateId,
    //   body: {ticket_types_required: [{ticket_type_id: ticketTypesGA?.[0]?.id, quantity: 10}]},
    // });
    // const startDate = gaDate && new Date(gaDate).getTime();
    // const byDate = res?.body?.event_session?._data?.find(
    //   (event: EventSessionT) => event?.start_datetime && new Date(event?.start_datetime).getTime() === startDate,
    // ) as EventSessionT;
    const res = await getEventSessionsForOneDate(templateId, onDate);
    const byDate = res?.body?.event_session?._data?.find(
      (sess: EventSessionT) => getTZdate(sess.start_datetime, order).format(formats.timeStart) === startDate,
    );
    const purchased = Object.values(calcGATickets(order)).reduce((p, n) => p + n, 0);
    const canBuy = byDate?.sold_out
      ? false
      : Number(byDate?.sold_quantity) + purchased + purchased <= Number(byDate?.capacity);
    setData?.({orderId: order.id, canBuy});
  });
};

export const isGACat = (category?: string) =>
  !!(category?.toLowerCase()?.includes('admission') || category?.toLowerCase()?.includes('general admission')) &&
  !category?.toLowerCase()?.includes('parking');

export const isGA = (ticket?: TicketT) => {
  const tmpCategory = ticket?.eventTemplateCategory?.toLowerCase();
  const tmpName = ticket?.eventTemplateName?.toLowerCase();
  return !!(tmpCategory?.includes('ga') || tmpCategory?.includes('admission') || tmpName?.includes('admission'));
};

export type GAtimeT = {start?: string; end?: string; timeZone?: string};
export const getGAtime = (order?: OrderT): GAtimeT => {
  const gaTicket = order?.tickets?.find((el) => isGA(el));
  return {start: gaTicket?.eventStartDate, end: gaTicket?.eventEndDate, timeZone: order?.sellerTimezone};
};

export const getTZdate = (date?: string | Date, order?: Partial<OrderT>) => {
  const tzDate = moment.tz(date, order?.sellerTimezone ? order?.sellerTimezone : '');
  return tzDate;
};

export const getExistingOrderFormCart = (
  order?: OrderT,
  purchaseRes?: PurchaseResT,
  events?: EventsT,
): OrderInfoT[] => {
  const ticketsMap: Record<string, PRTicketT[]> = {};
  const booked = purchaseRes?.ticket?._data?.filter((el) => el?.state === 'booked');
  booked?.forEach((prTicket) => {
    if (prTicket?.handler === 'donation') return;
    if (ticketsMap?.[prTicket.ticket_type_id]) {
      return (ticketsMap[prTicket.ticket_type_id] = [...ticketsMap[prTicket.ticket_type_id], prTicket]);
    }
    ticketsMap[prTicket.ticket_type_id] = [prTicket];
  });
  // const ticketTypes = events?.ticket_type?._data;
  const ticketGroups = events?.ticket_group?._data;
  return [
    {
      label: 'Existing order',
      title: order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '',
      value: Object.keys(ticketsMap).map((id) => {
        const ticketItem = ticketsMap[id]?.[0];
        const count = ticketsMap[id].length;
        const name = ticketGroups?.find((el) => el?.id === ticketItem?.ticket_group_id)?.name;
        const price = count * Number(ticketItem?.adjusted_value || 0);

        return {
          name,
          count,
          infoTicket: `(${formatCurrencyPrice(ticketItem?.currency_code || 'USD').format(price)})`,
          price: `(${formatCurrencyPrice(ticketItem?.currency_code || 'USD').format(price)})`,
        };
      }),
    },
  ];
};

export type TicketsMapKey =
  | 'ga_adult'
  | 'ga_senior'
  | 'ga_military'
  // | 'ga_child'
  | 'ga_junior'
  | 'ga_infant'
  | 'skating_adult'
  // | 'skating_child'
  | 'skating_junior'
  | 'vippass_adult'
  // | 'vippass_child'
  | 'vippass_junior'
  | 'parking'
  | 'elf'
  | 'ga_night_owl'
  | 'preferred_parking'
  | 'valet_parking'
  | 'pnc'
  //new
  | 'dinner_adult'
  | 'dinner_junior'
  | 'snow_tubing'
  | 'voucher';
export const getBookedTicketTypes = (order?: OrderT, events?: EventsT, addons?: OrderTicketsT[]) => {
  const e_ticket_types = events?.ticket_type?._data;
  const ticketsMap: Record<TicketsMapKey, {count: number; ticketType: string}> = {
    ga_adult: {count: 0, ticketType: ''},
    ga_senior: {count: 0, ticketType: ''},
    ga_military: {count: 0, ticketType: ''},
    // ga_child: {count: 0, ticketType: ''},
    ga_junior: {count: 0, ticketType: ''},
    ga_infant: {count: 0, ticketType: ''},
    ga_night_owl: {count: 0, ticketType: ''},
    skating_adult: {count: 0, ticketType: ''},
    // skating_child: {count: 0, ticketType: ''},
    skating_junior: {count: 0, ticketType: ''},
    vippass_adult: {count: 0, ticketType: ''},
    // vippass_child: {count: 0, ticketType: ''},
    vippass_junior: {count: 0, ticketType: ''},
    parking: {count: 0, ticketType: ''},
    preferred_parking: {count: 0, ticketType: ''},
    valet_parking: {count: 0, ticketType: ''},
    elf: {count: 0, ticketType: ''},
    //special events
    pnc: {count: 0, ticketType: ''},
    //new
    dinner_adult: {count: 0, ticketType: ''},
    dinner_junior: {count: 0, ticketType: ''},
    snow_tubing: {count: 0, ticketType: ''},
    voucher: {count: 0, ticketType: ''},
  };
  // without valet and preferred parking
  const selectedParking = order?.tickets
    ?.filter(
      (el) =>
        !el?.ticketTypeName?.toLowerCase().includes(preferred_key) &&
        !el?.ticketTypeName?.toLowerCase().includes(valet_key),
    )
    ?.find((el) => el?.ticketTypeName?.toLowerCase()?.includes('parking'));

  const gaGroup = events?.ticket_group?._data?.find((el) => el?.name?.toLowerCase()?.includes('admission'));
  const skatingGroup = events?.ticket_group?._data?.find((el) => el?.name?.toLowerCase()?.includes('skating'));
  // const vipPassGroup = events?.ticket_group?._data?.find((el) => el?.name?.toLowerCase()?.includes('vip express'));
  const vipPassGroup = events?.ticket_group?._data?.find((el) => el?.name?.toLowerCase()?.includes('vip experience'));
  const parkingGroup = events?.ticket_group?._data?.find((el) => el?.name === selectedParking?.ticketGroupName);
  const preferredParkingGroup = events?.ticket_group?._data?.find((el) =>
    el?.name?.toLowerCase().includes(preferred_key),
  );
  const valetParkingGroup = events?.ticket_group?._data?.find((el) => el?.name?.toLowerCase().includes(valet_key));
  const elfGroup = events?.ticket_group?._data?.find((el) => el?.name?.toLowerCase()?.includes('elf'));
  const pawsTicketGroup = events?.ticket_group?._data?.find((el) => el?.name?.toLowerCase()?.includes('paws'));
  const dinnerTicketGroup = events?.ticket_group?._data?.find((el) => el?.name?.toLowerCase()?.includes('dinner'));
  const snowTubingTicketGroup = events?.ticket_group?._data?.find((el) =>
    el?.name?.toLowerCase()?.includes('snow tubing'),
  );
  const merchandiseGroup = events?.ticket_group?._data?.find((el) => el?.name?.toLowerCase()?.includes('merchandise'));

  order?.tickets?.forEach((ticket) => {
    const tt = ticket?.ticketType?.toLowerCase();
    const tt_name = ticket?.ticketTypeName?.toLocaleLowerCase();
    if (tt === 'adult_admission') ticketsMap.ga_adult['count'] += 1;
    if (tt?.toLocaleLowerCase().includes('owl')) ticketsMap.ga_night_owl['count'] += 1;
    if (tt?.includes('admission') && tt?.includes('military')) ticketsMap.ga_military['count'] += 1;
    if (tt?.includes('admission') && tt?.includes('senior')) ticketsMap.ga_senior['count'] += 1;
    if (tt === 'adult_vip_admission' && ticket?.ticketTypeName?.toLowerCase().includes('adult'))
      ticketsMap.vippass_adult['count'] += 1;

    if (tt?.toLocaleLowerCase()?.includes('skating') && tt_name?.includes('adult'))
      ticketsMap.skating_adult['count'] += 1;

    //parking
    if (tt?.toLowerCase()?.includes('parking')) {
      if (tt_name?.toLowerCase()?.includes(preferred_key)) ticketsMap.preferred_parking['count'] += 1;
      else if (tt_name?.toLowerCase()?.includes(valet_key)) ticketsMap.valet_parking['count'] += 1;
      else ticketsMap.parking['count'] += 1;
    }
    if (tt?.toLowerCase()?.includes('elf')) ticketsMap.elf['count'] += 1;

    //junior
    if (tt?.includes('admission') && tt_name?.includes('junior')) ticketsMap.ga_junior['count'] += 1; //ga junior
    if (tt?.includes('vip') && tt?.includes('junior')) ticketsMap.vippass_junior['count'] += 1; //vip junior
    if (tt?.toLocaleLowerCase()?.includes('skating') && tt_name?.includes('junior'))
      ticketsMap.skating_junior['count'] += 1; //skating junior

    //infant
    if (tt?.includes('admission') && tt_name?.includes(infant_key_l)) ticketsMap.ga_infant['count'] += 1; //ga infant

    //special
    if (tt?.includes('paws ')) ticketsMap.pnc['count'] += 1; //pup ticket

    //new
    if (tt?.includes('dinner') && tt?.includes('junior')) ticketsMap.dinner_junior['count'] += 1;
    if (tt?.includes('dinner') && tt?.includes('adult')) ticketsMap.dinner_adult['count'] += 1;

    if (tt?.includes('snow tubing')) ticketsMap.snow_tubing['count'] += 1;

    if (tt?.includes('voucher')) ticketsMap.voucher['count'] += 1;
  });

  e_ticket_types?.forEach((type) => {
    const name = type?.name.toLowerCase();
    // const gl_code = type?.gl_code?.toLowerCase();
    //check ga
    if (name?.includes('adult') && type.ticket_group_id === gaGroup?.id) ticketsMap.ga_adult['ticketType'] = type.id;
    // if (name?.includes('child') && type.ticket_group_id === gaGroup?.id) ticketsMap.ga_child['ticketType'] = type.id;
    if (name?.includes('junior') && type.ticket_group_id === gaGroup?.id) ticketsMap.ga_junior['ticketType'] = type.id;
    if (name?.includes('senior') && type.ticket_group_id === gaGroup?.id) ticketsMap.ga_senior['ticketType'] = type.id;
    if (name?.includes('military') && type.ticket_group_id === gaGroup?.id)
      ticketsMap.ga_military['ticketType'] = type.id;
    if (name?.includes('owl') && type.ticket_group_id === gaGroup?.id) ticketsMap.ga_night_owl['ticketType'] = type.id;
    if (name?.includes(infant_key_l) && type.ticket_group_id === gaGroup?.id)
      ticketsMap.ga_infant['ticketType'] = type.id;
    //check skating
    if (name?.includes('adult') && type.ticket_group_id === skatingGroup?.id)
      ticketsMap.skating_adult['ticketType'] = type.id;
    if (name?.includes('junior') && type.ticket_group_id === skatingGroup?.id)
      ticketsMap.skating_junior['ticketType'] = type.id;

    //check parking
    if (name?.includes('parking') && type.ticket_group_id === parkingGroup?.id)
      ticketsMap.parking['ticketType'] = type.id;
    if (name?.includes(preferred_key) && type.ticket_group_id === preferredParkingGroup?.id)
      ticketsMap.preferred_parking['ticketType'] = type.id;
    if (name?.includes(valet_key) && type.ticket_group_id === valetParkingGroup?.id)
      ticketsMap.valet_parking['ticketType'] = type.id;

    //check vip pass
    if (name?.includes('adult') && type.ticket_group_id === vipPassGroup?.id)
      ticketsMap.vippass_adult['ticketType'] = type.id;
    if (name?.includes('junior') && type.ticket_group_id === vipPassGroup?.id)
      ticketsMap.vippass_junior['ticketType'] = type.id;

    //check elf
    if (name?.includes('elf') && type.ticket_group_id === elfGroup?.id) ticketsMap.elf['ticketType'] = type.id;

    //check pnc
    if (name?.includes('pup ticket') && type.ticket_group_id === pawsTicketGroup?.id)
      ticketsMap.pnc['ticketType'] = type.id;

    //new
    if (name?.includes('junior') && type.ticket_group_id === dinnerTicketGroup?.id)
      ticketsMap.dinner_junior['ticketType'] = type.id;
    if (name?.includes('adult') && type.ticket_group_id === dinnerTicketGroup?.id)
      ticketsMap.dinner_adult['ticketType'] = type.id;

    if (name?.includes('snow tubing') && type.ticket_group_id === snowTubingTicketGroup?.id)
      ticketsMap.snow_tubing['ticketType'] = type.id;

    if (name?.includes('voucher') && type.ticket_group_id === merchandiseGroup?.id)
      ticketsMap.voucher['ticketType'] = type.id;
  });

  //special_events
  addons?.forEach((addon) => {
    if (addon?.ticketGroupName?.toLowerCase()?.includes('paws')) {
      if (!addon?.eventTypeId || !addon?.count) return;
      const purchaseCount = ticketsMap.pnc['count'] + addon?.count;
      ticketsMap.pnc['ticketType'] = addon?.eventTypeId || '';
      ticketsMap.pnc['count'] = purchaseCount <= MAX_PNC_TICKETS ? purchaseCount : 2;
    }
  });

  return {ticketsMap};
};

export const getErrorResponse = (e: unknown) => {
  try {
    const jsonErr = JSON.parse(JSON.stringify(e));
    return jsonErr?.response?.text && (JSON.parse(jsonErr?.response?.text) as purchaseErrorT);
  } catch (e) {
    return false;
  }
};
export const formatPurchaseError = (e: unknown, setError: (err?: string) => void) => {
  const textErr = getErrorResponse(e);
  const baseMSG = 'Your card was declined, please confirm the entered information.';
  setError(baseMSG || textErr?.details?.data?._data?.[0]?._description);
};

export const dateGreaterEqualThen = (format1: string, format2: string) => {
  const date1: Record<number, number> = {};
  const date2: Record<number, number> = {};

  format1.split('-').forEach((el, i) => (date1[i] = Number(el)));
  format2.split('-').forEach((el, i) => (date2[i] = Number(el)));

  if (date1[0] > date2[0]) return true;
  if (date1[0] === date2[0] && date1[1] > date2[1]) return true;
  if (date1[0] === date2[0] && date1[1] === date2[1] && date1[2] >= date2[2]) return true;

  return false;
};

export const filterExistingOrder = (name?: string) => {
  return name?.toLowerCase().includes('visit') || name?.toLowerCase().includes('change');
};

export const formatHiddenSessions = (events?: EventsT): CustomHideSessionT[] => {
  const sessions = events?.meta?._data?.filter((el) => el?.metakey === 'hide_session') || [];
  const hiddenSessions: CustomHideSessionT[] = sessions?.map((el) => {
    const name = events?.event_template?._data?.find((template) => template?.id === el?.resource_id)?.name;
    const arrValue = strToArray(el?.value);
    return {
      ...el,
      name,
      arrValue,
    };
  });
  return hiddenSessions;
};

export const strToArray = (str?: string) => {
  if (!str) return [];
  const formatted = str?.replace(/'/g, '"');
  if (!formatted) return [];
  return JSON.parse(formatted);
};

export const checkIsHiddenSession = (date?: string, time?: string, hiddenSesions?: string[]) => {
  if (!date || !time || !hiddenSesions) return false;
  let isHidden = false;
  hiddenSesions?.forEach((el) => {
    const session = el.replaceAll('pm', '');
    const start = time.replace(' pm', '').replace('pm', '')?.split(' - ')?.[0];
    if (session.includes(date) && session.includes(start)) isHidden = true;
  });
  return isHidden;
};

export const getShortListOfTickets = (types?: (string | undefined)[]) => {
  const ListMap: Record<string, number> = {};
  const listArr = types?.filter((el) => !!el && !el?.includes('visit protection'));
  const listShorted = listArr?.map((type) => {
    if (type?.toLocaleLowerCase().includes('admission')) return `GA`;
    if (type?.toLocaleLowerCase().includes('skating')) return `skating`;
    if (type?.toLocaleLowerCase().includes('parking')) return `parking`;
    if (type?.toLocaleLowerCase().includes('vip')) return `VIP`;
    return type;
  });
  listShorted?.forEach((el) => {
    if (!el) return;
    ListMap[el] = (ListMap?.[el] || 0) + 1;
  });
  const last = Object.keys(ListMap).length - 1;
  return Object.keys(ListMap)
    .map((el, i) => (last !== i ? `${ListMap[el]} ${el}, ` : `${ListMap[el]} ${el}`))
    .join('');
};
