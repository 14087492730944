import styled from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {Logo} from '../../ui-kit/Logos/Logo';
import {Heading1, Text3} from '../../ui-kit/Typography';
import {PublicButton} from '../../ui-kit/Button';

export const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

export const ContentWrapper = styled.div`
  max-width: 605px;
  padding-top: 204px;
  ${Media.down.m} {
    padding-top: 85px;
    max-width: 100%;
  }
`;

export const LogoSilver = styled(Logo).attrs({variant: 'silver'})`
  width: 150px;
  height: 35px;
  margin-bottom: ${({theme}) => theme.spacer._1};
  ${Media.down.m} {
    margin: 0 auto;
  }
`;

export const ConfirmOrderTitle = styled(Heading1)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  text-transform: capitalize;
  margin-bottom: ${({theme}) => theme.spacer._1};

  ${Media.down.m} {
    text-align: center;
  }
`;

export const ConfirmOrderDescr = styled(Text3)`
  color: ${({theme}) => theme.palette.system.white};
  display: block;
  margin-bottom: ${({theme}) => theme.spacer._9};

  ${Media.down.m} {
    text-align: center;
    margin-bottom: ${({theme}) => theme.spacer._5};
  }
`;

export const DetailedCardWrapper = styled.div`
  margin-bottom: ${({theme}) => theme.spacer._9};
`;

export const BackButtonStyled = styled(PublicButton)``;
