import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledSvg = styled.div`
margin-left: ${({theme}) => theme.spacer._1}}
`;

export const StyledDescriptionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledTicketInfo = styled(StyledWrapper)`
  margin-top: 16px;
`;
