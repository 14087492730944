import React, {useMemo, useRef, useState} from 'react';
import {ExclamationCircleFilled} from '@ant-design/icons';
import {
  StyledTicketCardWrapper,
  StyledTicketCardBoard,
  StyledCountTicket,
  StyledRed,
  StyledInfo,
  StyledPrice,
  StyledPriceCoast,
  StyledTickedCardWrapper,
  StyledTickedCardButton,
  StyledCardFromTicketTitle,
  StyledCardFromTicketDate,
  StyledCardFromTicketDay,
  DateInnerSeparator,
  StyledLeftWrapper,
  SoldoutLine,
  AddonNameLine,
} from './styles';
import {TypeVariant, TicketCardPropsT} from './types';
import {IconSvg} from '../../Icon';
import {StyledTextDates, TextSmall} from '../../Typography/styles';
import {getTZdate} from '../../../helpers/orders';
import {formats} from '../../../helpers/helpers';
import {getSoldOut} from '../../../helpers/events';

const CardFromTicket: React.FC<TicketCardPropsT> = ({
  data,
  onClick,
  isActive,
  totalCoast,
  sellerTimezone,
  weatherProtection,
  onMonthChange,
  bookedSessions,
  addonName,
  specialNight,
}) => {
  const monRef = useRef<HTMLDivElement | null>(null);
  const [triggerTimer, setTriggerTimer] = useState(false);
  setTimeout(() => {
    if (monRef.current && getTZdate(data.title, {sellerTimezone}).format(formats.date3) === '01' && !triggerTimer) {
      const position = monRef.current.getBoundingClientRect().top;
      if (position && onMonthChange) {
        onMonthChange(position - 630);
        setTriggerTimer(true);
      }
    }
  }, 200);
  const chooseViewText = 'For selected tickets';
  const Click = () => {
    if (data.variant === TypeVariant.sold) return;
    onClick(data.id);
  };
  const soldout = useMemo(() => getSoldOut(data, bookedSessions, sellerTimezone), [data?.title]);
  const totalPrice = weatherProtection ? '$0' : totalCoast;
  const isSpecial = !!addonName || !!specialNight?.name;
  return (
    <StyledTicketCardWrapper $isActive={isActive} onClick={Click} ref={monRef} $hasAddon={isSpecial}>
      <AddonLine addonName={specialNight?.name || addonName} icon={specialNight?.icon} />
      <StyledTicketCardBoard {...data} $isActive={isActive}>
        {data.variant === TypeVariant.sold && (
          <StyledLeftWrapper>
            <StyledCardFromTicketTitle variant={data.variant}>
              <StyledCardFromTicketDate>
                {data.title && getTZdate(data.title, {sellerTimezone}).format(formats.date3)}
              </StyledCardFromTicketDate>
              <StyledCardFromTicketDay>
                {data.title && getTZdate(data.title, {sellerTimezone}).format(formats.date4)}
              </StyledCardFromTicketDay>
            </StyledCardFromTicketTitle>
            <DateInnerSeparator />
          </StyledLeftWrapper>
        )}
        {data.variant !== TypeVariant.sold && (
          <StyledLeftWrapper>
            <StyledCardFromTicketTitle variant={data.variant}>
              <StyledCardFromTicketDate>
                {data.title && getTZdate(data.title, {sellerTimezone}).format(formats.date3)}
              </StyledCardFromTicketDate>
              <StyledCardFromTicketDay>
                {data.title && getTZdate(data.title, {sellerTimezone}).format(formats.date4)}
              </StyledCardFromTicketDay>
            </StyledCardFromTicketTitle>
            <DateInnerSeparator />
            <StyledPriceCoast variant={data.variant}>
              <StyledPrice>{totalPrice}</StyledPrice>
              <StyledCountTicket>{chooseViewText}</StyledCountTicket>
              <SoldOutLabels soldout={soldout} />
            </StyledPriceCoast>
          </StyledLeftWrapper>
        )}
        {(data.variant === TypeVariant.fast || data.variant === TypeVariant.sold) && (
          <StyledTickedCardButton {...data}>
            {data.variant === TypeVariant.fast ? (
              <StyledTickedCardWrapper $isActive={isActive}>
                <StyledTextDates>{data.description}</StyledTextDates>
                {!!data.event && (
                  <StyledPriceCoast>
                    <IconSvg type={'snowGlobe'} />
                    <TextSmall>{data.event}</TextSmall>
                  </StyledPriceCoast>
                )}
              </StyledTickedCardWrapper>
            ) : (
              <>
                <StyledTextDates>{data.description}</StyledTextDates>
                {!!data.event && (
                  <StyledPriceCoast>
                    <IconSvg type={'snowGlobe'} />
                    <TextSmall>{data.event}</TextSmall>
                  </StyledPriceCoast>
                )}
              </>
            )}
          </StyledTickedCardButton>
        )}
        {data.variant === TypeVariant.default && (
          <StyledTickedCardButton {...data}>
            {!!data.event && (
              <StyledPriceCoast>
                <IconSvg type={'snowGlobe'} />
                <TextSmall>{data.event}</TextSmall>
              </StyledPriceCoast>
            )}
          </StyledTickedCardButton>
        )}
      </StyledTicketCardBoard>
    </StyledTicketCardWrapper>
  );
};

export const SoldoutVariant: React.FC<{title?: string}> = ({title}) => {
  return (
    <SoldoutLine>
      <StyledRed>
        <ExclamationCircleFilled />
      </StyledRed>
      <StyledInfo>{title} is sold out</StyledInfo>
    </SoldoutLine>
  );
};

const SoldOutLabels: React.FC<{
  soldout: {parking: boolean; parkingGG: boolean; skating: boolean; ga: boolean; vip: boolean; elf: boolean};
}> = ({soldout}) => {
  return (
    <>
      {soldout?.skating && <SoldoutVariant title={'Ice Skating'} />}
      {soldout?.parking && <SoldoutVariant title={'Parking'} />}
      {soldout?.vip && soldout?.elf ? (
        <SoldoutVariant title={'Vip & Elf'} />
      ) : (
        <>{soldout?.elf ? <SoldoutVariant title={'Elf'} /> : soldout?.vip && <SoldoutVariant title={'Vip'} />}</>
      )}
    </>
  );
};

const AddonLine: React.FC<{addonName?: string; icon?: string}> = ({addonName, icon}) => {
  if (!addonName) return null;
  const isPNC = addonName?.toLowerCase().includes('paws');
  return (
    <AddonNameLine>
      {addonName}
      {isPNC && <IconSvg type="paw" viewBox="0 -6 26 26" />}
      {icon && <img src={icon} />}
    </AddonNameLine>
  );
};
export default CardFromTicket;
