import styled, {css} from 'styled-components';
import {ReactComponent as LogoSilver} from './assets/logo-silver.svg';
import {ReactComponent as LogoHallmarkSilver} from './assets/logo-hallmark-silver.svg';
import {ReactComponent as LogoHallmarkBlack} from './assets/logo-hallmark-black.svg';
import {ReactComponent as LogoHallmarkWhite} from './assets/logo-hallmark-white.svg';
import {ReactComponent as LogoCorporateGold} from './assets/logo-corporate-gold.svg';
import {ReactComponent as LogoCorporateWhite} from './assets/logo-corporate-white.svg';
import {ReactComponent as LogoCorporateBlack} from './assets/logo-corporate-black.svg';
import LogoEmblemGold from './assets/logo-emblem-gold.png';
import {ReactComponent as LogoEmblemWhite} from './assets/logo-emblem-white.svg';
import {ReactComponent as LogoEmblemBlack} from './assets/logo-emblem-black.svg';

const logoEmblemStyles = css`
  display: block;
  width: 100px;
  height: 100px;
`;

const logoStyles = css`
  display: block;
  width: 306px;
  height: 107px;
`;

export const StyledLogoSilver = styled(LogoSilver)`
  ${logoStyles}
`;

export const StyledLogoHallmarkSilver = styled(LogoHallmarkSilver)`
  ${logoStyles}
`;

export const StyledLogoHallmarkBlack = styled(LogoHallmarkBlack)`
  ${logoStyles}
`;

export const StyledLogoHallmarkWhite = styled(LogoHallmarkWhite)`
  ${logoStyles}
`;

export const StyledLogoCorporateGold = styled(LogoCorporateGold)`
  ${logoStyles}
`;

export const StyledLogoCorporateWhite = styled(LogoCorporateWhite)`
  ${logoStyles}
`;

export const StyledLogoCorporateBlack = styled(LogoCorporateBlack)`
  ${logoStyles}
`;

export const StyledLogoEmblemGold = styled.img.attrs({src: LogoEmblemGold})`
  ${logoEmblemStyles}
`;

export const StyledLogoEmblemWhite = styled(LogoEmblemWhite)`
  ${logoEmblemStyles}
`;

export const StyledLogoEmblemBlack = styled(LogoEmblemBlack)`
  ${logoEmblemStyles}
`;
