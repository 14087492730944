import React from 'react';
import {useRecoilState} from 'recoil';
import {purchaseAdmission} from '../../../states/tickets';
import {HeaderNavigation} from '../../../ui-kit/AppLayout/HeaderNavigation';

export const PurchaseAdmissionHeader = () => {
  const [admission] = useRecoilState(purchaseAdmission);

  return (
    <HeaderNavigation
      percent={admission?.progress}
      title={'Purchase Tickets'}
      caption={`Order # ${admission?.orderNumber}`}
      location={admission?.sellerName}
      hideLinks
    />
  );
};
