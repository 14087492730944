import {ValueInfoTicketProps} from '../ui-kit/Order/typesOrderCardWithLogic';
import {EventSessionT} from './events';

export enum OrderInfoField {
  locationTitle = 'locationTitle',
  orderNumber = 'orderNumber',
  locationPark = 'locationPark',
}

export enum OrderData {
  label = 'label',
  mobileLabel = 'mobileLabel',
  title = 'title',
  subTitle = 'subTitle',
  mobileTitle = 'mobileTitle',
  tickets = 'tickets',
  upgradeTickets = 'upgradeTickets',
}

export type OrderTicketsT = {
  ticketGroupName: string;
  id?: string;
  count: number;
  eventEndDate?: string;
  eventStartDate?: string;
  time?: string;
  eventTypeId?: string;
  eventSessionId?: string;
  skip?: boolean;
  hideTime?: boolean;
  isSpecial?: boolean;
  ticketType?: string;
  ticketName?: string;
  templateId?: string;
  removed?: boolean;
  noTimeRewrite?: boolean;
};

export enum CodeAccessTypes {
  sent = 'sent',
  notsent = 'notsent',
}

export type TicketT = {
  createdAt?: string;
  deliveredAt?: string;
  eventEndDate?: string;
  eventStartDate?: string;
  eventTemplateCategory?: string;
  eventTemplateName?: string;
  guestEmail?: string;
  guestName?: string;
  id?: number;
  orderId?: number;
  outboundStatus?: CodeAccessTypes;
  queuedAt?: string;
  redeemedAt?: string;
  sentAt?: string;
  ticketGroupName: string;
  ticketTypeName?: string;
  ticketureScanCode?: string;
  ticketureTicketId?: string;
  updatedAt?: string;
  userId?: number;
  waiverNeeded?: boolean;
  ticketType?: string;
};
export type OrderMetadataT = {
  canCancelVisit?: boolean;
  canChangeDate?: boolean;
  canPurchaseAddOn?: boolean;
  canUpgradeToVip?: boolean;
  canViewTickets?: boolean;
  hasWaivers?: boolean;
  manageWaiversLink?: string;
  viewTicketLink?: string;
  sellerId?: string;
  sellerName?: string;
  sellerTimezone?: string;
  sellerVenue?: string;
  canPurchaseAddOns: boolean;
  allowRefund: boolean;
  allowDateChange: boolean;
  status?: null | boolean;
};

export type WaiverT = {
  createdAt?: string;
  email?: null | string;
  id?: number;
  metadata?: any;
  name?: null;
  orderId?: number;
  phoneNumber?: any;
  referenceCode?: any;
  sentAt?: any;
  signature?: any;
  signedAt?: any;
  ticketId?: number;
  ticketureTicketId?: string;
  updatedAt?: string;
  waiverStatus?: string;
};

export type OrderT = {
  createdAt?: string;
  dateChange?: boolean;
  eventDate?: string;
  id?: string;
  metadata?: OrderMetadataT;
  sellerId?: string;
  sellerName?: string;
  sellerTimezone?: string;
  sellerVenue?: string;
  sellerMeta?: object;
  sellerStripeAccount?: string;
  status?: null | boolean | string;
  tickets?: TicketT[];
  ticketureOrderId: string;
  ticketureOrderNumber?: string;
  totalPrice?: string;
  totalPayments?: string;
  updatedAt?: string;
  userId?: number;
  visitProtection?: boolean;
  waivers?: WaiverT[];
  eventEndDate?: string;
  orderCancelled?: boolean;
  refundedAmount?: number;
  weatherProtection?: boolean;
  eventArrival?: string;
  iceSkatingArrival?: string;
  parkingArrival?: string;
};

export type OrderInfoT = {
  label?: string;
  title?: string;
  value?: ValueInfoTicketProps[];
  bodyText?: string;
  mobTitle?: string;
  expandedMobTitle?: string;
  mobPlaceholder?: string;
  selectedBold?: boolean;
};

export type PaymentInfoT = {
  payment?: number;
};

export type DashboardOrderT = {
  eventDate?: string;
  ticketureOrderId: string;
  sellerName?: string;
  sellerTimezone?: string;
  sellerMeta?: object;
  ticketureOrderNumber?: string;
  eventDateFormat?: string;
  arrivalDateFormat?: string;
  isVip?: boolean;
  isProtection?: boolean;
  isSuite?: boolean;
  isFreeChangeDate?: boolean;
  viewTicketLink?: string;
  totalPrice?: string;
  totalPayments?: string;
  sellerVenue?: string;
  manageWaiversLink?: string;
  canPurchaseAddOns?: boolean;
  allowRefund?: boolean;
  allowDateChange?: boolean;
  refundedAmount?: string;
  isCancel?: boolean;
  countTickets?: string;
  id?: string;
  isIceSkating?: boolean;
  dateChangeDisabled?: boolean;
  cancelDisabled?: boolean;
  isDateChange?: boolean;
  weatherProtection?: boolean;
  hasGA?: boolean;
};

export type CartResT = {
  additional_info?: string;
  cart_number: string;
  channel_id: string;
  expires_at: string;
  expires_in: number;
  id: string;
  identity_id: string;
  invalid: boolean;
  managed: boolean;
  name: string;
  portal_id: string;
  seller_id?: string;
  state: string;
};

export type CardT = {
  card: any;
  client_ip: string;
  created: number;
  id: string;
  livemode: boolean;
  object: string;
  type: string;
  used: boolean;
};

export type OrderGATicketT = {
  orderId?: string;
  child?: number;
  adult?: number;
  senior?: number;
  military?: number;
};

export type canBuyVipT = {orderId?: string; canBuy: boolean};

export type VipSetT = {
  vippass?: EventSessionT;
  elf?: EventSessionT;
};

export type setVipFuncT = (key: 'vippass' | 'elf', event?: EventSessionT) => void;

export enum ErrorCodes {
  ticket_group_capacity_exceeded = 'ticket_group_capacity_exceeded',
}

export type SpecialNightRowT = {
  name?: string;
  note?: string;
  note_secondary?: string;
  description?: string;
  dates?: string[];
  icon?: string;
  image?: string;
  ticketID?: string;
};
