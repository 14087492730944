import {useRecoilState, useSetRecoilState} from 'recoil';
import {cancellationState} from '../states/order';
import {useEffect, useState} from 'react';
import {useCancelVisit, useGetOrderWithDetails} from './orders';
import {useNavigate, useParams} from 'react-router-dom';
import {route} from '../constants/routes';
import {useSegmentCancellation} from './customerIO';
import {commonOrderInfo} from '../states/common';

export const useCancellation = () => {
  const [reasonCancel, setReasonCancel] = useState<string>('');
  const [cancellation, setCancellation] = useRecoilState(cancellationState);
  const navigate = useNavigate();
  const {id} = useParams();
  const {order} = useGetOrderWithDetails(id);
  const {toChangeDatePageClick, onCancelComplete, onCancelReview} = useSegmentCancellation(order);
  const {cancelVisitInvoke} = useCancelVisit(onCancelComplete);
  const setOrderInfo = useSetRecoilState(commonOrderInfo);

  const setProgress = (val: number) => {
    setCancellation((prev) => ({...prev, progress: val}));
  };
  const setOrderNumber = (val: string | undefined) => {
    setCancellation((prev) => ({...prev, orderNumber: val}));
  };
  const onChangeReason = (value: string) => {
    setReasonCancel(value);
    setProgress(90);
  };
  const onCancel = async () => {
    if (id === undefined) {
      return;
    }
    await cancelVisitInvoke(id);
    navigate(route.cancelConfirmOrderPage.get({id: id}));
  };
  const onCancelBack = () => {
    navigate(route.dashboard.path);
  };
  const onClickToChangeDate = () => {
    if (!order?.ticketureOrderId) return;
    navigate(route.changeDate.get({id: order?.ticketureOrderId}));
  };

  useEffect(() => {
    setProgress(35);
  }, []);

  useEffect(() => {
    if (order?.id)
      setOrderInfo({orderId: order?.id, orderNumber: order?.ticketureOrderNumber, sellerName: order?.sellerName});
  }, [order?.id]);

  useEffect(() => {
    setOrderNumber(order?.ticketureOrderNumber);
  }, [order]);
  useEffect(() => {
    order?.id && toChangeDatePageClick();
  }, [order?.id]);
  return {
    setProgress,
    cancellation,
    reasonCancel,
    setReasonCancel,
    onChangeReason,
    onCancel,
    onCancelBack,
    onClickToChangeDate,
    onCancelReview,
  };
};

export const useCancelConfirmOrder = () => {
  const {id} = useParams();
  const {order, loading} = useGetOrderWithDetails(id);
  const navigate = useNavigate();

  const onClickToBack = () => {
    navigate(route.dashboard.path);
  };

  return {order, loading, onClickToBack};
};
