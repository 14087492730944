import styled from 'styled-components';
import {Media} from '../theme/breakpoints';
import mountain from '../assets/mountain-bg.png';
import night_sky from '../assets/night_sky_enchant_blue_rev.jpeg';

export const MainWrapper = styled.main.attrs({className: 'page-content'})`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  padding: 0;
  position: relative;
  z-index: 5;
  touch-action: manipulation;
  touch-action: pan-x pan-y;
  -ms-touch-action: manipulation;
  -ms-touch-action: pan-x pan-y;
  ${Media.down.l} {
    padding: 0px 24px 24px;
  }
  ${Media.down.m} {
    padding: 0px 16px 16px;
  }
`;

export const BackgroundWrapper = styled.div<{$isSky?: boolean}>`
  z-index: 2;
  position: relative;
  background: ${({theme}) => theme.palette.primary.midnightBlue};
  &::before {
    z-index: 1;
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-image: ${({$isSky}) => `url(${$isSky ? night_sky : mountain})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: bottom;
  }
`;

export const MobileViewWrapper = styled.div`
  touch-action: manipulation;
  touch-action: pan-x pan-y;
  -ms-touch-action: manipulation;
  -ms-touch-action: pan-x pan-y;
  display: none;
  ${Media.down.m} {
    display: block;
  }
`;

export const DesktopViewWrapper = styled.div`
  display: block;
  ${Media.down.m} {
    display: none;
  }
`;
