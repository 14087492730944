import styled from 'styled-components';
import {StyledHeading5, TextBody1, TextBody3} from '../../Typography/styles';
import {Media} from '../../theme/breakpoints';

export const StyledCard = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  padding: 24px 16px;
  gap: 16px;
  ${Media.down.l} {
    padding: 12px 12px;
  }
`;

export const WrapperDetail = styled.div`
  width: 100%;
  ${Media.down.m} {
    flex-direction: column-reverse;
    display: flex;
    width: 100%;
    margin-top: 6px;
  }
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  ${Media.down.m} {
    gap: 8px;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDetails = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // margin-top: ${({theme}) => theme.spacer._1};
`;

export const StyledText = styled(TextBody1)`
  line-height: 20px;
  ${Media.down.m} {
    font-size: 13px;
  }
`;

export const StyledGeneralInfo = styled(StyledHeading5)`
  line-height: 20px;
  font-size: 16px;
  margin-top: ${({theme}) => theme.spacer._0};
  ${Media.down.m} {
    font-size: 12px;
  }
`;

export const StyledRupture = styled.div`
  margin-top: ${({theme}) => theme.spacer._9};
`;

export const StyledWrapperDescription = styled(StyledWrapper)`
  border-bottom: 1px solid ${({theme}) => theme.palette.system.gray3};
`;

export const StylesTicketType = styled.div`
  border-top: 1px solid ${({theme}) => theme.palette.system.gray3};
  border-bottom: 1px solid ${({theme}) => theme.palette.system.gray3};
  padding: 4px 0 10px 0;
`;

export const Spacer = styled.div`
  padding: 8px 0 18px 0;
  border-bottom: 1px solid ${({theme}) => theme.palette.system.gray3};
  ${Media.down.m} {
    padding: 0px 0 8px 0;
  }
`;

export const StylesDataDiscount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({theme}) => theme.spacer._2};
  ${Media.down.m} {
    margin-top: 4px;
  }
`;
export const StylesRedeemCredits = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: ${({theme}) => theme.spacer._2};
  gap: 8px;
  width: 100%;
`;

export const StylesDataChangeFee = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({theme}) => theme.spacer._5} 0;
  border-bottom: 1px solid ${({theme}) => theme.palette.system.gray};
`;

export const StyledIcon = styled.span`
  margin-left: 8px;
`;

export const StyledTotal = styled(StylesDataDiscount)`
  margin-top: 0;
  padding: 24px 0 0 0;
  ${Media.down.m} {
    padding: 12px 0 0 0;
  }
`;

export const StyledBalance = styled(StylesDataDiscount)`
  margin-top: 0;
  padding: 12px 0 0 0;
`;

export const StyledBoldTotal = styled(StyledHeading5)`
  line-height: 29px;
  ${Media.down.m} {
    font-size: 20px;
  }
`;

export const LabelWrapper = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const VisitLabel = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  ${Media.down.m} {
    margin-bottom: 4px;
  }
  & > span {
    padding: 14px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 48px;
    ${Media.down.m} {
      margin-top: 6px;
    }
    .anticon {
      font-size: 20px;
    }
    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
    }
  }
`;

export const PaidInfoText = styled(TextBody3)`
  font-weight: 700;
`;

export const BalanceInfoText = styled(TextBody3)`
  color: ${({theme}) => theme.palette.secondary.roseRed};
  font-weight: 700;
`;

export const FacilityFeeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({theme}) => theme.spacer._1};
`;
