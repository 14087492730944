import styled from 'styled-components';
import {Media} from '../theme/breakpoints';

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 84px;
  background: ${({theme}) => theme.palette.secondary.lightGold};
  border-radius: 50%;

  ${Media.up.m} {
    width: 124px;
    height: 124px;
  }
`;

export const ImageAvatar = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;

  ${Media.up.m} {
    width: 124px;
    height: 124px;
  }
`;
