import React from 'react';
import {StyledSvg} from './styles';
import {SvgChildren} from './Children';
import {StyledSvgIconProps} from './types';
import mapIcom from '../../ui-kit/assets/icons/map.png';

export const IconSvg: React.FC<StyledSvgIconProps> = ({
  className,
  type,
  width,
  stroke,
  fillChildren,
  height,
  fill,
  viewBox,
  strokeWidth,
}) => {
  if (!type) return null;

  return type == 'map' ? (
    <img src={mapIcom} width={24} alt="map" />
  ) : (
    <StyledSvg
      className={className}
      width={width || '24'}
      height={height || '24'}
      stroke={stroke}
      viewBox={viewBox || '0 0 24 24'}
      strokeWidth={strokeWidth}
      fill={fill}>
      <SvgChildren fillChildren={fillChildren} type={type} />
    </StyledSvg>
  );
};
