import {useEffect, useState} from 'react';
import {OrderT} from '../types/orders';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import {getOrderWithDetails} from '../queries/orderGroup';

export const getOrderFromUrl = () => {
  const [order, setOrder] = useState<OrderT | undefined>();
  // const [checkoutInfo, setCheckOutInfo] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const {id} = useParams();

  const fetchOrder = async () => {
    if (!id) return;
    try {
      setLoading(true);
      const res = await getOrderWithDetails(id);
      setOrder(res?.body);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, [id]);

  return {order, loading};
};

export const useGetCurrentTab = (tabs: string[]) => {
  const {search} = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    !search ? setSearchParams(`view=${tabs[0]}`, {replace: true}) : setSearchParams(search, {replace: true});
  }, [search]);

  const onSelect = (index: number) => {
    setSearchParams(`view=${tabs[index]}`, {replace: true});
  };
  const currentTab = tabs.findIndex((item) => searchParams.get('view') === item);

  return {onSelect, currentTab};
};
