import {Typography} from 'antd';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';

import {Media} from '../theme/breakpoints';
import {mixins} from '../theme/mixins';
import {horizontalGradientBG} from '../theme/palette';

const {Text} = Typography;

const common = css`
  font-style: normal;
  color: ${({theme}) => theme.palette.system.darkBlack};
`;

const heading = css`
  display: block;
`;

export const StyledHeading1 = styled(Text)`
  ${common};
  ${heading};
  ${mixins.font.primary.bold};
  font-size: 28px;
  line-height: 34px;
  ${Media.down.m} {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const StyledHeading2 = styled(Text)`
  ${common};
  ${heading};
  ${mixins.font.primary.bold};
  font-size: 60px;
  line-height: 72px;
  ${Media.down.m} {
    font-size: 32px;
    line-height: 39px;
  }
`;

export const StyledHeading3 = styled(Text)`
  ${common};
  ${heading};
  ${mixins.font.primary.bold};
  font-size: 48px;
  line-height: 58px;
  ${Media.down.m} {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const StyledHeading4 = styled(Text)`
  ${common};
  ${heading};
  ${mixins.font.primary.bold};
  font-size: 36px;
  line-height: 42px;
  ${Media.down.m} {
    font-size: 26px;
    line-height: 32px;
  }
`;

export const StyledHeading5 = styled(Text)`
  ${common};
  ${heading};
  ${mixins.font.primary.bold};
  font-size: 24px;
  line-height: 32px;
  ${Media.down.m} {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const StyledHeading6 = styled(Text)`
  ${common};
  ${heading};
  ${mixins.font.primary.bold};
  font-size: 20px;
  line-height: 28px;
  ${Media.down.m} {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const StyledHeadingMedium = styled(Text)`
  ${common};
  ${heading};
  ${mixins.font.primary.bold};
  font-size: 32px;
  line-height: normal;
  letter-spacing: 1.28px;
  ${Media.down.m} {
    padding-top: 4px;
    font-size: 26px;
    line-height: 32px;
  }
`;

export const StyledHeadingMediumTitle = styled(Text)`
  ${common};
  ${heading};
  ${mixins.font.primary.bold};
  font-size: 28px;
  line-height: 32px;
  ${Media.down.l} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const StyledHeadingMedium2 = styled(Text)`
  ${common};
  ${heading};
  ${mixins.font.primary.bold};
  font-size: 26px;
  line-height: 32px;
`;

export const StyledHeadingMedium3 = styled(Text)`
  ${common};
  ${heading};
  ${mixins.font.primary.bold};
  font-size: 20px;
  line-height: 28px;
  ${Media.down.m} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const TextBody1 = styled(Text)`
  ${common};
  ${mixins.font.primary.light};
  font-size: 16px;
  line-height: 19px;
`;

export const TextBody2 = styled(Text)`
  ${common};
  ${mixins.font.primary.light};
  font-size: 14px;
  line-height: 20px;
`;

export const TextBody3 = styled(Text)`
  ${common};
  ${mixins.font.primary.light};
  font-size: 20px;
  line-height: 24px;
  ${Media.down.l} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const TextBody5 = styled(Text)`
  ${common};
  ${mixins.font.primary.light};
  font-size: 20px;
  line-height: 24px;
`;

export const TextCaption = styled(Text)`
  ${common};
  ${mixins.font.primary.light};
  font-size: 12px;
  line-height: 16px;
`;

export const TextSmall = styled(Text)`
  ${common};
  ${mixins.font.primary.light};
  font-size: 10px;
  line-height: 12px;
`;
export const TextSmallBold = styled(Text)`
  ${common};
  ${mixins.font.primary.bold};
  font-size: 14px;
  line-height: 20px;
`;

export const TextSixteenBodyBold = styled(Text)`
  ${common};
  ${mixins.font.primary.bold};
  font-size: 16px;
  line-height: 24px;
`;

export const TextVerySmallBold = styled(Text)`
  ${common};
  ${mixins.font.primary.bold};
  font-size: 10px;
  line-height: 12px;
`;

export const BoldTextBody1 = styled(Text)`
  ${common};
  ${mixins.font.primary.bold};
  font-size: 16px;
  line-height: 24px;
`;

export const LinkBody = styled(Link)`
  ${common};
  ${mixins.font.primary.bold};
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
`;

export const LinkBody2 = styled(Link)`
  ${common};
  ${mixins.font.primary.bold};
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
`;

export const StyledTextNote = styled(Text)`
  ${common};
  ${mixins.font.tertiary.light};
  font-size: 14px;
  line-height: 22px;
`;

export const blockquoteStyles = css`
  ${mixins.font.primary.light};
  font-style: italic;
  font-size: 24px;
  line-height: 32px;
  ${Media.down.m} {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const buttonTextStyles = css`
  ${mixins.font.primary.bold};
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  ${Media.down.m} {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const switchButtonTextStyles = css`
  ${mixins.font.primary.light};
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  ${Media.down.m} {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const formErrorTextStyles = switchButtonTextStyles;

export const labelTextStyles = css`
  ${mixins.font.primary.light};
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
`;

export const primaryTabTextStyles = css`
  ${mixins.font.primary.bold};
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
`;

export const secondaryTabTextStyles = css`
  ${mixins.font.primary.light};
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
`;

export const linkButtonTextStyles = primaryTabTextStyles;

export const overlineStyles = css`
  ${mixins.font.primary.light};
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  ${Media.down.m} {
    font-style: italic;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const formLabelStyles = css`
  ${mixins.font.primary.light};
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  ${Media.down.m} {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const navItemStyles = css`
  ${mixins.font.primary.light};
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  text-transform: uppercase;

  ${Media.down.m} {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const checkValidationTextStyles = css`
  ${mixins.font.primary.bold};
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
`;

export const horizontalGradientText = css`
  ${horizontalGradientBG};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const StyledTextDates = styled(Text)`
  ${common};
  ${heading};
  ${mixins.font.secondary.bold};
  font-size: 22px;
  line-height: 26px;
  text-transform: capitalize;
  width: 90px;
  display: block;
  text-align: right;
  ${Media.down.m} {
    width: auto;
  }
`;

export const StyledTextTimes = styled(Text)`
  ${common};
  ${heading};
  ${mixins.font.secondary.bold};
  font-size: 18px;
  line-height: 16px;
  text-transform: capitalize;
`;

export const StyledElfAvailable = styled(Text)`
  color: ${({theme}) => theme.palette.primary.darkGold};
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: -7px;
  width: 100%;
`;
