import React from 'react';

import {StyledText, StyledInfoTicket, StylesTicketType} from './styles';
import {TicketInfoWithLogicProps} from './types';
import {TextBody2, TextSixteenBodyBold} from '../../../../Typography/styles';
import {purchaseOrderTicketTypeT} from '../../../../../types/purchase';
import {CenteredLoader} from '../../../../Loader';

export const TicketsInfoList: React.FC<{items?: purchaseOrderTicketTypeT[]}> = ({items}) => {
  const vip = items?.find((el) => !!el?.isVip);
  const itemsWithoutVip = items?.filter((el) => !el?.isVip);

  if (!items?.length) return <CenteredLoader />;
  return (
    <>
      {itemsWithoutVip?.map((el, i) => (
        <TicketInfoWithLogic data={el} key={i} vipElement={vip} />
      ))}
    </>
  );
};

const TicketInfoWithLogic: React.FC<TicketInfoWithLogicProps> = ({data, vipElement}) => {
  const vipExist = !!vipElement?.countInfo && !!data?.isGa;
  return (
    <>
      <StylesTicketType $noBotOffset={vipExist}>
        <StyledInfoTicket>
          <TextSixteenBodyBold>{data?.ticketType}</TextSixteenBodyBold>
          <TextBody2>
            <div dangerouslySetInnerHTML={{__html: data?.countInfo || ''}} />
          </TextBody2>
        </StyledInfoTicket>
        <StyledText $isAmount>{data?.amount}</StyledText>
      </StylesTicketType>
      {vipExist && (
        <StylesTicketType $noTopOffset={true}>
          <StyledInfoTicket>
            <TextBody2>
              <b>VIP</b> {vipElement?.countInfo}
            </TextBody2>
          </StyledInfoTicket>
          <StyledText>{vipElement?.amount}</StyledText>
        </StylesTicketType>
      )}
    </>
  );
};

export default TicketInfoWithLogic;
