import React, {useEffect, useState} from 'react';
// import {useSetRecoilState} from 'recoil';
// import {addonSession} from '../../states/session';
import moment from 'moment-timezone';
import {
  StyledChooseTicket,
  StyledTitleAddText,
  // AddOnsList,
  AddOnsCards,
  AddonDateSubTitle,
  // AddonDateSubDescr,
  AddonDateTextHead,
  PickAddonDateBlock,
  TimeCardLines,
  AddOnsCardWrapper,
} from './styles';
import {AddPurchaseOrderProps} from './types';
import {AddonCardFunnelWithLogic} from '../../ui-kit/Cards/CardFunnelWithLogic/CardFunnelWithLogic';
import {CenteredLoader} from '../../ui-kit/Loader';
import {Label} from '../../ui-kit/Label';
import {getDescriptionByName} from '../../helpers/purchaseAddOns';
import {AddonDatePickerProps} from '../../ui-kit/Cards/CardFunnelWithLogic/types';
import {useGetAddonTime} from '../../hooks/purchase';
import {AddonTimeCard} from './AddonTimeCard';
import {SoldoutLine, StyledInfo, StyledRed} from '../../ui-kit/Cards/CardFromTicket/styles';
import {ExclamationCircleFilled} from '@ant-design/icons';
import {toUpper} from '../../helpers/helpers';

const AddPurchaseOrderDetail: React.FC<AddPurchaseOrderProps> = ({
  addons,
  addonsInfo,
  addonsState,
  changeState,
  availableAddons,
  eventsLoading,
  purchaseError,
  parkingItems,
  ticketsAvSessions,
  gaTime,
  hiddenSesions,
}) => {
  const addonKeys = Object.keys(addonsInfo || {});
  const addonReverse = addons?.slice().reverse();
  const availableAddon: any = [];
  const unavailableAddons: any = [];
  for (let i = 0; addonReverse && i < addonReverse?.length; i++) {
    const addon = addonReverse[i];
    const matched = availableAddons?.find((item) => item.groupName === addon.name);
    if (matched) {
      availableAddon.push(addon);
    } else {
      unavailableAddons.push(addon);
    }
  }
  const reOrderedAddons = [...availableAddon, ...unavailableAddons];

  return (
    <>
      <StyledTitleAddText>AVAILABLE ADD ONS</StyledTitleAddText>
      <StyledChooseTicket>
        Make your evening even more magical with a few added touches. Our available add ons are listed below.
      </StyledChooseTicket>
      <AddOnsCards>
        {eventsLoading && <CenteredLoader variant={'light'} />}
        {reOrderedAddons?.map(
          (item) =>
            !item.name?.includes('Suite') &&
            item.id !== undefined &&
            addonKeys.includes(item.id) && (
              <AddOnsCardWrapper key={item.id}>
                <AddonCardFunnelWithLogic
                  name={item?.name}
                  description={item?.description}
                  addonTickets={item?.id ? addonsInfo?.[item?.id] : undefined}
                  addonsState={addonsState}
                  changeState={changeState}
                  addonId={item?.id}
                  availableAddons={availableAddons}
                  parkingItems={parkingItems}
                  ticketsAvSessions={ticketsAvSessions}
                  gaTime={gaTime}
                  templateId={item.id}
                  hiddenSesions={hiddenSesions}
                />
              </AddOnsCardWrapper>
            ),
        )}
      </AddOnsCards>
      {/* <AddOnsList>
        {eventsLoading && <CenteredLoader variant={'light'} />}
        {addons?.map((item) => (
          <AddonCardFunnelWithLogic
            key={item.id}
            name={item?.name}
            description={item?.description}
            addonTickets={item?.id ? addonsInfo?.[item?.id] : undefined}
            addonsState={addonsState}
            changeState={changeState}
            addonId={item?.id}
            availableAddons={availableAddons}
            parkingItems={parkingItems}
            ticketsAvSessions={ticketsAvSessions}
            gaTime={gaTime}
            templateId={item.id}
            hiddenSesions={hiddenSesions}
          />
        ))}
      </AddOnsList> */}
      {purchaseError && <Label type={'error'} label={purchaseError} />}
    </>
  );
};

// const descriptions = {
//   none: '',
//   skating:
//     'Select your Ice Skating entry time window.  Please be aware of your general admission time and schedule your ice skating 15 min after.',
//   parking:
//     'Your parking arrival window.  Please be aware of your general admission time and schedule your parking the same time as your general admission arrival window.',
//   santaPackage: 'Select your Secret Santa Visit time.',
// };

export const AddPurchaseAddonDate: React.FC<AddPurchaseOrderProps> = ({
  addons,
  addonsInfo,
  addonsState,
  changeState,
  availableAddons,
  purchaseError,
  parkingItems,
  gaTime,
  addAddonSession,
  hiddenSesions,
}) => {
  return (
    <>
      <StyledTitleAddText>SELECT ARRIVAL WINDOW</StyledTitleAddText>
      <StyledChooseTicket style={{maxWidth: 650}}>
        Please select your arrival window below. Guests are welcome to arrive during their selected window; however,
        early entries will not be accommodated.
      </StyledChooseTicket>
      {addons?.map((item) => (
        <AddonDatePicker
          item={item}
          addonTickets={item?.id ? addonsInfo?.[item?.id] : undefined}
          addonsState={addonsState}
          addonsChangeState={changeState}
          availableAddons={availableAddons}
          parkingItems={parkingItems}
          key={item?.id}
          gaTime={gaTime}
          addAddonSession={addAddonSession}
          hiddenSesions={hiddenSesions}
        />
      ))}
      {purchaseError && <Label type={'error'} label={purchaseError} />}
    </>
  );
};

interface TimeRangeProps {
  targetRange: string;
  ranges: any[];
  timezone: string;
}
const TimeRangeComparison = ({targetRange, ranges, timezone}: TimeRangeProps) => {
  const compareTimeRanges = () => {
    const referenceTimeValue = parseInt(targetRange, 10);
    const closestTime = ranges.reduce(
      (closest, current) => {
        const dinnerStart = moment.tz(current?.start_datetime, timezone).format('hmm');
        const currentTimeValue = parseInt(dinnerStart, 10);
        const currentDiff = Math.abs(currentTimeValue - referenceTimeValue);
        if (currentDiff < closest.diff) {
          return {time: current, diff: currentDiff};
        }
        return closest;
      },
      {time: '', diff: Infinity},
    );
    return [closestTime.time];
  };
  return compareTimeRanges();
};

const AddonDatePicker: React.FC<AddonDatePickerProps> = ({
  item,
  addonsState,
  gaTime,
  addAddonSession,
  hiddenSesions,
}) => {
  const [chosen, setChosen] = useState<string | undefined>('');
  const [timeSessions, setTimeSessions] = useState<any>([]);
  // const [timeInfo, setTimeInfo] = useState<any>([]);
  // const setAddonSession = useSetRecoilState(addonSession);
  const tickets = item?.id && addonsState ? addonsState?.[item.id] : undefined;
  const isSelected = tickets && Object.values(tickets).reduce((p, n) => p + n, 0);
  const name = getDescriptionByName(item?.name);
  const {times, loading} = useGetAddonTime(!isSelected, tickets, item, gaTime, name === 'parking');
  const onChose = (id?: string) => {
    // if (timeInfo?.length > 0 && timeInfo.find((ticket: any) => ticket?.name === item?.name)) {
    // }
    // const newTimeInfo: any = [...timeInfo, {item, times}];
    // setTimeInfo(newTimeInfo);
    // setAddonSession(newTimeInfo);
    addAddonSession?.(item?.id, id);
    setChosen(id);
  };
  const hiddenSessionByTemplateId = hiddenSesions?.find((el) => el?.resource_id === item?.id)?.arrValue;
  if (!isSelected) return null;
  useEffect(() => {
    if (gaTime?.timeZone && item?.name === 'Dinner' && times) {
      const gaStart = moment.tz(gaTime?.start, gaTime?.timeZone).format('hmm');
      const theTimeSessions = TimeRangeComparison({targetRange: gaStart, ranges: times, timezone: gaTime?.timeZone});
      setTimeSessions(theTimeSessions);
    } else {
      setTimeSessions(times);
    }
  }, [gaTime, times, item]);
  return (
    <PickAddonDateBlock>
      <AddonDateTextHead>
        <AddonDateSubTitle>{toUpper(`${item?.name}`)}</AddonDateSubTitle>
        {/* <AddonDateSubDescr>{descriptions[name]}</AddonDateSubDescr> */}
      </AddonDateTextHead>
      {!times && loading && <CenteredLoader variant={'light'} />}
      <TimeCardLines>
        {timeSessions?.map((time: any) => (
          <AddonTimeCard
            {...time}
            key={time?.id}
            onChose={onChose}
            chosen={chosen}
            gaTime={gaTime}
            selected={isSelected}
            ticketName={name}
            isParking={name === 'parking'}
            hiddenSesions={hiddenSessionByTemplateId}
          />
        ))}
        {!times?.length && !loading && <NotAvailable title={item?.name} />}
      </TimeCardLines>
    </PickAddonDateBlock>
  );
};

const NotAvailable: React.FC<{title?: string}> = ({title}) => {
  return (
    <SoldoutLine>
      <StyledRed>
        <ExclamationCircleFilled />
      </StyledRed>
      <StyledInfo>{title} is no available.</StyledInfo>
    </SoldoutLine>
  );
};
export default AddPurchaseOrderDetail;
