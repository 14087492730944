import React, {useEffect} from 'react';
import Profile from '../../components/Profile';
import {useViewer} from '../../hooks/auth';
import {useGetCurrentTab} from '../../hooks/helpers';
import {useUserAccountAnalytics} from '../../hooks/customerIO';
import {useUpdateUser} from '../../hooks/user';

const tabs = ['myProfile', 'preferences'];

const ProfileContainer = () => {
  const viewer = useViewer();
  const {currentTab, onSelect} = useGetCurrentTab(tabs);
  const {onProfileVisited} = useUserAccountAnalytics();
  const {handleUpdate, handlePictureUpload, loading, updatePreferences, error} = useUpdateUser();
  useEffect(() => {
    onProfileVisited();
  }, []);
  return (
    <Profile
      viewer={viewer || undefined}
      loading={!viewer ? true : false}
      currentTab={currentTab}
      onSelect={onSelect}
      handleUpdate={handleUpdate}
      handlePictureUpload={handlePictureUpload}
      updateLoading={loading}
      updatePreferences={updatePreferences}
      isError={error}
    />
  );
};

export default ProfileContainer;
