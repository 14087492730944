import {ButtonProps} from 'antd';
import React from 'react';
import {useSetRecoilState} from 'recoil';
import {selectedTabState, selectedOptState} from '../../states/general';
import {
  StyledLink,
  StyledLinkButton,
  StyledLinkView,
  StyledLinkButtonView,
  ButtonPrimaryLink,
  DashboardButton,
  AppleWalletButton,
  GoogleWalletButton,
  VipOptButton,
} from './styles';
import {ButtonPropsT, LinkButtonViewT, DashboardButtonT, VipButtonState} from './types';
import {WithChildren} from '../../types/helpers';
import {Link} from 'react-router-dom';
import Trash from '../../ui-kit/assets/icons/trash.png';
import {MobileView} from '../AppLayout/View';
import Wallet from '../../ui-kit/assets/wallet/wallet.png';
import {IconSvg} from '../Icon';

export const LinkButton: React.FC<ButtonProps & ButtonPropsT & {to: string; disabled?: boolean}> = ({
  to,
  children,
  disabled,
  ...props
}) => (
  <StyledLink {...props} to={disabled ? '#' : to} $isDisabled={disabled}>
    <StyledLinkButton {...props}>
      <>{children}</>
      <IconSvg type="chevron" fill="none" />
    </StyledLinkButton>
  </StyledLink>
);

// export const LinkButton: React.FC<ButtonProps & ButtonPropsT & {to?: string}> = ({to, children, ...props}) => {
//   if (to) {
//     return (
//       <StyledLink {...props} to={to}>
//         <StyledLinkButton {...props}>
//           <>
//             {children}
//             <RightOutlined />
//           </>
//         </StyledLinkButton>
//       </StyledLink>
//     );
//   } else {
//     return (
//       <StyledLinkButton {...props}>
//         <>
//           {children}
//           <RightOutlined />
//         </>
//       </StyledLinkButton>
//     );
//   }
// };

export const HomeLink: React.FC<WithChildren> = ({children, ...props}) => {
  const setSelectedTabState = useSetRecoilState(selectedTabState);
  const setSelectedOptState = useSetRecoilState(selectedOptState);
  const handleClick = () => {
    setSelectedTabState(-1);
    setSelectedOptState('');
  };
  return (
    <Link onClick={handleClick} to={'/'} {...props}>
      {children}
    </Link>
  );
};

export const VipOptionButton: React.FC<VipButtonState> = ({add, onClick}) => {
  return (
    <StyledLinkView onClick={onClick}>
      <VipOptButton add={add}>
        {!add ? (
          'ADD'
        ) : (
          <p>
            <img src={Trash} alt="" />
            REMOVE
          </p>
        )}
      </VipOptButton>
    </StyledLinkView>
  );
};

export const LinkButtonView: React.FC<LinkButtonViewT> = ({toLink, children, blank, onClick}) => (
  <StyledLinkView onClick={onClick}>
    <StyledLinkButtonView href={toLink} target={blank ? '_blank' : '_self'}>
      <>
        {children}
        <IconSvg type="chevron" fill="none" />
      </>
    </StyledLinkButtonView>
  </StyledLinkView>
);

export const LinkConfirmButtonView: React.FC<LinkButtonViewT> = ({toLink, children, blank}) => (
  <>
    <ButtonPrimaryLink href={toLink} target={blank ? '_blank' : '_self'}>
      {children}
    </ButtonPrimaryLink>
  </>
);

export const DashboardCardButton: React.FC<DashboardButtonT> = ({
  toLink,
  value,
  blank,
  walletButton,
  walletType,
  onClick,
  Icon,
}) => {
  return (
    <>
      {!walletButton ? (
        // If !walletButton, return DashboardButton
        <DashboardButton href={toLink} target={blank ? '_blank' : '_self'} onClick={onClick}>
          {value}
          {Icon && <MobileView>{Icon}</MobileView>}
        </DashboardButton>
      ) : walletType === 'safari' ? (
        // If walletButton is true and walletType is 'safari', return AppleWalletButton
        <AppleWalletButton href={toLink} target={blank ? '_blank' : '_self'} onClick={onClick}>
          <img src={Wallet} />
          Add to Apple Wallet
        </AppleWalletButton>
      ) : walletType === 'chrome' ? (
        // If walletButton is true and walletType is 'chrome', return GoogleWalletButton
        <GoogleWalletButton href={toLink} target={blank ? '_blank' : '_self'} onClick={onClick}>
          <img src={Wallet} />
          Add to Google Wallet
        </GoogleWalletButton>
      ) : null}
    </>
  );
};
