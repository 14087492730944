import styled, {css} from 'styled-components';
import {BoldText1, Text1, Text3} from '../../ui-kit/Typography';
import {Media} from '../../ui-kit/theme/breakpoints';
import {horizontalGradientBG, linearGoldGradient, linearWhiteGradient} from '../../ui-kit/theme/palette';
import {Button} from '../../ui-kit/Button';
import {mixins} from '../../ui-kit/theme/mixins';

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 143px;
  max-width: 1100px;
  ${Media.down.l} {
    width: 100%;
    padding: 110px 10px 10px 10px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // margin-bottom: ${({theme}) => theme.spacer._5};
  margin-bottom: 16px;
  gap: 8px;
  ${Media.down.l} {
    gap: 0px;
    margin-bottom: 12px;
  }
`;

export const TitleDescr = styled(Text3)`
  color: ${({theme}) => theme.palette.system.white};
  font-weight: 700;
  ${Media.down.l} {
    margin-top: 6px;
    font-size: 18px;
    line-height: 20px;
  }
  margin-bottom: 8px;
`;

export const TabsWrapper = styled.div`
  .ant-tabs-nav {
    &::before {
      display: none;
    }
  }
  .ant-tabs-tab {
    padding: 8px 16px;
    border-radius: 20px !important;
    background-color: ${({theme}) => theme.palette.secondary.seaBlue};
    .ant-tabs-tab-btn {
      text-shadow: none;
      color: ${({theme}) => theme.palette.system.white};
    }
    & + .ant-tabs-tab {
      margin: 0 0 0 10px;
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab-active {
    background-color: ${({theme}) => theme.palette.system.white} !important;
    .ant-tabs-tab-btn {
      color: ${({theme}) => theme.palette.system.darkBlack} !important;
    }
  }
`;

/* CREDITS */
export const CreditBlocks = styled.div`
  ${Media.down.s} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
`;
const BlockStyles = css`
  border-radius: 8px;
  width: 100%;
  max-width: 370px;
  margin-bottom: 14px;
  box-sizing: border-box;
  ${Media.down.s} {
    max-width: unset;
  }
`;

export const AvailableBlock = styled.div`
  ${linearWhiteGradient}
  ${BlockStyles};
  margin-top: 20px;
`;

export const UpperCard = styled.div`
  & > span:first-child {
    width: 70px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 20px;
`;
export const BottomCards = styled.div`
  display: flex;
  border-top: 1px solid ${({theme}) => theme.palette.system.darkBlack};
`;

export const AvBottomCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 50%;
  height: 40px;
  & + div {
    border-left: 1px solid ${({theme}) => theme.palette.system.darkBlack};
  }
  & > div {
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
    font-weight: 400;
    font-size: 12px;
    color: ${({theme}) => theme.palette.system.darkGray};
  }
  & > span {
    font-size: 12px;
    color: ${({theme}) => theme.palette.system.darkGray};
    font-weight: 700;
  }
`;

export const HowItWorksBlock = styled.div`
  ${BlockStyles};
  padding: 10px 12px;
  background-color: ${({theme}) => theme.palette.system.white};
  position: relative;
  height: 140px;
  ${Media.down.m} {
    height: 140px;
  }
  .anticon-question-circle,
  .anticon-close-circle {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

export const HowItItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const HowItItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const HowItIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: ${({theme}) => theme.palette.secondary.seaBlue};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HowItText = styled(Text3)`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  max-width: 83px;
  margin-top: 6px;
  ${Media.down.m} {
    font-size: 12px;
  }
`;

export const CustomArrowWrapper = styled.div``;

export const ReferralLinkBlock = styled.div`
  ${BlockStyles};
  padding: 16px 12px;
  background-color: ${({theme}) => theme.palette.system.white};
`;

export const RefLinkValue = styled(Text1)`
  display: block;
  font-size: 14px;
`;

export const RefButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const CopyButton = styled(Button)`
  color: ${({theme}) => theme.palette.system.darkBlack} !important;
  background-color: ${({theme}) => theme.palette.system.gray3} !important;
  margin-top: 15px;
  width: 100%;
  ${Media.down.s} {
    width: 49%;
  }
`;
export const MobShareButton = styled(Button)`
  color: ${({theme}) => theme.palette.system.darkBlack} !important;
  background-color: ${({theme}) => theme.palette.system.gray3} !important;
  margin-top: 15px;
  display: none;
  ${Media.down.s} {
    width: 49%;
    display: block;
  }
`;

export const BlockTitle = styled(BoldText1)`
  color: ${({theme}) => theme.palette.system.darkBlack};
  line-height: 18px;
  font-size: 14px;
`;
export const CreditsValue = styled(BoldText1)`
  color: ${({theme}) => theme.palette.primary.midnightBlue};
  font-size: 36px;
`;

export const DesktopShareButtonWrapper = styled.div`
  & > div > div {
    justify-content: center;
    align-items: center;
    width: 340px;
  }
  ${Media.down.s} {
    display: none;
  }
`;
export const MobileShareButtonWrapper = styled.div`
  display: none;
  ${Media.down.s} {
    background-color: ${({theme}) => theme.palette.primary.midnightBlue};
    border-top: 2px solid ${({theme}) => theme.palette.system.gray1};
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 14px 20px;
    display: flex;
    justify-content: center;
    z-index: 500;
  }
`;

export const ShareButton = styled(Button)`
  ${horizontalGradientBG};
  color: ${({theme}) => theme.palette.system.darkBlack};
  width: 100%;
  max-width: 370px;
  text-transform: uppercase;
  ${Media.down.s} {
    max-width: 100%;
  }
  &:hover,
  &:active,
  &:focus {
    color: ${({theme}) => theme.palette.system.darkBlack};
    ${horizontalGradientBG};
    filter: brightness(86%);
  }
`;

export const TabTitle = styled(BoldText1)`
  font-size: 18px;
  color: ${({theme}) => theme.palette.system.white};
  margin-top: 20px;
  display: block;
`;

export const TabDescr = styled(Text1)`
  font-size: 16px;
  color: ${({theme}) => theme.palette.system.white};
  width: 360px;
  display: block;
`;

export const PerkCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 0fr);
  grid-gap: 30px;
  margin-top: 30px;
  ${Media.down.l} {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const PerkCardWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  width: 390px;
  position: relative;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  &:hover {
    span {
      color: ${({theme}) => theme.palette.primary.midnightBlue};
      ${linearGoldGradient};
    }
    img {
      transform: scale(1.05);
    }
  }
  ${Media.down.l} {
    width: 100%;
    max-width: 550px;
  }
  ${Media.down.s} {
    max-width: 390px;
  }
`;

export const PerkCardImage = styled.img`
  position: relative;
  z-index: 1;
  max-height: 111px;
  transform: scale(1.01);
  transition: 0.15s all;
  ${Media.down.l} {
    width: 100%;
    height: auto;
    max-height: unset;
  }
  ${Media.down.s} {
    max-height: 111px;
  }
`;

export const PerkCardTitle = styled.span`
  z-index: 2;
  margin-top: -15px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  transition: 0.15s all;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
  color: ${({theme}) => theme.palette.system.white};

  background-color: ${({theme}) => theme.palette.primary.midnightBlue};
`;

export const PerkCardDescription = styled.div`
  padding: 36px 30px 16px 30px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: center;
`;

export const ActivityTable = styled.div`
  margin-top: 10px;
  width: 100%;
  max-width: 700px;
  color: ${({theme}) => theme.palette.system.white};
`;
export const ActivityTableHead = styled.div`
  width: 100%;
  & div {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    ${Media.down.xs} {
      font-size: 18px;
    }
  }
  padding-bottom: 16px;
  border-bottom: 1px solid ${({theme}) => theme.palette.system.gray3};
  margin-bottom: 16px;
`;
export const ActivityTableBody = styled.div`
  width: 100%;
  & div {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    ${mixins.font.primary.light};
    ${Media.down.xs} {
      font-size: 18px;
    }
  }
  & > div {
    margin-bottom: 16px;
  }

  max-height: 600px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 6px;
  }
  padding-right: 4px;
  box-sizing: border-box;
`;

export const ActivityTableRow = styled.div`
  display: flex;
  align-items: center;
`;

export const ActivityDateColumn = styled.div`
  width: 25%;
  ${Media.down.m} {
    width: 90%;
  }
  ${Media.down.xs} {
    width: 70%;
  }
  ${Media.down.xs} {
    & > span {
      font-weight: 700;
    }
  }
`;

export const ActivityDescrColumn = styled.div`
  width: 65%;
  ${Media.down.m} {
    display: none;
  }
`;

export const ActivityValueColumn = styled.div`
  width: 10%;
  text-align: right;
  ${Media.down.xs} {
    width: 30%;
  }
`;

export const ActivityMobileValue = styled.div`
  display: none;
  ${Media.down.m} {
    display: block;
  }
`;

const expandDown = css`
  height: 100%;
  width: 100%;
  opacity: 1;
`;

export const ExpandWrapper = styled.div<{$expanded?: boolean}>`
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  padding: 10px 12px;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.system.white};
  transition: 0.15s all;
  ${({$expanded}) => $expanded && expandDown};
  p {
    font-size: 13px;
    margin-top: 8px;
    ${Media.down.m} {
      font-size: 12px;
    }
  }
  a {
    font-size: 12px;
    color: ${({theme}) => theme.palette.system.darkBlack};
    span {
      text-decoration: underline;
    }
  }
`;
