import styled from 'styled-components';
import {Button} from 'antd';
import {mixins} from '../../ui-kit/theme/mixins';
import {Media} from '../../ui-kit/theme/breakpoints';
import {HeadingMediumTitle, Text2, Text3, Heading6} from '../../ui-kit/Typography';

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 40px;
`;

export const ContentWrapper = styled.div`
  padding-top: ${({theme}) => theme.spacer._16};
  ${Media.down.m} {
    padding-top: 104px;
  }
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // margin-bottom: ${({theme}) => theme.spacer._5};
  margin-bottom: 16px;
  gap: 8px;
  ${Media.down.l} {
    gap: 0px;
    margin-bottom: 0;
    margin-top: 14px;
  }
`;

export const Title = styled(HeadingMediumTitle)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  text-transform: capitalize;
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.04em;
`;

export const Description = styled(Text3)`
  color: ${({theme}) => theme.palette.system.white};
`;

export const CancelCard = styled.div`
  max-width: 547px;
  padding: ${({theme}) => theme.spacer._9};
  background: ${({theme}) => theme.palette.secondary.lightGold};
  border-radius: 8px;
  ${Media.down.m} {
    padding: 24px;
  }
`;

export const CancelCardTitle = styled(Heading6)`
  margin-bottom: ${({theme}) => theme.spacer._1};
`;

export const CancelCardWrapperSelect = styled.div`
  margin-bottom: ${({theme}) => theme.spacer._1};
  .ant-select-selector {
    border: 1px solid #495258 !important;
    .ant-select-selection-placeholder {
      color: #495258 !important;
    }
    .ant-select-arrow {
      font-size: 10px;
    }
  }
`;

export const CancelCardDescription = styled(Text2)`
  display: block;
  margin-bottom: ${({theme}) => theme.spacer._2};
`;
export const CancelCardDescriptionMod = styled(Text2)`
  text-transform: capitalize;
  font-weight: 700;
  margin-left: ${({theme}) => theme.spacer._0};
`;

export const ButtonToChangeDateStyled = styled(Button)`
  text-transform: capitalize;
  font-weight: 700;
  margin-left: ${({theme}) => theme.spacer._0};
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    background: inherit;
    color: inherit;
    border: none;
  }
`;

export const CancelCardButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 24px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  background: ${({theme}) => theme.palette.primary.alarmColor};
  text-shadow: 0px 1px 0px ${({theme}) => theme.palette.secondary.lightGold};
  ${mixins.font.primary.bold};
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: ${({theme}) => theme.palette.system.white};
  &:disabled {
    background: ${({theme}) => theme.palette.primary.alarmColor};
    color: ${({theme}) => theme.palette.system.white};
    opacity: 0.5;
  }
  &:hover,
  &:active,
  &:focus {
    background: ${({theme}) => theme.palette.secondary.roseRed};
    color: ${({theme}) => theme.palette.system.white};
    opacity: 1;
  }
`;

export const ChangeDateButton = styled(CancelCardButton)`
  background-color: transparent;
  border: 1px solid ${({theme}) => theme.palette.primary.midnightBlue};
  color: ${({theme}) => theme.palette.primary.midnightBlue};

  &:hover,
  &:active,
  &:focus {
    background-color: ${({theme}) => theme.palette.system.darkBlack};
    color: ${({theme}) => theme.palette.system.white};
    opacity: 1;
    border: 1px solid ${({theme}) => theme.palette.primary.midnightBlue};
  }
`;
