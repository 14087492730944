import {EventSessionT, EventsT, EventTemplateT, TicketGroupT, TicketTypeT} from '../types/events';
import {PurchaseTicketItemT} from '../queries/types/purchaseAddOns';
import {addonsIdsWithDates, addonsInfoValuesT, purchaseAddonsValuesT, ticketsAvSessionsT} from '../hooks/purchase';
import {calcAddonsStateResT, ConfirmPurchaseOrderT, purchaseOrderInfo, PurchaseResT} from '../types/purchase';
import {OrderT} from '../types/orders';
import {User} from '../types/auth';
import {getPaymentFormInfo} from './upgradeToVip';
import {cutTicketName, GAtimeT, getTZdate, isGA} from './orders';
import {addon_available_dates, child_key, formats, infant_key_l} from './helpers';
import {timeCardVariant} from '../components/PurchaseOne/types';
import {confirmPaymentInfo} from '../states/order';
import {useRecoilValue} from 'recoil';
import imagesJSON from '../assets/tickets_images.json';
import special_nights from '../assets/special_nights.json';

export const toCreatePurchaseTicket = (
  addonsTime: addonsIdsWithDates,
  addonsState: purchaseAddonsValuesT,
  ticketTypeId?: string,
): PurchaseTicketItemT => {
  let eventTemplateId = '';
  Object.keys(addonsState).forEach((evId) => {
    if (Object.keys(addonsState[evId]).some((t) => t === ticketTypeId)) eventTemplateId = evId;
  });
  const event_session_id = addonsTime?.find((el) => el.eventTempId === eventTemplateId)?.sessionId;
  if (!event_session_id) return {};
  return {
    ticket_type_id: ticketTypeId,
    event_session_id: event_session_id,
  };
};

export const useGetAddons = (events?: EventsT) => {
  return events?.event_template?._data?.filter(
    (el) => el?.category?.toLowerCase() === 'addon' || el?.category?.toLowerCase() === 'add on',
  );
};

export const useGetAddonsGroup = (events?: EventsT) => {
  const addonsNames = events?.event_template?._data
    ?.filter((el) => el?.category?.toLowerCase() === 'addon' || el?.category?.toLowerCase() === 'add on')
    .map((el) => el?.name);
  return events?.ticket_group?._data.filter((el) =>
    addonsNames?.some((adName) => adName?.toLowerCase()?.includes(el?.name?.toLowerCase() || '')),
  );
};

export const useGetTicketTypesFromGroups = (events?: EventsT, groups?: TicketGroupT[]): TicketTypeT[] => {
  const groupIds = groups?.map((el) => el?.id);
  return events?.ticket_type?._data.filter((el) => groupIds?.includes(el?.ticket_group_id)) || [];
};

export const getAddonsGroupConnection = (addonsGroups?: TicketGroupT[], addons?: EventTemplateT[]) => {
  return addons
    ?.filter(
      (el) =>
        addonsGroups?.find((group) => !!group?.name && el?.name?.toLowerCase().includes(group?.name?.toLowerCase()))
          ?.id,
    )
    ?.map((el) => ({
      groupId: addonsGroups?.find(
        (group) => !!group?.name && el?.name?.toLowerCase().includes(group?.name?.toLowerCase()),
      )?.id,
      addonId: el?.id,
      addonName: el?.name,
    }));
};

export const calcAddonsState = (addonsState: purchaseAddonsValuesT, tickets?: TicketTypeT[]): calcAddonsStateResT => {
  const ticketCounts = Object.values(addonsState);
  const prices: {ticket?: string; price?: number}[] = [];
  ticketCounts.forEach((el) => {
    Object.keys(el).forEach((ticketKey) =>
      prices.push({
        ticket: ticketKey,
        price: Number(tickets?.find((t) => t.id === ticketKey)?.currency_amount) * el[ticketKey],
      }),
    );
  });
  const total = prices.reduce((prev, el) => prev + Number(el?.price || 0), 0);
  return {prices, total};
};

export type FutureOrderTicketT = TicketTypeT & {count: number; calcPrice: number; groupName?: string};

export const formatFutureOrderInfo = (
  purchaseState: purchaseAddonsValuesT,
  addonsInfo: addonsInfoValuesT,
  addonsGroups?: TicketGroupT[],
) => {
  const groupsIds = Object.keys(addonsInfo);
  const items: FutureOrderTicketT[] = [];
  let parkingItems = 0;
  groupsIds?.forEach((groupId) => {
    const infoItem = addonsInfo?.[groupId];
    const purchaseItem = purchaseState?.[groupId];

    infoItem?.forEach((info) => {
      const count = purchaseItem[info?.id] || 0;
      if (!count) return;
      const groupName = addonsGroups?.find((group) => String(group?.id) === String(info?.ticket_group_id))?.name;
      if (groupName?.toLowerCase().includes('parking')) parkingItems += count;
      items.push({...info, count, groupName, calcPrice: count * Number(info?.currency_amount || 0) || 0});
    });
  });

  return {items, parkingItems};
};

export const createPurchaseOrderInfo = (
  order?: OrderT,
  events?: EventsT,
  purchaseRes?: PurchaseResT,
  viewer?: User | null,
  // addonsSessions?: any,
): purchaseOrderInfo => {
  const {total, subTotal, tax, totalPaid, balance, facilityFee, items, discount} = getPaymentFormInfo(
    order,
    purchaseRes,
    events,
  );
  const eventArrival = order?.tickets?.find((ticket) =>
    ticket?.ticketGroupName.toLocaleLowerCase()?.includes('admission'),
  );
  const iceSkatingArrival = order?.tickets?.find((ticket) =>
    ticket?.ticketGroupName.toLocaleLowerCase()?.includes('skating'),
  );
  const parkingArrival = order?.tickets?.find((ticket) =>
    ticket?.ticketGroupName.toLocaleLowerCase()?.includes('parking'),
  );
  // const skatingSessionName = getAddonSession;
  return {
    total: total,
    subTotal: subTotal,
    facilityFee: facilityFee,
    tax: tax,
    balance: balance,
    totalPaid: totalPaid,
    isProtection: order?.visitProtection,
    location: `${order?.sellerName} - ${order?.sellerVenue}`,
    arrival: order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '',
    eventArrival:
      getTZdate(eventArrival?.eventStartDate, order).format(formats.timeEnd) +
      ' - ' +
      getTZdate(eventArrival?.eventEndDate, order).format(formats.timeEnd),
    iceSkatingArrival:
      getTZdate(iceSkatingArrival?.eventStartDate, order).format(formats.timeEnd) +
      ' - ' +
      getTZdate(iceSkatingArrival?.eventEndDate, order).format(formats.timeEnd),
    parkingArrival:
      getTZdate(parkingArrival?.eventStartDate, order).format(formats.timeEnd) +
      ' - ' +
      getTZdate(parkingArrival?.eventEndDate, order).format(formats.timeEnd),
    items,
    orderedBy: `${viewer?.firstName} ${viewer?.lastName}`,
    orderNumber: order?.ticketureOrderNumber,
    discountName: purchaseRes?.cartmod?._data?.[0]?.name || '',
    discount: discount,
  };
};

export const getPurchaseTicketsIds = (state: purchaseAddonsValuesT) => {
  const ticketsCountMap = Object.values(state);
  const ids: string[] = [];
  ticketsCountMap.forEach((tickets) => {
    Object.keys(tickets).forEach((id) => {
      for (let i = 0; i < tickets?.[id]; i++) {
        ids.push(id);
      }
    });
  });
  return ids;
};

export const toConfirmPurchaseOrder = (viewer?: User | null, order?: OrderT): ConfirmPurchaseOrderT => {
  const paymentInfo = useRecoilValue(confirmPaymentInfo);

  // !paymentInfo, redirect to EnchantChristmas
  if (!paymentInfo) {
    // const link = 'https://enchantchristmas.com';
    // window.location.href = link;
  }
  const paymentMethods = paymentInfo?.payments;
  const paymentMethod = paymentMethods[paymentMethods?.length - 1]?.identifier;
  interface DiscountItem {
    name: string;
  }
  const discounts = (paymentInfo?.discounts as DiscountItem[]) || [];
  const discountName = discounts?.length > 0 ? discounts.map((item) => item?.name).join(', ') : '';
  const items: ConfirmPurchaseOrderT = [];
  const addons = order?.tickets?.filter(
    (el) => el?.eventTemplateName !== 'Parking' && el?.eventTemplateCategory === 'Addon',
  );
  addons?.forEach((el, i) => {
    const label = `${el?.eventTemplateName}, Check In Time`;
    const id = el?.id || 100 + i;
    const value = el?.eventStartDate ? getTZdate(el?.eventStartDate, order).format(formats.timeEnd) : '';
    if (!items?.some((item) => item?.label === label)) items?.push({label, id, value});
  });

  const eventDate = order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '';
  const arrival = order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.timeEnd) : '';

  const values = [
    {label: 'Order Number', id: 0, value: order?.ticketureOrderNumber || ''},
    {label: 'Location', id: 1, value: order?.sellerName || ''},
    {label: 'Event Date', id: 2, value: eventDate},
    {label: 'Event Arrival Window', id: 3, value: arrival},
    ...items,
  ];
  values.push({label: 'Ordered by:', id: 9999, value: `${viewer?.firstName} ${viewer?.lastName}`});
  values.push({label: 'Discounts applied:', id: 6, value: `${discountName}`});
  paymentMethod && values.push({label: 'Paid by:', id: 7, value: `${paymentMethod}`});
  return values;
};

export const getGAOnly = (order?: OrderT) => {
  return order?.tickets?.filter((el) => isGA(el));
};

export type AvailableAddonT = {
  groupName?: string;
  available: {
    Adult?: number;
    Child?: number;
    Minor?: number;
    Junior?: number;
    Parking?: number;
    'Secret Santa Visit'?: number;
    'Paws n Claus'?: number;
    'Snow Tubing'?: number;
  };
};
export const useGetAvailableAddons = (order?: OrderT): AvailableAddonT[] => {
  const MAX_PNC_COUNT = 2;

  const addons = order?.tickets?.filter(
    (el) => el?.eventTemplateCategory === 'Addon' || el?.eventTemplateCategory === 'Add On',
  );

  const ga = getGAOnly(order);
  const childGa = ga?.filter(
    (el) => el?.ticketType?.toLowerCase()?.includes('junior') || el?.ticketType?.toLowerCase()?.includes(infant_key_l),
  );
  const adultGa = ga?.filter(
    (el) =>
      el?.ticketType?.toLowerCase()?.includes('adult') ||
      el?.ticketType?.toLowerCase()?.includes('military') ||
      el?.ticketType?.toLowerCase()?.includes('senior') ||
      el?.ticketType?.toLowerCase()?.includes('owl'),
  );
  const hasOwl = ga?.some((el) => el?.ticketType?.toLowerCase()?.includes('owl'));
  const adult_skatingAddon =
    addons?.filter((el) => el?.ticketType === 'adult_skating' && el?.ticketTypeName === 'Adult')?.length || 0;
  const adult_dinnerAddon =
    addons?.filter((el) => el?.ticketType?.includes('dinner adult') && el?.ticketTypeName === 'Adult')?.length || 0;
  const child_skatingAddon =
    addons?.filter((el) => el?.ticketType?.includes('skating') && el?.ticketTypeName === child_key)?.length || 0;
  const child_dinnerAddon =
    addons?.filter((el) => el?.ticketType?.includes('dinner') && el?.ticketTypeName === child_key)?.length || 0;
  const minor_skatingAddon =
    addons?.filter((el) => el?.ticketType === 'adult_skating' && el?.ticketTypeName === 'Minor')?.length || 0;
  const minor_dinnerAddon =
    addons?.filter((el) => el?.ticketType?.includes('dinner adult') && el?.ticketTypeName === 'Minor')?.length || 0;
  const snowTubbingAddon =
    addons?.filter((el) => el?.ticketTypeName?.toLowerCase().includes('snow tubing'))?.length || 0;

  const ParkingAvailable = addons?.filter((el) => el?.ticketTypeName?.toLowerCase().includes('parking'))?.length;
  const adultSkatingAvailable = Number(adultGa?.length || 0) - adult_skatingAddon;
  const childSkatingAvailable = Number(childGa?.length || 0) - child_skatingAddon;
  const adultDinnerAvailable = Number(adultGa?.length || 0) - adult_dinnerAddon;
  const childDinnerAvailable = Number(childGa?.length || 0) - child_dinnerAddon;
  const minorDinnerAvailable = Number(childGa?.length || 0) - Number(minor_skatingAddon + minor_dinnerAddon);
  const minorSkatingAvailable = Number(childGa?.length || 0) - Number(minor_skatingAddon + child_skatingAddon);
  const santaPackAvailable = addons?.filter((el) => el?.ticketTypeName?.toLowerCase().includes('santa'))?.length;
  const pncAvailable =
    MAX_PNC_COUNT - (addons?.filter((el) => el?.ticketTypeName?.toLowerCase().includes('paws'))?.length || 0);
  const snowTubbingAvailable = Number(adultGa?.length || 0) - snowTubbingAddon;

  return [
    {
      groupName: 'Ice Skating',
      available: {
        Adult: adultSkatingAvailable,
        Minor: minorSkatingAvailable,
        Child: childSkatingAvailable,
        Junior: childSkatingAvailable,
      },
    },
    {
      groupName: 'Parking',
      available: {
        Parking: ParkingAvailable,
      },
    },
    {
      groupName: 'Secret Santa Visit',
      available: {
        'Secret Santa Visit': santaPackAvailable,
      },
    },
    {
      groupName: 'Dinner',
      available: {
        Adult: adultDinnerAvailable,
        Minor: minorDinnerAvailable,
        Child: childDinnerAvailable,
        Junior: childDinnerAvailable,
      },
    },
    {
      groupName: 'Paws n Claus',
      available: {
        'Paws n Claus': pncAvailable,
      },
    },
    {
      groupName: 'Snow Tubing',
      available: {
        'Snow Tubing': hasOwl ? 0 : snowTubbingAvailable,
      },
    },
  ];
};

type keys = 'Adult' | 'Junior' | 'Child' | 'Minor' | 'Parking' | 'Secret Santa Visit' | 'Paws n Claus';
export const getAvailableCount = (addon?: AvailableAddonT, ticket?: TicketTypeT): number => {
  if (!ticket?.name || !addon) return 0;
  const availableCount = addon.available?.[cutTicketName(ticket.name) as keys];

  if (cutTicketName(ticket.name) === 'Parking') {
    const av = 5 - Number(availableCount);
    if (av > 0) return av;
    return 0;
  }
  if (cutTicketName(ticket.name)?.toLocaleLowerCase().includes('santa')) {
    const av = 1 - Number(availableCount);
    if (av > 0) return av;
    return 0;
  }
  if (Number(availableCount || 0) <= 0) return 0;
  return availableCount || 0;
};

export const getAvailableToAdd = (addon?: AvailableAddonT, ticket?: TicketTypeT, parkingItems?: number): boolean => {
  if (!ticket?.name || !addon) return true;
  const availableCount = addon.available?.[cutTicketName(ticket.name) as keys];
  if (cutTicketName(ticket.name) === 'Parking') {
    const av = 5 - Number(availableCount) - Number(parkingItems);
    return av <= 0;
  }
  return false;
};

export const getDescriptionByName = (name?: string) => {
  if (name?.toLowerCase()?.includes('skating')) return 'skating';
  if (name?.toLowerCase()?.includes('dinner')) return 'dinner';
  if (name?.toLowerCase()?.includes('parking')) return 'parking';
  if (name?.toLowerCase()?.includes('santa')) return 'santaPackage';
  return 'none';
};

export const getPossibleTimes = (
  gaTime?: GAtimeT,
  sessions?: EventSessionT[],
  isParking?: boolean,
): EventSessionT[] => {
  if (!gaTime?.start || !gaTime?.end) return [];
  const start = new Date(gaTime.start)?.getTime();
  const end = new Date(gaTime.end)?.getTime();
  // const start_cut = gaTime.start.split('T')?.[0];
  return (
    sessions?.filter((el) => {
      if (el?.start_datetime && el?.end_datetime) {
        const start_1 = new Date(el.start_datetime).getTime();

        if (!isParking) return start_1 >= start;
        // const start_cut_1 = el.start_datetime.split('T')?.[0];
        return end > start_1;
      }
      return false;
    }) || []
  );
};

export const getTimeCardTitle = (
  name?: string,
  isParking?: boolean,
  start?: string,
  end?: string,
  sellerTimezone?: string,
) => {
  const sDate = start && getTZdate(start, {sellerTimezone}).format(formats.timeStart);
  const eDate = end && getTZdate(end, {sellerTimezone}).format(formats.timeEnd);
  if (!sDate || !eDate) return '';
  if (isParking) return start && getTZdate(start, {sellerTimezone}).format(formats.time);
  if (name?.toLocaleLowerCase().includes('santa')) {
    return `${sDate} PM`;
  }
  return `${sDate} - ${eDate}`;
};

export const checkAllDatesAdded = (addonsState: purchaseAddonsValuesT, dates: addonsIdsWithDates) => {
  let pass = false;
  const eventsToCheck = Object.keys(addonsState).filter((evId) =>
    Object.values(addonsState[evId])?.reduce((p, n) => p + n, 0),
  );

  if (eventsToCheck?.length) pass = true;
  eventsToCheck?.forEach((id) => {
    if (!dates?.some((el) => el?.eventTempId === id)) pass = false;
  });
  return pass;
};

type getTimeCardVariantPropsT = {
  sold_out?: boolean;
  canPurchase?: boolean;
  isPaw?: boolean;
  isOwl?: boolean;
};

export const getTimeCardVariant = ({
  sold_out,
  canPurchase,
  isPaw,
  isOwl,
}: getTimeCardVariantPropsT): timeCardVariant => {
  if (sold_out) return 'soldout';
  if (!canPurchase) return 'unavailable';
  if (isPaw) return 'paw';
  if (isOwl) return 'owl';
  return 'default';
};

export const checkIsAddonSoldOut = (ticketsAvSessions?: ticketsAvSessionsT, ticketId?: string) => {
  if (!ticketId || !ticketsAvSessions) return false;
  const noSoldOut = ticketsAvSessions?.[ticketId]?.filter((el) => !el?.sold_out)?.length;

  return !noSoldOut;
};

export const checkIsAllAvailableTimes = (times?: EventSessionT[], hiddenSessions?: string[], timeZone?: string) => {
  let available = Number(times?.length || 0);
  const onDate = getTZdate(times?.[0]?.start_datetime, {sellerTimezone: timeZone}).format(formats.onDate);
  times?.forEach((time) => {
    const startTime = getTZdate(time?.start_datetime, {sellerTimezone: timeZone}).format(formats.timeStart);
    hiddenSessions?.forEach((session) => {
      const session_ = session?.replaceAll('pm', '');
      if (session_.includes(onDate) && session_.includes(startTime)) {
        available = available - 1;
      }
    });
  });
  return available > 0;
};

export const filterAddOns = (addons?: EventTemplateT[], order?: OrderT) => {
  const date = getTZdate(order?.eventDate, order).format(formats.date5);
  const sellerName = order?.sellerName;

  return addons?.filter((addon) => {
    // PNC
    if (addon?.name?.toLowerCase()?.includes('paws')) {
      return addon_available_dates.pnc.dates.includes(date) && sellerName?.includes(addon_available_dates.pnc.location);
    }

    // LADIES NIGHT
    if (addon.name?.toLowerCase()?.includes('ladies night')) {
      return addon_available_dates.ladies_night.dates.includes(date);
    }

    // Night of Giving
    if (addon?.name?.toLowerCase()?.includes('night of giving')) {
      return (
        addon_available_dates.night_of_giving.dates.includes(date) &&
        sellerName?.includes(addon_available_dates.night_of_giving.location)
      );
    }

    return true;
  });
};

export const getAddonImage = (name?: string) => {
  if (!name) return '';
  const key = Object.keys(imagesJSON).find((el) => el.toLowerCase() === name.toLowerCase()) as keyof typeof imagesJSON;

  // set default image for parking
  if (name?.toLocaleLowerCase().includes('parking') && !key) return imagesJSON['Parking'];

  return key ? imagesJSON[key] : '';
};

export const getMetaForSpecialEvents = (name?: string) => {
  if (name?.toLowerCase()?.includes('paws n')) {
    const pnc = special_nights.find((el) => el.ticketID === 'Pup Ticket');
    return {
      description: pnc?.description,
      note: pnc?.note,
      note_secondary: pnc?.note_secondary,
    };
  }
  return null;
};
