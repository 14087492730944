import React from 'react';

import AddPurchaseOrderDetail, {AddPurchaseAddonDate} from './AddPurchaseOrderDetail';
import AddPurchaisePaymentDetail from './AddPurchasePaymentDetail';
import {
  StyledPageWrapper,
  StyledContentWrapper,
  StyledTitleWrapper,
  StyledTitle,
  StyledDescription,
  // StyledWrapperTimeRemaining,
  // StyledWrapperHelp,
  // StyledTextTimeRemaining,
  // StyledParamIcon,
} from './styles';
import {PurchasePageProps} from './types';
import {DesktopView, MobileView} from '../../ui-kit/AppLayout/View';
import {OrderCardWithLogic} from '../../ui-kit/Order/OrderCardWithLogic';
import {OrderInfoComponent} from '../OrdersInfo';
import {CenteredLoader} from '../../ui-kit/Loader';
import {getGAtime} from '../../helpers/orders';

export const PurchasePage: React.FC<PurchasePageProps> = ({
  setIsConfirm,
  orderDetailsInfo,
  orderInfo,
  footerNav,
  addons,
  orderItems,
  paymentInfo,
  addonsState,
  addonsInfo,
  changeState,
  orderLoading,
  availableAddons,
  steps,
  eventsLoading,
  orderCreatedAt,
  purchaseError,
  parkingItems,
  addAddonSession,
  avLoading,
  ticketsAvSessions,
  hiddenSesions,
}) => {
  const paymentSkipping = Number(orderDetailsInfo?.balance) <= 0;
  return (
    <StyledPageWrapper>
      {steps?.step === '1' && (
        <>
          <StyledContentWrapper>
            <StyledTitleWrapper>
              <StyledTitle style={{whiteSpace: 'pre-line'}}>Select New Add Ons for Your Order Below.</StyledTitle>
            </StyledTitleWrapper>
            <DesktopView>
              <OrderInfoComponent orderInfo={orderInfo} orderLoading={orderLoading} />
            </DesktopView>
          </StyledContentWrapper>
          <OrderCardWithLogic
            data={orderItems}
            paymentInfo={paymentInfo}
            show={true}
            loading={orderLoading}
            icon="plus"
          />
          {orderLoading || avLoading ? (
            <CenteredLoader variant={'light'} mt={'10px'} />
          ) : (
            <AddPurchaseOrderDetail
              addons={addons}
              addonsInfo={addonsInfo}
              addonsState={addonsState}
              changeState={changeState}
              availableAddons={availableAddons}
              eventsLoading={eventsLoading}
              purchaseError={purchaseError}
              parkingItems={parkingItems}
              ticketsAvSessions={ticketsAvSessions}
              gaTime={getGAtime(orderInfo)}
              hiddenSesions={hiddenSesions}
            />
          )}
        </>
      )}
      {steps?.step === '2' && (
        <>
          <StyledContentWrapper>
            <StyledTitleWrapper>
              <StyledTitle>Select New Add Ons for Your Order Below.</StyledTitle>
              <DesktopView></DesktopView>
            </StyledTitleWrapper>
            <DesktopView>
              <OrderInfoComponent orderInfo={orderInfo} orderLoading={orderLoading} />
            </DesktopView>
          </StyledContentWrapper>
          <OrderCardWithLogic
            data={orderItems}
            paymentInfo={paymentInfo}
            orderInfo={orderInfo}
            show={false}
            icon="plus"
          />
          <AddPurchaseAddonDate
            addons={addons}
            addonsInfo={addonsInfo}
            addonsState={addonsState}
            changeState={changeState}
            availableAddons={availableAddons}
            eventsLoading={eventsLoading}
            purchaseError={purchaseError}
            parkingItems={parkingItems}
            gaTime={getGAtime(orderInfo)}
            addAddonSession={addAddonSession}
            hiddenSesions={hiddenSesions}
          />
        </>
      )}
      {steps?.step === '3' && (
        <>
          <StyledContentWrapper>
            <StyledTitleWrapper>
              <StyledTitle>Review your updated order</StyledTitle>
              <DesktopView>
                <StyledDescription>
                  Please review your order to ensure all the details are correct. Visit protection will be added if you
                  originally added it to your order.
                </StyledDescription>
              </DesktopView>
              <MobileView>
                <StyledDescription>
                  Please review your order to ensure all the details are correct. Visit protection will be added if you
                  originally added it to your order.
                </StyledDescription>
              </MobileView>
            </StyledTitleWrapper>
          </StyledContentWrapper>
          {/* <OrderCardWithLogic data={orderItems} paymentInfo={paymentInfo} orderInfo={orderInfo} show={false} /> */}
          {footerNav?.stripeAccountId !== undefined && (
            <AddPurchaisePaymentDetail
              orderCreatedAt={orderCreatedAt}
              setIsConfirm={setIsConfirm}
              orderDetailsInfo={orderDetailsInfo}
              stripeAccount={footerNav?.stripeAccountId}
              paymentSkipping={paymentSkipping}
            />
          )}
        </>
      )}
    </StyledPageWrapper>
  );
};
