import styled, {css} from 'styled-components';
import {linearLeftGradient} from '../theme/palette';
import {primaryTabTextStyles, secondaryTabTextStyles} from '../Typography/styles';
import {TabsButtonPropsT} from './types';
import {Media} from '../theme/breakpoints';

const tabsButtonPrimaryStyles = css`
  ${primaryTabTextStyles};
  ${linearLeftGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 2px solid gold;
  padding-bottom: 10px;
  font-weight: 700;
  &:focus,
  &:active,
  &:hover {
    ${linearLeftGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &:focus,
  &:hover {
    border-color: gold;
  }
`;

const tabsButtonSecondaryStyles = css`
  ${secondaryTabTextStyles};
  font-weight: 700;
  color: ${({theme}) => theme.palette.system.white};
  &:focus,
  &:active,
  &:hover {
    color: ${({theme}) => theme.palette.system.white};
  }
`;

export const StyledTabsButton = styled.button<TabsButtonPropsT>`
  border: none;
  ${({variant}) => {
    switch (variant) {
      case 'primary':
        return tabsButtonPrimaryStyles;
      case 'secondary':
        return tabsButtonSecondaryStyles;
      default:
        return tabsButtonPrimaryStyles;
    }
  }};
  ${Media.down.m} {
    flex: 1;
  }

  background-color: transparent;
  padding: 1px 20px 6px 20px;
  // margin-right: 40px;
  min-width: 127px;
  border-radius: 0px;
  ${Media.down.s} {
    padding: 1px 0 6px 0;
    min-width: 90px;
  }
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  // border-bottom: 1px solid ${({theme}) => theme.palette.system.lightGray};
  ${Media.down.m} {
    align-items: stretch;
  }
`;

export const TabItem = styled.div``;

export const ButtonTab = styled.div<{$isActive?: boolean}>`
  width: auto;
  display: flex;
  padding: 6px 14px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 40px;
  cursor: pointer;
  text-align: center;
  font-family: Proxima Nova;
  font-size: 14px;
  font-style: normal;
  font-weight: ${({$isActive}) => ($isActive ? '700' : '400')};
  line-height: 20px;
  ${({theme}) => {
    switch (theme) {
      case 'light':
        return `
          background: #f3efea;
          color: #020e16;
          margin-right: 8px;
        `;
      default:
        return `
          background: #1f4762;
          color: #ffffff;
          margin-right: 8px;
        `;
    }
  }};
`;
