import React from 'react';
import {Progress} from 'antd';
import {ProgressBarWrapper} from './styles';
import {IProgressBarProps} from './types';

export const ProgressBar: React.FC<IProgressBarProps> = ({percent}) => {
  return (
    <ProgressBarWrapper>
      <Progress percent={percent} size="small" showInfo={false} />
    </ProgressBarWrapper>
  );
};
