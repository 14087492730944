import styled, {css, StyledProps} from 'styled-components';
import {color, StyledSvgIconProps} from './types';

const getColor = ({theme, color, defaultColor}: {theme: any; color?: color; defaultColor: string}) => {
  switch (color) {
    case 'gray':
      return theme.palette.system.gray1;
    case 'white':
      return theme.palette.system.white;
    case 'black':
      return theme.palette.system.black;
    case 'none':
      return 'none';
    default:
      return defaultColor;
  }
};

const stroke = ({theme, stroke}: StyledProps<StyledSvgIconProps>) => {
  return getColor({theme, color: stroke, defaultColor: 'transparent'});
};

export const fill = ({theme, fill}: StyledProps<StyledSvgIconProps>) => {
  return getColor({theme, color: fill, defaultColor: theme.palette.system.gray1});
};

export const StyledSvg = styled.svg`
  & > * {
    stroke: ${stroke};
  }
  & > path {
    ${({strokeWidth}) =>
      strokeWidth &&
      css`
        stroke-width: ${strokeWidth};
      `};
  }
  fill: ${fill};
`;

export const StyledPath = styled.path`
  fill: ${fill};
  stroke: ${stroke};
`;
