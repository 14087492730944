import React from 'react';

import {
  FooterLeftMobileComponent,
  FooterNavigation,
  FooterScrollNavigation,
} from '../../../ui-kit/AppLayout/FooterNavigation';
import {DesktopView, MobileView} from '../../../ui-kit/AppLayout/View';
import {useChangeDateNavigation, useScrollPosition} from '../../../hooks/navigation';
import {formatCurrencyPrice} from '../../../helpers/helpers';
import {useRecoilState} from 'recoil';
import {changeDateState} from '../../../states/order';
import {deductionAmount} from '../../../states/purchase';
import {getPaymentSkipping} from '../../../states/general';

export const ChangeDateFooter = () => {
  const [netTotal] = useRecoilState(deductionAmount);
  const [changeDate] = useRecoilState(changeDateState);
  const [paymentSkipping] = useRecoilState(getPaymentSkipping);
  const navigation = useChangeDateNavigation();
  const showLabel = !!navigation?.additionalPayment;
  const paymentCount = netTotal
    ? netTotal
    : showLabel
    ? `+${formatCurrencyPrice('USD').format(navigation?.additionalPayment || 0)}`
    : undefined;
  const paymentLabelDesktop = changeDate?.cartId ? 'Balance Due:' : 'Additional Payment:';
  const paymentLabel = changeDate?.cartId ? 'Balance Due:' : 'Subtotal:';
  const {isBottom} = useScrollPosition();
  const checkoutDisabled = navigation?.checkout ? !navigation.checkout.cardFilled : false;
  const buttonLabel = changeDate?.cartId ? 'Confirm Purchase' : 'Next';

  return (
    <>
      {changeDate?.step === 1 ? (
        <>
          <DesktopView>{!isBottom && <FooterScrollNavigation title={'Scroll to view more'} />}</DesktopView>
          <MobileView>
            <FooterScrollNavigation title={'Scroll to view more'} hideOnBottom />
          </MobileView>
        </>
      ) : (
        <>
          <DesktopView>
            <FooterNavigation
              buttonType={navigation?.buttonType}
              buttonTitle={navigation?.buttonTitle}
              onClick={navigation?.onNextClick}
              conditionButton={checkoutDisabled || navigation?.conditionButton}
              component={
                showLabel && !paymentSkipping ? (
                  <FooterLeftMobileComponent title={paymentLabelDesktop} value={paymentCount} />
                ) : null
              }
            />
          </DesktopView>
          <MobileView>
            <FooterNavigation
              buttonType={'primary'}
              buttonTitle={buttonLabel}
              conditionButton={checkoutDisabled || navigation?.conditionMobileButton}
              component={
                showLabel && !paymentSkipping ? (
                  <FooterLeftMobileComponent title={paymentLabel} value={paymentCount} />
                ) : null
              }
              onClick={navigation?.onNextMobileClick}
            />
          </MobileView>
        </>
      )}
    </>
  );
};
