import styled from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {BoldText1, HeadingMedium2, Text1} from '../../ui-kit/Typography';

export const OrderTitleWrapper = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  ${Media.down.m} {
    display: flex;
    position: absolute;
    left: 50;
    top: 0;
  }
`;

export const LocationTitle = styled(HeadingMedium2)`
  color: ${({theme}) => theme.palette.system.white};
  ${Media.down.m} {
    display: none;
  }
`;

export const OrderTitleDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OrderTitleDetailsMain = styled(Text1)`
  color: ${({theme}) => theme.palette.system.white};
`;

export const OrderTitleDetails = styled(Text1)`
  color: ${({theme}) => theme.palette.system.white};
  ${Media.down.m} {
    display: none;
  }
`;

export const BoldTitleDetails = styled(BoldText1)`
  color: ${({theme}) => theme.palette.system.white};
  margin-left: ${({theme}) => theme.spacer._0};
`;
