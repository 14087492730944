import React, {useState, useEffect, useRef} from 'react';
import {toUpper} from '../../helpers/helpers';
import {MonthTag} from './styles';
import moment from 'moment-timezone';
import {
  LeftSideWrapper,
  ContentTitle,
  ContentDescrWrapper,
  SwitchWrapper,
  RightSideWrapper,
  TimeElementsWrapper,
  ContentTitleSmall,
  // ContentDescrSmallWrapper,
  ContentDescrSmall,
  UpperTimeCardsContainer,
  TimeCardsWrapper,
  TimeCardsSwitchWrapper,
  ButtonWrapper,
  // TransparentButton,
  PopTimeSlot,
  BannerSubtitmeWrapper,
  ContentTitleSmallSecondary,
  TimeCardsContainer,
  DateCardWrapper,
  TimeCardsMobileWrapper,
  // MonthTag,
} from './styles';
import {SelectDateProps} from './types';
import TicketCardsSwitch from '../../ui-kit/Cards/CardFromTicket/TicketCardsSwitch';
import TimeCardsSwitch from '../../ui-kit/Cards/CardFromTime/TimeCardsSwitch';
// import TimeCardWithButtonShapeSwitch from '../../ui-kit/Cards/TimeCardWithButtonShape/TimeCardWithButtonShapeSwitch';
import {CurrentTimeCardProps} from '../../ui-kit/Cards/CardFromTime/types';
import {Switch} from '../../ui-kit/Switch';
import {DesktopView, MobileView} from '../../ui-kit/AppLayout/View';
import {steps} from '../../types/helpers';
import {DatesTicketsCardProps} from '../../ui-kit/Cards/CardFromTicket/types';
import {getSortDates} from '../../helpers/changeDate';
import {CenteredLoader} from '../../ui-kit/Loader';
import {BookedTemplateIds, CustomHideSessionT, CustomSessionsT} from '../../types/events';

const dateOptions = ['All', 'Special Events', 'Weekdays', 'Weekends'];

export const SelectDate: React.FC<SelectDateProps> = ({
  changeDate,
  setIsDateSelected,
  dataTicketCard,
  setDateCardId,
  sellerTimezone,
  weatherProtection,
  bookedSessions,
  modals,
  specialNights,
}) => {
  const dateWrapperRef = useRef<HTMLDivElement | null>(null);
  const [monPosition, setMonPosition] = useState<number>(0);
  const [monthChanged, setMonthChanged] = useState<string>('November');

  const [switchValue, setSwitchValue] = useState('All');
  const [dates, setDates] = useState<DatesTicketsCardProps[]>(dataTicketCard);
  const onChangeSwitch = (type: string) => {
    setSwitchValue(type);
  };

  const handleScroll = (event: any) => {
    if (monPosition <= event.target.scrollTop) {
      setMonthChanged('December');
    } else {
      setMonthChanged('November');
    }
  };
  const getMonChangedPosition = (newValue: number) => {
    if (newValue) {
      setMonPosition?.(newValue);
    }
  };
  const [isActiveTickedCard, setIsActiveTickedCard] = useState<string | null>(null);
  const onClickTickedCard = (id: string) => {
    setIsActiveTickedCard(id);
    setDateCardId(id);
    setIsDateSelected(true);
  };

  useEffect(() => {
    if (!dataTicketCard) return;
    const sortDates = getSortDates(switchValue, dataTicketCard, specialNights, sellerTimezone);
    setDates(sortDates || []);
  }, [switchValue]);
  const monthLabel = switchValue === 'Special Events';
  //SPECIAL EVENTS WITHOUT PAWS & CLAUS
  const specialNights_ = specialNights?.filter((el) => el.name?.toLowerCase() !== 'paws & claus');
  return (
    <LeftSideWrapper>
      <DesktopView>
        <ContentTitleSmall>{toUpper('Select New Date')}</ContentTitleSmall>
        <ContentDescrSmall>
          Choose from one of the available date selections below. If the new date is more expensive than your original
          purchase, you will need to pay the difference in price.
        </ContentDescrSmall>
      </DesktopView>
      <MobileView>
        <ContentTitle>{changeDate?.step === steps.one ? 'Select New Date' : 'Select New Date'}</ContentTitle>
        <ContentDescrSmall>
          Choose from one of the available date selections below. If the new date is more expensive than your original
          purchase, you will need to pay the difference in price.
        </ContentDescrSmall>
      </MobileView>
      <SwitchWrapper>
        <Switch options={dateOptions} value={switchValue} onChange={onChangeSwitch} />
      </SwitchWrapper>
      {!monthLabel && <MonthTag>{monthChanged}</MonthTag>}
      <DateCardWrapper onScroll={handleScroll} ref={dateWrapperRef}>
        {dates.map((item, index) => (
          <TicketCardsSwitch
            key={item.id}
            prevData={index > 0 ? dates[index - 1] : undefined}
            data={item}
            isActiveTickedCard={isActiveTickedCard}
            onMonthChange={getMonChangedPosition}
            onClick={onClickTickedCard}
            sellerTimezone={sellerTimezone}
            weatherProtection={weatherProtection}
            bookedSessions={bookedSessions}
            addons={item?.addons}
            modals={modals}
            specialNights={specialNights_}
            showMonthLabel={monthLabel}
          />
        ))}
      </DateCardWrapper>
    </LeftSideWrapper>
  );
};

type SelectArrivalProps = {
  setIsTimeSelected: (v: boolean) => void;
  dataTimeCard: {[key: string]: CurrentTimeCardProps[]} | undefined;
  onClickTimeCard: (v: string) => void;
  loading?: boolean;
  sellerTimezone?: string;
  selectedGA?: string;
  sessions: CustomSessionsT[];
  nightOwl?: boolean;
  sellerHasOwl?: boolean;
  hiddenSesions: CustomHideSessionT[];
  eventsWithPrice?: any[];
  templateId?: string;
  bookedTemplates?: BookedTemplateIds;
  removedTickets?: string[];
};

type diffPrice = {
  diffPrice: number;
};

export const SelectArrival: React.FC<SelectArrivalProps> = ({
  setIsTimeSelected,
  dataTimeCard,
  onClickTimeCard,
  loading,
  selectedGA,
  eventsWithPrice,
  sessions,
  sellerTimezone,
  nightOwl,
  sellerHasOwl,
  hiddenSesions,
  bookedTemplates,
  removedTickets,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [priceDiffInfo, setPriceDiffInfo] = useState<diffPrice | undefined>();
  const [isActiveTimeCardGA, setIsActiveTimeCardGA] = useState<string | null>(null);
  const [isActiveTimeCardSkating, setIsActiveTimeCardSkating] = useState<string | null>(null);
  const [isActiveTimeCardDinner, setIsActiveTimeCardDinner] = useState<string | null>(null);
  const [isActiveTimeCardParking, setIsActiveTimeCardParking] = useState<string | null>(null);
  const [isActiveTimeCardParking_preferred, setIsActiveTimeCardParking_preferred] = useState<string | null>(null);
  const [isActiveTimeCardParking_valet, setIsActiveTimeCardParking_valet] = useState<string | null>(null);
  const [isActiveTimeCardSnowTubing, setIsActiveTimeCardSnowTubing] = useState<string | null>(null);
  const onClickTimeCardGA = (id: string) => {
    setIsActiveTimeCardGA(id);
    onClickTimeCard(id);
    setIsSelected(true);
    setIsTimeSelected(true);
  };
  const onClickTimeCardSkating = (id: string) => {
    setIsActiveTimeCardSkating(id);
    onClickTimeCard(id);
    setIsTimeSelected(true);
  };

  const onClickTimeCardDinner = (id: string) => {
    setIsActiveTimeCardDinner(id);
    onClickTimeCard(id);
    setIsTimeSelected(true);
  };

  const onClickTimeCardParking = (id: string) => {
    setIsActiveTimeCardParking(id);
    onClickTimeCard(id);
    setIsTimeSelected(true);
  };

  const onClickTimeCardParking_preferred = (id: string) => {
    setIsActiveTimeCardParking_preferred(id);
    onClickTimeCard(id);
    setIsTimeSelected(true);
  };

  const onClickTimeCardParking_valet = (id: string) => {
    setIsActiveTimeCardParking_valet(id);
    onClickTimeCard(id);
    setIsTimeSelected(true);
  };
  const onClickTimeCardSnowTubing = (id: string) => {
    setIsActiveTimeCardSnowTubing(id);
    onClickTimeCard(id);
    setIsTimeSelected(true);
  };
  useEffect(() => {
    setIsActiveTimeCardSkating(null);
    setIsActiveTimeCardParking(null);
  }, [selectedGA]);
  const gaLength = Number(dataTimeCard?.general_admission?.length || 0);
  const hs_ga = hiddenSesions?.find((el) => el?.resource_id === bookedTemplates?.ga)?.arrValue;
  const hs_parking = hiddenSesions?.find((el) => el?.resource_id === bookedTemplates?.parking)?.arrValue;
  const hs_skating = hiddenSesions?.find((el) => el?.resource_id === bookedTemplates?.skating)?.arrValue;
  const hs_dinner = hiddenSesions?.find((el) => el?.resource_id === bookedTemplates?.dinner)?.arrValue;

  const iceSkatingRemoved = removedTickets?.some((el) => el.toLocaleLowerCase() === 'ice skating');
  const dinnerRemoved = removedTickets?.some((el) => el.toLocaleLowerCase() === 'dinner');
  const parkingRemoved = removedTickets?.some((el) => el.toLocaleLowerCase() === 'parking');
  const preferredParkingRemoved = removedTickets?.some((el) => el.toLocaleLowerCase() === 'preferred parking');
  const valetParkingRemoved = removedTickets?.some((el) => el.toLocaleLowerCase() === 'valet parking');
  const snowTubingRemoved = removedTickets?.some((el) => el.toLocaleLowerCase() === 'snow tubing');
  useEffect(() => {
    if (dataTimeCard?.general_admission && eventsWithPrice) {
      const selectedDate = moment
        .tz(dataTimeCard?.general_admission[0]?.start, sellerTimezone || '')
        .format('YYYY-MM-DD');

      const diffPrices =
        eventsWithPrice?.length > 0 &&
        eventsWithPrice[0]?.price_schedule?._data.filter(
          (session: any) => moment.tz(session?.from, sellerTimezone || '').format('YYYY-MM-DD') === selectedDate,
        );
      if (diffPrices) {
        const diffPrice: number = Math.abs(
          Number(diffPrices[0]?.currency_amount) - Number(diffPrices[1]?.currency_amount),
        );

        const thePriceDiffInfo = {diffPrices, diffPrice};
        setPriceDiffInfo(thePriceDiffInfo);
      }
    }
  }, [dataTimeCard?.general_admission, eventsWithPrice, sellerTimezone]);
  return (
    <RightSideWrapper>
      <ContentTitleSmall>{toUpper('Select Arrival Window')}</ContentTitleSmall>
      <ContentDescrWrapper>
        <ContentDescrSmall>
          Please select your arrival window below. Guests are welcome to arrive during their selected window; however,
          early entries will not be accommodated.
        </ContentDescrSmall>
      </ContentDescrWrapper>

      <TimeElementsWrapper selected={true}>
        {dataTimeCard?.general_admission && (
          <>
            <BannerSubtitmeWrapper>
              <ContentTitleSmall>{toUpper('General Admission')}</ContentTitleSmall>
              {priceDiffInfo?.diffPrice !== undefined && !isNaN(priceDiffInfo?.diffPrice) && (
                <PopTimeSlot>Popular time slot</PopTimeSlot>
              )}
            </BannerSubtitmeWrapper>
            <UpperTimeCardsContainer>
              {loading ? (
                <CenteredLoader variant={'light'} />
              ) : (
                <>
                  <DesktopView>
                    <TimeCardsWrapper>
                      {dataTimeCard?.general_admission?.map((item, index) => (
                        <TimeCardsSwitchWrapper key={item.id}>
                          <TimeCardsSwitch
                            key={item.id}
                            data={item}
                            isActiveTickedCard={isActiveTimeCardGA}
                            onClick={onClickTimeCardGA}
                            sessions={sessions}
                            priceDiffInfo={priceDiffInfo}
                            sellerTimezone={sellerTimezone}
                            nightOwl={nightOwl}
                            hideLast={sellerHasOwl ? index + 1 === gaLength && !nightOwl : false}
                            hiddenSesions={hs_ga}
                            isLastGA={index + 1 === gaLength}
                            addons={item?.addons}
                            preventSkip
                          />
                        </TimeCardsSwitchWrapper>
                      ))}
                    </TimeCardsWrapper>
                  </DesktopView>

                  <MobileView>
                    <TimeCardsMobileWrapper>
                      {dataTimeCard?.general_admission &&
                        dataTimeCard?.general_admission?.map((item, index) => (
                          <TimeCardsSwitch
                            key={item.id}
                            data={item}
                            isActiveTickedCard={isActiveTimeCardGA}
                            sellerTimezone={sellerTimezone}
                            onClick={onClickTimeCardGA}
                            nightOwl={nightOwl}
                            hideLast={sellerHasOwl ? index + 1 === gaLength && !nightOwl : false}
                            hiddenSesions={hs_ga}
                            isLastGA={index + 1 === gaLength}
                            addons={item?.addons}
                            preventSkip
                          />
                        ))}
                    </TimeCardsMobileWrapper>
                  </MobileView>
                  <ButtonWrapper>{/* <TransparentButton>See More</TransparentButton> */}</ButtonWrapper>
                </>
              )}
            </UpperTimeCardsContainer>
          </>
        )}
      </TimeElementsWrapper>

      <TimeElementsWrapper selected={isSelected}>
        {dataTimeCard?.ice_skating && !iceSkatingRemoved && (
          <>
            <ContentTitleSmallSecondary>ICE SKATING</ContentTitleSmallSecondary>
            {/* <ContentDescrSmallWrapper>
              <ContentDescrSmall>Select your Ice Skating entry time window.</ContentDescrSmall>
            </ContentDescrSmallWrapper> */}
            <TimeCardsContainer>
              {loading ? (
                <CenteredLoader variant={'light'} />
              ) : (
                <>
                  <DesktopView>
                    <TimeCardsWrapper>
                      {dataTimeCard?.ice_skating?.map((item) => (
                        <TimeCardsSwitchWrapper key={item.id}>
                          <TimeCardsSwitch
                            key={item.id}
                            data={item}
                            isActiveTickedCard={isActiveTimeCardSkating}
                            onClick={onClickTimeCardSkating}
                            hiddenSesions={hs_skating}
                            sellerTimezone={sellerTimezone}
                          />
                        </TimeCardsSwitchWrapper>
                      ))}
                    </TimeCardsWrapper>
                  </DesktopView>

                  <MobileView>
                    <TimeCardsMobileWrapper>
                      {dataTimeCard?.ice_skating?.map((item) => (
                        <TimeCardsSwitch
                          key={item.id}
                          data={item}
                          isActiveTickedCard={isActiveTimeCardSkating}
                          onClick={onClickTimeCardSkating}
                          hiddenSesions={hs_skating}
                          sellerTimezone={sellerTimezone}
                        />
                      ))}
                    </TimeCardsMobileWrapper>
                  </MobileView>
                  <ButtonWrapper>{/* <TransparentButton>See More</TransparentButton> */}</ButtonWrapper>
                </>
              )}
            </TimeCardsContainer>
          </>
        )}
      </TimeElementsWrapper>
      <TimeElementsWrapper selected={isSelected}>
        {dataTimeCard?.dinner && !dinnerRemoved && (
          <>
            <ContentTitleSmallSecondary>DINNER</ContentTitleSmallSecondary>
            {/* <ContentDescrSmallWrapper>
              <ContentDescrSmall>Select your Ice Skating entry time window.</ContentDescrSmall>
            </ContentDescrSmallWrapper> */}
            {dataTimeCard?.dinner?.length == 0 && (
              <ContentDescrSmall>Please select a different date to see available dinner options.</ContentDescrSmall>
            )}
            <TimeCardsContainer>
              {loading ? (
                <CenteredLoader variant={'light'} />
              ) : (
                <>
                  <DesktopView>
                    <TimeCardsWrapper>
                      {dataTimeCard?.dinner?.map((item) => (
                        <TimeCardsSwitchWrapper key={item.id}>
                          <TimeCardsSwitch
                            key={item.id}
                            data={item}
                            isActiveTickedCard={isActiveTimeCardDinner}
                            onClick={onClickTimeCardDinner}
                            hiddenSesions={hs_dinner}
                            sellerTimezone={sellerTimezone}
                          />
                        </TimeCardsSwitchWrapper>
                      ))}
                    </TimeCardsWrapper>
                  </DesktopView>

                  <MobileView>
                    <TimeCardsMobileWrapper>
                      {dataTimeCard?.dinner?.map((item) => (
                        <TimeCardsSwitch
                          key={item.id}
                          data={item}
                          isActiveTickedCard={isActiveTimeCardDinner}
                          onClick={onClickTimeCardDinner}
                          hiddenSesions={hs_dinner}
                          sellerTimezone={sellerTimezone}
                        />
                      ))}
                    </TimeCardsMobileWrapper>
                  </MobileView>
                  <ButtonWrapper>{/* <TransparentButton>See More</TransparentButton> */}</ButtonWrapper>
                </>
              )}
            </TimeCardsContainer>
          </>
        )}
      </TimeElementsWrapper>

      <TimeElementsWrapper selected={isSelected}>
        {dataTimeCard?.parking && !parkingRemoved && (
          <>
            <ContentTitleSmallSecondary>PARKING</ContentTitleSmallSecondary>
            {/* <ContentDescrSmallWrapper>
              <ContentDescrSmall>Select your Parking time.</ContentDescrSmall>
            </ContentDescrSmallWrapper> */}
            <TimeCardsContainer>
              {loading ? (
                <CenteredLoader variant={'light'} />
              ) : (
                <>
                  <DesktopView>
                    <TimeCardsWrapper>
                      {dataTimeCard?.parking?.map((item) => (
                        <TimeCardsSwitchWrapper key={item.id}>
                          <TimeCardsSwitch
                            key={item.id}
                            data={item}
                            isActiveTickedCard={isActiveTimeCardParking}
                            onClick={onClickTimeCardParking}
                            hiddenSesions={hs_parking}
                            sellerTimezone={sellerTimezone}
                          />
                        </TimeCardsSwitchWrapper>
                      ))}
                    </TimeCardsWrapper>
                  </DesktopView>

                  <MobileView>
                    <TimeCardsMobileWrapper>
                      {dataTimeCard?.parking?.map((item) => (
                        <TimeCardsSwitch
                          key={item.id}
                          data={item}
                          isActiveTickedCard={isActiveTimeCardParking}
                          onClick={onClickTimeCardParking}
                          hiddenSesions={hs_parking}
                          sellerTimezone={sellerTimezone}
                        />
                      ))}
                    </TimeCardsMobileWrapper>
                  </MobileView>
                  <ButtonWrapper>{/* <TransparentButton>See More</TransparentButton> */}</ButtonWrapper>
                </>
              )}
            </TimeCardsContainer>
          </>
        )}
      </TimeElementsWrapper>

      <TimeElementsWrapper selected={isSelected}>
        {dataTimeCard?.preferred_parking && !preferredParkingRemoved && (
          <>
            <ContentTitleSmallSecondary>Preferred PARKING</ContentTitleSmallSecondary>
            {/* <ContentDescrSmallWrapper>
              <ContentDescrSmall>Select your Parking time.</ContentDescrSmall>
            </ContentDescrSmallWrapper> */}
            <TimeCardsContainer>
              {loading ? (
                <CenteredLoader variant={'light'} />
              ) : (
                <>
                  <DesktopView>
                    <TimeCardsWrapper>
                      {dataTimeCard?.preferred_parking?.map((item) => (
                        <TimeCardsSwitchWrapper key={item.id}>
                          <TimeCardsSwitch
                            key={item.id}
                            data={item}
                            isActiveTickedCard={isActiveTimeCardParking_preferred}
                            onClick={onClickTimeCardParking_preferred}
                            hiddenSesions={hs_parking}
                            sellerTimezone={sellerTimezone}
                          />
                        </TimeCardsSwitchWrapper>
                      ))}
                    </TimeCardsWrapper>
                  </DesktopView>

                  <MobileView>
                    <TimeCardsMobileWrapper>
                      {dataTimeCard?.preferred_parking?.map((item) => (
                        <TimeCardsSwitch
                          key={item.id}
                          data={item}
                          isActiveTickedCard={isActiveTimeCardParking_preferred}
                          onClick={onClickTimeCardParking_preferred}
                          hiddenSesions={hs_parking}
                          sellerTimezone={sellerTimezone}
                        />
                      ))}
                    </TimeCardsMobileWrapper>
                  </MobileView>
                  <ButtonWrapper>{/* <TransparentButton>See More</TransparentButton> */}</ButtonWrapper>
                </>
              )}
            </TimeCardsContainer>
          </>
        )}
      </TimeElementsWrapper>

      <TimeElementsWrapper selected={isSelected}>
        {dataTimeCard?.valet_parking && !valetParkingRemoved && (
          <>
            <ContentTitleSmallSecondary>VALET PARKING</ContentTitleSmallSecondary>
            {/* <ContentDescrSmallWrapper>
              <ContentDescrSmall>Select your Parking time.</ContentDescrSmall>
            </ContentDescrSmallWrapper> */}
            <TimeCardsContainer>
              {loading ? (
                <CenteredLoader variant={'light'} />
              ) : (
                <>
                  <DesktopView>
                    <TimeCardsWrapper>
                      {dataTimeCard?.valet_parking?.map((item) => (
                        <TimeCardsSwitchWrapper key={item.id}>
                          <TimeCardsSwitch
                            key={item.id}
                            data={item}
                            isActiveTickedCard={isActiveTimeCardParking_valet}
                            onClick={onClickTimeCardParking_valet}
                            hiddenSesions={hs_parking}
                            sellerTimezone={sellerTimezone}
                          />
                        </TimeCardsSwitchWrapper>
                      ))}
                    </TimeCardsWrapper>
                  </DesktopView>

                  <MobileView>
                    <TimeCardsMobileWrapper>
                      {dataTimeCard?.valet_parking?.map((item) => (
                        <TimeCardsSwitch
                          key={item.id}
                          data={item}
                          isActiveTickedCard={isActiveTimeCardParking_valet}
                          onClick={onClickTimeCardParking_valet}
                          hiddenSesions={hs_parking}
                          sellerTimezone={sellerTimezone}
                        />
                      ))}
                    </TimeCardsMobileWrapper>
                  </MobileView>
                  <ButtonWrapper>{/* <TransparentButton>See More</TransparentButton> */}</ButtonWrapper>
                </>
              )}
            </TimeCardsContainer>
          </>
        )}
      </TimeElementsWrapper>
      <TimeElementsWrapper selected={isSelected}>
        {dataTimeCard?.snow_tubing && !snowTubingRemoved && (
          <>
            <ContentTitleSmallSecondary>SNOW TUBING</ContentTitleSmallSecondary>
            <TimeCardsContainer>
              {loading ? (
                <CenteredLoader variant={'light'} />
              ) : (
                <>
                  <DesktopView>
                    <TimeCardsWrapper>
                      {dataTimeCard?.snow_tubing?.map((item) => (
                        <TimeCardsSwitchWrapper key={item.id}>
                          <TimeCardsSwitch
                            key={item.id}
                            data={item}
                            isActiveTickedCard={isActiveTimeCardSnowTubing}
                            onClick={onClickTimeCardSnowTubing}
                            sellerTimezone={sellerTimezone}
                          />
                        </TimeCardsSwitchWrapper>
                      ))}
                    </TimeCardsWrapper>
                  </DesktopView>

                  <MobileView>
                    <TimeCardsMobileWrapper>
                      {dataTimeCard?.snow_tubing?.map((item) => (
                        <TimeCardsSwitch
                          key={item.id}
                          data={item}
                          isActiveTickedCard={isActiveTimeCardSnowTubing}
                          onClick={onClickTimeCardSnowTubing}
                          sellerTimezone={sellerTimezone}
                        />
                      ))}
                    </TimeCardsMobileWrapper>
                  </MobileView>
                  <ButtonWrapper>{/* <TransparentButton>See More</TransparentButton> */}</ButtonWrapper>
                </>
              )}
            </TimeCardsContainer>
          </>
        )}
      </TimeElementsWrapper>
    </RightSideWrapper>
  );
};
