declare global {
  interface Window {
    analytics: any;
  }
}
import React, {useState} from 'react';
import {
  AvBottomCard,
  AvailableBlock,
  BlockTitle,
  BottomCards,
  CreditsValue,
  HowItWorksBlock,
  HowItItemWrapper,
  ReferralLinkBlock,
  ShareButton,
  UpperCard,
  HowItIconWrapper,
  HowItText,
  CustomArrowWrapper,
  HowItItems,
  RefLinkValue,
  CopyButton,
  CreditBlocks,
  DesktopShareButtonWrapper,
  MobileShareButtonWrapper,
  RefButtons,
  MobShareButton,
  ExpandWrapper,
} from './styles';
import {IconSvg} from '../../ui-kit/Icon';
import {CloseCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {icon} from '../../ui-kit/Icon/types';
import {notification} from 'antd';
import {ReferralT} from '../../queries/types/referrals';
import {REFERRAL_URL} from '../../constants/env';
import {ReferralsDataT} from '../../types/referrals';
import {PageLogoLoader} from '../../ui-kit/Loader';
import ShareThis from '../ShareThis';
import moment from 'moment';
import {referralsTermsLink} from '../../constants/routes';

type CreditsPropsT = {
  referral: ReferralT;
  onShareClick?: () => void;
  referrals?: ReferralsDataT;
  loading?: boolean;
  orderDetail?: object;
};

export const Credits: React.FC<CreditsPropsT> = ({referral, onShareClick, referrals, loading, orderDetail}) => {
  const [shareTo, setShareTo] = useState(false);
  // Handle Share
  const handleShare = () => {
    const event = 'Referral Link Shared';
    if (orderDetail) {
      const order = orderDetail as {
        user: any;
        sellerName: any;
        sellerVenue: any;
        eventDate: any;
        sellerTimezone: string;
        tickets: [any];
      };
      const arrivalWindow = order?.tickets.find((item) => item.ticketGroupName.includes('Admission'))?.eventStartDate;
      const data = {
        name: `${order?.user?.firstName} ${order?.user?.lastName}`,
        email: `${order?.user?.email}`,
        location: order?.sellerName,
        venue: order?.sellerVenue,
        date: moment.tz(order?.eventDate, order?.sellerTimezone).format('YYYY-MM-DD'),
        time: moment.tz(arrivalWindow, order?.sellerTimezone).format('h:mmA'),
      };
      window?.analytics?.track(event, data);
    }
    setShareTo(!shareTo);
  };
  const link = `${REFERRAL_URL}${referral}`;
  const shareData = {
    title: 'Referral',
    text: 'I’m going to Enchant! Join me and experience the magic: ',
    url: link,
  };
  const onCopy = () => {
    navigator?.clipboard?.writeText(link);
    notification.success({
      message: 'Link copied to clipboard',
      duration: 1.5,
      placement: 'bottomRight',
    });
  };
  const onShare = async () => {
    try {
      await navigator.share(shareData);
      onShareClick?.();
    } catch (error) {}
  };
  if (loading) return <PageLogoLoader height="300px" />;
  return (
    <CreditBlocks>
      <AvailableBlock>
        <UpperCard>
          <BlockTitle>Credits Available</BlockTitle>
          <CreditsValue>${referrals?.remaining || 0}</CreditsValue>
        </UpperCard>
        <BottomCards>
          <AvBottomCard>
            <div>
              <IconSvg type="userGroup" width="12px" height="12px" viewBox="0 0 12 12" />
              Total referrals
            </div>
            <span>{referrals?.totalReferrals}</span>
          </AvBottomCard>
          <AvBottomCard>
            <div>
              <IconSvg type="pointer" width="12px" height="12px" viewBox="0 0 12 12" />
              Total earned
            </div>
            <span>${referrals?.totalEarned || 0}</span>
          </AvBottomCard>
        </BottomCards>
      </AvailableBlock>
      <HowItWorksBlock>
        <HowItTooltip />
        <BlockTitle>How the magic works</BlockTitle>
        <HowItItems>
          <HowItItem iconType="people" text="Share your unique link" arrowed />
          <HowItItem iconType="gift" text="Gift $10 Voucher" arrowed />
          <HowItItem iconType="ticket" text="Earn $5 credits" />
        </HowItItems>
      </HowItWorksBlock>
      <ReferralLinkBlock>
        <BlockTitle>Your Referral Link</BlockTitle>
        <RefLinkValue>{link}</RefLinkValue>
        <RefButtons>
          <CopyButton onClick={onCopy} onFocusCapture={onShareClick}>
            Copy Link
          </CopyButton>
          <MobShareButton onClick={onShare}>Share Link</MobShareButton>
        </RefButtons>
      </ReferralLinkBlock>
      <DesktopShareButtonWrapper>
        {shareTo ? (
          <ShareThis dataUrl={link} onShareClick={onShareClick} />
        ) : (
          <ShareButton onClick={handleShare}>Start Referring</ShareButton>
        )}
      </DesktopShareButtonWrapper>
      <MobileShareButtonWrapper>
        <ShareButton onClick={onShare}>Start Referring</ShareButton>
      </MobileShareButtonWrapper>
    </CreditBlocks>
  );
};

const HowItItem: React.FC<{iconType?: icon; text?: string; arrowed?: boolean}> = ({iconType, text, arrowed}) => {
  const viewbox = iconType === 'people' ? '0 0 20 20' : '0 0 16 16';
  const size = iconType === 'people' ? '20px' : '16px';
  return (
    <>
      <HowItItemWrapper>
        <HowItIconWrapper>
          <IconSvg type={iconType} width={size} height={size} viewBox={viewbox} stroke="white" fill="none" />
        </HowItIconWrapper>
        <HowItText>{text}</HowItText>
      </HowItItemWrapper>
      {arrowed && (
        <CustomArrowWrapper>
          <IconSvg type="customArrow" />
        </CustomArrowWrapper>
      )}
    </>
  );
};

const HowItTooltip = () => {
  const [expanded, setExpanded] = useState(false);
  const onExpand = () => setExpanded((prev) => !prev);

  return (
    <>
      <ExpandWrapper $expanded={expanded}>
        <BlockTitle>How the magic works</BlockTitle>
        <p>
          Gift your friends $10 Enchant Shop credits, and we&apos;ll gift you $5 in referral credits. Just share the
          link to start enjoying the rewards!
        </p>
        <a href={referralsTermsLink} target="_blank" rel="noreferrer">
          <span>Terms & Conditions</span> apply
        </a>
      </ExpandWrapper>
      {expanded ? <CloseCircleOutlined onClick={onExpand} /> : <QuestionCircleOutlined onClick={onExpand} />}
    </>
  );
};
