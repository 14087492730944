import {getQuery, postQuery} from './hooks';
import {EventsQueryList} from '../constants/api';
import {orderIdT, sellerIdT, onDateT, eventTemplateIdT} from './types/groups';
import {AvailableEventSessionsAndPriceReqBody} from './types/changeDate';
import {TIX_URL} from '../constants/env';

export const getEventsAvailable = async (sellerId: sellerIdT) =>
  await getQuery(EventsQueryList.getEventAvailable(sellerId)); //

export const getSellerData = async (sellerId: sellerIdT) =>
  await getQuery(EventsQueryList.getSellerData(sellerId), TIX_URL);

export const getEventSessions = async (eventTemplateId: eventTemplateIdT) =>
  await getQuery(EventsQueryList.getEventSessions(eventTemplateId));

export const getEventSessionsForOneDate = async (eventTemplateId: eventTemplateIdT, onDate: onDateT) =>
  await getQuery(EventsQueryList.getEventSessionsForOneDate(eventTemplateId, onDate)); //

export const setPostEventSessions = async (orderId: orderIdT) =>
  await postQuery(EventsQueryList.postEventSessions(orderId));

export const getAvailableEventSessionsAndPrice = async ({
  eventTemplateId,
  body,
}: AvailableEventSessionsAndPriceReqBody) =>
  await postQuery(EventsQueryList.getEventSessionAndPrice(eventTemplateId)).send(body);
