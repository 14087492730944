import {Modal} from 'antd';
import styled, {css} from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {HeadingMediumTitle} from '../../ui-kit/Typography';
import {TextBody1, TextSmallBold} from '../../ui-kit/Typography/styles';
import {Button} from '../../ui-kit/Button';
// import {mixins} from '../../ui-kit/theme/mixins';

export const EventModalWrapper = styled(Modal)`
  width: 800px !important;
  height: 410px;
  ${Media.down.m} {
    width: 400px;
    height: 600px;
    .ant-modal-close-x {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 100%;
    }
  }
  ${Media.down.xs} {
    width: 100%;
    height: 500px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    background-color: transparent;
    border-radius: none;
    padding: 0;
    box-shadow: none;
    height: 100%;
  }
  .ant-modal-body {
    padding: 0;
    height: 100%;
  }
`;

export const RemoveModalWrapper = styled(Modal)`
  width: 440px !important;
  height: 260px;
  ${Media.down.m} {
    width: 320px !important;
    .ant-modal-close-x {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 100%;
    }
  }
  ${Media.down.xs} {
    width: 100%;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    background-color: transparent;
    border-radius: none;
    padding: 0;
    box-shadow: none;
    height: 100%;
  }
  .ant-modal-body {
    padding: 0;
    height: 100%;
  }
`;

export const RemoveWrapper = styled.div`
  padding: 24px 36px;
`;

export const PNCWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 22px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  ${Media.down.m} {
    flex-direction: column;
  }
`;

export const PNCImageSide = styled.div`
  width: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  ${Media.down.m} {
    width: 100%;
    max-height: 200px;
  }
`;

export const PNCSide = styled.div`
  width: 50%;
  padding: 12px 24px;
  box-sizing: border-box;
  ${Media.down.m} {
    width: 100%;
    padding-top: 26px;
  }
`;

export const TicketTitle = styled(HeadingMediumTitle)`
  margin-bottom: 10px;
  color: ${({theme}) => theme.palette.primary.midnightBlue};
`;
export const TicketDescription = styled(TextBody1)<{height?: string}>`
  margin-bottom: 10px;
  line-height: 16px !important;
  font-size: 14px;
  display: block;
  text-shadow: 0px 0px ${({theme}) => theme.palette.primary.midnightBlue};
  color: ${({theme}) => theme.palette.primary.midnightBlue};
  ${({height}) => height && ` height: ${height}`};
  & > span {
    display: block;
    margin-top: 4px;
    font-weight: 600;
  }
`;

export const PurchaseRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const activeButton = css`
  background-color: ${({theme}) => theme.palette.primary.enchantGold};
  color: ${({theme}) => theme.palette.system.white};
  &:hover {
    background-color: ${({theme}) => theme.palette.primary.enchantGold};
    color: ${({theme}) => theme.palette.system.white};
  }
`;

const skipButton = css`
  background-color: transparent;
  color: ${({theme}) => theme.palette.primary.midnightBlue};
  border-radius: 4px;
  &:hover {
    background-color: transparent;
  }
`;

export const PurchaseButton = styled(Button)<{$filled?: boolean; $rounded?: boolean}>`
  width: 100%;
  margin-top: 20px;
  ${({$filled}) => ($filled ? activeButton : skipButton)};
  ${({$rounded}) => $rounded && ` border-radius: 24px`};
  text-transform: uppercase;
`;

export const NoteRow = styled(TextSmallBold)`
  text-transform: capitalize;
`;

export const TicketName = styled(TextSmallBold)`
  font-size: 24px;
  margin-right: 10px;
  color: ${({theme}) => theme.palette.primary.midnightBlue};
`;

export const PNCImage = styled.img`
  height: 410px;
  ${Media.down.m} {
    width: 100%;
    max-height: 450px;
    margin-top: -60px;
    height: unset;
    object-fit: cover;
  }
`;

export const InformButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
