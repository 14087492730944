import {
  OrderInfoField,
  OrderData,
  OrderTicketsT,
  setVipFuncT,
  VipSetT,
  OrderInfoT,
  SpecialNightRowT,
} from '../types/orders';
import {OrderT} from '../types/orders';
import {DatesTicketsCardProps} from '../ui-kit/Cards/CardFromTicket/types';
import {OrderDataT} from '../components/ChangeDate/types';
import {formatCurrency, formats, preferred_key, uniqArrayWithObjects, valet_key, vip_key_l} from './helpers';
import {EventTemplateIdsT} from '../queries/types/changeDate';
import {CustomSessionsT, EventSessionT, EventsT} from '../types/events';
import {getEventTemplateIdByTicketGroup} from '../helpers/events';
import {purchaseOrderTicketTypeT, PurchaseResT} from '../types/purchase';
import {
  isGA,
  isGACat,
  cartTicketsToOrderInfo,
  getTZdate,
  getBookedTicketTypes,
  TicketsMapKey,
  filterExistingOrder,
  getExistingOrderFormCart,
} from './orders';
import {useViewer} from '../hooks/auth';
import moment from 'moment';
import {Modal} from 'antd';

export const toCreateOrderInfoField = (order?: OrderT) => {
  return {
    [OrderInfoField.locationTitle]: order?.sellerName || '',
    [OrderInfoField.orderNumber]: order?.ticketureOrderNumber || '',
    [OrderInfoField.locationPark]: order?.sellerVenue || '',
  };
};

export const toCreateOrderDataChangeDate = (
  order?: OrderT,
  idTimeCard?: string,
  dataTicketCard?: DatesTicketsCardProps[],
  events?: EventsT,
) => {
  const ticketsObj: {
    [key: string]: OrderTicketsT;
  } = {};
  order?.tickets?.map((field) => {
    if (filterExistingOrder(field?.ticketTypeName)) return;
    const theEventTempName = field?.eventTemplateName?.includes('Vouchers')
      ? 'Merchandise'
      : field?.eventTemplateName || '';
    const eventTemplateId = getEventTemplateIdByTicketGroup(theEventTempName || '', events);
    if (ticketsObj[field.ticketGroupName]) {
      return (ticketsObj[field.ticketGroupName].count += 1);
    } else {
      return (ticketsObj[field.ticketGroupName] = {
        ticketGroupName: field.ticketGroupName,
        id: eventTemplateId,
        count: 1,
        time:
          field.eventStartDate && field.eventEndDate
            ? `${getTZdate(field.eventStartDate, order).format(formats.timeStart)} - ${getTZdate(
                field.eventEndDate,
                order,
              ).format(formats.timeEnd)}`
            : '',
      });
    }
  });

  const tickets = Object.values(ticketsObj);
  const sortTickets = tickets.sort((a, b) => a.ticketGroupName.localeCompare(b.ticketGroupName));
  const totalTickets = tickets.reduce((acc, ticket) => {
    return (acc += ticket.count);
  }, 0);

  const dataStepOne = {
    info: [
      {
        [OrderData.label]: 'Existing Order',
        [OrderData.mobileLabel]: 'Current Order Date',
        [OrderData.title]: order?.eventDate || '',
        [OrderData.mobileTitle]: `${totalTickets} x Tickets`,
        [OrderData.tickets]: sortTickets,
      },
      {
        [OrderData.label]: 'Change Date',
        [OrderData.subTitle]: 'Select New Date Below',
      },
    ],
    payment: 0,
  };
  const containsVip = order?.tickets?.find((el) => el?.ticketGroupName.toLowerCase()?.includes('vip'));
  const containsElf = order?.tickets?.find((el) => el?.ticketGroupName.toLowerCase()?.includes('elf'));
  const ticketsTwoStep: OrderTicketsT[] = [];
  tickets.forEach((ticket) => {
    if (
      ticket.ticketGroupName.toLowerCase().includes('admission') ||
      ticket.ticketGroupName.toLowerCase().includes('skating') ||
      ticket.ticketGroupName.toLowerCase().includes('dinner') ||
      ticket.ticketGroupName.toLowerCase().includes('merchandise') ||
      ticket.ticketGroupName.toLowerCase().includes('parking') ||
      ticket.ticketGroupName.toLowerCase().includes('paws') ||
      ticket.ticketGroupName.toLowerCase().includes('snow tubing')
    ) {
      const isSpecial = ticket.ticketGroupName.toLowerCase().includes('paws') ? true : undefined;
      const isVoucher = ticket.ticketGroupName.toLowerCase().includes('merchandise');
      if (isVoucher) {
        return ticketsTwoStep.push({
          ticketGroupName: ticket.ticketGroupName,
          id: ticket.id,
          count: ticket.count,
          isSpecial,
          skip: true,
          noTimeRewrite: true,
        });
      }
      ticketsTwoStep.push({ticketGroupName: ticket.ticketGroupName, id: ticket.id, count: ticket.count, isSpecial});

      if (containsVip && ticket.ticketGroupName.toLowerCase().includes('admission'))
        ticketsTwoStep.push({
          ticketGroupName: containsVip.ticketGroupName,
          id: ticket.id,
          count: ticket.count,
          skip: true,
        });
      if (containsElf && ticket.ticketGroupName.toLowerCase().includes('admission'))
        ticketsTwoStep.push({
          ticketGroupName: containsElf.ticketGroupName,
          id: ticket.id,
          count: 1,
          skip: true,
          hideTime: true,
        });
    } else {
      return;
    }
  });
  const currentDateCard = dataTicketCard && dataTicketCard?.find((el) => el.id === idTimeCard);
  const dataStepTwo = {
    info: [
      {
        [OrderData.label]: 'Existing Order',
        [OrderData.mobileLabel]: 'Current Order Date',
        [OrderData.title]: order?.eventDate || '',
        [OrderData.mobileTitle]: `${totalTickets} x Tickets`,
        [OrderData.tickets]: sortTickets,
      },
      {
        [OrderData.label]: 'Change Date',
        [OrderData.mobileLabel]: 'Selected New Date',
        [OrderData.title]: currentDateCard?.title || '',
        [OrderData.mobileTitle]: `${totalTickets} x Tickets`,
        [OrderData.tickets]: ticketsTwoStep,
      },
    ],
    payment: currentDateCard ? currentDateCard?.price : 0,
  };
  return {dataStepOne, dataStepTwo};
};

export const toCreateChangeDateOrderDetails = (
  order?: OrderT,
  orderData?: OrderDataT,
  cartId?: string,
  discount?: {name?: string; value?: string},
) => {
  const viewer = useViewer();
  const isProtection = order?.visitProtection;
  const ticketsCategoryGA = order?.tickets?.filter((el) => isGA(el));
  const ticketsGA: {
    count: number;
    type: string;
  }[] = [];
  ticketsCategoryGA?.forEach((el) => {
    const currentTicket = ticketsGA.find((ticket) => ticket.type === el.ticketTypeName);
    if (!currentTicket) {
      ticketsGA.push({
        count: 1,
        type: el.ticketTypeName || '',
      });
    } else {
      const newTicket = {
        ...currentTicket,
        count: currentTicket.count && currentTicket.count + 1,
      };
      const currentIdx = ticketsGA.findIndex((el) => el === currentTicket);
      ticketsGA.splice(currentIdx, 1, newTicket);
    }
  });

  const changeFee = 3;
  const tax = 0;
  const facilityFree = 0;
  // const orderTickets = ticketsGA.map((el) => `${el.count} x ${el.type} (${formatCurrency(el.count * changeTax)})`);
  const orderTickets = ticketsGA.map((el) => `${el.count} x ${el.type}`);
  const ticketsGAOrderData = woRemoved(orderData?.info[1]?.tickets)?.find((el) => isGACat(el.ticketGroupName));
  const amount = orderData ? orderData.payment : 0;
  const tickets = [
    {
      name: 'General Admission',
      time: ticketsGAOrderData?.time || '',
      id: '1',
      ticket: orderTickets,
      amount: amount,
    },
  ];
  const subtotal = !isProtection ? amount + changeFee : amount;
  const total = subtotal + tax + facilityFree;

  const dataOrderDetails = {
    location: `${order?.sellerName} - ${order?.sellerVenue}`,
    eventDate: `${orderData?.info[1]?.title && getTZdate(orderData?.info[1]?.title, order).format(formats.date1)}`,
    id: `${order?.ticketureOrderId}`,
    ticketType: tickets,
    subtotal: formatCurrency(subtotal),
    tax: formatCurrency(tax),
    facilityFree: formatCurrency(facilityFree),
    total: formatCurrency(total),
    isProtection: isProtection,
    changeFee: formatCurrency(changeFee),
    sellerId: order?.sellerId,
    cartId,
    orderNumber: order?.ticketureOrderNumber,
    orderedBy: `${viewer?.firstName} ${viewer?.lastName}`,
    discountName: discount?.name,
    discount: discount?.value,
  };

  return dataOrderDetails;
};

export const toCreateChangeDateReqBody = async (
  orderData?: OrderDataT,
  order?: OrderT,
  events?: EventsT,
  eventTemplateIds?: EventTemplateIdsT[],
  vipTickets?: VipSetT,
) => {
  if (!orderData || !eventTemplateIds) return;
  const tickets: {ticket_type_id?: string; event_session_id?: string}[] = [];
  const addons = orderData?.info?.[1]?.tickets?.filter((el) => el?.isSpecial && el?.time);
  const {ticketsMap} = getBookedTicketTypes(order, events, addons);
  const selectedTickets = woRemoved(orderData?.info[1]?.tickets);
  //<--- sessions
  const eventTypeGA = selectedTickets?.find(
    (ticket) => ticket?.ticketGroupName.toLowerCase().includes('admission') && !ticket?.skip,
  );
  const eventTypeSkating = selectedTickets?.find(
    (ticket) => ticket?.ticketGroupName.toLowerCase().includes('skating') && !ticket?.skip,
  );
  const eventTypeDinner = selectedTickets?.find(
    (ticket) => ticket?.ticketGroupName.toLowerCase().includes('dinner') && !ticket?.skip,
  );
  const eventTypeVoucher = selectedTickets?.find((ticket) =>
    ticket?.ticketGroupName.toLowerCase().includes('merchandise'),
  );

  const eventTypeParking = selectedTickets?.find(
    (ticket) =>
      ticket?.ticketGroupName.toLowerCase().includes('parking') &&
      !ticket?.ticketGroupName.toLowerCase().includes(valet_key) &&
      !ticket?.ticketGroupName.toLowerCase().includes(preferred_key) &&
      !ticket?.skip,
  );
  const eventTypeParkingValet = selectedTickets?.find(
    (ticket) => ticket?.ticketGroupName.toLowerCase().includes(valet_key) && !ticket?.skip,
  );
  const eventTypeParkingPreferred = selectedTickets?.find(
    (ticket) => ticket?.ticketGroupName.toLowerCase().includes(preferred_key) && !ticket?.skip,
  );
  const eventTypePNC = selectedTickets?.find(
    (ticket) => ticket?.ticketGroupName.toLowerCase().includes('paws') && !ticket?.skip,
  );
  const eventTypeSnowTubing = selectedTickets?.find(
    (ticket) => ticket?.ticketGroupName.toLowerCase().includes('snow tubing') && !ticket?.skip,
  );
  // sessions ---->

  Object.keys(ticketsMap)?.forEach((ticketKey) => {
    const key = ticketKey as TicketsMapKey;

    if (key.includes('ga')) {
      if (!eventTypeGA) return;
      for (let i = 0; i < ticketsMap[key].count; i++)
        tickets.push({ticket_type_id: ticketsMap[key].ticketType, event_session_id: eventTypeGA.eventSessionId});
    }

    if (key.includes('skating')) {
      if (!eventTypeSkating) return;
      for (let i = 0; i < ticketsMap[key].count; i++)
        tickets.push({ticket_type_id: ticketsMap[key].ticketType, event_session_id: eventTypeSkating.eventSessionId});
    }

    if (key.includes('dinner')) {
      if (!eventTypeDinner) return;
      for (let i = 0; i < ticketsMap[key].count; i++)
        tickets.push({ticket_type_id: ticketsMap[key].ticketType, event_session_id: eventTypeDinner.eventSessionId});
    }

    if (key.includes('tubing')) {
      if (!eventTypeSnowTubing) return;
      for (let i = 0; i < ticketsMap[key].count; i++)
        tickets.push({
          ticket_type_id: ticketsMap[key].ticketType,
          event_session_id: eventTypeSnowTubing.eventSessionId,
        });
    }

    // <-- PARKING
    if (key === 'parking') {
      if (!eventTypeParking) return;
      for (let i = 0; i < ticketsMap[key].count; i++)
        tickets.push({ticket_type_id: ticketsMap[key].ticketType, event_session_id: eventTypeParking.eventSessionId});
    }
    if (key.includes(preferred_key)) {
      if (!eventTypeParkingPreferred) return;
      for (let i = 0; i < ticketsMap[key].count; i++)
        tickets.push({
          ticket_type_id: ticketsMap[key].ticketType,
          event_session_id: eventTypeParkingPreferred.eventSessionId,
        });
    }
    if (key.includes(valet_key)) {
      if (!eventTypeParkingValet) return;
      for (let i = 0; i < ticketsMap[key].count; i++)
        tickets.push({
          ticket_type_id: ticketsMap[key].ticketType,
          event_session_id: eventTypeParkingValet.eventSessionId,
        });
    }
    // PARKING -->

    if (key.includes('vippass')) {
      if (!vipTickets?.vippass) return;
      const vip = vipTickets.vippass;
      for (let i = 0; i < ticketsMap[key].count; i++)
        tickets.push({ticket_type_id: ticketsMap[key].ticketType, event_session_id: vip.id});
    }
    if (key.includes('elf')) {
      if (!vipTickets?.elf) return;
      const elf = vipTickets.elf;
      if (ticketsMap[key].count) tickets.push({ticket_type_id: ticketsMap[key].ticketType, event_session_id: elf.id});
    }

    //voucher
    if (key.includes('voucher')) {
      if (!eventTypeVoucher) return;
      for (let i = 0; i < ticketsMap[key].count; i++)
        if (eventTypeVoucher?.eventSessionId)
          tickets.push({
            ticket_type_id: ticketsMap[key].ticketType,
            event_session_id: eventTypeVoucher?.eventSessionId,
          });
    }

    //SPECIAL EVENTS
    if (key.includes('pnc')) {
      if (!eventTypePNC) return;
      for (let i = 0; i < ticketsMap['pnc'].count; i++)
        if (eventTypePNC?.eventSessionId)
          tickets.push({
            ticket_type_id: ticketsMap[key].ticketType,
            event_session_id: eventTypePNC?.eventSessionId,
          });
    }
  });
  return tickets;
};

export const getChangeFeeDate = (
  purchaseState?: PurchaseResT,
  events?: EventsT,
  order?: OrderT,
): purchaseOrderTicketTypeT | undefined => {
  if (order?.visitProtection) return undefined;
  const tickets = purchaseState?.ticket?._data?.filter((el) => el?.handler === 'donation');
  const items_2 = cartTicketsToOrderInfo(events, tickets);
  const changeFee = items_2?.[0];
  if (changeFee?.amount)
    return {
      ticketType: 'Date Change Fee',
      countInfo: changeFee.countInfo,
      amount: changeFee.amount,
    };
  return undefined;
};

export const getSortDates = (
  type: string,
  allDates: DatesTicketsCardProps[],
  specialNights?: SpecialNightRowT[],
  sellerTimezone?: string,
) => {
  if (type === 'All') return allDates;
  if (type === 'Special Events')
    return allDates.filter((el) => {
      const calendarDate = moment.tz(el?.title, sellerTimezone ?? '').format('L');
      return !!specialNights?.some((el) => el?.dates?.includes(calendarDate));
    });

  if (type === 'Weekdays')
    return allDates.filter((el) => {
      const weekday = el?.title && new Date(el?.title).getDay();
      return weekday !== 6 && weekday !== 0;
    });

  if (type === 'Weekends') {
    const sortDates = allDates.filter((el) => {
      const weekday = el?.title && new Date(el?.title).getDay();
      return weekday === 6 || weekday === 0;
    });
    return sortDates;
  }
};

export const getVipsForChangeDate = (
  setVipTicket: setVipFuncT,
  sessions: CustomSessionsT[],
  order?: OrderT,
  orderData?: OrderDataT,
) => {
  const elfEvents: EventSessionT[] = [];
  const selectedGAtime = orderData?.info?.[1]?.tickets?.[0]?.time;
  sessions?.forEach((session) => {
    const normalizeName = session.name.toLowerCase().replaceAll(' ', '_');
    // const normalizeName = eventGroupName[0].toLowerCase() + '_' + eventGroupName.slice(1);
    session?.event_session?._data.forEach((event) => {
      const eventTime = `${getTZdate(event.start_datetime, order).format(formats.timeStart)} - ${getTZdate(
        event.end_datetime,
        order,
      ).format(formats.timeEnd)}`;
      if (normalizeName.toLowerCase().includes('vip') && eventTime === selectedGAtime) {
        setVipTicket('vippass', event);
      }
      if (normalizeName?.toLowerCase().includes('elf')) {
        elfEvents.push(event);
      }
    });
  });

  const elfForTime = elfEvents?.find((event) => {
    const eventStart = `${getTZdate(event.start_datetime, order).format(formats.timeStart)} `;
    const gaStart = orderData?.info?.[1]?.tickets?.[0]?.time?.split('-')?.[0];
    return eventStart === gaStart;
  });
  if (elfForTime) setVipTicket('elf', elfForTime);
  else setVipTicket('elf', elfEvents?.[0]);
};

export type AvVipT = {
  available?: EventSessionT[];
  purchased: number;
};
export const getAvVipSessions = (sessions: CustomSessionsT[], order?: OrderT): AvVipT => {
  const purchased = order?.tickets?.filter((el) => el?.ticketGroupName.toLowerCase().includes(vip_key_l))?.length || 0;
  const available = sessions?.find((el) => el?.name?.toLowerCase()?.includes(vip_key_l))?.event_session?._data;
  return {purchased, available};
};

export type AvSkatingT = {
  available?: EventSessionT[];
  purchased: number;
  withTime?: any[];
};
export const getAvSkatingSessions = (sessions: CustomSessionsT[], order?: OrderT): AvSkatingT => {
  const purchased = order?.tickets?.filter((el) => el?.ticketGroupName.toLowerCase().includes('skating'))?.length || 0;
  const available = sessions?.find((el) => el?.name?.toLowerCase()?.includes('skating'))?.event_session?._data;
  const withTime = available?.map((el) => ({
    ...el,
    ct: getTZdate(el?.start_datetime, order)?.format(formats.timeStart),
  }));
  return {purchased, available, withTime};
};

export const checkSellerHasOwl = (events?: EventsT) => {
  return events?.ticket_type?._data?.some((el) => el?.name?.toLowerCase()?.includes('owl'));
};

export const checkIsNightOwl = (order?: OrderT, events?: EventsT): string | null => {
  if (!order || !events) return null;
  if (order.tickets?.some((el) => el?.ticketTypeName?.toLowerCase().includes('owl'))) {
    const value = events?.meta?._data?.find((el) => el?.metakey === 'night_owl')?.value?.replace(/'/g, '"');
    return `${value}` || null;
  }
  return null;
};

export const inNightOwlTime = (cardTime?: string, owl?: string) => {
  if (!owl || !cardTime) return false;
  let contains = false;
  const clearTime = cardTime.replace(' pm', '').replaceAll(':', '');
  const owlTime = owl?.replaceAll('PM', '').replaceAll(':', '');
  const owlArray: string[] = JSON.parse(owlTime);

  owlArray?.forEach((el) => {
    // const [ts_owl, te_owl] = el.split(' - ');
    // const [ts_card, te_card] = clearTime.split(' - ');
    // if (Number(ts_card) >= Number(ts_owl) && Number(te_card) <= Number(te_owl)) contains = true;
    const te_card = clearTime.split(' - ')?.[1];
    const te_owl = el.split(' - ')?.[1];
    if (Number(te_card) === Number(te_owl)) contains = true;
  });
  return contains;
};

export const getExitingOrderInfoCD = ({
  order,
  purchaseRes,
  events,
  orderData,
  specialAddons,
  inappropriatePncDate,
}: {
  order?: OrderT;
  purchaseRes?: PurchaseResT;
  events?: EventsT;
  hasVip: boolean;
  orderData?: OrderDataT;
  specialAddons?: OrderTicketsT[];
  inappropriatePncDate?: boolean;
}): OrderInfoT[] => {
  const ticketTypesExist = uniqArrayWithObjects(
    order?.tickets?.map((el) => ({
      tt: el.ticketType,
      tn: el?.ticketTypeName,
    })) || [],
  );
  specialAddons?.forEach((addon) => {
    ticketTypesExist.push({tt: addon?.ticketType, tn: addon?.ticketName, special: true});
  });
  const info = purchaseRes
    ? getExistingOrderFormCart(order, purchaseRes, events)
    : [
        {
          label: 'Current Order Date',
          title: order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '',
          mobTitle: order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '',
          expandedMobTitle: order?.eventDate ? getTZdate(order?.eventDate, order).format(formats.date1) : '',
          selectedBold: true,
          value: ticketTypesExist
            ?.filter((el) => !filterExistingOrder(el?.tn))
            ?.map((el) => {
              const item = order?.tickets?.find(
                (ticket) => ticket?.ticketType === el.tt && ticket?.ticketTypeName === el.tn,
              );

              const ticketName =
                item?.eventTemplateName === item?.ticketTypeName
                  ? item?.eventTemplateName
                  : `${item?.eventTemplateName} ${item?.ticketTypeName}`;
              const time =
                item?.eventStartDate && item?.eventEndDate
                  ? `(${getTZdate(item?.eventStartDate, order).format(formats.timeStart)} - ${getTZdate(
                      item?.eventEndDate,
                      order,
                    ).format(formats.timeEnd)} arrival)`
                  : undefined;
              return {
                name: ticketName,
                count: order?.tickets?.filter(
                  (ticket) => ticket?.ticketType === el.tt && ticket?.ticketTypeName === el.tn,
                ).length,
                infoTicket: time,
              };
            }),
        },
      ];

  // const ticketsWOpnc = order?.tickets?.filter((el) => !el?.ticketGroupName?.toLowerCase()?.includes('paws'))
  const cleanSelectedTickets = woRemoved(orderData?.info[1]?.tickets);
  if (cleanSelectedTickets?.length) {
    info.push({
      label: 'Change Date',
      title: orderData?.info[1]?.title ? getTZdate(orderData?.info[1]?.title, order).format(formats.date1) : '',
      mobTitle: orderData?.info[1]?.title ? getTZdate(orderData?.info[1]?.title, order).format(formats.date1) : '',
      expandedMobTitle: orderData?.info[1]?.title
        ? getTZdate(orderData?.info[1]?.title, order).format(formats.date1)
        : '',
      selectedBold: true,
      value: ticketTypesExist
        ?.filter((el) => !filterExistingOrder(el?.tn))
        ?.filter((el) => (el?.tt?.toLowerCase().includes('paws') ? !inappropriatePncDate : true))
        ?.map((el) => {
          //<-- check if special event
          if (el?.special) {
            const item = specialAddons?.find((ticket) => ticket?.ticketType === el.tt && ticket?.ticketName === el.tn);
            const selectedTime = cleanSelectedTickets?.find(
              (el) => el?.ticketGroupName === item?.ticketGroupName,
            )?.time;
            return {
              name: item?.ticketGroupName,
              count: item?.count,
              infoTicket: selectedTime ? `(${selectedTime} arrival)` : '',
            };
          }
          // --->
          const item = order?.tickets?.find(
            (ticket) => ticket?.ticketType === el.tt && ticket?.ticketTypeName === el.tn,
          );
          const itemExist = cleanSelectedTickets?.find((el) => el?.ticketGroupName === item?.ticketGroupName);
          const selectedTime = cleanSelectedTickets?.find((el) => el?.ticketGroupName === item?.ticketGroupName)?.time;
          const ticketName =
            item?.eventTemplateName === item?.ticketTypeName
              ? item?.eventTemplateName
              : `${item?.eventTemplateName} ${item?.ticketTypeName}`;
          const time = selectedTime ? `(${selectedTime} arrival)` : '';
          if (!itemExist) return {name: '', count: 0, infoTicket: ''};
          return {
            name: ticketName,
            count: order?.tickets?.filter((ticket) => ticket?.ticketType === el.tt && ticket?.ticketTypeName === el.tn)
              .length,
            infoTicket: time,
          };
        }),
    });
  } else {
    info.push({
      label: 'Selected New Date',
      title: '',
      mobTitle: 'N/A',
      expandedMobTitle: '',
      selectedBold: false,
      bodyText: 'Select New Date Below',
      value: [],
    });
  }

  return info;
};

export const CertainDatesModal = (tickets?: string, onOk?: () => void) => {
  if (!tickets) return;
  Modal.confirm({
    title: 'Limited tickets available on this day.',
    content: `${tickets} is only available on certain dates. You can still change your order date, but your tubing tickets will not be transferred or refunded. We recommend selecting another date when ${tickets} is available.`,
    cancelText: 'No',
    okText: 'Remove tickets and continue',
    onOk: onOk,
  });
};

export const SoldOutModal = (tickets?: string, onOk?: () => void) => {
  if (!tickets) return;
  Modal.confirm({
    title: 'Limited tickets available on this day.',
    content: `${tickets} is sold out for this day. Would you like to continue? If so, ${tickets} will be removed from your order.`,
    cancelText: 'No',
    okText: 'Remove tickets and continue',
    onOk: onOk,
  });
};

export const woRemoved = (tickets?: OrderTicketsT[]) => tickets?.filter((el) => !el.removed);
