import React, {useRef} from 'react';
import {useState} from 'react';
import {User} from '../../types/auth';
import {SmallBold} from '../../ui-kit/Typography';
import {TextBody1, TextBody2} from '../../ui-kit/Typography/styles';
import {
  TabContent,
  AvatarWrapper,
  FieldWrapper,
  ButtonWrapper,
  AvatarInput,
  AddressWrapper,
  ErrorWrapper,
  ResetButton,
} from './styles';
import {Button} from '../../ui-kit/Button';
import Input from '../../ui-kit/Input';
import Avatar from '../../ui-kit/Avatar';
import {SaveUserValuesT, useProfileForm} from '../../hooks/user';
import {Label} from '../../ui-kit/Label';
import Select from '../../ui-kit/Select';
import {DatePicker, DatePickerProps} from 'antd';
import moment from 'moment';
import {formats} from '../../helpers/helpers';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

type MyProfilePropsT = {
  viewer?: User;
  handleUpdate: (values: SaveUserValuesT) => void;
  handlePictureUpload: (values: any) => void;
  updateLoading?: boolean;
  isError?: boolean;
};
const genderOptions = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
];
const relationshipOptions = [
  {label: 'Single', value: 'Single'},
  {label: 'Dating', value: 'Dating'},
  {label: 'Engaged', value: 'Engaged'},
  {label: 'Married', value: 'Married'},
  {label: 'Divorced', value: 'Divorced'},
  {label: 'In a Relationship', value: 'In a Relationship'},
  {label: 'Not specified', value: 'Not specified'},
];
export const MyProfile: React.FC<MyProfilePropsT> = ({
  viewer,
  handleUpdate,
  updateLoading,
  handlePictureUpload,
  isError,
}) => {
  const {values, handleChange, handleChangeAddress, error, address, selectAddress, resetAddress} =
    useProfileForm(viewer);
  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = () => {
    setIsEdit((prevState) => !prevState);
  };
  const onSubmit = async () => {
    if (error) return;
    await handleUpdate(values);
    setIsEdit(false);
  };
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e.target.name as keyof SaveUserValuesT, e.target.value);
  const handleChangeAddressInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChangeAddress(e.target.name as keyof SaveUserValuesT, e.target.value);

  const handleChangeSelect = (name: keyof SaveUserValuesT) => (value: string) => handleChange(name, value);
  const handleChangeDatePicker =
    (name: keyof SaveUserValuesT): DatePickerProps['onChange'] =>
    (date: any, dateString?: string) =>
      handleChange(name, dateString);
  const userAddress = [
    viewer?.metadata?.address?.streetAddress || '',
    viewer?.metadata?.address?.city || '',
    viewer?.metadata?.address?.state || '',
  ]
    ?.filter((a) => a)
    .join(', ');
  const geoRef = useRef<any>(null);
  const onReset = () => {
    resetAddress();
    if (typeof document !== 'undefined') {
      const input = document.getElementById('react-select-3-input');
      input?.focus();
      input?.blur();
    }
  };
  return (
    <TabContent>
      <AvatarWrapper $isEditing={isEdit}>
        <Avatar link={viewer?.metadata?.profileImageUrl} />
        <AvatarInput type="file" accept="image/*" onChange={handlePictureUpload} />
      </AvatarWrapper>
      <FieldWrapper>
        <SmallBold>First Name</SmallBold>
        {isEdit ? (
          <Input size={'small'} name="firstName" value={values?.firstName} onChange={handleChangeInput} />
        ) : (
          <TextBody1>{viewer?.firstName}</TextBody1>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <SmallBold>Last Name</SmallBold>
        {isEdit ? (
          <Input size={'small'} name="lastName" value={values?.lastName} onChange={handleChangeInput} />
        ) : (
          <TextBody1>{viewer?.lastName}</TextBody1>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <SmallBold>Email</SmallBold>
        {isEdit ? (
          <Input size={'small'} name="email" value={values?.email} onChange={handleChangeInput} />
        ) : (
          <TextBody1>{viewer?.email}</TextBody1>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <SmallBold>Phone</SmallBold>
        {isEdit ? (
          <Input size={'small'} name="phoneNumber" value={values?.phoneNumber} onChange={handleChangeInput} />
        ) : (
          <TextBody1>{viewer?.phoneNumber}</TextBody1>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <SmallBold>Gender</SmallBold>
        {isEdit ? (
          <Select
            size={'small'}
            options={genderOptions}
            value={values?.gender}
            onChange={handleChangeSelect('gender')}
          />
        ) : (
          <TextBody1>{viewer?.metadata?.gender}</TextBody1>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <SmallBold>Date of Birth</SmallBold>
        {isEdit ? (
          <DatePicker
            name="dateOfBirth"
            defaultValue={moment(values?.dateOfBirth)}
            onChange={handleChangeDatePicker('dateOfBirth')}
          />
        ) : (
          <TextBody1>
            {viewer?.metadata?.dateOfBirth && moment(viewer?.metadata?.dateOfBirth).format(formats.calendarDate)}
          </TextBody1>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <SmallBold>Relationship Status</SmallBold>
        {isEdit ? (
          <Select
            size={'small'}
            options={relationshipOptions}
            value={values?.relationshipStatus}
            onChange={handleChangeSelect('relationshipStatus')}
          />
        ) : (
          <TextBody1>{viewer?.metadata?.relationshipStatus}</TextBody1>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <SmallBold>Address</SmallBold>
        {isEdit ? (
          <>
            <ResetButton onClick={onReset}>
              <SmallBold>Reset address</SmallBold>
            </ResetButton>
            <AddressWrapper>
              <GooglePlacesAutocomplete
                ref={geoRef}
                selectProps={{
                  value: address,
                  onChange: selectAddress,
                  isClearable: true,
                  defaultInputValue: userAddress,
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['us', 'ca', 'mx'],
                  },
                }}
                apiOptions={{
                  language: 'en',
                }}
              />
            </AddressWrapper>
          </>
        ) : (
          <TextBody1>{userAddress}</TextBody1>
        )}
      </FieldWrapper>
      {values?.address && isEdit && (
        <>
          <FieldWrapper $separate>
            {isEdit ? (
              <Input
                name="address2"
                value={values?.address?.address2}
                onChange={handleChangeAddressInput}
                placeholder="Suite, unit, or building #"
              />
            ) : (
              <TextBody1>{values?.address?.address2}</TextBody1>
            )}
          </FieldWrapper>
          <FieldWrapper $isPair $separate>
            {isEdit ? (
              <Input name="city" value={values?.address?.city} onChange={handleChangeAddressInput} placeholder="City" />
            ) : (
              <TextBody1>{values?.address?.city}</TextBody1>
            )}
            {isEdit ? (
              <Input
                name="state"
                value={values?.address?.state}
                onChange={handleChangeAddressInput}
                placeholder="State"
              />
            ) : (
              <TextBody1>{values?.address?.state}</TextBody1>
            )}
          </FieldWrapper>
          <FieldWrapper $isPair $separate>
            {isEdit ? (
              <Input
                name="zipCode"
                value={values?.address?.zipCode}
                onChange={handleChangeAddressInput}
                placeholder="Zip Code"
              />
            ) : (
              <TextBody1>{values?.address?.zipCode}</TextBody1>
            )}
            {isEdit ? (
              <Input
                name="country"
                value={values?.address?.country}
                onChange={handleChangeAddressInput}
                placeholder="Country / Region"
              />
            ) : (
              <TextBody1>{values?.address?.country}</TextBody1>
            )}
          </FieldWrapper>
        </>
      )}
      {isError && (
        <ErrorWrapper>
          <TextBody2>
            We could not update your profile at this time. Please try again or contact us for support.
          </TextBody2>
        </ErrorWrapper>
      )}
      {!isEdit ? (
        <Button onClick={handleEdit} variant={'tertiary'} width={'100%'}>
          Edit
        </Button>
      ) : (
        <ButtonWrapper>
          <Button onClick={onSubmit} variant={'primary'} color="primary" width={'100%'} disabled={updateLoading}>
            Save
          </Button>
        </ButtonWrapper>
      )}
      {isEdit && error && <Label type="error" label={error} />}
    </TabContent>
  );
};
