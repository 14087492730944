import {Button} from 'antd';
import styled, {css} from 'styled-components';

import {ActiveProps} from './types';
import {linearGoldGradient, soldOutGradient} from '../../theme/palette';
import {StyledHeading5} from '../../Typography/styles';

const StyledActive = css`
  opacity: 1;
`;

const StyledDisactive = css`
  opacity: 0.4;
`;

const activeCard = (isActive: boolean) => {
  switch (isActive) {
    case true:
      return StyledActive;
    default:
      return StyledDisactive;
  }
};

export const StyledCard = styled.div<ActiveProps>`
  ${({$isActive}) => activeCard($isActive)};
  width: 100%;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  padding: 26px 24px;
  margin-bottom: 10px;
  position: relative;
`;

export const StyledSvgTop = styled.div`
  position: absolute;
  top: -20px;
  left: 13px;
`;
export const StyledSvgButtom = styled.div`
  position: absolute;
  bottom: -20px;
  right: 13px;
`;

export const StyledTitle = styled(StyledHeading5)`
  line-height: 29px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CounterButton = styled(Button).attrs({shape: 'circle'})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  ${linearGoldGradient};
  cursor: pointer;
  color: ${({theme}) => theme.palette.system.darkBlack};
  &:hover,
  &:active,
  &:focus {
    ${linearGoldGradient}
    color: ${({theme}) => theme.palette.system.darkBlack};
    opacity: 1;
  }
  & i > svg {
    fill: ${({theme}) => theme.palette.system.darkBlack};
  }
`;

export const TrashButton = styled(CounterButton)`
  ${soldOutGradient}
  color: ${({theme}) => theme.palette.system.white};
  &:hover,
  &:active,
  &:focus {
    ${soldOutGradient}
    color: ${({theme}) => theme.palette.system.white};
  }
`;

export const AdvImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
`;
