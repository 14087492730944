import React, {useEffect, useRef, useState} from 'react';
// import {Form} from 'antd';
import {Form as FormAntd} from 'antd';

import {
  LeftSideWrapper,
  //ContentTitleSmall,
  FormElementsWrapper,
  InputsContainer,
  CheckBoxWrapper,
  CheckboxText,
  CheckboxTextUnderlined,
  //ContentTitleMobileWrapper,
  //ContentTitleDesktopWrapper,
  CodeForm,
  CardContainer,
  CardErrorLine,
  PaymentButton,
  PaymentButtonsWrapper,
  PaymentButtonWrapperLeft,
  PaymentButtonWrapperRight,
  TimeLeftRow,
  PersonalDetailsRow,
  PersonalDetailsLabel,
  PersonalDetailsValue,
  PersonalDetailsCard,
  CheckboxTextWrapper,
  FormCardTitle,
} from './styles';
import {CustomCheckbox} from '../../ui-kit/Checkbox';

import {useStripe} from '@stripe/react-stripe-js';
import {PaymentFormInitialT} from '../../helpers/payment';
import {StripeCardElement} from '@stripe/stripe-js/types/stripe-js/elements';
import {usePaymentDataT} from '../../hooks/payment';
import {IconSvg} from '../../ui-kit/Icon';
import {Label, WeatherProtectionLabel} from '../../ui-kit/Label';
import {ticketingTermsConditionsLink, privacyPolicyLink} from '../../constants/routes';
// import PhoneInput from 'react-phone-input-2';
import {useNavigate} from 'react-router-dom';
import {route} from '../../constants/routes';
import {CheckoutStateT} from '../../states/order';

export type PaymentFormProps = {
  initial: PaymentFormInitialT;
  paymentData: usePaymentDataT;
  setCheckPolicy?: (v: boolean) => void;
  showPayButtons?: boolean;
  timeLeft?: number | null;
  purchaseError?: string;
  setPurchaseError?: (val: string) => void;
  setCheckout: (val: CheckoutStateT) => void;
  balanceDue?: number;
  order?: any;
  paymentSkipping?: boolean;
  isAdmission?: boolean;
};
const errors = {
  terms: 'Sorry, you must read and accepts the Ticketing terms & conditions to process your order',
};

let card: StripeCardElement | undefined = undefined;

export const PaymentForm: React.FC<PaymentFormProps> = ({
  paymentData,
  initial,
  setCheckPolicy,
  showPayButtons,
  timeLeft,
  purchaseError,
  setPurchaseError,
  setCheckout,
  balanceDue,
  order,
  paymentSkipping,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const {onSetSuccessCard, setPurchaseFunc, onSubmit} = paymentData;
  const [cardError, setCardError] = useState<any>('');
  const [formError, setFormError] = useState<any>('');
  const [terms, setTerms] = useState<boolean>(false);
  // const [zip, onZipChange] = useState<string>('');
  // const handleChangeZip = (e: React.ChangeEvent<HTMLInputElement>) => onZipChange(e.target.value);
  const stripe = useStripe();
  const [form] = FormAntd.useForm();
  const navigate = useNavigate();
  const skipCard = balanceDue === 0 || paymentSkipping;
  useEffect(() => {
    const elements = stripe?.elements();
    card = elements?.create('card', {hidePostalCode: false});
    const onChange = (event: any) => {
      setPurchaseError?.('');
      setTerms(false);
      if (event.error) {
        setCardError(event.error.message);
      } else {
        setCardError('');
      }
    };
    card?.on('change', onChange);
    cardRef?.current && card?.mount(cardRef.current);

    return () => card?.destroy();
  }, [!!stripe]);

  const submitCard = async (): Promise<boolean | string> => {
    if (skipCard) {
      onSetSuccessCard('token');
      return 'skip';
    }
    if (!stripe || !card) return false;

    // updated API
    const res = await stripe.createPaymentMethod({type: 'card', card});
    if (res.paymentMethod) {
      onSetSuccessCard(res.paymentMethod);
      return res.paymentMethod.id;
    } else {
      setCheckout({cardFilled: false});
      return false;
    }
  };

  const setFunction = () => {
    const submitForm = async () => {
      try {
        if (!terms) {
          setFormError(errors.terms);
          return false;
        }
        const values = {
          terms,
        };
        const res = await onSubmit(values);
        if (!res) return false;
        const res2 = await submitCard();
        if (!res2) return false;
        return res2;
      } catch (e) {
        console.log(e);
        return false;
      }
    };
    setPurchaseFunc(submitForm);
  };

  const freeCDNotice = `You will receive a receipt for the total value of your transaction; however, you will not be charged an additional fee or cost to exchange your order.`;
  const termsChange = (e: any) => {
    setFormError('');
    setTerms(e?.target?.checked);
    if (e?.target?.checked) setCheckout({cardFilled: true});
  };
  useEffect(() => {
    setFunction();
    if (setCheckPolicy) {
      setCheckPolicy(terms);
    }
  }, [terms]);

  useEffect(() => {
    if (timeLeft === 0) {
      navigate(route.dashboard.path);
    }
  }, [timeLeft]);

  return (
    <LeftSideWrapper>
      <TimeLeftRow>
        {timeLeft !== null && timeLeft !== 0 && <Label type={'time'} label={`Cart expires in ${timeLeft} minutes`} />}
      </TimeLeftRow>
      <FormAntd>
        <CodeForm form={form}>
          <FormElementsWrapper>
            <InputsContainer>
              <PersonalDetailsCard>
                <FormCardTitle>Personal Details</FormCardTitle>
                <PersonalDetailsRow>
                  <PersonalDetailsLabel>Name</PersonalDetailsLabel>
                  <PersonalDetailsValue>
                    {initial?.firstName} {initial?.lastName}
                  </PersonalDetailsValue>
                </PersonalDetailsRow>
                <PersonalDetailsRow>
                  <PersonalDetailsLabel>Email</PersonalDetailsLabel>
                  <PersonalDetailsValue>{initial?.email}</PersonalDetailsValue>
                </PersonalDetailsRow>
                <PersonalDetailsRow>
                  <PersonalDetailsLabel>Phone number</PersonalDetailsLabel>
                  <PersonalDetailsValue>{initial?.phone}</PersonalDetailsValue>
                </PersonalDetailsRow>
              </PersonalDetailsCard>
            </InputsContainer>
          </FormElementsWrapper>
          {order?.metadata?.allowDateChange && (
            <WeatherProtectionLabel title={'IMPORTANT:'} label={freeCDNotice} type={'secondary'} icon={true} />
          )}
          {!skipCard && (
            <>
              <PersonalDetailsCard>
                <FormCardTitle>Payment Details</FormCardTitle>
                <CardContainer ref={cardRef}></CardContainer>
                {/*<CleaveZipStyled*/}
                {/*  placeholder="zip"*/}
                {/*  options={{blocks: [5], numericOnly: true}}*/}
                {/*  value={zip}*/}
                {/*  onChange={handleChangeZip}*/}
                {/*  name="zip"*/}
                {/*/>*/}
                {showPayButtons && (
                  <>
                    <PaymentButtonsWrapper>
                      <PaymentButtonWrapperLeft>
                        <PaymentButton>
                          <IconSvg type={'applePay'} width={'55'} height={'23'} viewBox="0 0 55 23" />
                        </PaymentButton>
                      </PaymentButtonWrapperLeft>
                      <PaymentButtonWrapperRight>
                        <PaymentButton>
                          <IconSvg type={'googlePay'} width={'63'} height={'24'} viewBox="0 0 63 24" />
                        </PaymentButton>
                      </PaymentButtonWrapperRight>
                    </PaymentButtonsWrapper>
                  </>
                )}
                <CardErrorLine>{cardError}</CardErrorLine>
              </PersonalDetailsCard>
            </>
          )}
          {purchaseError && <Label type={'error'} label={purchaseError} />}
          <PersonalDetailsCard>
            <FormCardTitle>Terms of Service</FormCardTitle>
            <CheckBoxWrapper>
              <CustomCheckbox checked={terms} onChange={termsChange}></CustomCheckbox>
              <CheckboxTextWrapper>
                <CheckboxText>I’ve read and accept the </CheckboxText>
                <CheckboxTextUnderlined href={ticketingTermsConditionsLink} target="_blank">
                  Ticketing Terms & Conditions
                </CheckboxTextUnderlined>
                <CheckboxText> and </CheckboxText>
                <CheckboxTextUnderlined href={privacyPolicyLink} target="_blank">
                  Privacy Policy
                </CheckboxTextUnderlined>
                <CheckboxText> of Enchant Christmas.</CheckboxText>
              </CheckboxTextWrapper>
            </CheckBoxWrapper>
          </PersonalDetailsCard>
          {formError && <Label type={'error'} label={formError} />}
        </CodeForm>
      </FormAntd>
    </LeftSideWrapper>
  );
};
