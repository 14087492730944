import React from 'react';
import {useGetCurrentProgress} from '../../../hooks/navigation';
import {HeaderNavigation} from '../../../ui-kit/AppLayout/HeaderNavigation';
import {useRecoilValue} from 'recoil';
import {commonOrderInfo} from '../../../states/common';

export const CancellationHeader = () => {
  const progress = useGetCurrentProgress();
  const orderInfo = useRecoilValue(commonOrderInfo);
  return (
    <HeaderNavigation
      percent={progress}
      title={'Cancel Your Visit'}
      caption={`Order # ${orderInfo?.orderNumber}`}
      location={orderInfo?.sellerName}
      hideLinks
    />
  );
};
