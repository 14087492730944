import React from 'react';
import {UpgradeVipPage} from '../../components/UpgradeVip';
import {useUpgradeVip} from '../../hooks/upgradeVip';
import {useHideIntercomLauncher} from '../../hooks/customerIO';

export const UpgradeVip = () => {
  const {
    upgradeVip,
    vipPassData,
    dataOrderDetails,
    setIsConfirm,
    loading,
    orderInfo,
    vipSelected,
    setVip,
    additionalPayment,
    paymentForm,
    orderCreatedAt,
    order,
    purchaseError,
    canUpgrade,
  } = useUpgradeVip();
  useHideIntercomLauncher();

  return (
    <UpgradeVipPage
      upgradeVip={upgradeVip}
      dataVip={vipPassData}
      dataOrderDetails={dataOrderDetails}
      orderInfo={orderInfo}
      setIsConfirm={setIsConfirm}
      vipState={vipSelected}
      setVip={setVip}
      paymentInfo={additionalPayment}
      paymentForm={paymentForm}
      orderCreatedAt={orderCreatedAt}
      order={order}
      orderLoading={loading}
      purchaseError={purchaseError}
      canUpgrade={canUpgrade}
    />
  );
};
