import styled from 'styled-components';

export const StyledSelectWrapper = styled.div`
  width: 100%;
  & > .ant-select {
    width: 100%;
  }
  & > .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
  }
  & > .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${({theme}) => theme.palette.primary.darkGold};
  }
  & > .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 2px solid ${({theme}) => theme.palette.primary.darkGold};
    box-shadow: none;
  }
  & > .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }
  & > .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${({theme}) => theme.palette.system.gray4};
  }
  .ant-select-arrow {
    color: ${({theme}) => theme.palette.system.gray1};
  }
`;

export const PlaceholderContainer = styled.div`
  margin-top: 3px;
`;

export const SelectTooltipWrapper = styled.span`
  margin-right: 10px;
`;

export const PlaceholderWithTooltipText = styled.span``;
