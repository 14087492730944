import {isMobile} from 'react-device-detect';
import {ValueInfoTicketProps} from '../ui-kit/Order/typesOrderCardWithLogic';

export const formatCurrency = (number: number): string => {
  return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(number);
};

const hasDigit = (number: number) => number % 1 != 0;
export const formatCurrencyInteger = (number: number): string => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: hasDigit(number) ? 2 : 0,
    style: 'currency',
    currency: 'USD',
  }).format(number);
};

export function capitalize(str: string): string {
  return str.replace(/\b[a-z](?=[a-z]{2})/gi, function (letter) {
    return letter.toUpperCase();
  });
}

export function toUpper(str: string): string {
  return str.toUpperCase();
}
export const viewInfoTicketDesktop = (item: ValueInfoTicketProps): string => {
  if (item.name) return `${item.count} x ${item?.name} ${item.infoTicket}`;

  return `${item.count} x ${item.infoTicket} ${item.price}`;
};

// Get first leter of words if length > 12
export const viewInfoTicketMobile = (item: ValueInfoTicketProps): string => {
  if (item.name) return `${item.count} x ${item?.name} ${item.price ? item.price : ''}  | `;

  return `${item.count} x ${item.infoTicket}`;

  // if ((item?.infoTicket?.length || 0) > 12) {
  //   const str = item.infoTicket;
  //   const shortInfoReg = str?.match(/\b\w/g);
  //   const shortInfo = shortInfoReg?.join('');
  //   return `${item.count} x ${shortInfo} | `;
  // }
  // return `${item.count} x ${item.infoTicket} | `;
};

export const uniqArray = (value: any, index: number, self: any) => {
  return self.indexOf(value) === index;
};

export const removeEmptyFields = (obj: any) => {
  Object.keys(obj).forEach((k) => {
    !obj[k] && delete obj[k];
    if (typeof obj[k] === 'object') removeEmptyFields(obj[k]);
  });
  return obj;
};
export const uniqArrayWithObjects = (array: any[]) => {
  const output: any[] = [];
  array?.forEach((el) => {
    const finded = output?.some((item) => JSON.stringify(el) === JSON.stringify(item));
    if (!finded) output.push(el);
  });
  return output;
};

export const formatCurrencyPrice = (currencyCode?: string) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  });
export const validateEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getLocationName = (location?: string) => (location?.includes('/') ? location?.split('/')?.[1] : location);

export const formats = {
  date1: 'MMMM Do, YYYY',
  date2: 'ddd, MMMM D',
  date3: 'DD',
  date4: 'ddd',
  date5: 'D MMM',
  timeStart: 'h:mm',
  timeEnd: 'h:mm A',
  reservedDate: 'dddd, MMMM Do YYYY, h:mm a',
  onDate: 'YYYY-MM-DD',
  time: 'h:mm A',
  calendarDate: 'D MMMM YYYY',
  calendarDate2: 'D MMM YYYY',
};

export const scrollUp = () => window.scrollTo(0, 0);
export const scrollUpMobile = () => isMobile && window.scrollTo(0, 0);

export const MAX_GA_TICKETS = 15;

export const child_key = 'Junior';
export const adult_key = 'Adult';
export const infant_key = 'Infant';
export const vip_key = 'VIP';

export const preferred_key = 'preferred';
export const valet_key = 'valet';

export const child_key_l = child_key.toLocaleLowerCase();
export const adult_key_l = adult_key.toLocaleLowerCase();
export const infant_key_l = infant_key.toLocaleLowerCase();
export const vip_key_l = vip_key.toLocaleLowerCase();
export const MAX_PNC_TICKETS = 2;

//addons available only by date/location
export const PNC_date = '3 Dec';
export const special_addons_dates = {
  pnc: PNC_date,
};

export const addon_available_dates = {
  pnc: {
    dates: [PNC_date],
    location: 'San Jose',
  },
  ladies_night: {
    dates: ['14 Dec', '21 Dec'],
  },
  night_of_giving: {
    dates: ['6 Dec'],
    location: 'San Jose',
  },
};
