import styled from 'styled-components';
import {Button, Input} from 'antd';
import {fontPrimaryLight} from '../theme/font';
import {linearGoldGradient} from '../theme/palette';

export const CounterWrapper = styled.div<{$disabled?: boolean}>`
  display: flex;
  align-items: center;
  gap: ${({theme}) => theme.spacer._1};
  ${({$disabled}) => $disabled && ` opacity: 0.4; pointer-events: none;`};
`;

export const CounterButton = styled(Button).attrs({shape: 'circle'})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  stroke-width: 25;
  stroke: ${({theme}) => theme.palette.system.darkBlack};
  ${linearGoldGradient};
  cursor: pointer;
  color: ${({theme}) => theme.palette.system.darkBlack};
  &:disabled {
    color: ${({theme}) => theme.palette.system.gray1};
    background: ${({theme}) => theme.palette.system.gray3};
    opacity: 0.7;
  }
  &:hover,
  &:active,
  &:focus {
    ${linearGoldGradient};
    color: ${({theme}) => theme.palette.system.darkBlack};
    opacity: 1;
    filter: brightness(1.1);
  }
  & i > svg {
    fill: ${({theme}) => theme.palette.system.darkBlack};
  }
`;

export const StyledInput = styled(Input)`
  width: 34px;
  height: 19px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
  ${fontPrimaryLight};
  font-size: 16px;
  line-height: 19px;
  color: ${({theme}) => theme.palette.system.darkBlack};
  :focus {
    box-shadow: none;
  }
`;

export const NumValue = styled.div`
  //width: 20px;
  text-align: center;
`;
