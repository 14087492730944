import styled from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {Logo} from '../../ui-kit/Logos/Logo';
import {LogoCorporate} from '../../ui-kit/Logos/LogoCorporate';
import {Heading2, Text5, Heading5, HeadingMedium3, Text1, BoldText1} from '../../ui-kit/Typography';
import {horizontalGradientBG, linearWhiteGradient} from '../../ui-kit/theme/palette';
import {ReactComponent as QrCode} from './assets/QrCode.svg';
import {IconSvg} from '../../ui-kit/Icon';

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
  ${Media.up.m} {
    position: relative;
    bottom: -36px;
    padding-top: 124px;
  }

  ${Media.down.m} {
    padding-top: ${({theme}) => theme.spacer._15};
    padding-bottom: 139px;
  }
`;

export const LogoStyled = styled(Logo).attrs({variant: 'hallmarkSilver'})`
  margin: 0 auto;
  width: 411px;
  height: 123px;
  margin-bottom: ${({theme}) => theme.spacer._9};

  ${Media.down.m} {
    width: 271px;
    height: 90px;
    margin-bottom: ${({theme}) => theme.spacer._5};
  }
`;

export const TitleStyled = styled(Heading2)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  text-transform: capitalize;
  text-align: center;
  margin-bottom: ${({theme}) => theme.spacer._1};
`;

export const SubtitleTop = styled(Text5)`
  display: block;
  max-width: 660px;
  margin: 0 auto;
  text-align: center;
  color: ${({theme}) => theme.palette.system.white};
  margin-bottom: ${({theme}) => theme.spacer._9};
`;

export const CodeWrapper = styled.div`
  width: 226px;
  height: 226px;
  margin: 0 auto;
  margin-bottom: ${({theme}) => theme.spacer._9};
`;

export const QrCodeStyled = styled(QrCode)`
  width: 100%;
  height: 100%;
`;

export const SubtitleBottom = styled(Text5)`
  display: block;
  max-width: 588px;
  margin: 0 auto;
  text-align: center;
  color: ${({theme}) => theme.palette.system.white};
  margin-bottom: ${({theme}) => theme.spacer._9};
`;

export const LineStyled = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.palette.system.white};
  margin-bottom: ${({theme}) => theme.spacer._9};
`;

export const TitleOrderStyled = styled(Heading5)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  text-transform: capitalize;
  margin-bottom: ${({theme}) => theme.spacer._3};
`;

export const OrderWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({theme}) => theme.spacer._9};
`;

export const CardsList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${({theme}) => theme.spacer._5};
  ${Media.down.m} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 104px;
  }
`;

export const CardsItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 282px;
  width: 100%;
  padding: ${({theme}) => theme.spacer._5};
  background: ${({theme}) => theme.palette.primary.midnightBlue};
  border: 3px solid ${({theme}) => theme.palette.primary.enchantGold};
  border-radius: 10px;
  ${Media.down.m} {
    max-width: 100%;
  }
`;

export const CardContentWrapper = styled.div``;

export const CardTitle = styled(HeadingMedium3)`
  text-transform: capitalize;
  color: ${({theme}) => theme.palette.system.white};
  margin-bottom: ${({theme}) => theme.spacer._3};
`;

export const CardDescription = styled(Text1)`
  color: ${({theme}) => theme.palette.system.white};
  display: block;
  margin-bottom: ${({theme}) => theme.spacer._3};
`;

// Footer //
export const LogoFooterDesktopStyled = styled(Logo).attrs({variant: 'silver'})`
  margin: 0 auto;
  width: 200px;
  height: 46px;
  margin-bottom: ${({theme}) => theme.spacer._3};
`;

export const LogoFooterMobileStyled = styled(LogoCorporate).attrs({variant: 'gold'})`
  margin: 0 auto;
  width: 264px;
  height: 61px;
  margin-bottom: ${({theme}) => theme.spacer._2};
`;

export const TextFooterStyled = styled(Text1)`
  color: ${({theme}) => theme.palette.system.white};
  display: block;
  text-align: center;
`;

export const TextFooterBold = styled.span`
  font-weight: 700;
`;

export const WebsiteLink = styled.a``;

export const TextWebsiteStyled = styled(BoldText1)`
  color: ${({theme}) => theme.palette.system.white};
  display: block;
  text-align: center;
  padding: ${({theme}) => theme.spacer._3} 0;

  ${Media.down.m} {
    padding: ${({theme}) => theme.spacer._2} 0;
  }
`;

export const SocialLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({theme}) => theme.spacer._5};
  padding-bottom: ${({theme}) => theme.spacer._3};
`;

export const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  ${horizontalGradientBG};

  &:hover,
  &:active,
  &:focus {
    ${linearWhiteGradient}
    color: ${({theme}) => theme.palette.system.darkBlack};
    opacity: 1;
  }
  & i > svg {
    fill: ${({theme}) => theme.palette.system.darkBlack};
  }
`;

export const IconStyled = styled(IconSvg)`
  fill: ${({theme}) => theme.palette.primary.midnightBlue};
`;
