import React, {useEffect} from 'react';
import {useGetOrders, useResetStates} from '../../hooks/orders';
import {sortOrders} from '../../helpers/orders';
import {toCreateOrderData, toCreateViewerDetails} from '../../helpers/dashboard';
import {useViewer} from '../../hooks/auth';
import {getAvailableGAEventsFromOrders} from '../../hooks/events';
import {loadDashboard, useUserAccountAnalytics} from '../../hooks/customerIO';
import {OrderPage} from '../../components/Orders';
import {useGetCurrentTab} from '../../hooks/helpers';

const tabs = ['upcoming', 'past'];

export const OrdersContainer = () => {
  useResetStates();
  const {orders, loading} = useGetOrders();
  loadDashboard(orders?.length);
  const ordersData = toCreateOrderData(orders);
  const {upcomingOrders, pastOrders} = sortOrders(ordersData);
  const viewer = useViewer();
  const viewerDetails = toCreateViewerDetails(viewer);
  const {vipAvailable} = getAvailableGAEventsFromOrders(orders);
  const fullLoading = !orders?.length && loading;
  const {currentTab, onSelect} = useGetCurrentTab(tabs);
  const {onOrdersVisited} = useUserAccountAnalytics();

  useEffect(() => {
    onOrdersVisited();
  }, []);

  return (
    <OrderPage
      loading={fullLoading}
      upcomingOrders={upcomingOrders}
      pastOrders={pastOrders}
      viewerDetails={viewerDetails}
      canBuyVip={vipAvailable}
      orders={orders}
      currentTab={currentTab}
      onSelect={onSelect}
    />
  );
};
