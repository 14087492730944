import {OrderT} from '../types/orders';
import {User} from '../types/auth';
import {PurchaseResT} from '../types/purchase';

export const createStartData = (user?: User | null, order?: OrderT) => ({
  uid: user?.id,
  name: `${user?.firstName} ${user?.lastName}`,
  email: user?.email,
  ticketureOrderId: order?.ticketureOrderId,
  ticketureOrderNumber: order?.ticketureOrderNumber,
  sellerName: order?.sellerName,
  sellerVenue: order?.sellerVenue,
  sellerTimezone: order?.sellerTimezone,
  eventDate: order?.eventDate,
  createdAt: order?.createdAt,
  updatedAt: order?.updatedAt,
  visitProtection: order?.visitProtection,
  dateChange: order?.dateChange,
  orderCancelled: order?.orderCancelled,
  metadata: order?.metadata,
});

export const createReviewPageData = (user?: User | null, order?: OrderT, purchaseRes?: PurchaseResT) => ({
  uid: user?.id,
  name: `${user?.firstName} ${user?.lastName}`,
  email: user?.email,
  ticketureOrderId: order?.ticketureOrderId,
  ticketureOrderNumber: order?.ticketureOrderNumber,
  sellerName: order?.sellerName,
  sellerVenue: order?.sellerVenue,
  sellerTimezone: order?.sellerTimezone,
  eventDate: order?.eventDate,
  createdAt: order?.createdAt,
  updatedAt: order?.updatedAt,
  cart_id: purchaseRes?.cart?._data?.[0]?.id,
  grand_totals: purchaseRes?.cart_fees?._data?.[0]?.grand_totals,
  visitProtection: order?.visitProtection,
  dateChange: order?.dateChange,
  orderCancelled: order?.orderCancelled,
  metadata: order?.metadata,
});
