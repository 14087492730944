import React from 'react';
import {ContentTitleSmall, StyledChooseTicket} from '../PurchaseOne/styles';
import {
  PageWrapper,
  ContentWrapper,
  TopWrapper,
  Title,
  VipPassWrapper,
  ReviewTitle,
  ReviewDescription,
  ReviewContentContainer,
  OrderWrapper,
} from './styles';
import {toUpper} from '../../helpers/helpers';
import {DesktopView} from '../../ui-kit/AppLayout/View';
import {UpgradeToVipCard} from '../../ui-kit/Cards/VipElf/VipElfGuideSwitcher';
import {BillingInfo} from './Form';
import {steps} from '../../types/helpers';
import {TitleWrapper} from '../ChangeDate/styles';
import {OrderInfoComponent} from '../OrdersInfo';
import {UpgradeVipPagePropsT} from './types';
import {Payment} from '../../containers/Payment';
import {OrderCardWithLogic} from '../../ui-kit/Order/OrderCardWithLogic';
import {CenteredLoader} from '../../ui-kit/Loader';
import {Label} from '../../ui-kit/Label';

export const UpgradeVipPage: React.FC<UpgradeVipPagePropsT> = ({
  dataVip,
  upgradeVip,
  orderInfo,
  paymentInfo,
  dataOrderDetails,
  order,
  setVip,
  vipState,
  paymentForm,
  orderCreatedAt,
  orderLoading,
  purchaseError,
  canUpgrade,
}) => {
  const skipPayment = Number(paymentForm.balance_numeric) <= 0;
  return (
    <PageWrapper>
      <ContentWrapper>
        {upgradeVip?.step === steps.two ? (
          <>
            <ReviewTitle>Review your updated order</ReviewTitle>
            <ReviewDescription>
              Please review your order to ensure all the details are correct. Visit protection will be added if you
              originally added it to your order.
            </ReviewDescription>
            <OrderWrapper>
              {/* <OrderCardWithLogic paymentInfo={paymentInfo} data={orderInfo} show={true} loading={orderLoading} /> */}
            </OrderWrapper>
            <ReviewContentContainer>
              {upgradeVip?.stripeAccountId !== undefined && (
                <Payment
                  orderCreatedAt={orderCreatedAt}
                  stripeAccount={upgradeVip?.stripeAccountId}
                  paymentSkipping={skipPayment}
                />
              )}
              <BillingInfo
                dataOrderDetails={dataOrderDetails}
                paymentForm={paymentForm}
                paymentSkipping={skipPayment}
              />
            </ReviewContentContainer>
          </>
        ) : (
          <>
            <TopWrapper>
              <TitleWrapper>
                <Title>Please select VIP access for your order below</Title>
              </TitleWrapper>
              <DesktopView>
                <OrderInfoComponent orderInfo={order} />
              </DesktopView>
            </TopWrapper>
            <OrderWrapper>
              <OrderCardWithLogic paymentInfo={paymentInfo} data={orderInfo} show={true} icon="plus" />
            </OrderWrapper>
            <ContentTitleSmall>{toUpper('Upgrade to VIP')}</ContentTitleSmall>
            {orderLoading || canUpgrade?.evLoading ? (
              <CenteredLoader variant={'light'} />
            ) : (
              <>
                {!canUpgrade?.available && !canUpgrade?.evLoading ? (
                  <Label type={'error'} label={'Upgrade to VIP is not available for this session.'} />
                ) : (
                  <>
                    <StyledChooseTicket style={{maxWidth: 500}}>
                      Make your night even more memorable with our VIP options!
                    </StyledChooseTicket>
                    <VipPassWrapper>
                      {dataVip?.map((item) => (
                        <UpgradeToVipCard data={item} key={item.id} setVip={setVip} vipState={vipState} />
                        // <VipElfGuideSwitcher data={item} key={item.id} setVip={setVip} vipState={vipState} />
                      ))}
                      {purchaseError && <Label type={'error'} label={purchaseError} />}
                    </VipPassWrapper>
                  </>
                )}
              </>
            )}
          </>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};
