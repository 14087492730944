/* eslint-disable prettier/prettier */
import styled, {css} from 'styled-components';
import {ButtonProps, Form, Input, InputProps, Tag} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {Button, PublicButton} from '../Button';
import {checkValidationTextStyles, formErrorTextStyles} from '../Typography/styles';
import {CheckCircleFilled, CloseCircleFilled} from '@ant-design/icons';
import {Tooltip} from '../Tooltip';
import {TooltipInputProps} from './types';
import { Media } from '../theme/breakpoints';

const {Item} = Form;

export const StyledFormItem = styled(Item)`
  && {
    position: relative;
    margin-bottom: 8px;
  }
  .ant-form-item-explain-error {
    width: 350px;
    background-color: ${({theme}) => theme.palette.primary.alarmColor};
    border-radius: 2px;
    padding: 0 ${({theme}) => theme.spacer._1};
    color: ${({theme}) => theme.palette.system.white};
    margin: ${({theme}) => theme.spacer._1} 0;
    ${formErrorTextStyles};
    ${Media.down.m} {
      width: 100%;
    }
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border: 2px solid ${({theme}) => theme.palette.state.red};
  }
  .ant-form-item-required {
    width: 100%;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-col.ant-form-item-label {
    padding-bottom: 4px;
  }
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border: 2px solid ${({theme}) => theme.palette.state.red};
  }
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
    .ant-select-selector {
    border: 2px solid ${({theme}) => theme.palette.state.red} !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
    cursor: pointer;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 3px;
  }
`;

const inputStyle = css`
  color: ${({theme}) => theme.palette.system.gray1};
  outline: none;
  padding: 5px 12px;
  box-sizing: border-box;
  border-radius: 5px;
  :active {
    border: 2px solid ${({theme}) => theme.palette.primary.darkGold};
  }
  :focus {
    border: 2px solid ${({theme}) => theme.palette.primary.darkGold};
    box-shadow: none;
  }
  &:hover {
    border-color: ${({theme}) => theme.palette.primary.darkGold};
  }
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${({theme}) => theme.palette.primary.darkGold};
  }
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    box-shadow: none;
  }
  &.ant-input[disabled] {
    background-color: ${({theme}) => theme.palette.system.gray4};
  }
`;

const getSize = (size: 'small' | 'middle' | 'large') => {
  switch (size) {
    case 'small': {
      return '28px';
    }
    case 'middle': {
      return '36px';
    }
    case 'large': {
      return '42px';
    }
  }
};

export const StyledInput = styled(Input)<InputProps & TooltipInputProps>`
  ${inputStyle}
  ${(props) => `height: ${getSize(props.size || 'middle')}`}
`;

export const StyledTextArea = styled(TextArea)`
  ${inputStyle}
`;

export const TooltipWrapper = styled.div`
  margin-left: 5px;
`;

export const FormButton = styled(PublicButton)<ButtonProps>`
  width: 100%;
`;

export const ValidationButton = styled(Button)`
  border-radius: 4px;
  height: 24px;
  padding: 4px 6px;
  ${checkValidationTextStyles};
  &.ant-btn-primary[disabled] {
    color: ${({theme}) => theme.palette.system.white};
    background-color: ${({theme}) => theme.palette.primary.darkGold};
    opacity: 0.5;
  }
`;

export const ValidationTag = styled(Tag)<{$valid?: boolean}>`
  ${checkValidationTextStyles};
  background-color: ${({theme, $valid}) => ($valid ? theme.palette.state.green : theme.palette.state.red)};
  border-color: ${({theme, $valid}) => ($valid ? theme.palette.state.green : theme.palette.state.red)};
  color: ${({theme}) => theme.palette.system.white};
  border-radius: 4px;
  height: 25px;
  padding: 3px 5px;
`;

export const StyledCheckCircle = styled(CheckCircleFilled)`
  color: ${({theme}) => theme.palette.system.white};
`;

export const StyledCloseCircle = styled(CloseCircleFilled)`
  color: ${({theme}) => theme.palette.system.white};
`;

export const TextAreaToolTip = styled(Tooltip)`
  position: absolute;
  right: 16px;
  top: 8px;
`;
