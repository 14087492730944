import styled from 'styled-components';
import {LogoEmblem} from '../../Logos/LogoEmblem';
import {ShoppingCartOutlined, DownOutlined} from '@ant-design/icons';
import {linearGoldGradient, undrlineGradient} from '../../theme/palette';
import {fontPrimaryLight, fontPrimaryBold} from '../../theme/font';
import {BoldText1, Caption} from '../../Typography';
import {ButtonPropsT} from '../../Button/types';
import {Button} from '../../Button';
import {Media} from '../../theme/breakpoints';
import {defaultTheme} from '../../theme/theme';
import {Link} from 'react-router-dom';
import {primaryTabTextStyles} from '../../Typography/styles';

// Header
export const HeaderLayout = styled.div`
  position: absolute;
  width: 100%;
  // padding-top: ${({theme}) => theme.spacer._3};
  padding-top: 40px;
  z-index: 10;
  max-width: 1000px;
  margin: 0 auto;
  transform: translateX(-50%);
  left: 50%;
`;

export const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({theme}) => theme.spacer._0};
  // padding: 0 ${defaultTheme.spacer._4};
  padding: 0 16px;

  ${Media.up.m} {
    padding: 0 ${defaultTheme.spacer._4} ${defaultTheme.spacer._5} ${defaultTheme.spacer._3};
  }

  ${Media.up.l} {
    padding: 0;
  }
`;

export const HeaderTopLeft = styled.div<{$centeredLogo?: boolean}>`
  display: flex;
  ${({$centeredLogo}) =>
    $centeredLogo &&
    `
    margin: 0 auto;
  `}
`;

export const HeaderTopRight = styled.div`
  display: flex;
`;

export const HeaderLogoEmblem = styled(LogoEmblem).attrs({variant: 'gold'})<{$showMobile?: boolean}>`
  display: block;
  width: 36px;
  height: 36px;
  // margin-right: ${({theme}) => theme.spacer._3};
  margin-right: 20px;
  ${Media.down.m} {
    display: ${({$showMobile}) => ($showMobile ? 'block' : 'none')};
    margin-right: 5px;
  }
`;

export const HeaderMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const HeaderMobileTitle = styled(BoldText1)`
  line-height: 22px;
  color: ${({theme}) => theme.palette.system.white};
`;

export const HeaderDesktopWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderDesktopTitle = styled(BoldText1)`
  line-height: 22px;
  font-size: 20px;
  color: ${({theme}) => theme.palette.secondary.brightGold};
`;

export const HeaderMobileCaption = styled(Caption)`
  color: ${({theme}) => theme.palette.system.lightGray};
`;

export const HeaderDesktopCaption = styled(Caption)`
  color: ${({theme}) => theme.palette.system.white};
`;

// Cart
export const CartWrapper = styled.div`
  position: relative;
  width: 34px;
  height: 34px;
`;

export const CartStyled = styled(ShoppingCartOutlined)`
  position: absolute;
  bottom: 0;
  left: 0;
  color: ${({theme}) => theme.palette.system.white};
  font-size: 24px;
`;

export const CartCountStyled = styled.div<{count?: number}>`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  ${({theme, count}) => (count ? linearGoldGradient : `background: ${theme.palette.system.gray2}`)};
  border: 1.5px solid ${({theme}) => theme.palette.primary.midnightBlue};
  border-radius: 20px;
  ${fontPrimaryLight};
  font-size: 12px;
  line-height: 1.33;
  color: ${({theme}) => theme.palette.system.darkBlack};
`;

// IconStar
export const IconStarWrapper = styled.div`
  width: 34px;
  height: 34px;
  margin-right: ${({theme}) => theme.spacer._1};
`;

// Progressbar
export const ProgressBarWrapper = styled.div`
  & .ant-progress-inner {
    background-color: ${({theme}) => theme.palette.state.darkGray};
  }

  & .ant-progress-bg {
    ${linearGoldGradient}
  }

  ${Media.down.s} {
    padding: 0 14px;
  }
`;

// DropDown
export const DropDownWrapper = styled.div`
  & .ant-dropdown-trigger {
    color: ${({theme}) => theme.palette.secondary.lightGold};
    ${fontPrimaryBold};
    font-size: 16px;
    line-height: 1.38;
  }
`;

export const DownOutlinedStyled = styled(DownOutlined)`
  font-size: 10px;
`;

export const DropDownLink = styled.a``;

export const LogoutButton = styled(Button)<ButtonPropsT>`
  background-color: transparent;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border: none;
  }
  color: ${({theme}) => theme.palette.system.white};
  ${Media.down.s} {
    span {
      display: none;
    }
  }
`;

export const LinkHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  ${Media.down.xs} {
    gap: 20px;
  }
  ${Media.down.xxs} {
    gap: 10px;
  }
`;

export const LinkHeader = styled(Link)<{isActive?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: ${({theme}) => theme.palette.system.white};
  border-bottom: 3px solid transparent;
  ${primaryTabTextStyles};
  padding-bottom: 10px;
  font-weight: 700;
  background-color: transparent;
  padding: 10px 0 6px 0;
  border-radius: 0px;
  ${Media.down.s} {
    font-size: 16px;
    font-weight: 600;
  }
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    ${linearGoldGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  ${({isActive}) =>
    isActive &&
    `border-bottom: 3px solid gold;${linearGoldGradient};-webkit-background-clip: text;-webkit-text-fill-color: transparent; ${undrlineGradient}`}
`;
