import {AuthQueryList} from '../constants/api';
import {getQuery, postQuery} from './hooks';
import {magicLinkLoginReq, sendMagicLinkReq} from './types/auth';

export const magicLinkLoginQuery = async (body: magicLinkLoginReq) => await postQuery(AuthQueryList.login).send(body);

export const userQuery = async () => await getQuery(AuthQueryList.user);

export const sendMagicLinkQuery = async (body: sendMagicLinkReq) =>
  await postQuery(AuthQueryList.sendMagicLink).send(body);

export const logoutQuery = async () => await postQuery(AuthQueryList.logout);
