import {Route} from '../helpers/Route';

export enum SearchKey {
  type = 'type',
}

export const route = {
  uikit: Route.of({path: '/ui-kit'}),
  magicLinkAccess: Route.of({path: '/magic-link-access'}),
  //private routes
  manageOrder: Route.of({path: '/manage-order'}),
  dashboard: Route.of({path: '/'}),
  orders: Route.of({path: '/orders'}),
  profile: Route.of({path: '/profile'}),
  referrals: Route.of({path: '/referrals'}),
  changeDate: Route.of<{id: string}>({path: '/change-date/:id'}),
  confirmOrder: Route.of<{id: string}>({path: '/confirm-order/:id'}),
  cancellationPage: Route.of<{id: string}>({path: '/cancel-visit/:id'}),
  cancelConfirmOrderPage: Route.of<{id: string}>({path: '/cancel-confirm-order/:id'}),
  addPurchase: Route.of<{id: string}>({path: '/add-ons-Purchase/:id'}),
  purchaseAdmission: Route.of<{id: string}>({path: '/purchase-tickets/:id'}),
  upgradeVipPage: Route.of<{id: string}>({path: '/upgrade-vip/:id'}),
  confirmOrderVipPage: Route.of<{id: string}>({path: '/confirm-order-upgrade-vip/:id'}),
  confirmOrderPurchaseOnePage: Route.of({path: '/confirm-order-purchase-one/:id'}),
  confirmPurchaseAdmission: Route.of<{id: string}>({path: '/confirm-order-purchase-tickets/:id'}),
  orderNotFoundPage: Route.of({path: '/order-not-found'}),
};

export const paths = {
  manageOrder: 'manage-order',
  dashboard: '',
  orders: 'orders',
  referrals: 'referrals',
  profile: 'profile',
  changeDate: 'change-date',
  confirmOrder: 'confirm-order',
  cancellationPage: 'cancel-visit',
  cancelConfirmOrderPage: 'cancel-confirm-order',
  addPurchase: 'add-ons-Purchase',
  upgradeVipPage: 'upgrade-vip',
  confirmOrderVipPage: 'confirm-order-upgrade-vip',
  confirmOrderPurchaseOnePage: 'confirm-order-purchase-one',
  ticketConfirmationPage: 'ticket-confirmation',
  orderNotFoundPage: 'order-not-found',
  admission: 'purchase-tickets',
};

export const excludePathsHeader = [paths.confirmOrder];
export const excludePathsFooter = [paths.manageOrder, paths.confirmOrder];

export const ticketingTermsConditionsLink = 'https://enchantchristmas.com/ticketing-terms/';
export const referralsTermsLink = 'https://enchantchristmas.com/referral-program-terms-conditions/';
export const privacyPolicyLink = 'https://enchantchristmas.com/privacy-policy/';
export const surveyLink = 'https://form.typeform.com/to/XNb1cnDp';
