import React from 'react';

import {StyledPublicButton, StyledCorporateButton} from './styles';
import {ButtonPropsT} from './types';

export const PublicButton: React.FC<ButtonPropsT> = ({children, icon, disabled, ...props}) => {
  return (
    <StyledPublicButton {...props} disabled={disabled}>
      {icon && <i>{icon}</i>}
      {children}
    </StyledPublicButton>
  );
};

export const Button: React.FC<ButtonPropsT> = ({children, icon, disabled, ...props}) => {
  return (
    <StyledCorporateButton {...props} disabled={disabled}>
      {icon && <i>{icon}</i>}
      {children}
    </StyledCorporateButton>
  );
};
