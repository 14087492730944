import React from 'react';
import {StyledTitleAddText} from '../PurchaseOne/styles';
import {TicketsList} from './styles';
import {TicketsPickerPropsT} from './types';
import {TicketCard} from './TicketCard';

export const TicketsPicker: React.FC<TicketsPickerPropsT> = ({tickets, changeTickets, valueState, vipInfo, hasOwl}) => {
  return (
    <>
      <StyledTitleAddText>GENERAL ADMISSION</StyledTitleAddText>
      <TicketsList>
        {tickets?.map((el) => (
          <TicketCard
            ticket={el}
            key={el?.id}
            changeTickets={changeTickets}
            valueState={valueState}
            vipInfo={vipInfo}
            hasOwl={hasOwl}
          />
        ))}
      </TicketsList>
    </>
  );
};
