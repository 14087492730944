import React from 'react';
import {DetailedCardConfirm} from '../../ui-kit/Order/DetailedCard';
import {AssignCard} from '../../ui-kit/Order/AssignCard';
import {SocialSharing} from './SocialSharing';
// import {usePurchase, usePurchaseSteps} from '../../hooks/purchase';
import {
  ConfirmOrderWrapper,
  LogoSilver,
  ConfirmOrderTitle,
  ConfirmOrderTitleDescr,
  AssignCardWrapper,
  DetailCardWrapper,
  DetailedCardFootNote,
  SocialSharingDesktop,
  SocialSharingMobile,
} from './styles';
import {CurrentProps} from '../../ui-kit/Order/types';
import {ConfirmPurchaseOrderT} from '../../types/purchase';
import {CenteredLoader} from '../../ui-kit/Loader';
import {Link} from 'react-router-dom';
import {toUpper} from '../../helpers/helpers';
import {LeftOutlined} from '@ant-design/icons';
// import {usePurchase} from '../../hooks/purchase';
// import { steps } from '../../types/helpers';

type ConfirmUpgradeVipPagePropsT = {
  dataOrderDetails: CurrentProps[];
  reservedDate?: string;
  countWaivers?: number;
  waiversLink?: string;
  viewTicketLink?: string;
  canManageWaivers?: boolean;
};

export const LogoSilverLink = () => {
  return (
    <Link to={'/'}>
      <LogoSilver />
    </Link>
  );
};
// const steps = usePurchaseSteps();
// const {payments} = usePurchase(steps);
export const ConfirmUpgradeVipPage: React.FC<ConfirmUpgradeVipPagePropsT> = ({
  dataOrderDetails,
  reservedDate,
  countWaivers,
  viewTicketLink,
  waiversLink,
  canManageWaivers,
}) => {
  return (
    <ConfirmOrderWrapper>
      <div style={{maxWidth: '681px'}}>
        <a href="/">
          <ConfirmOrderTitleDescr>
            <LeftOutlined />
            Back to My Account
          </ConfirmOrderTitleDescr>
        </a>
        <LogoSilverLink />
        <ConfirmOrderTitle>{toUpper('You have upgraded to VIP')}</ConfirmOrderTitle>
        <ConfirmOrderTitleDescr>Your updated QR code has been emailed to you.</ConfirmOrderTitleDescr>
        <SocialSharingMobile>
          <SocialSharing></SocialSharing>
        </SocialSharingMobile>
        {canManageWaivers && countWaivers && (
          <AssignCardWrapper>
            <AssignCard value={`${countWaivers}`} waiversLink={waiversLink} buttonTitle="Assign Waiver" />
          </AssignCardWrapper>
        )}
        <DetailCardWrapper>
          <DetailedCardConfirm viewTicketLink={viewTicketLink} data={dataOrderDetails} buttonTitle="VIEW TICKETS" />
        </DetailCardWrapper>
        <DetailedCardFootNote>Reserved on: {reservedDate}</DetailedCardFootNote>
      </div>
      <SocialSharingDesktop>
        <SocialSharing></SocialSharing>
      </SocialSharingDesktop>
    </ConfirmOrderWrapper>
  );
};

type ConfirmPurchaseOnePagePropsT = {
  dataOrder?: ConfirmPurchaseOrderT;
  reservedDate?: string;
  viewTicketLink?: string;
  loading?: boolean;
  countWaivers?: number;
  waiversLink?: string;
  canManageWaivers?: boolean;
};

export const ConfirmPurchaseOnePage: React.FC<ConfirmPurchaseOnePagePropsT> = ({
  dataOrder,
  reservedDate,
  loading,
  countWaivers,
  viewTicketLink,
  waiversLink,
  canManageWaivers,
}) => {
  return (
    <ConfirmOrderWrapper>
      <div style={{maxWidth: '681px'}}>
        <a href="/">
          <ConfirmOrderTitleDescr>
            <LeftOutlined />
            Back to My Account
          </ConfirmOrderTitleDescr>
        </a>
        <LogoSilverLink />
        <ConfirmOrderTitle>{toUpper('you have added additional tickets to your order')}</ConfirmOrderTitle>
        <ConfirmOrderTitleDescr>Your updated QR code and receipt have been emailed to you.</ConfirmOrderTitleDescr>
        <SocialSharingMobile>
          <SocialSharing></SocialSharing>
        </SocialSharingMobile>
        {canManageWaivers && countWaivers && (
          <AssignCardWrapper>
            <AssignCard value={`${countWaivers}`} waiversLink={waiversLink} buttonTitle="Assign Waiver" />
          </AssignCardWrapper>
        )}
        {loading ? (
          <CenteredLoader />
        ) : (
          <>
            <DetailCardWrapper>
              <DetailedCardConfirm viewTicketLink={viewTicketLink} data={dataOrder} buttonTitle="VIEW TICKETS" />
            </DetailCardWrapper>
            <DetailedCardFootNote>Reserved on: {reservedDate}</DetailedCardFootNote>
          </>
        )}
      </div>
      <SocialSharingDesktop>
        <SocialSharing></SocialSharing>
      </SocialSharingDesktop>
    </ConfirmOrderWrapper>
  );
};

type ConfirmOrderPagePropsT = {
  dataOrderDetails: CurrentProps[];
  reservedDate?: string;
  countWaivers?: number;
  waiversLink?: string;
  viewTicketLink?: string;
  canManageWaivers?: boolean;
};

export const ConfirmOrder: React.FC<ConfirmOrderPagePropsT> = ({
  dataOrderDetails,
  reservedDate,
  countWaivers,
  viewTicketLink,
  waiversLink,
  canManageWaivers,
}) => {
  return (
    <ConfirmOrderWrapper>
      <div style={{maxWidth: '681px'}}>
        <a href="/">
          <ConfirmOrderTitleDescr>
            <LeftOutlined />
            Back to My Account
          </ConfirmOrderTitleDescr>
        </a>
        <LogoSilverLink />
        <ConfirmOrderTitle>{toUpper('Your date has been updated.')}</ConfirmOrderTitle>
        <ConfirmOrderTitleDescr>Your updated QR code has been emailed to you.</ConfirmOrderTitleDescr>
        <SocialSharingMobile>
          <SocialSharing></SocialSharing>
        </SocialSharingMobile>
        {canManageWaivers && countWaivers && (
          <AssignCardWrapper>
            <AssignCard value={`${countWaivers}`} waiversLink={waiversLink} buttonTitle="Assign Waiver" />
          </AssignCardWrapper>
        )}
        <DetailCardWrapper>
          <DetailedCardConfirm viewTicketLink={viewTicketLink} data={dataOrderDetails} buttonTitle="VIEW TICKETS" />
        </DetailCardWrapper>
        <DetailedCardFootNote>Reserved on: {reservedDate}</DetailedCardFootNote>
      </div>
      <SocialSharingDesktop>
        <SocialSharing></SocialSharing>
      </SocialSharingDesktop>
    </ConfirmOrderWrapper>
  );
};
