import React from 'react';
import {
  OrderTitleWrapper,
  LocationTitle,
  OrderTitleDetailsWrapper,
  OrderTitleDetailsMain,
  BoldTitleDetails,
  OrderTitleDetails,
} from './styles';
import {OrderT} from '../../types/orders';
import {CenteredLoader} from '../../ui-kit/Loader';

type OrderInfoPropsT = {
  orderInfo?: OrderT;
  orderLoading?: boolean;
};

export const OrderInfoComponent: React.FC<OrderInfoPropsT> = ({orderInfo, orderLoading}) => {
  return (
    <OrderTitleWrapper>
      <LocationTitle>{orderInfo?.sellerName}</LocationTitle>
      <OrderTitleDetailsWrapper>
        <OrderTitleDetailsMain>Order Number</OrderTitleDetailsMain>
        <BoldTitleDetails>{orderInfo?.ticketureOrderNumber}</BoldTitleDetails>
      </OrderTitleDetailsWrapper>
      <OrderTitleDetails>{orderInfo?.sellerVenue}</OrderTitleDetails>
      {orderLoading && <CenteredLoader />}
    </OrderTitleWrapper>
  );
};
