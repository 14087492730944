import React from 'react';
import {ConfirmUpgradeVipPage} from '../../components/Confirms';
import {useConfirmOrderUpgradeVip} from '../../hooks/confirmOrderUpgradeVip';
import {toCreateOrderDetails} from '../../helpers/ticketConfirmation';
import {PageSpinLoader} from '../../ui-kit/Loader';
import {getTZdate} from '../../helpers/orders';
import {formats} from '../../helpers/helpers';
import {usePreloadOrders} from '../../hooks/preload';

export const ConfirmUpgradeVip = () => {
  const {order, loading, user} = useConfirmOrderUpgradeVip();
  const {dataOrderDetails, countWaivers, viewTicketLink} = toCreateOrderDetails(order, user);
  const reservedDate = order?.createdAt ? getTZdate(order?.createdAt, order).format(formats.reservedDate) : '';
  const canManageWaivers = order?.tickets?.some((el) => el?.ticketTypeName?.toLowerCase().includes('skating'));
  usePreloadOrders();
  if (loading) return <PageSpinLoader />;
  return (
    <ConfirmUpgradeVipPage
      dataOrderDetails={dataOrderDetails}
      reservedDate={reservedDate}
      countWaivers={countWaivers}
      viewTicketLink={viewTicketLink}
      waiversLink={order?.metadata?.manageWaiversLink}
      canManageWaivers={canManageWaivers}
    />
  );
};
