import React from 'react';
import {VipOptionButton} from '../../Button/LinkButton';
import {StyledSvgTop, StyledWrapper, StyledTitle, StyledSvgButtom, AdvImage} from './styles';
import {AdvContainer} from '../../../components/PurchaseOne/styles';
import {StyledCard} from '../CardFunnelWithLogic/styles';
import {FunnelCardProps, NameCard} from './types';
import VipCard from './VipCard/VipCard';
import {IconSvg} from '../../Icon';
import {getAddonImage} from '../../../helpers/purchaseAddOns';

export const UpgradeToVipCard: React.FC<FunnelCardProps> = ({data, vipState, setVip}) => {
  // console.log('data', data, 'vip', vipState, 'set', setVip);
  const onCounterClick = () => setVip(data.index);
  const isChosen = data?.index ? !!vipState?.[data.index] : false;
  return (
    <StyledCard golden_frame={vipState?.vipPass} vipCard={true}>
      <AdvContainer advImg="ic-hd">
        <AdvImage src={getAddonImage('VIP')} />
      </AdvContainer>
      <div style={{height: 180}}></div>
      <div style={{height: 'auto', display: 'flex', flexDirection: 'column', gap: 12}}>
        <StyledSvgTop>
          <IconSvg type={'sparkGold'} width={'34'} height={'34'} viewBox="0 0 34 34" />
        </StyledSvgTop>
        <StyledWrapper>
          <StyledTitle>{data.type}</StyledTitle>
        </StyledWrapper>
        {data.type === NameCard.vip && <VipCard data={data} />}
        <VipOptionButton add={isChosen} onClick={onCounterClick} />
        <StyledSvgButtom>
          <IconSvg type={'sparkGold'} width={'34'} height={'34'} viewBox="0 0 34 34" />
        </StyledSvgButtom>
      </div>
    </StyledCard>
  );
};

// const VipElfGuideSwitcher: React.FC<FunnelCardProps> = ({data, vipState, setVip}) => {
//   const onCounterClick = () => setVip(data.index);
//   const isChosen = data?.index ? !!vipState?.[data.index] : false;

//   return (
//     <StyledCard $isActive={data?.isActive}>
//       <StyledSvgTop>
//         <IconSvg type={'sparkGold'} width={'34'} height={'34'} viewBox="0 0 34 34" />
//       </StyledSvgTop>
//       <StyledWrapper>
//         <StyledTitle>{data.type}</StyledTitle>
//         <CounterButton onClick={onCounterClick}>
//           {!isChosen ? (
//             <PlusOutlined />
//           ) : (
//             <TrashButton>
//               <DeleteOutlined />
//             </TrashButton>
//           )}
//         </CounterButton>
//       </StyledWrapper>
//       {data.type === NameCard.vip && <VipCard data={data} />}
//       {data.type === NameCard.elf && <ElfCard data={data} />}
//       <StyledSvgButtom>
//         <IconSvg type={'sparkGold'} width={'34'} height={'34'} viewBox="0 0 34 34" />
//       </StyledSvgButtom>
//     </StyledCard>
//   );
// };

// export default VipElfGuideSwitcher;
