import styled from 'styled-components';
// import {LinkButton} from '../../ui-kit/Button/LinkButton';
import {Logo} from '../../ui-kit/Logos/Logo';
import {Media} from '../../ui-kit/theme/breakpoints';
import {Heading1, Text3, Heading6, Text2} from '../../ui-kit/Typography';
import Input from '../../ui-kit/Input';
import {StyledLinkButton, StyledLinkButtonView} from '../../ui-kit/Button/styles';

export const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${Media.down.l} {
    justify-content: flex-start;
    padding: 50px 0;
    height: calc(100vh + 20px);
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 670px;
  ${Media.down.l} {
    width: 100%;
  }
`;

export const HelpLink = styled.div`
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 10px;

  color: #ffffff;
  font-size: 22px;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 670px;
  margin-bottom: 16px;
  ${Media.down.l} {
    width: 100%;
    margin-bottom: 6px;
  }
`;

export const EnchantLogo = styled(Logo).attrs({variant: 'silver'})`
  width: 150px;
  height: 35px;
`;

export const TitleWrapper = styled.div`
  margin-top: 16px;
`;

export const ManageOrderTitle = styled(Heading1)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  text-transform: capitalize;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 18px;
  text-align: center;
  margin-top: -4px;
  ${Media.down.m} {
    font-size: 28px;
    line-height: 30px;
  }
`;

export const ManageOrderText = styled(Text3)`
  color: ${({theme}) => theme.palette.system.white};
  font-size: 22px;
  margin-top: -10px;
  ${Media.down.l} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const LinkButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  ${Media.down.l} {
    flex-direction: column;
    margin-top: 14px;
  }
`;

// export const LinkButtonPrimary = styled(LinkButton)`
//   width: 214px;
// `;

// export const LinkButtonSecondary = styled(LinkButton)`
//   width: 190px;
// `;

export const LinkButtonPrimary = styled(StyledLinkButton)`
  width: 214px;
  ${Media.down.l} {
    width: 100%;
    min-height: 44px;
    margin-bottom: 24px;
  }
`;

export const LinkButtonSecondary = styled(StyledLinkButtonView)`
  width: 190px;
  ${Media.down.l} {
    width: 100%;
    min-height: 44px;
    margin-bottom: 24px;
  }
`;
export const LinkButtonThird = styled(StyledLinkButtonView)`
  width: 214px;
  ${Media.down.l} {
    width: 100%;
    min-height: 44px;
    margin-bottom: 24px;
  }
`;

export const RetrieveContainer = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${Media.down.m} {
    width: 100%;
    padding: 0 20px;
    & > form {
      width: 100%;
    }
  }
`;

export const StyledRetrieveInput = styled(Input)`
  width: 436px;
  margin-right: 8px;
  height: 44px;
  ${Media.down.s} {
    width: 100%;
  }
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  max-width: 690px;
  color: ${({theme}) => theme.palette.system.white};
  text-align: center;
  ${Media.down.l} {
    width: 100%;
    padding: 0 10px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

export const SuccessTitle = styled(Heading6)`
  color: ${({theme}) => theme.palette.system.white};
`;

export const StyledTextNote = styled(Text2)`
  color: ${({theme}) => theme.palette.system.white};
`;

export const TextErrorWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: -25px;
  left: 0;
  background-color: ${({theme}) => theme.palette.primary.alarmColor};
  border-radius: 2px;
  padding: 0 ${({theme}) => theme.spacer._1};
  margin-bottom: ${({theme}) => theme.spacer._3};
  ${Media.down.s} {
    position: static;
  }
`;

export const StyledTextError = styled(Text2)`
  color: ${({theme}) => theme.palette.system.white};
`;

export const InlineFormWrapper = styled.div`
  display: flex;
  position: relative;
  ${Media.down.s} {
    flex-direction: column;
    width: 100%;
  }
`;

export const ItemFormWrapper = styled.div`
  display: block;
  ${Media.down.m} {
    .ant-btn {
      width: 100% !important;
    }
  }
`;

// Possibilities

export const PossibilitiesWrapper = styled.div`
  width: 100%;
  max-width: 595px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 24px;
  ${Media.down.m} {
    margin-top: 4px;
    max-width: 300px;
  }
`;

export const PossibilitiesTitle = styled(Heading6)`
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  color: #ffffff;
  ${Media.down.m} {
    font-size: 15px;
    margin-top: 6px;
  }
`;

export const PossibilitiesCards = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  ${Media.down.m} {
    gap: 4px;
  }
`;

export const PossibilitiesCard = styled.div`
  width: 48%;
  padding: 0 16px 0 16px;
  border-radius: 5px;
  border: 1px solid #1f4762;
  height: 56px;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  ${Media.down.m} {
    height: 40px;
    width: 100%;
  }
`;

export const PurchaseLinkOut = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PurchaseLink = styled.a`
  text-decoration: underline gray !important;
  color: #ffffff !important;
  font-weight: 300;
  font-size: 15px;
`;

export const PurchaseLinkText = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
`;
