import {SelectProps, InternalSelectProps} from 'antd/lib/select';
import React from 'react';
import {SelectTooltipWrapper, StyledSelectWrapper, PlaceholderWithTooltipText, PlaceholderContainer} from './styles';
import {Select as AntSelect} from 'antd';
import {Tooltip} from '../Tooltip';
import {PlaceholderWithTooltipProps} from './types';

const Select: React.FC<SelectProps & InternalSelectProps> = ({...props}) => (
  <StyledSelectWrapper>
    <AntSelect {...props} />
  </StyledSelectWrapper>
);

export const PlaceholderWithTooltip: React.FC<PlaceholderWithTooltipProps> = ({title, placeholder}) => {
  return (
    <PlaceholderContainer>
      <SelectTooltipWrapper>
        <Tooltip title={title} />
      </SelectTooltipWrapper>
      <PlaceholderWithTooltipText>{placeholder}</PlaceholderWithTooltipText>
    </PlaceholderContainer>
  );
};

export default Select;
