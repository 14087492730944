import {CartQueryList} from '../constants/api';
import {
  applyCodeReq,
  checkCodeReq,
  checkoutCartReq,
  createTicketUserReq,
  reserveTicketsInCartReq,
} from './types/payment';
import {postQuery, getQuery} from './hooks';
import {API_URL} from '../constants/env';

export const createCartQuery = async () => await postQuery(CartQueryList.createCart());

export const reserveTicketsCartQuery = async ({cartId, body}: reserveTicketsInCartReq) =>
  await postQuery(CartQueryList.reserveTicketsInCart(cartId)).send(body);

export const checkoutCart = async ({cartId, body}: checkoutCartReq) =>
  await postQuery(CartQueryList.checkoutCart(cartId)).send(body);

export const guestCheckoutCart = async ({cartId, body}: checkoutCartReq) =>
  await postQuery(CartQueryList.guestCheckoutCart(cartId)).send(body);

export const createTicketUserQuery = async ({body}: createTicketUserReq) =>
  await postQuery(CartQueryList.createTicketUser()).send(body);

export const checkPromoCode = async ({code, sellerId}: checkCodeReq) =>
  await getQuery(CartQueryList.checkCode(code, sellerId), API_URL);

export const applyCode = async ({sellerId, cartId, body}: applyCodeReq) =>
  await postQuery(CartQueryList.applyCode(cartId, sellerId)).send(body);
