import React from 'react';
import {PurchasePage} from '../../components/PurchaseOne';
import {usePurchase, usePurchaseSteps} from '../../hooks/purchase';
import {useHideIntercomLauncher} from '../../hooks/customerIO';

export const AddOnePurchase = () => {
  const steps = usePurchaseSteps();
  const {
    footerNav,
    order,
    setIsConfirm,
    orderItems,
    paymentInfo,
    addons,
    orderDetailsInfo,
    addonsState,
    addonsInfo,
    changeState,
    availableAddons,
    orderLoading,
    eventsLoading,
    orderCreatedAt,
    purchaseError,
    parkingItems,
    addAddonSession,
    ticketsAvSessions,
    avLoading,
    hiddenSesions,
  } = usePurchase(steps);
  useHideIntercomLauncher();
  return (
    <PurchasePage
      setIsConfirm={setIsConfirm}
      footerNav={footerNav}
      orderInfo={order}
      addons={addons}
      orderItems={orderItems}
      paymentInfo={paymentInfo}
      orderDetailsInfo={orderDetailsInfo}
      addonsState={addonsState}
      addonsInfo={addonsInfo}
      changeState={changeState}
      orderLoading={orderLoading}
      availableAddons={availableAddons}
      steps={steps}
      eventsLoading={eventsLoading}
      orderCreatedAt={orderCreatedAt}
      purchaseError={purchaseError}
      parkingItems={parkingItems}
      addAddonSession={addAddonSession}
      ticketsAvSessions={ticketsAvSessions}
      avLoading={avLoading}
      hiddenSesions={hiddenSesions}
    />
  );
};
