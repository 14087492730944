import React from 'react';
import {TLogoCorporate} from './types';
import {StyledLogoCorporateGold, StyledLogoCorporateWhite, StyledLogoCorporateBlack} from './styles';

export const LogoCorporate: React.FC<TLogoCorporate> = ({variant, ...props}): JSX.Element => {
  {
    switch (variant) {
      case 'gold':
        return <StyledLogoCorporateGold {...props} />;
      case 'white':
        return <StyledLogoCorporateWhite {...props} />;
      case 'black':
        return <StyledLogoCorporateBlack {...props} />;
      default:
        return <StyledLogoCorporateGold {...props} />;
    }
  }
};
