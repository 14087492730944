import React, {useEffect, useState} from 'react';

import {
  FooterScrollLayout,
  PublicButtonStyled,
  FooterScrollTitle,
  FooterScrollBody,
  FooterLayout,
  FooterBody,
  FooterLeft,
  FooterLeftMobileTitle,
  FooterLeftMobileValue,
  FooterLeftMobileWrapper,
  FooterTicketConfirmationWrapper,
} from './styles';
import {
  IFooterScrollNavigationProps,
  IFooterNavigationProps,
  FooterLeftMobileProps,
  FooterTicketConfirmationPropsT,
} from './types';
import {MainWrapper} from '../styles';
import {TicketConfirmationFooter} from '../../../components/TicketConfirmation/TicketConfirmationFooter';
import {FooterLogoLoader} from '../../Loader';
import debounce from 'lodash.debounce';
export const FooterNavigation: React.FC<IFooterNavigationProps> = ({
  buttonType,
  buttonTitle,
  onClick,
  component,
  conditionButton,
  colorLineTop = 'gold',
}) => {
  const [loading, setLoading] = useState(false);

  const onButtonClick = async () => {
    if (!onClick) return;
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return (
    <FooterLayout colorLineTop={colorLineTop}>
      <MainWrapper>
        <FooterBody>
          {!loading ? (
            <>
              <FooterLeft>{component}</FooterLeft>
              <PublicButtonStyled variant={buttonType} onClick={onButtonClick} disabled={conditionButton}>
                {buttonTitle}
              </PublicButtonStyled>
            </>
          ) : (
            <FooterLogoLoader />
          )}
        </FooterBody>
      </MainWrapper>
    </FooterLayout>
  );
};

export const FooterScrollNavigation: React.FC<IFooterScrollNavigationProps> = ({title, hideOnBottom}) => {
  const [scrolled, setScrolled] = useState(false);
  const scrollToBottom = () => {
    window.scrollTo({
      top: window.screen.height,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    if (!hideOnBottom) return;
    const listener = () => {
      if (window.innerHeight + window.pageYOffset + 50 >= document.body.scrollHeight) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    const debouncedCb = debounce(listener, 300);

    window.addEventListener('scroll', debouncedCb);
    return () => window.removeEventListener('scroll', debouncedCb);
  }, [hideOnBottom]);
  if (scrolled && hideOnBottom) return null;
  return (
    <FooterScrollLayout>
      <MainWrapper>
        <FooterScrollBody>
          <FooterScrollTitle onClick={scrollToBottom}>{title}</FooterScrollTitle>
        </FooterScrollBody>
      </MainWrapper>
    </FooterScrollLayout>
  );
};

export const FooterLeftMobileComponent: React.FC<FooterLeftMobileProps> = ({title, value}) => {
  return (
    <FooterLeftMobileWrapper>
      <FooterLeftMobileTitle>{title}</FooterLeftMobileTitle>
      <FooterLeftMobileValue>{value}</FooterLeftMobileValue>
    </FooterLeftMobileWrapper>
  );
};

export const FooterTicketConfirmation: React.FC<FooterTicketConfirmationPropsT> = ({reservedDate}) => {
  return (
    <FooterTicketConfirmationWrapper>
      <TicketConfirmationFooter reservedDate={reservedDate} />
    </FooterTicketConfirmationWrapper>
  );
};
