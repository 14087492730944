import {atom} from 'recoil';

export type commonOrderInfoType = {
  orderNumber?: string;
  sellerName?: string;
  orderId?: string;
};

export const commonOrderInfo = atom<commonOrderInfoType | undefined>({
  key: 'commonOrderInfo',
  default: undefined,
});
