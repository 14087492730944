import {InfoCircleOutlined} from '@ant-design/icons';
import styled, {css} from 'styled-components';
import {Media} from '../theme/breakpoints';
import {labelTextStyles} from '../Typography/styles';
import {StyledLabelPropsT} from './types';
import {vipGradient} from '../theme/palette';

const labelPrimaryStyles = css`
  background-color: ${({theme}) => theme.palette.state.green};
`;

const labelSecondaryStyles = css`
  span {
    font-weight: 700;
  }
  background-color: ${({theme}) => theme.palette.state.orange};
`;

const labelTertiaryStyles = css`
  background-color: ${({theme}) => theme.palette.state.gray};
`;

const labelQuaternaryStyles = css`
  background-color: ${({theme}) => theme.palette.state.darkGray};
`;

const labelErrorStyles = css`
  background-color: ${({theme}) => theme.palette.state.red};
`;

const labelGoldenStyles = css`
  ${vipGradient}
  color: ${({theme}) => theme.palette.system.darkBlack} !important;
  font-size: 12px;
`;

const labelTimeStyles = css`
  background-color: ${({theme}) => theme.palette.state.red};
  display: flex;
  justify-content: space-between;
  align-items: center;
  .anticon {
    font-size: 20px;
  }
  margin-top: 4px;
  height: 48px;
  ${Media.down.m} {
    height: 38px;
  }
`;

export const StyledLabel = styled.span<StyledLabelPropsT>`
  ${labelTextStyles};
  color: ${({theme}) => theme.palette.system.white} !important;
  ${({variant}) => {
    switch (variant) {
      case 'primary':
        return labelPrimaryStyles;
      case 'secondary':
        return labelSecondaryStyles;
      case 'tertiary':
        return labelTertiaryStyles;
      case 'quaternary':
        return labelQuaternaryStyles;
      case 'error':
        return labelErrorStyles;
      case 'time':
        return labelTimeStyles;
      case 'gold':
        return labelGoldenStyles;
      default:
        return labelPrimaryStyles;
    }
  }};
  padding: 4px 8px;
  display: inline-flex;
  gap: 5px;
  border-radius: 4px;
  align-items: center;
  ${({isTitle}) =>
    isTitle &&
    css`
      ${Media.down.m} {
        margin-bottom: 0;
        margin-top: 16px;
      }
      margin-bottom: 16px;
      align-items: flex-start;
    `};
`;

export const InfoIcon = styled(InfoCircleOutlined)``;
