import React from 'react';
import {useSetRecoilState} from 'recoil';
import {selectedOptState} from '../../states/general';
import {LinkWrapper, OptionCard, OptionCardWrapper, OptLogo, OptTitle, OptTitleLine} from './styles';
import ticket from '../../ui-kit/assets/icons/ticket.png';
import gift from '../../ui-kit/assets/icons/gift.png';
import profile from '../../ui-kit/assets/icons/profile.png';
import help from '../../ui-kit/assets/icons/help.png';
import chat from '../../ui-kit/assets/icons/chat.png';
import star from '../../ui-kit/assets/icons/star.png';
import {route, surveyLink} from '../../constants/routes';
import {Link} from 'react-router-dom';
import {IconSvg} from '../../ui-kit/Icon';

type HomeOptT = {
  logo: any;
  title: string;
  description: string;
  index?: number;
  externalLink?: string;
  link?: string;
  id?: string;
  ghost?: boolean;
};
// const Empty: React.FC<PropsWithChildren> = ({children}) => <>{children}</>;

const HomeOption: React.FC<HomeOptT> = ({logo, title, externalLink, link, id, ghost}) => {
  const Wrapper = link ? Link : externalLink ? LinkWrapper : Link;
  const setSelectedOptState = useSetRecoilState(selectedOptState);
  const onClick = () => {
    if (externalLink || link) return;
    setSelectedOptState(title);
  };
  const reactLinkProps = link ? {to: link || '#'} : {to: '#'};
  const externalLinkProps = externalLink ? {href: externalLink, target: '_blank'} : {};
  return (
    <Wrapper {...reactLinkProps} {...externalLinkProps} id={id}>
      <OptionCard onClick={onClick} $ghost={ghost}>
        <OptTitleLine>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <OptLogo src={logo} alt="Logo" />
            <OptTitle>{title}</OptTitle>
          </div>
          <IconSvg type="shortArrow" fill="black" />
        </OptTitleLine>
        {/* <OptDescription>{description}</OptDescription> */}
      </OptionCard>
    </Wrapper>
  );
};

export const HomeOptions = () => {
  const options: HomeOptT[] = [
    {
      logo: ticket,
      title: 'Manage My Orders',
      description: '1 upcoming event at Enchant Washington, DC',
      link: route.orders.path,
    },
    {
      logo: gift,
      title: 'Gift $10, Earn $5',
      description: '$2 available in credits, share link to earn more',
      link: route.referrals.path,
    },
    {
      logo: profile,
      title: 'Update My Profile',
      description: 'Keep your profile and preferences up to date.',
      link: route.profile.path,
    },
    {
      logo: help,
      title: 'Read FAQs',
      description: 'Connect with our AI bot and guest services team.',
      externalLink: 'https://help.enchantchristmas.com/en/',
      //id: 'launch_intercom',
    },
    {
      logo: chat,
      title: 'Chat with Guest Services',
      description: 'Connect with our AI bot and guest services team.',
      // externalLink: 'https://help.enchantchristmas.com/en/',
      id: 'launch_intercom',
    },
    {
      logo: star,
      title: 'My Account Feedback Survey',
      description: '',
      externalLink: surveyLink,
      ghost: true,
      id: 'survey',
    },
  ];
  return (
    <OptionCardWrapper>
      {options.map((option, index) => (
        <HomeOption
          key={index}
          logo={option.logo}
          title={option.title}
          description={option.description}
          externalLink={option.externalLink}
          link={option.link}
          id={option.id}
          ghost={option.ghost}
        />
      ))}
    </OptionCardWrapper>
  );
};
