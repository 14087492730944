import React from 'react';
import {StyledLogoSilver, StyledLogoHallmarkSilver, StyledLogoHallmarkBlack, StyledLogoHallmarkWhite} from './styles';
import {TLogo} from './types';

export const Logo: React.FC<TLogo> = ({variant = 'silver', ...props}) => {
  {
    switch (variant) {
      case 'silver':
        return <StyledLogoSilver {...props} />;
      case 'hallmarkSilver':
        return <StyledLogoHallmarkSilver {...props} />;
      case 'hallmarkBlack':
        return <StyledLogoHallmarkBlack {...props} />;
      case 'hallmarkWhite':
        return <StyledLogoHallmarkWhite {...props} />;
      default:
        return <StyledLogoSilver {...props} />;
    }
  }
};
