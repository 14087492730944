import {useGetAvailableEvents} from './events';
import {EventSessionT, EventsT, EventTemplateT} from '../types/events';
import {useEffect, useState} from 'react';
import {purchaseStepsStateT, useCartPurchase} from './purchase';
import {
  addVipTicketsForGA,
  calcAdmissionPayment,
  createDataOrderDetails,
  getExitingOrderInfoGA,
  getGATemplates,
  getGATypes,
  getVipPassTemplates,
  getVipPassTypes,
  initialGAvaluesState,
  toCreatePurchaseGATickets,
  validateCapacity,
} from '../helpers/admission';
import {SetterOrUpdater, useRecoilState, useSetRecoilState, useRecoilValue} from 'recoil';
import {purchaseAdmission, purchaseAdmissionStateT} from '../states/tickets';
import {gaValuesStateT, PurchaseAdmissionStateT, PurchaseResT} from '../types/purchase';
import {getTZdate, isGA, isGACat} from '../helpers/orders';
import {ButtonType} from '../ui-kit/Button/types';
import {adult_key_l, child_key_l, formats, scrollUp, vip_key_l} from '../helpers/helpers';
import {getEventSessionsForOneDate} from '../queries/eventsGroup';
import {OrderT} from '../types/orders';
import {setPurchaseAddOns} from '../queries/orderGroup';
import {getPaymentFormInfo} from '../helpers/upgradeToVip';
import {checkoutState} from '../states/order';
import {creditsAppliedState} from '../states/purchase';
import {route} from '../constants/routes';
import {useNavigate, useParams} from 'react-router-dom';
import {onRequestErrorT, useOnRequestsError, useSegmentPurchaseGA} from './customerIO';
import {checkIsNightOwl} from '../helpers/changeDate';
import {OrdersQueryList} from '../constants/api';
import {useViewer} from './auth';
import {useGetOrderWithDetails} from './orders';

type useAdmissionStatePropsT = {
  steps: purchaseStepsStateT;
  admission?: purchaseAdmissionStateT;
  setAdmission: SetterOrUpdater<purchaseAdmissionStateT | undefined>;
  valuesState?: gaValuesStateT;
  purchase: () => Promise<boolean>;
  redirect?: () => void;
  checkout: (source?: string, creditsRedeemed?: boolean) => Promise<boolean>;
  cartId?: string;
  balance?: number;
  cartedAmount?: number;
};
export const useAdmissionState = ({
  setAdmission,
  purchase,
  valuesState,
  steps,
  checkout,
  redirect,
  cartId,
  balance,
  cartedAmount,
}: useAdmissionStatePropsT) => {
  const setCheckout = useSetRecoilState(checkoutState);
  const creditsApplied = useRecoilValue(creditsAppliedState);
  const setNextClick = (func: () => void) => setAdmission((prev) => ({...prev, onNextClick: func}));
  const setConditionButton = (b: boolean) => setAdmission((prev) => ({...prev, conditionButton: b}));
  const setShowNext = (b: boolean) => setAdmission((prev) => ({...prev, showButton: b}));
  const setProgress = (n: number) => setAdmission((prev) => ({...prev, progress: n}));
  const setSubTotal = (n: number) => setAdmission((prev) => ({...prev, subtotal: n}));
  const setButton = (title: string, type?: ButtonType) =>
    setAdmission((prev) => ({...prev, buttonTitle: title, buttonType: type}));
  const setCartId = (val?: string) => setAdmission((prev) => ({...prev, cartId: val}));
  const selectedCount = Object.values(valuesState || {}).reduce((p, n) => p + n.count, 0);
  useEffect(() => {
    if (steps.step === '1' && !selectedCount) {
      setShowNext(false);
      setConditionButton(true);
      setProgress(15);
      setButton('Next');
      setCheckout(undefined);
    }
    if (steps.step === '1' && selectedCount) {
      setShowNext(true);
      setConditionButton(false);
      setProgress(45);
    }
    if (steps.step === '2') {
      setProgress(75);
    }
  }, [!!selectedCount, steps.step]);

  const goNextStep1 = async () => {
    const success = await purchase();
    if (success) {
      steps.setStep2();
      setButton('Confirm Purchase');
    }
  };
  const goNextStep2 = async (source?: string, creditsRedeemed?: boolean) => {
    const success = await checkout(source, creditsRedeemed);
    success && redirect?.();
  };

  useEffect(() => {
    if (steps.step === '1') {
      setNextClick(goNextStep1);
    }
    if (steps.step === '2' && cartId) {
      setNextClick(goNextStep2);
      setCartId(cartId);
    }
    if (steps.step === '2' && !cartId && !selectedCount) {
      steps.setStep1();
    }
  }, [steps.step, selectedCount, cartId, creditsApplied]);
  useEffect(() => {
    setSubTotal(balance || 0);
  }, [balance]);
  useEffect(() => {
    if (cartId) return;
    setSubTotal(cartedAmount || 0);
  }, [cartedAmount]);
};

export const useAdmission = (steps: purchaseStepsStateT) => {
  const navigate = useNavigate();
  const viewer = useViewer();
  const {id} = useParams();
  const creditsApplied = useRecoilValue(creditsAppliedState);
  const [admission, setAdmission] = useRecoilState(purchaseAdmission);
  const [purchaseRes, setPurchaseRes] = useState<PurchaseResT>();
  const {order, loading: orderLoading} = useGetOrderWithDetails(id);
  const {events, loading: eventsLoading} = useGetAvailableEvents(order?.sellerId);
  const {onAddonComplete, onAddonReview, toAddonPageClick} = useSegmentPurchaseGA(order);
  const {
    tickets,
    loading: ticketsLoading,
    changeTickets,
    valuesState,
    vipTickets,
    hasVip,
  } = getGATicketsToPurchase(events, order);
  const vipTicketsDetails = addVipTicketsForGA(vipTickets, valuesState);
  const paymentInfo = calcAdmissionPayment(valuesState, vipTicketsDetails, hasVip);
  const orderInfo = getExitingOrderInfoGA({gaState: valuesState, order, events, hasVip, vipTicketsDetails});
  const paymentForm = getPaymentFormInfo(order, purchaseRes, events);
  const hasOwl = !!checkIsNightOwl(order, events);
  const onSuccessPurchase = (res: any) => {
    setPurchaseRes({...res, created: new Date()});
    onAddonReview(res);
  };
  const {onRequestError} = useOnRequestsError({order});
  const {handlePurchase, error} = usePurchaseAdmission(
    order?.id,
    valuesState,
    tickets,
    vipTickets,
    onSuccessPurchase,
    onRequestError,
  );

  useEffect(() => {
    if (order?.id)
      setAdmission((prev) => ({...prev, orderNumber: order?.ticketureOrderNumber, sellerName: order?.sellerName}));
  }, [order?.id]);

  const redirect = () => order?.id && navigate(route.confirmOrderPurchaseOnePage.get({id: order?.id}));
  const checkoutSuccess = () => onAddonComplete(purchaseRes);
  const cartId = purchaseRes?.cart?._data?.[0]?.id || '';
  const identityId = purchaseRes?.cart?._data?.[0]?.identity_id || '';
  const {cartPurchase: checkout} = useCartPurchase(
    cartId,
    identityId,
    order?.sellerId,
    checkoutSuccess,
    onRequestError,
    false,
    creditsApplied,
  );
  useAdmissionState({
    steps,
    admission,
    setAdmission,
    valuesState,
    purchase: handlePurchase,
    checkout,
    cartId,
    cartedAmount: paymentInfo.payment,
    balance: paymentForm?.balance_numeric,
    redirect,
  });
  const dataOrderDetails = createDataOrderDetails(order, purchaseRes, viewer);

  useEffect(() => {
    order?.id && toAddonPageClick();
  }, [order?.id]);

  return {
    order,
    orderLoading,
    eventsLoading,
    ticketsLoading,
    tickets,
    changeTickets,
    valuesState,
    paymentInfo,
    orderInfo,
    paymentForm,
    orderCreatedAt: purchaseRes?.created,
    dataOrderDetails,
    vipInfo: {
      hasVip,
      childSoldOut: vipTickets?.find((el) => el?.name.toLowerCase().includes(child_key_l))?.isSoldOut,
      adultSoldOut: vipTickets?.find((el) => el?.name.toLowerCase().includes(adult_key_l))?.isSoldOut,
    },
    error,
    hasOwl,
  };
};

export const getGATicketsToPurchase = (events?: EventsT, order?: OrderT) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [hasVip, setHasVip] = useState<boolean>(false);
  const [tickets, setTickets] = useState<PurchaseAdmissionStateT | undefined>();
  const [vipTickets, setVipTickets] = useState<PurchaseAdmissionStateT | undefined>();
  const [valuesState, setValuesState] = useState<gaValuesStateT>();
  const eTemplates = events?.event_template?._data;
  const eTypes = events?.ticket_type?._data;
  const gaGroup = events?.ticket_group?._data.find((el) => isGACat(el?.name));
  const vipGroup = events?.ticket_group?._data.find((el) => el?.name?.toLowerCase().includes(vip_key_l));
  const changeTickets = (value?: number, typeId?: string) => {
    if (!typeId || value === undefined) return;
    setValuesState((prev) => ({
      ...prev,
      [typeId]: {...prev?.[typeId], price: Number(prev?.[typeId]?.price) || 0, count: value},
    }));
  };
  const toggleDisable = (b: boolean, types?: string[]) => {
    if (!valuesState) return;
    const newObj: gaValuesStateT = {};
    Object.keys(valuesState).forEach((key) => {
      const type = valuesState[key].type;
      if (!types || !type) return (newObj[key] = {...valuesState[key], disabled: b});

      newObj[key] = types.includes(type) ? {...valuesState[key], disabled: b} : {...valuesState[key]};
    });
    setValuesState(newObj);
  };

  useEffect(() => {
    //initial
    if (!eTemplates?.length || !eTypes?.length || !order) return;
    //check vip
    const vipTemplate = getVipPassTemplates(eTemplates);
    const isVipPass = order?.tickets?.some((el) => el?.ticketGroupName.toLowerCase().includes(vip_key_l));
    setHasVip(!!isVipPass);
    const vipTypes = getVipPassTypes(eTypes, vipGroup);
    const vipTicketsByType: PurchaseAdmissionStateT = vipTypes.map((el) => ({
      ...el,
      eventTemplateId: vipTemplate?.id,
    }));
    if (!!isVipPass) setVipTickets(vipTicketsByType);

    //check ga
    const gaTicket = order?.tickets?.find((el) => isGACat(el?.ticketGroupName));
    const startTime = getTZdate(gaTicket?.eventStartDate, order).format(formats.timeStart);
    const endTime = getTZdate(gaTicket?.eventEndDate, order).format(formats.timeEnd);
    const arrivalTime = `(${startTime} - ${endTime})`;

    const gaTemplate = getGATemplates(eTemplates);
    const types = getGATypes(eTypes, gaGroup);
    const ticketsByType: PurchaseAdmissionStateT = types.map((el) => ({
      ...el,
      eventTemplateId: gaTemplate?.id,
    }));
    setValuesState(initialGAvaluesState(types, arrivalTime));
    setTickets(ticketsByType);
  }, [eTemplates?.length, !!order]);

  useEffect(() => {
    //get available info
    if (!eTemplates) return;
    const gaTemplate = getGATemplates(eTemplates);
    const vipTemplate = getVipPassTemplates(eTemplates);
    if (!tickets?.length || !gaTemplate || !order) return;
    getAvalableGAevents(order, gaTemplate, setLoading, setTickets, hasVip, setVipTickets, vipTemplate);
  }, [!!tickets?.length]);

  const selectedCount = Object.values(valuesState || {}).reduce((p, n) => p + n.count, 0);
  useEffect(() => {
    //disable on max capacity/limit
    if (!tickets?.length || !valuesState) return;
    validateCapacity(selectedCount, tickets, hasVip, toggleDisable, valuesState, vipTickets);
  }, [selectedCount]);

  return {tickets, loading, valuesState, changeTickets, vipTickets, hasVip};
};

export const getAvalableGAevents = (
  order: OrderT,
  gaTemplate: EventTemplateT,
  setLoading: (v: boolean) => void,
  setTickets: React.Dispatch<React.SetStateAction<PurchaseAdmissionStateT | undefined>>,
  hasVip: boolean,
  setVipTickets: React.Dispatch<React.SetStateAction<PurchaseAdmissionStateT | undefined>>,
  vipTemplate?: EventTemplateT,
) => {
  const ticketCategoryGA = order?.tickets?.find((ticket) => isGA(ticket));

  const onDate = getTZdate(ticketCategoryGA?.eventStartDate, order).format(formats.onDate);
  const startDate = getTZdate(ticketCategoryGA?.eventStartDate, order).format(formats.timeStart);

  const checkAvailable = async () => {
    if (!gaTemplate.id) return setLoading(false);
    setLoading(true);
    //check ga
    const res = await getEventSessionsForOneDate(gaTemplate.id, onDate);
    const byDate = res?.body?.event_session?._data?.find(
      (sess: EventSessionT) => getTZdate(sess.start_datetime, order).format(formats.timeStart) === startDate,
    );
    setTickets((prev) =>
      prev?.map((el) => ({
        ...el,
        isSoldOut: byDate?.sold_out,
        sold_quantity: byDate?.sold_quantity || 0,
        capacity: Number(byDate?.capacity) || 0,
        eventSessionId: byDate?.id,
      })),
    );

    //check vip
    if (hasVip && vipTemplate?.id) {
      const res = await getEventSessionsForOneDate(vipTemplate.id, onDate);
      const byDate = res?.body?.event_session?._data?.find(
        (sess: EventSessionT) => getTZdate(sess.start_datetime, order).format(formats.timeStart) === startDate,
      );
      setVipTickets((prev) =>
        prev?.map((el) => ({
          ...el,
          isSoldOut: byDate?.sold_out,
          sold_quantity: byDate?.sold_quantity || 0,
          capacity: Number(byDate?.capacity) || 0,
          eventSessionId: byDate?.id,
        })),
      );
    }
    setLoading(false);
  };

  try {
    checkAvailable();
  } catch (e) {
    setLoading(false);
  }
};

export const usePurchaseAdmission = (
  orderId?: string,
  gaState?: gaValuesStateT,
  tickets?: PurchaseAdmissionStateT,
  vipTickets?: PurchaseAdmissionStateT,
  onSuccess?: (value: any) => void,
  onRequestError?: onRequestErrorT,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const handlePurchase = async () => {
    if (!orderId || !gaState || !tickets?.length) return false;
    setLoading(true);

    const ticketsToPurchase = toCreatePurchaseGATickets(tickets, gaState, vipTickets);
    if (!ticketsToPurchase) return false;
    const body = {tickets: ticketsToPurchase};
    try {
      const res = await setPurchaseAddOns({orderId, body});
      setLoading(false);
      scrollUp();
      res?.body && onSuccess?.(res?.body);
      return true;
    } catch (e) {
      onRequestError?.(JSON.stringify(e), OrdersQueryList.purchaseAddOns(orderId), body);
      setError(
        'Sorry! Something unexpected has happened on our end. Our engineers have been notified. Please try again later.',
      );
      setLoading(false);
      return false;
    }
  };
  return {handlePurchase, loading, error};
};
