import styled from 'styled-components';
import {Heading5, Heading6, SmallBold, Text2} from '../../ui-kit/Typography';
import {TextBody1, TextBody3, TextSmallBold} from '../../ui-kit/Typography/styles';
import {Button} from '../../ui-kit/Button';
import {ButtonPropsT} from '../../ui-kit/Button/types';
import {Form} from 'antd';
import {Media} from '../../ui-kit/theme/breakpoints';

export const PaymentFormTitleWrapper = styled.div`
  padding-top: ${({theme}) => theme.spacer._2};
  padding-bottom: ${({theme}) => theme.spacer._2};
`;

export const PaymentFormTitle = styled(SmallBold)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
`;

export const FormCardTitle = styled(TextBody3)`
  font-weight: 700;
  margin-bottom: 16px;
  font-size: 16px;
  display: block;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & {
    .react-tel-input .form-control {
      width: 100%;
    }
  }
`;

export const InputsColumnLeft = styled(InputsContainer)`
  padding-right: ${({theme}) => theme.spacer._3};
`;

export const InputsColumnRight = styled(InputsContainer)`
  padding-left: ${({theme}) => theme.spacer._3};
`;

export const PaymentButtonWrapperLeft = styled(InputsContainer)`
  padding-right: ${({theme}) => theme.spacer._1};
`;

export const PaymentButtonWrapperRight = styled(InputsContainer)`
  padding-left: ${({theme}) => theme.spacer._1};
`;

export const PaymentButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({theme}) => theme.spacer._3} 0;
  border-bottom: 1px solid ${({theme}) => theme.palette.secondary.nightBlue};
`;

export const PaymentButton = styled(Button)<ButtonPropsT>`
  width: 100%;
  color: ${({theme}) => theme.palette.system.white};
  background: ${({theme}) => theme.palette.primary.darkBlack};
  border: 1px solid ${({theme}) => theme.palette.system.white};
  border-radius: 8px;
  &:hover,
  &:active,
  &:focus {
    color: ${({theme}) => theme.palette.system.white};
    background: ${({theme}) => theme.palette.primary.darkBlack};
    border: 1px solid ${({theme}) => theme.palette.system.white};
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: ${({theme}) => theme.spacer._2};
`;

export const CheckboxText = styled(Text2)`
  color: ${({theme}) => theme.palette.system.darkBlack};
`;

export const CheckboxTextUnderlined = styled.a`
  ${TextSmallBold};
  color: ${({theme}) => theme.palette.system.darkBlack};
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    color: ${({theme}) => theme.palette.system.white};
    text-decoration: underline;
  }
`;

export const CodeForm = styled(Form)``;

export const ContentTitle = styled(Heading5)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
`;

export const ContentTitleSmall = styled(Heading6)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
`;

export const ContentTitleDesktopWrapper = styled.div`
  display: block;
  ${Media.down.l} {
    display: none;
  }
`;
export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 54%;
  padding-left: 67px;
  ${Media.down.xl} {
    padding-left: 33px;
  }
  ${Media.down.l} {
    padding-left: 67px;
    width: 100%;
    padding: 0;
    border: none;
  }
`;

export const ContentTitleMobileWrapper = styled.div`
  display: none;
  ${Media.down.l} {
    display: block;
    padding-top: ${({theme}) => theme.spacer._5};
    margin-top: ${({theme}) => theme.spacer._1};
  }
`;

export const FormElementsWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  ${Media.down.xl} {
    padding-right: 30px;
  }
  ${Media.down.l} {
    padding-right: 30px;
    width: 100%;
    padding: 0;
  }
`;

export const CardContainer = styled.div`
  background-color: ${({theme}) => theme.palette.system.white};
  padding: 15px;
  max-height: 47px;
  overflow-y: hidden;
  //padding-right: 0;
  //border-top-left-radius: 4px;
  //border-bottom-left-radius: 4px;
  //width: 85%;
`;

export const CardLine = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({theme}) => theme.palette.system.white};
  border-radius: 4px;
`;

export const CardErrorLine = styled.div`
  padding: 10px 0;
  color: ${({theme}) => theme.palette.secondary.roseRed};
`;

export const TimeLeftRow = styled.div`
  & > span {
    width: 100%;
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 16px;
    padding: 14px;
  }
  ${Media.down.m} {
    position: absolute;
    top: -5px;
    width: 100%;
  }
`;

export const PersonalDetailsCard = styled.div`
  padding: 14px 14px;
  padding-bottom: 8px;
  border-radius: 8px;
  margin-bottom: 14px;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  ${Media.down.m} {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;

export const PersonalDetailsRow = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const PersonalDetailsLabel = styled(TextBody1)`
  line-height: 20px;
`;

export const PersonalDetailsValue = styled(TextBody1)`
  line-height: 20px;
  font-weight: 700;
`;
export const CheckboxTextWrapper = styled.div``;
