import React, {CSSProperties} from 'react';
import {SeparatorLine, SeparatorVerticalLine, DateSeparatorWrapper} from './style';

interface SeparatorProps {
  vertical?: boolean;
  style?: CSSProperties;
}
interface DateProp {
  date: string;
}
export const Separator: React.FC<SeparatorProps> = ({vertical, style, ...props}) => {
  return vertical ? <SeparatorVerticalLine style={style} {...props} /> : <SeparatorLine style={style} {...props} />;
};

export const DateSeparator: React.FC<DateProp> = ({date, ...props}) => {
  return (
    <DateSeparatorWrapper {...props}>
      <SeparatorLine></SeparatorLine>
      {date}
      <SeparatorLine></SeparatorLine>
    </DateSeparatorWrapper>
  );
};
interface MonthProp {
  month: string;
}
export const MonthSeparator: React.FC<MonthProp> = ({month, ...props}) => {
  return <DateSeparatorWrapper {...props}>{month}</DateSeparatorWrapper>;
};
