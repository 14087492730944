import {LoadingOutlined} from '@ant-design/icons';
import React from 'react';
import {
  LoaderLine,
  PageLoaderContainer,
  StyledSpin,
  PageSpinLoaderContainer,
  FooterLogoLoaderContainer,
  LogoWrapper,
} from './styles';
import {Spin} from 'antd';
import logo from './assets/logo.gif';

export const Loader: React.FC<{variant?: 'light' | 'dark'}> = ({...props}) => (
  <StyledSpin indicator={<LoadingOutlined />} {...props} />
);

export const PageLoader = () => (
  <PageLoaderContainer>
    <img src={logo} />
  </PageLoaderContainer>
);

export const CenteredLoader: React.FC<{variant?: 'light' | 'dark'; mt?: string}> = ({variant, mt}) => (
  <LoaderLine mt={mt}>
    <Loader variant={variant} />
  </LoaderLine>
);

export const PageSpinLoader: React.FC<{height?: string; color?: string}> = ({height, color}) => (
  <PageSpinLoaderContainer height={height} color={color}>
    <Spin indicator={<LoadingOutlined />} />
  </PageSpinLoaderContainer>
);

export const PageLogoLoader: React.FC<{height?: string}> = ({height}) => (
  <PageSpinLoaderContainer height={height}>
    <img src={logo} />
  </PageSpinLoaderContainer>
);

export const FooterLogoLoader = () => (
  <FooterLogoLoaderContainer>
    <LogoWrapper>
      <img src={logo} width={60} height={60} />
    </LogoWrapper>
  </FooterLogoLoaderContainer>
);
