import styled from 'styled-components';

import {PublicButton} from '../../../../Button';
import Input from '../../../../Input';
import {Media} from '../../../../theme/breakpoints';

export const StylesTicketType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NewInput = styled(Input)`
  max-width: 406px;
  margin-right: 15px;
  height: 44px;
  border: 1px solid ${({theme}) => theme.palette.system.gray1};
  :active,
  :focus,
  &:hover {
    border: 1px solid ${({theme}) => theme.palette.system.gray1};
  }
  ${Media.down.l} {
    margin-right: 15px;
  }
`;

export const NewPublicButton = styled(PublicButton)`
  color: ${({theme}) => theme.palette.system.darkBlack};
  background-color: ${({theme}) => theme.palette.system.white};
  border: 1px solid ${({theme}) => theme.palette.system.gray1};
  transition: 0.2s all;
  &:active,
  &:focus,
  &:hover {
    color: ${({theme}) => theme.palette.system.darkBlack};
    border: 1px solid ${({theme}) => theme.palette.system.gray1};
  }
  &:disabled {
    opacity: 0.3;
    background-color: ${({theme}) => theme.palette.system.gray1} !important;
  }
`;

export const StyledWrapper = styled.div`
  padding: 8px 0 4px 0;
  width: 100%;
`;
