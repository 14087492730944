import {useParams} from 'react-router-dom';
import {useGetOrderWithDetails} from './orders';
import {useRecoilState} from 'recoil';
import {userState} from '../states/session';

export const useConfirmOrderUpgradeVip = () => {
  const [user] = useRecoilState(userState);
  const {id} = useParams();
  const {order, loading} = useGetOrderWithDetails(id);

  return {order, loading, user};
};
