import React from 'react';
import {ThemeProvider} from 'styled-components';
import {Layout} from './containers/Layout';
import RoutesSwitch from './Routes';
import {Normalize} from 'styled-normalize';
import {defaultTheme} from './ui-kit/theme/theme';
import 'antd/dist/antd.css';
import './ui-kit/fonts/fonts.css';
import './ui-kit/AlertModal/styles.css';
import 'react-phone-input-2/lib/style.css';
import RecoilProvider from './containers/RecoilProvider';
import CurrentUser from './containers/CurrentUser';
import {PreloadProvider} from './containers/PreloadProvider';

function App() {
  return (
    <RecoilProvider>
      <CurrentUser>
        <PreloadProvider>
          <ThemeProvider theme={defaultTheme}>
            <Layout>
              <RoutesSwitch />
            </Layout>
            <Normalize />
          </ThemeProvider>
        </PreloadProvider>
      </CurrentUser>
    </RecoilProvider>
  );
}

export default App;
