import React from 'react';
import {WithChildren} from '../../types/helpers';
import {Footer} from './Footer';
import {AppLayout} from '../../ui-kit/AppLayout';
import {Header} from './Header';
import {useLocation} from 'react-router-dom';
import {excludePathsHeader, excludePathsFooter} from '../../constants/routes';

export const Layout: React.FC<WithChildren> = ({children}) => {
  const {pathname} = useLocation();
  const hideHeader = excludePathsHeader.some((path) => pathname.includes(path));
  return (
    <>
      {!hideHeader && <Header />}
      <AppLayout hideFooter={excludePathsFooter.some((path) => pathname.includes(path))} footer={<Footer />}>
        {children}
      </AppLayout>
    </>
  );
};
