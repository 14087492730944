import React from 'react';
import {CartStyled, CartCountStyled, CartWrapper} from './styles';
import {ICartProps} from './types';

export const Cart: React.FC<ICartProps> = ({count = 0}) => {
  return (
    <CartWrapper>
      <CartStyled />
      <CartCountStyled count={count}>{count}</CartCountStyled>
    </CartWrapper>
  );
};
