import styled from 'styled-components';
import {Button} from '../../ui-kit/Button';
import {Media} from '../../ui-kit/theme/breakpoints';
import {Heading5, Heading6, Text1, Text3} from '../../ui-kit/Typography';
import {DescrProps, TimeElementsWrapperProps} from './types';
import {scrollStyles} from '../../ui-kit/theme/scroll';
import {Separator} from '../../ui-kit/Separators';

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: ${({theme}) => theme.spacer._15};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({theme}) => theme.spacer._3};
  ${Media.down.m} {
    flex-direction: column;
    margin-bottom: ${({theme}) => theme.spacer._2};
  }
`;

export const TitleWrapper = styled.div`
  width: 80%;
  text-transform: capitalize;
  ${Media.down.xl} {
    width: 70%;
  }
  ${Media.down.m} {
    width: 100%;
  }
`;

export const ChangeDateContentContainer = styled.div<{$reverse?: boolean}>`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 38px;
  position: relative;
  ${Media.down.l} {
    flex-direction: ${({$reverse}) => ($reverse ? 'column-reverse' : 'column')};
    padding-top: ${({$reverse}) => ($reverse ? '40px' : '0')};
    margin-top: 24px;
  }
`;

export const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // padding-right: 30px;
  ${Media.down.xl} {
    // padding-right: 33px;
  }
  ${Media.down.l} {
    padding-right: 30px;
    width: 100%;
    padding: 0;
  }
`;
export const RightSideWrapper = styled.div<{$half?: boolean}>`
  display: flex;
  flex-direction: column;
  ${({$half}) => ($half ? ` width: 49%;` : '  max-width: 660px; flex: 1;')}
  ${Media.down.xl} {
    // padding-left: 30px;
  }
  ${Media.down.l} {
    padding-left: 30px;
    width: 100%;
    padding: 0;
    border: none;
  }
`;

export const ContentTitleSmallSecondary = styled(Heading6)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  margin-top: ${({theme}) => theme.spacer._7};
`;
export const PopTimeSlot = styled.div`
  display: flex;
  padding: 6px 10px;
  flex-direction: column;
  align-items: flex-start;
  font-family: Proxima Nova;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 10px;
  width: 100%;
  max-width: 96px;
  border-radius: 6px;
  border: 0.5px solid #becdcc;
  background: #1f4762;
  color: #ffffff;
`;

export const ContentDescrWrapper = styled.div<DescrProps>`
  width: ${({width}) => width || '100%'};
  margin-top: ${({theme}) => theme.spacer._1};
  margin-bottom: ${({theme}) => theme.spacer._5};
  ${Media.down.l} {
    width: 100%;
  }
`;

export const BannerSubtitmeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentTitle = styled(Heading5)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
`;

export const ContentTitleSmall = styled(Heading6)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
`;

export const ContentDescrSmallWrapper = styled.div`
  margin-top: ${({theme}) => theme.spacer._0};
`;

export const ContentDescr = styled(Text3)`
  color: ${({theme}) => theme.palette.system.white};
  ${Media.down.l} {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const ContentDescrSmall = styled(Text1)`
  color: ${({theme}) => theme.palette.system.white};
  ${Media.down.l} {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  margin-top: ${({theme}) => theme.spacer._3};
  margin-bottom: ${({theme}) => theme.spacer._1};
  margin-bottom: 18px;
  ${Media.down.m} {
    margin-top: ${({theme}) => theme.spacer._1};
    margin-bottom: ${({theme}) => theme.spacer._0};
  }
`;

export const TimeElementsWrapper = styled.div<TimeElementsWrapperProps>`
  opacity: ${({selected}) => (selected ? 1 : 0.5)};
`;

export const UpperTimeCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 8px 0;
`;

export const TimeCardsContainer = styled(UpperTimeCardsContainer)`
  border: none;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ValueItem = styled(Text1)``;

export const TimeCardsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-height: 220px;
  overflow-y: auto;
  ${scrollStyles};
  ${Media.down.l} {
    gap: 50px;
  }
`;
export const TimeCardsMobileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: ${({theme}) => theme.spacer._2};
  max-height: 205px;
  overflow-y: auto;
  padding: 6px;
  ${scrollStyles};
  ${Media.down.m} {
    max-height: 100%;
    gap: 6px;
  }
`;

export const TimeCardsSwitchWrapper = styled.div`
  max-width: 200px;
  max-height: 50px;
  height: auto;
  flex-grow: 1;
  &:empty {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: block;
`;

export const TransparentButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border: none;
  }
`;

export const FormElementsWrapper = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.palette.secondary.nightBlue};
`;

export const FormInputWrapper = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid transparent;
`;

export const ElementOr = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 50%);
  display: flex;
  justify-content: center;
  border-radius: 50%;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 1px;
    width: 48%;
    background-color: ${({theme}) => theme.palette.secondary.nightBlue};
    transform-origin: center center;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    right: 0;
    width: 48%;
    background-color: ${({theme}) => theme.palette.secondary.nightBlue};
    transform-origin: center center;
  }
`;

export const DateCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spacer._1};
  width: 100%;
  margin-bottom: ${({theme}) => theme.spacer._2};
  max-height: 540px;
  overflow-y: scroll;
  padding: 4px;
  ${scrollStyles};
  ${Media.down.m} {
    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;

export const DatesWrapper = styled.div<{$hide?: boolean}>`
  width: 46%;
  ${Media.down.l} {
    max-width: 100%;
    width: 100%;
  }
  ${Media.down.m} {
    ${({$hide}) => $hide && ` display: none;`};
  }
`;

export const ArrivalWrapper = styled.div<{$hide?: boolean}>`
  width: 46%;
  ${Media.down.l} {
    width: 100%;
  }
  ${Media.down.m} {
    ${({$hide}) => $hide && ` display: none;`};
  }
`;
export const MonthTag = styled.div`
  width: 120px;
  border-radius: 4px;
  padding: 4px 10px;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  font-family: Poppins Bold;
  color: #020e16;
  margin: 12px 0;
  background: linear-gradient(
    169deg,
    #b9976a 0%,
    #d8c288 21.35%,
    #e4d19d 37.5%,
    #f9eccb 55.21%,
    #e4d19d 74.48%,
    #d4b67a 100%
  );
  // ${Media.down.m} {}
`;

export const LineSeparator = styled(Separator)`
  position: absolute;
  width: 1px !important;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
`;
