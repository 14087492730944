import {atom} from 'recoil';
import {ButtonType} from '../ui-kit/Button/types';

export type ProgressStateT = {
  progress?: number;
};

export type СhangeDateStateT = ProgressStateT & {
  isDateSelected?: boolean;
  isTimeSelected?: boolean;
  isReview?: boolean;
  isConfirm?: boolean;
  setIsReview?: (v: boolean) => void;
  step?: number;
  conditionButton?: boolean;
  conditionMobileButton?: boolean;
  buttonType?: ButtonType;
  buttonTitle?: string;
  orderNumber?: string;
  dateCardId?: string;
  orderId?: string;
  additionalPayment?: number;
  onClickPayment?: () => Promise<boolean | string>;
  onNextClick?: (id?: string) => void;
  cartId?: string;
  stripeAccountId?: string;
  canSkipTickets?: string[];
};

export type UpgradeVipStateT = ProgressStateT & {
  step?: number;
  buttonType?: ButtonType;
  buttonTitle?: string;
  conditionButton?: boolean;
  orderNumber?: string;
  isConfirm?: boolean;
  subtotal?: number;
  orderId?: string;
  onClickPayment?: () => Promise<boolean | string>;
  onNextClick?: (id?: string) => void;
  cartId?: string;
  stripeAccountId?: string;
};
export type CancellationStateT = ProgressStateT & {
  orderNumber?: string;
};
export type CheckoutStateT = {
  cardFilled?: boolean;
};
export const cancellationState = atom<CancellationStateT>({
  key: 'cancellationState',
  default: {
    progress: undefined,
    orderNumber: '',
  },
});

export const changeDateState = atom<СhangeDateStateT | undefined>({
  key: 'changeDateState',
  default: undefined,
});

export const upgradeVipState = atom<UpgradeVipStateT | undefined>({
  key: 'upgradeVipState',
  default: undefined,
});

export type TicketConfirmationStateT = {
  reservedDate?: string;
};

export const ticketConfirmationState = atom<TicketConfirmationStateT | undefined>({
  key: 'ticketConfirmationState',
  default: undefined,
});
export const checkoutState = atom<CheckoutStateT | undefined>({
  key: 'checkoutState',
  default: undefined,
});

export const confirmPaymentInfo = atom<any | undefined>({
  key: 'confirmPaymentInfo',
  default: undefined,
});
