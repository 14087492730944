import styled from 'styled-components';

import {StyledHeading6, TextBody1, TextSixteenBodyBold, TextSmallBold} from '../../Typography/styles';
import {Media} from '../../theme/breakpoints';
import {NumericInput} from '../../NumericInput';

export const StyledCard = styled.div<{
  $isPicked?: boolean;
  $disabled?: boolean;
  golden_frame?: boolean;
  vipCard?: boolean;
}>`
  width: 100%;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.system.white};
  padding: 12px 14px;
  margin-bottom: 8px;
  transition: 0.4s all;
  position: relative;
  ${({$isPicked}) => $isPicked && `box-shadow: 0px 0px 12px #e7d6a4;`};
  ${({$disabled}) => $disabled && `opacity: 0.7; pointer-events: none;`};
  ${({golden_frame}) => golden_frame && `box-shadow: 0px 0px 8px #C7B09C, 0px 0px 6px #C7B09C;`};
  ${({vipCard}) =>
    vipCard &&
    `background: linear-gradient(169deg, #E0DACD 0%, #FFF 41.67%, #DBC491 100%); position: relative; maxWidth: 550px; padding: 24px 20px;
    ${Media.down.l} {
      padding: 16px 14px 16px 14px;
    }
    `};
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledWrapperTicket = styled(StyledWrapper)`
  justify-content: space-between;
  margin-top: ${({theme}) => theme.spacer._3};
  margin: auto 0;
  margin-bottom: 0;
`;

export const StyledLocation = styled(StyledHeading6)`
  line-height: 24px;
  ${Media.down.m} {
    font-size: 16px;
  }
`;

export const StyledSvg = styled.div`
  margin-left: ${({theme}) => theme.spacer._1};
`;

export const StyledCoast = styled(TextSixteenBodyBold)`
  line-height: 22px;
`;

export const StyledAge = styled(TextBody1)`
  max-width: 165px;
  display: block;
`;

export const StyledNumericInput = styled(NumericInput)`
  margin: auto 0;
  margin-bottom: 0;
`;

export const TicketDescription = styled(TextBody1)`
  margin-bottom: 10px;
  line-height: 16px !important;
  font-size: 14px;
  display: block;
  text-shadow: 0px 0px ${({theme}) => theme.palette.primary.midnightBlue};
  color: ${({theme}) => theme.palette.primary.midnightBlue};
  & > span {
    display: block;
    margin-top: 4px;
    font-weight: 600;
  }
`;

export const NoteRow = styled(TextSmallBold)`
  text-transform: capitalize;
`;
