import {Spin} from 'antd';
import styled from 'styled-components';

export const StyledSpin = styled(Spin)<{variant?: 'light' | 'dark'}>`
  .ant-spin-dot {
    color: ${({theme, variant}) => {
      if (!theme?.palette?.system) return '#020E16';
      switch (variant) {
        case 'light':
          return theme.palette.system.white;
        case 'dark':
          return theme.palette.system.darkBlack;
        default:
          return '#020E16';
      }
    }} !important;
  }

  .ant-spin-dot {
    font-size: 32px;
  }
`;

export const PageLoaderContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #041c2c;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot {
    font-size: 48px;
    color: #262626;
  }
`;

export const LoaderLine = styled.div<{mt?: string}>`
  ${({mt}) => mt && ` margin-top: ${mt};`};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot {
    color: #262626;
  }
`;

export const PageSpinLoaderContainer = styled.div<{height?: string; color?: string}>`
  width: 100%;
  min-height: ${({height}) => (height ? height : '100vh')};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot {
    font-size: 48px;
    color: ${({color}) => (color ? color : '#000000')};
  }
`;

export const FooterLogoLoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
