import styled from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {HeadingMediumTitle, Heading5, Text1, Text3} from '../../ui-kit/Typography';

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;

  ${Media.down.m} {
    padding-top: 104px;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({theme}) => theme.spacer._5};
  padding-top: 20px;
  ${Media.down.s} {
    padding-top: 0;
  }
`;

export const Title = styled(HeadingMediumTitle)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  text-transform: capitalize;
  ${Media.down.l} {
    margin-top: 12px;
    font-size: 24px !important;
    line-height: 26px;
  }
`;

export const OrderWrapper = styled.div`
  margin-bottom: ${({theme}) => theme.spacer._3};
`;

export const VipPassWrapper = styled.div`
  max-width: 50%;
  margin-top: ${({theme}) => theme.spacer._5};
  ${Media.down.m} {
    max-width: 100%;
  }
`;

export const SubTitle = styled(Heading5)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  text-transform: uppercase;
  margin-bottom: ${({theme}) => theme.spacer._0};
`;

export const Description = styled(Text1)`
  color: ${({theme}) => theme.palette.system.white};
`;

// Review
export const ReviewTitle = styled(HeadingMediumTitle)`
  color: ${({theme}) => theme.palette.secondary.brightGold};
  text-transform: capitalize;
  margin-bottom: ${({theme}) => theme.spacer._1};
  margin-top: 26px;
  ${Media.down.l} {
    margin-top: 12px;
  }
`;

export const ReviewDescription = styled(Text3)`
  display: block;
  max-width: 589px;
  color: ${({theme}) => theme.palette.system.white};
  margin-bottom: ${({theme}) => theme.spacer._5};
  ${Media.down.l} {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const OrderReviewWrapper = styled.div`
  margin-bottom: ${({theme}) => theme.spacer._14};
  ${Media.down.m} {
    margin-bottom: ${({theme}) => theme.spacer._5};
  }
`;

export const ReviewContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  ${Media.down.l} {
    padding-top: 40px;
    display: flex;
    flex-direction: column-reverse;
  }
`;
