import {ButtonType} from '../ui-kit/Button/types';
import {ProgressStateT} from './order';
import {atom} from 'recoil';

export type purchaseAdmissionStateT = ProgressStateT & {
  step?: number;
  buttonType?: ButtonType;
  buttonTitle?: string;
  conditionButton?: boolean;
  orderNumber?: string;
  sellerName?: string;
  isConfirm?: boolean;
  subtotal?: number;
  orderId?: string;
  onClickPayment?: () => Promise<boolean | string>;
  onNextClick?: (id?: string) => void;
  cartId?: string;
  showButton?: boolean;
};

export const purchaseAdmission = atom<purchaseAdmissionStateT | undefined>({
  key: 'purchaseAdmission',
  default: undefined,
});

const determineBrowserType = () => {
  if (typeof window === 'undefined') return 'other';
  const isSafari =
    window.navigator.userAgent.indexOf('Safari') >= 0 || window.navigator.userAgent.indexOf('WebKit') >= 0;
  // const isOpera = window?.opera && navigator.userAgent.indexOf('Opera') >= 0;
  const isChrome =
    window.navigator.userAgent.indexOf('Chrome/') >= 0 && window.navigator.userAgent.indexOf('WebKit') >= 0;

  return isChrome ? 'chrome' : isSafari && !isChrome ? 'safari' : 'other';
};

export const browserDetector = atom<string>({
  key: 'browserDetector',
  default: determineBrowserType(),
});
