import React from 'react';

import {CardLabel, CardValue, StyledCard, StyledWrapper, ButtonWrapper} from './styles';
import {DetailedCardProps} from './types';
import {PublicButton} from '../Button';
import {LinkConfirmButtonView} from '../Button/LinkButton';

const DetailedCard: React.FC<DetailedCardProps> = ({data, buttonTitle, onClickViewTickets}) => {
  return (
    <StyledCard>
      {data?.map((item) => (
        <StyledWrapper key={item.id}>
          <CardLabel>{item.label}</CardLabel>
          <CardValue>{item.value}</CardValue>
        </StyledWrapper>
      ))}
      {buttonTitle && (
        <ButtonWrapper>
          <PublicButton onClick={onClickViewTickets} variant={'primary'}>
            {buttonTitle}
          </PublicButton>
        </ButtonWrapper>
      )}
    </StyledCard>
  );
};

export default DetailedCard;

export const DetailedCardConfirm: React.FC<DetailedCardProps> = ({data, buttonTitle, viewTicketLink}) => {
  return (
    <StyledCard>
      {data?.map((item) => (
        <StyledWrapper key={item.id}>
          <CardLabel>{item.label}</CardLabel>
          <CardValue>{item.value}</CardValue>
        </StyledWrapper>
      ))}
      {buttonTitle && (
        <ButtonWrapper>
          <LinkConfirmButtonView toLink={viewTicketLink} blank={true}>
            {buttonTitle}
          </LinkConfirmButtonView>
        </ButtonWrapper>
      )}
    </StyledCard>
  );
};
