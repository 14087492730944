import React, {useEffect} from 'react';
import {useLoginMagicLink} from '../../hooks/auth';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {searchKeys} from '../../types/helpers';
import {route} from '../../constants/routes';
import {MagicLinkReceived} from '../../components/MagicLink/MagicLinkReceived';

export const MagicLinkAccess: React.FC = () => {
  const [searchParams] = useSearchParams();
  const paramEmail = searchParams.get(searchKeys.email);
  const paramMagicCode = searchParams.get(searchKeys.magicCode);
  const navigate = useNavigate();
  const GroupView = () => navigate(route.dashboard.path);
  const notFound = () => navigate(route.orderNotFoundPage.path);
  const {onLogin} = useLoginMagicLink(GroupView, notFound);

  useEffect(() => {
    if (paramEmail && paramMagicCode) onLogin({email: paramEmail, magicCode: paramMagicCode});
  }, [!!paramEmail]);

  return <MagicLinkReceived />;
};
