import {Button} from 'antd';
import styled, {css} from 'styled-components';
import {ButtonPropsT} from '../Button/types';
import {Media} from '../theme/breakpoints';
import {switchButtonTextStyles} from '../Typography/styles';

const switchButtonPrimaryStyles = css`
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  color: ${({theme}) => theme.palette.system.darkBlack};
  padding: 6px 24px;
  border-radius: 40px;
  border: none;
  ${Media.down.l} {
    padding: 6px 14px;
  }
  ${Media.down.xxs} {
    padding: 6px 11px;
  }
`;

const switchButtonSecondaryStyles = css`
  background-color: ${({theme}) => theme.palette.secondary.seaBlue};
  color: ${({theme}) => theme.palette.system.white};
  padding: 6px 14px;
  border-radius: 40px;
  border: none;

  ${Media.down.xxs} {
    padding: 6px 11px;
  }
`;

export const StyledSwitchButton = styled(Button)<ButtonPropsT>`
  ${switchButtonTextStyles};
  ${({variant}) => {
    switch (variant) {
      case 'primary':
        return switchButtonPrimaryStyles;
      case 'secondary':
        return switchButtonSecondaryStyles;
      default:
        return switchButtonPrimaryStyles;
    }
  }};
  margin-right: 8px;
`;
