import React from 'react';
import {useRecoilState} from 'recoil';
import {deductionAmount} from '../../../states/purchase';
import {
  FooterLeftMobileComponent,
  FooterNavigation,
  FooterScrollNavigation,
} from '../../../ui-kit/AppLayout/FooterNavigation';
import {DesktopView, MobileView} from '../../../ui-kit/AppLayout/View';
import {useAdmissionNavigation, useScrollPosition} from '../../../hooks/navigation';
import {formatCurrencyPrice} from '../../../helpers/helpers';

export const PurchaseAdmissionFooter = () => {
  const [netTotal] = useRecoilState(deductionAmount);
  const admission = useAdmissionNavigation();
  const showLabel = !!admission?.subtotal;

  const paymentLabelDesktop = admission?.cartId ? 'Balance Due:' : 'Additional Payment:';
  const paymentLabel = admission?.cartId ? 'Balance Due:' : 'Subtotal:';
  const paymentCount = netTotal
    ? netTotal
    : showLabel
    ? `+${formatCurrencyPrice('USD').format(admission?.subtotal || 0)}`
    : undefined;
  const checkoutDisabled = admission?.checkout ? !admission.checkout.cardFilled : false;
  const buttonLabel = admission?.cartId ? 'Confirm Purchase' : 'Next';

  const {isBottom} = useScrollPosition();
  if (!admission?.showButton) {
    if (isBottom) return null;
    return <FooterScrollNavigation title="Scroll to view more" />;
  }
  return (
    <>
      <DesktopView>
        <FooterNavigation
          buttonType={admission?.buttonType}
          buttonTitle={admission?.buttonTitle}
          onClick={admission?.onNextClick}
          conditionButton={checkoutDisabled || admission?.conditionButton}
          colorLineTop="gold"
          component={showLabel ? <FooterLeftMobileComponent title={paymentLabelDesktop} value={paymentCount} /> : null}
        />
      </DesktopView>
      <MobileView>
        <FooterNavigation
          buttonType={'primary'}
          buttonTitle={buttonLabel}
          component={showLabel ? <FooterLeftMobileComponent title={paymentLabel} value={paymentCount} /> : null}
          onClick={admission?.onNextClick}
          conditionButton={checkoutDisabled || admission?.conditionButton}
          colorLineTop="grey"
        />
      </MobileView>
    </>
  );
};
