import styled from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {Text3, Heading6} from '../../ui-kit/Typography';

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 143px;
  max-width: 1100px;
  ${Media.down.l} {
    padding-top: 100px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // margin-bottom: ${({theme}) => theme.spacer._5};
  margin-bottom: 16px;
  gap: 8px;
`;

export const TitleDescr = styled(Text3)`
  color: ${({theme}) => theme.palette.system.white};
  font-weight: 700;
  ${Media.down.l} {
    margin-top: 6px;
    font-size: 18px;
    line-height: 20px;
  }
`;

export const TabTitle = styled(Heading6)`
  margin-right: ${({theme}) => theme.spacer._3};
  ${Media.down.l} {
    margin-right: ${({theme}) => theme.spacer._1};
  }
`;
