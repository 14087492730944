import React from 'react';
import {CancellationPage} from '../../components/Cancellation';
import {useCancellation} from '../../hooks/cancellation';

export const Cancellation = () => {
  const {reasonCancel, onChangeReason, setProgress, onCancel, onCancelBack, onClickToChangeDate, onCancelReview} =
    useCancellation();
  return (
    <CancellationPage
      reasonCancel={reasonCancel}
      onChangeReason={onChangeReason}
      setProgress={setProgress}
      onCancel={onCancel}
      onCancelBack={onCancelBack}
      onClickToChangeDate={onClickToChangeDate}
      onCancelReview={onCancelReview}
    />
  );
};
