import React from 'react';
import {capitalize} from '../../helpers/helpers';
import {HomeOptions} from '../HomeOptions';
import {ContentWrapper, PageWrapper, TitleDescr, TitleWrapper} from './styles';
import {TitleMedium} from '../styles';
import {CenteredLoader} from '../../ui-kit/Loader';

type DashboardPagePropsT = {
  viewerDetails: {
    name?: string;
  };
  loading?: boolean;
};

export const DashboardPage: React.FC<DashboardPagePropsT> = ({viewerDetails, loading}) => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <TitleDescr>{capitalize(`Welcome, ${viewerDetails.name?.toLocaleLowerCase()}!`)}</TitleDescr>
          <TitleMedium>{'What would you like to do?'}</TitleMedium>
        </TitleWrapper>
        {/* <br /> */}
        {loading ? <CenteredLoader variant={'light'} /> : <HomeOptions />}
      </ContentWrapper>
    </PageWrapper>
  );
};
