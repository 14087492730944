//
// eslint-disable-next-line @typescript-eslint/ban-types
import * as React from 'react';

export type anyObject = object;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type anyArray = any[];

// eslint-disable-next-line @typescript-eslint/ban-types
export type anyNonNullish = {};

export type ArrayOfKeys<T extends anyObject> = [...(keyof T)[]];

export type ArrayValues<T extends anyArray> = T[number];

export type StringKeys<T extends anyObject, K extends keyof T> = Omit<T, K> & {[K2 in K]: string};

export type ChangedElement<T, E> = E & {name: T};

export type OnChange<
  T extends string,
  E extends HTMLElement = HTMLTextAreaElement & HTMLInputElement,
> = React.ChangeEvent<ChangedElement<T, E>>;

export type TStringObj = {
  [key: string]: string;
};

export type WithChildren = {
  children?: React.ReactNode;
};

export type SelectOptionT = {
  label: React.ReactNode;
  value?: string | number | null;
  disabled?: boolean;
};

export type ErrResponse = {message?: string};

export enum searchKeys {
  token = 'token',
  expires = 'expires',
  email = 'email',
  magicCode = 'magicCode',
}

export enum Portals {
  codes = 'codes',
  groups = 'groups',
}

export type BaseObjT = {
  id: number;
  createdAt: string;
  updatedAt: string;
};

export enum steps {
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
  six = 6,
}
