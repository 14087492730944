import React from 'react';
import {useRecoilState} from 'recoil';
import {purchaseState, deductionAmount} from '../../../states/purchase';
import {
  FooterNavigation,
  FooterScrollNavigation,
  FooterLeftMobileComponent,
} from '../../../ui-kit/AppLayout/FooterNavigation';
import {DesktopView, MobileView} from '../../../ui-kit/AppLayout/View';

import {usePurchaiseAddOneNavigation, useScrollPosition} from '../../../hooks/navigation';
import {formatCurrencyPrice} from '../../../helpers/helpers';

export const UpgradePurchaiseFooter = () => {
  const [state] = useRecoilState(purchaseState);
  const [netTotal] = useRecoilState(deductionAmount);
  const navigation = usePurchaiseAddOneNavigation();
  const {isBottom} = useScrollPosition();
  if (navigation && !navigation?.showButton) {
    if (isBottom) return null;
    return <FooterScrollNavigation title="Scroll to view more" />;
  }
  const paymentLabelDesktop = navigation?.cartId ? `Balance Due:` : 'Additional Payment:';
  const paymentLabel = navigation?.cartId ? 'Balance Due:' : 'Subtotal:';
  const paymentCount = netTotal
    ? netTotal
    : state?.payment
    ? `+${formatCurrencyPrice('USD').format(state?.payment || 0)}`
    : undefined;
  const checkoutDisabled = navigation?.checkout ? !navigation.checkout.cardFilled : false;
  const buttonLabel = navigation?.cartId ? 'Confirm Purchase' : 'Next';

  return (
    <>
      <DesktopView>
        <FooterNavigation
          buttonType={navigation?.buttonType}
          buttonTitle={navigation?.buttonTitle}
          onClick={navigation?.onNextClick}
          conditionButton={checkoutDisabled || navigation?.conditionButton}
          colorLineTop="gold"
          component={
            paymentCount ? (
              <FooterLeftMobileComponent title={paymentLabelDesktop} value={state ? paymentCount : '0'} />
            ) : (
              <></>
            )
          }
        />
      </DesktopView>
      <MobileView>
        <FooterNavigation
          buttonType={'primary'}
          buttonTitle={buttonLabel}
          component={
            paymentCount ? <FooterLeftMobileComponent title={paymentLabel} value={state ? paymentCount : '0'} /> : <></>
          }
          onClick={navigation?.onNextClick}
          colorLineTop="grey"
          conditionButton={checkoutDisabled || navigation?.conditionButton}
        />
      </MobileView>
    </>
  );
};
