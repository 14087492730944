import Cookies from 'js-cookie';
import {useEffect, useState} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {session, session as sessionState, userState} from '../states/session';
import {MagicLinkBodyT, User} from '../types/auth';
import {magicLinkLoginQuery, sendMagicLinkQuery, userQuery, logoutQuery} from '../queries/auth';
import {analyticsIdentifyFN, useUserLoginAnalytics, useUserLogoutAnalytics} from './customerIO';

type TokenType = string | null | undefined;

export const getToken = (): TokenType => {
  return Cookies.get('id_token');
};

export const removeToken = () => {
  Cookies.remove('id_token');
};

export const setToken = (token: TokenType, expires?: number): TokenType | void => {
  if (!token) {
    return Cookies.remove('id_token');
  }
  return Cookies.set('id_token', token, {expires: expires || 365});
};

export function useFetchSession() {
  const [loading, setLoading] = useState(false);
  const [success, SetSuccess] = useState(false);
  const checkAuth = useCheckAuthorize();
  const setSession = useSetRecoilState(sessionState);
  const [mounted, setMounted] = useState(false);
  const token = getToken();

  useEffect(() => {
    if (mounted) return;
    setMounted(true);
    const setAuthorize = async () => {
      setLoading(true);
      if (!token) return setLoading(false);

      const res = await checkAuth();
      if (res) {
        setSession({sessionToken: token, user: res});
        SetSuccess(true);
        setLoading(false);
        if (res.hmac) analyticsIdentifyFN(res.ticketureIdentityId, res.hmac);
      } else {
        setToken(null);
        setSession(null);
        return {loading: false, success};
      }

      setLoading(false);
    };
    setAuthorize();
  }, []);

  if (!mounted) return {loading: true, success};
  if (!token) {
    setToken(null);
    setSession(null);
    return {loading: false, success};
  }
  return {loading, success};
}

export const useCheckAuthorize = () => async () => {
  try {
    const res = await userQuery(); //check auth query
    if (res.body) return res.body as User;
    return false;
  } catch (e) {
    return false;
  }
};

export const useViewer = () => {
  const user = useRecoilState(userState);

  return user[0];
};

export const useLoginMagicLink = (redirect?: () => void, onFail?: () => void) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | undefined>('');
  const setSession = useSetRecoilState(session);
  const onLoginAnalytics = useUserLoginAnalytics();
  const onLogin = async (values: MagicLinkBodyT) => {
    try {
      setError('');
      setSuccess(false);

      const body = {email: values?.email || '', magicCode: values?.magicCode || ''};
      const res = await magicLinkLoginQuery(body);
      if (res.status) {
        const token = res.body?.id_token;
        setToken(token);
        const currentUser = (await userQuery()) as {body: User};
        setSession({sessionToken: token, user: currentUser.body});
        setSuccess(true);
        onLoginAnalytics(currentUser.body);
        redirect?.();
      }
    } catch (e) {
      console.log(e);
      setError('Login error');
      onFail?.();
      // redirect?.();
    }
  };
  return {onLogin, success, error};
};

export type useSendMagicLinkRes = {
  onSend: (values: any) => void;
  success?: boolean;
  loading?: boolean;
  error?: string;
};

export enum Portals {
  guest = 'guest',
}

export const useSendMagicLink = (onSuccess?: () => void): useSendMagicLinkRes => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>('');
  const onSend = async (values: any) => {
    try {
      setError('');
      setLoading(true);
      setSuccess(false);

      const body = {email: values?.email.toLowerCase(), portal: Portals.guest};
      const res = await sendMagicLinkQuery(body);
      if (res.status) {
        setLoading(false);
        onSuccess?.();
        setSuccess(true);
      }
    } catch (e) {
      setLoading(false);
      setError("That email doesn't seem to be available in our system.");
    }
  };
  return {onSend, success, error, loading};
};

export const useLogOut = () => {
  const onLogoutFN = useUserLogoutAnalytics();
  const setSession = useSetRecoilState(session);

  return async () => {
    const res = await logoutQuery();
    if (res?.ok) {
      onLogoutFN();
      setToken(null);
      setSession(null);
    }
  };
};
