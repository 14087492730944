import React from 'react';
import {RightSideWrapper} from './styles';
import {BillingInfoProps} from './types';
import {OrderDetailsCard} from '../../ui-kit/Cards/OrderDetailsCard/OrderDetailsCard';

export const BillingInfo: React.FC<BillingInfoProps> = ({
  dataOrderDetails,
  paymentForm,
  showPromo,
  paymentSkipping,
}) => {
  return (
    <RightSideWrapper $half>
      <OrderDetailsCard
        data={dataOrderDetails}
        paymentForm={paymentForm}
        showPromo={showPromo}
        paymentSkipping={paymentSkipping}
      />
    </RightSideWrapper>
  );
};
