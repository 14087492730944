import {atom} from 'recoil';
export const selectedTabState = atom({
  key: 'selectedTabState',
  default: -1,
});

export const selectedOptState = atom({
  key: 'selectedOptState',
  default: '',
});
export const getPaymentSkipping = atom({
  key: 'paymentSkipping',
  default: false,
});
