import React from 'react';
import {ConfirmPurchaseOnePage} from '../../components/Confirms';
import {useParams} from 'react-router-dom';
import {useGetOrderWithDetails} from '../../hooks/orders';
import {toConfirmPurchaseOrder} from '../../helpers/purchaseAddOns';
import {useViewer} from '../../hooks/auth';
import {getTZdate} from '../../helpers/orders';
import {formats} from '../../helpers/helpers';
import {usePreloadOrders} from '../../hooks/preload';

export const ConfirmPurchaseOne = () => {
  const {id} = useParams();
  const viewer = useViewer();
  const {order, loading} = useGetOrderWithDetails(id);

  const orderValues = toConfirmPurchaseOrder(viewer, order);
  const waiversCount = order?.waivers?.length;
  const canManageWaivers = order?.tickets?.some((el) => el?.ticketType?.toLowerCase().includes('skating'));
  const reservedDate = order?.createdAt ? getTZdate(order?.createdAt, order).format(formats.reservedDate) : '';
  usePreloadOrders();
  return (
    <ConfirmPurchaseOnePage
      dataOrder={orderValues}
      reservedDate={reservedDate}
      loading={loading}
      waiversLink={order?.metadata?.manageWaiversLink}
      countWaivers={waiversCount}
      viewTicketLink={order?.metadata?.viewTicketLink}
      canManageWaivers={canManageWaivers}
    />
  );
};
