import React, {useMemo} from 'react';
// import moment from 'moment-timezone';
import CardFromTime from './CardFromTime';
import {TypeVariant, TimeCardPropsT, CurrentTimeCardProps} from './types';
import {checkIsHiddenSession, getTZdate} from '../../../helpers/orders';
import {formats} from '../../../helpers/helpers';
import {SoldOutModal} from '../../../helpers/changeDate';

const onSoldOutClick = (data: CurrentTimeCardProps, cb: () => void) => {
  if (data.eventGroupName?.toLowerCase().includes('admission')) return;
  SoldOutModal(data?.eventGroupName, cb);
};

export const TimeCardsSwitch: React.FC<TimeCardPropsT> = ({
  data,
  onClick,
  isActiveTickedCard,
  sessions,
  sellerTimezone,
  nightOwl,
  hideLast,
  hiddenSesions,
  priceDiffInfo,
  isLastGA,
  addons,
  preventSkip,
}) => {
  const isActive = isActiveTickedCard === data.id;
  const cardClick = () => {
    if (preventSkip && data.variant === TypeVariant.sold) return;
    if (data.variant === TypeVariant.sold) {
      return onSoldOutClick(data, () => onClick(data.id));
    }
    onClick(data.id);
  };
  let elfLabel = '';
  const hasElf = sessions?.find((el) => el?.name?.toLowerCase()?.includes('elf'));
  if (hasElf) {
    hasElf.event_session?._data?.forEach((el) => {
      const elfStart = getTZdate(el?.start_datetime, {sellerTimezone}).format(formats.timeStart);
      const gaStart = getTZdate(data?.start, {sellerTimezone}).format(formats.timeStart);
      if (elfStart === gaStart && !el?.sold_out) {
        elfLabel = 'Elf Guide Available';
      }
    });
  }
  const isHidden = useMemo(
    () =>
      checkIsHiddenSession(getTZdate(data?.start, {sellerTimezone}).format(formats.onDate), data.time, hiddenSesions),
    [!!data?.start],
  );
  const isMuted = (nightOwl && !isLastGA) || hideLast || isHidden;
  const isPaws = addons?.some((el) => el?.name?.toLowerCase()?.includes('paws'));
  if (data.variant) {
    return (
      <CardFromTime
        isActive={isActive}
        key={data.id}
        onClick={cardClick}
        data={data}
        priceDiffInfo={priceDiffInfo}
        sellerTimezone={sellerTimezone}
        isActiveTickedCard={isActiveTickedCard}
        elfLabel={elfLabel}
        nightOwl={nightOwl}
        isMuted={isMuted}
        isPaws={isPaws}
      />
    );
  }
  return null;
};

export default TimeCardsSwitch;
