import {useEffect, useRef, useState} from 'react';
import {
  getEventSessions,
  getEventSessionsForOneDate,
  getAvailableEventSessionsAndPrice,
  getSellerData,
} from '../queries/eventsGroup';
// import moment from 'moment-timezone';
import {
  EventsT,
  SessionsT,
  EventSessionT,
  CustomSessionsT,
  DateEventsT,
  EventTemplateT,
  BookedSessionsT,
  BookedTemplateIds,
} from '../types/events';
import {canBuyVipT, OrderT, TicketT} from '../types/orders';
import {EventTemplateIdsT} from '../queries/types/changeDate';
import {checkVipBuy, getTZdate, isGA, isGACat} from '../helpers/orders';
import {vip_key_l} from '../helpers/helpers';
import {preloadLoading, preloadedAvailableEvents} from '../states/preload';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {CD_AddonT} from '../helpers/events';
import {CertainDatesModal, SoldOutModal} from '../helpers/changeDate';
import {changeDateState} from '../states/order';

export const useGetAvailableEvents = (sellerId?: string) => {
  const allEvents = useRecoilValue(preloadedAvailableEvents);
  const loading = useRecoilValue(preloadLoading);
  const eventsLoading = loading?.events && !allEvents?.[sellerId as string];

  return {events: allEvents?.[sellerId as string], loading: eventsLoading};
};

export const useGetEventsSessions = (eventTemplateId?: string) => {
  const [events, setEvents] = useState<SessionsT>();
  const fetchEvents = async () => {
    if (!eventTemplateId) return;
    const res = await getEventSessions(eventTemplateId);
    setEvents(res.body);
  };
  useEffect(() => {
    fetchEvents();
  }, [eventTemplateId]);

  return {events, refetch: fetchEvents};
};

export const toCreateBodyForRequest = (ticketTypes?: TicketT[], events?: EventsT) => {
  const ticketsByType: {
    groupName?: string;
    type?: string;
    count: number;
  }[] = [];
  ticketTypes?.forEach((el) => {
    const currentItem = ticketsByType.find((item) => item.type === el.ticketTypeName);
    if (!currentItem) {
      ticketsByType.push({
        groupName: el.ticketGroupName,
        type: el.ticketTypeName,
        count: 1,
      });
    } else {
      const newItem = {
        ...currentItem,
        count: currentItem.count && currentItem.count + 1,
      };
      const currentIdx = ticketsByType.findIndex((el) => el === currentItem);
      ticketsByType.splice(currentIdx, 1, newItem);
    }
  });
  const bodyForReq: {
    ticket_type_id?: string;
    quantity: number;
  }[] = [];
  ticketsByType.forEach((item) => {
    const ticketGroupsIds = events?.ticket_group._data
      .filter((el) => {
        return el?.name?.toLowerCase() === item?.groupName?.toLowerCase();
      })
      .map((g) => g.id);
    const ticketsTypesByGroup = events?.ticket_type._data.filter((el) => {
      return (
        !!item?.type &&
        ticketGroupsIds?.includes(el.ticket_group_id) &&
        el?.name.toLowerCase().includes(item?.type?.toLowerCase())
      );
    });
    const ticketType = ticketsTypesByGroup?.find((el) => {
      return !!item?.type?.toLowerCase() && el.name.toLowerCase().includes(item?.type?.toLowerCase());
    });
    bodyForReq.push({ticket_type_id: ticketType?.id, quantity: item.count});
  });

  return {ticket_types_required: bodyForReq};
};

export const useGetAvailableEventsSessionsAndPrice = (order?: OrderT, events?: EventsT) => {
  const [eventsWithPrice, setEventsWithPrice] = useState<DateEventsT[]>([]);
  const [eventTemplateIds, setEventTemplateIds] = useState<EventTemplateIdsT[]>([]);

  useEffect(() => {
    const gaTicketTypes = order?.tickets?.filter((el) => isGA(el));
    const iceSkatingTicketTypes = order?.tickets?.filter((el) =>
      el.eventTemplateName?.toLowerCase()?.includes('skating'),
    );
    const dinnerTicketTypes = order?.tickets?.filter((el) => el.eventTemplateName?.toLowerCase()?.includes('dinner'));
    const vouchersTicketTypes = order?.tickets?.filter((el) =>
      el.eventTemplateName?.toLowerCase()?.includes('vouchers'),
    );
    const parkingTicketTypes = order?.tickets?.filter((el) => el.eventTemplateName?.toLowerCase()?.includes('parking'));
    const snowTubingTicketTypes = order?.tickets?.filter((el) =>
      el.eventTemplateName?.toLowerCase()?.includes('snow tubing'),
    );
    const eventGroupGA = events?.ticket_group?._data.find((el) => isGACat(el.name));
    const eventGroupIS = events?.ticket_group?._data.find((el) => el.name?.toLowerCase()?.includes('skating'));
    const eventGroupDN = events?.ticket_group?._data.find((el) => el.name?.toLowerCase()?.includes('dinner'));
    const eventGroupVC = events?.ticket_group?._data.find((el) => el.name?.toLowerCase()?.includes('merchandise'));
    const eventGroupP = events?.ticket_group?._data.find((el) => el.name?.toLowerCase()?.includes('parking'));
    const eventGroupST = events?.ticket_group?._data.find((el) => el.name?.toLowerCase()?.includes('snow tubing'));

    const gaEventTemplateId = eventGroupGA?.event_template_id;
    const ISEventTemplateId = eventGroupIS?.event_template_id;
    const DNEventTemplateId = eventGroupDN?.event_template_id;
    const VCEventTemplateId = eventGroupVC?.event_template_id;
    const PEventTemplateId = eventGroupP?.event_template_id;
    const STEventTemplateId = eventGroupST?.event_template_id;
    const bodyGA = toCreateBodyForRequest(gaTicketTypes, events);
    const bodyIceSkating = toCreateBodyForRequest(iceSkatingTicketTypes, events);
    const bodyDinner = toCreateBodyForRequest(dinnerTicketTypes, events);
    const bodyVoucher = toCreateBodyForRequest(vouchersTicketTypes, events);
    const bodyParking = toCreateBodyForRequest(parkingTicketTypes, events);
    const bodySnowTubing = toCreateBodyForRequest(snowTubingTicketTypes, events);
    const eventsTemplateIds: EventTemplateIdsT[] = [];

    if (gaTicketTypes?.length && gaEventTemplateId) {
      eventsTemplateIds.push({id: gaEventTemplateId, body: bodyGA});
    }
    if (iceSkatingTicketTypes?.length && ISEventTemplateId) {
      eventsTemplateIds.push({id: ISEventTemplateId, body: bodyIceSkating});
    }
    if (dinnerTicketTypes?.length && DNEventTemplateId) {
      eventsTemplateIds.push({id: DNEventTemplateId, body: bodyDinner});
    }
    if (vouchersTicketTypes?.length && VCEventTemplateId) {
      eventsTemplateIds.push({id: VCEventTemplateId, body: bodyVoucher});
    }
    if (parkingTicketTypes?.length && PEventTemplateId) {
      eventsTemplateIds.push({id: PEventTemplateId, body: bodyParking});
    }
    if (snowTubingTicketTypes?.length && STEventTemplateId) {
      eventsTemplateIds.push({id: STEventTemplateId, body: bodySnowTubing});
    }
    setEventTemplateIds(eventsTemplateIds);
  }, [!!order?.id, !!events]);

  const fetchDateEvents = async () => {
    setEventsWithPrice([]);
    if (!eventTemplateIds?.length) return;
    const res = await Promise.all(
      eventTemplateIds.map((el) => getAvailableEventSessionsAndPrice({eventTemplateId: el.id, body: el.body})),
    );
    res.forEach((el) => {
      setEventsWithPrice((prev: DateEventsT[]) => [...prev, el.body]);
    });
  };
  useEffect(() => {
    fetchDateEvents();
  }, [eventTemplateIds?.length]);

  return {eventsWithPrice, fetchDateEvents, eventTemplateIds};
};

export const useGetMultiplyEventsSessions = (eventTemplateIds?: string[]) => {
  const [sessions, setSessions] = useState<SessionsT[]>([]);
  const fetchEvents = async () => {
    if (!eventTemplateIds?.length) return;
    await Promise.all(
      eventTemplateIds.map((el) =>
        getEventSessions(el).then((res) => setSessions((prev: SessionsT[]) => [...prev, res.body])),
      ),
    );
  };
  useEffect(() => {
    fetchEvents();
  }, [eventTemplateIds?.length]);

  return {sessions, refetch: fetchEvents};
};

export const useGetEventsSessionsForOneDate = (eventTemplateId?: string, onDate?: string) => {
  const [event, setEvent] = useState<EventSessionT[]>();
  const fetchEvent = async () => {
    if (!eventTemplateId || !onDate) return;
    const res = await getEventSessionsForOneDate(eventTemplateId, onDate);
    setEvent(res.body);
  };
  useEffect(() => {
    fetchEvent();
  }, [eventTemplateId, onDate]);

  return {event, getEventForOneDate: fetchEvent};
};

const certainDatesTicketsNames = ['snow tubing'];
export const useGetMultiplyEventsSessionsForOneDate = (
  eventTemplateIds: {eventGroupName: string; eventTemplateId: string}[] | undefined,
  onDate: string,
  id: string | undefined,
  addons?: CD_AddonT[],
  purchasedTickets?: TicketT[],
) => {
  const [sessions, setSessions] = useState<CustomSessionsT[]>([]);
  const [voucherSession, setVoucherSession] = useState<EventSessionT>();
  const [loadingSessions, setLoadingSessions] = useState(true);
  const [certainDatesPT, setCertainDatesPT] = useState<CustomSessionsT[]>();
  const setChangeDateState = useSetRecoilState(changeDateState);
  const dataRef = useRef<string>('');
  const fetchEvents = async () => {
    setLoadingSessions(true);
    if (!eventTemplateIds?.length || !onDate) return;
    //fix double render
    if (dataRef.current === onDate) return;
    dataRef.current = onDate;
    const fetchedSessions: CustomSessionsT[] = [];

    await Promise.all(
      eventTemplateIds.map((el) =>
        getEventSessionsForOneDate(el.eventTemplateId, onDate).then((res) =>
          fetchedSessions.push({
            name: el.eventGroupName,
            isSpecial: addons?.some((addon) => addon?.group?.name === el.eventGroupName),
            ...res.body,
          }),
        ),
      ),
    );

    //get voucher session
    const vs = fetchedSessions?.find((el) => el?.name?.toLowerCase().includes('merchandise'))?.event_session
      ?._data?.[0];
    setVoucherSession(vs);

    const certainDatesTickets = fetchedSessions
      .filter((el) => purchasedTickets?.some((p) => p.ticketGroupName === el.name))
      .filter((el) => certainDatesTicketsNames.includes(el.name.toLowerCase()));

    const na_certainDatesTickets = certainDatesTickets.filter((el) => !el.event_session?._data?.length); //not available
    const purchasedCertainDatesTickets = certainDatesTickets.filter((el) => !!el.event_session?._data?.length); //purchased

    const notAvailableTickets = fetchedSessions
      .filter((el) => purchasedTickets?.some((p) => p.ticketGroupName === el.name))
      .filter((el) => !el.event_session?._data?.length)
      .filter((el) => !el.name.toLowerCase().includes('protection'))
      .filter((el) => !el.name.toLowerCase().includes('fees'))
      .filter((el) => !certainDatesTicketsNames.includes(el.name.toLowerCase()));

    const onRemoveConfirm_cd = () => {
      setChangeDateState((prev) => ({...prev, canSkipTickets: na_certainDatesTickets?.map((el) => el.name)}));
    };
    const onRemoveConfirm_na = () => {
      setChangeDateState((prev) => ({...prev, canSkipTickets: notAvailableTickets?.map((el) => el.name)}));
    };

    CertainDatesModal(na_certainDatesTickets?.map((el) => el.name).join(', '), onRemoveConfirm_cd);
    SoldOutModal(notAvailableTickets?.map((el) => el.name).join(', '), onRemoveConfirm_na);

    setSessions(fetchedSessions);
    setCertainDatesPT(purchasedCertainDatesTickets);
    setLoadingSessions(false);
  };

  useEffect(() => {
    setSessions([]);
    fetchEvents();
  }, [eventTemplateIds?.length, onDate, id]);

  return {sessions, refetch: fetchEvents, loadingSessions, certainDatesPT, voucherSession};
};

export const getAvailableGAEventsFromOrders = (orders?: OrderT[]) => {
  const eventsAll = useRecoilValue(preloadedAvailableEvents);
  const [vipAvailable, setVipAvailable] = useState<canBuyVipT[]>([]);
  const events = Object.values(eventsAll || {});

  const setVip = (value: canBuyVipT) =>
    setVipAvailable((prev) => {
      if (prev?.some((el) => el.orderId === value.orderId)) return prev;
      return [...prev, value];
    });
  useEffect(() => {
    checkVipBuy(events, orders, setVip);
  }, [events?.length]);

  return {vipAvailable};
};

export type getBookedEventsPropsT = {
  order?: OrderT;
  eventTemplates?: EventTemplateT[];
};

export const getBookedEvents = ({order, eventTemplates}: getBookedEventsPropsT): BookedSessionsT => {
  const [vipSessions, setVipSessions] = useState<EventSessionT[] | null>(null);
  const [elfSessions, setElfSessions] = useState<EventSessionT[] | null>(null);
  const [gaSessions, setGASessions] = useState<EventSessionT[] | null>(null);
  const [skatingSessions, setSkatingSessions] = useState<EventSessionT[] | null>(null);
  const [dinnerSessions, setDinnerSessions] = useState<EventSessionT[] | null>(null);
  const [voucherSessions, setVoucherSessions] = useState<EventSessionT[] | null>(null);
  const [parkingSessions, setParkingSessions] = useState<EventSessionT[] | null>(null);
  const [parkingGGSessions, setParkingGGSessions] = useState<EventSessionT[] | null>(null);
  const [parkingGCSessions, setParkingGCSessions] = useState<EventSessionT[] | null>(null);

  const haveSkating = order?.tickets?.some((el) => el?.ticketGroupName.toLowerCase()?.includes('skating'));
  const haveDinner = order?.tickets?.some((el) => el?.ticketGroupName.toLowerCase()?.includes('dinner'));
  const haveVoucher = order?.tickets?.some((el) => el?.ticketGroupName.toLowerCase()?.includes('merchandise'));
  const haveParking = order?.tickets?.some((el) => el?.ticketGroupName.toLowerCase()?.includes('parking'));
  const haveVip = order?.tickets?.some((el) => el?.ticketGroupName.toLowerCase()?.includes('vip'));
  const templateIds = {
    vip: haveVip ? eventTemplates?.find((el) => el?.name?.toLowerCase()?.includes(vip_key_l))?.id : undefined,
    elf: eventTemplates?.find((el) => el?.name?.toLowerCase()?.includes('elf'))?.id,
    skating: haveSkating ? eventTemplates?.find((el) => el?.name?.toLowerCase()?.includes('skating'))?.id : undefined,
    dinner: haveDinner ? eventTemplates?.find((el) => el?.name?.toLowerCase()?.includes('dinner'))?.id : undefined,
    voucher: haveVoucher ? eventTemplates?.find((el) => el?.name?.toLowerCase()?.includes('vouchers'))?.id : undefined,
    ga: eventTemplates?.find((el) => el?.name?.toLowerCase().includes('admission'))?.id,
    parking: haveParking ? eventTemplates?.find((el) => el?.name?.toLowerCase() === 'parking')?.id : undefined,
    parkingGeico: haveParking
      ? eventTemplates?.find((el) => el?.name?.toLowerCase() === 'parking - geico garage')?.id
      : undefined,
    parkingGarage: haveParking
      ? eventTemplates?.find((el) => el?.name?.toLowerCase() === 'parking - garage c')?.id
      : undefined,
  };
  //vip
  useEffect(() => {
    if (!templateIds?.vip) return;
    getEventSessions(templateIds.vip).then((res) => {
      setVipSessions(res?.body?.event_session?._data);
    });
  }, [templateIds?.vip]);
  //elf
  useEffect(() => {
    if (!templateIds?.elf) return;
    getEventSessions(templateIds?.elf).then((res) => {
      setElfSessions(res?.body?.event_session?._data);
    });
  }, [templateIds?.vip]);

  //ga
  useEffect(() => {
    if (!templateIds?.ga) return;
    getEventSessions(templateIds.ga).then((res) => setGASessions(res?.body?.event_session?._data));
  }, [templateIds?.ga]);

  //skating
  useEffect(() => {
    if (!templateIds?.skating) return;
    getEventSessions(templateIds.skating).then((res) => setSkatingSessions(res?.body?.event_session?._data));
  }, [templateIds?.skating]);

  //dinner
  useEffect(() => {
    if (!templateIds?.dinner) return;
    getEventSessions(templateIds.dinner).then((res) => setDinnerSessions(res?.body?.event_session?._data));
  }, [templateIds?.dinner]);

  // //vouchers
  useEffect(() => {
    if (!templateIds?.voucher) return;
    getEventSessions(templateIds.voucher).then((res) => setVoucherSessions(res?.body?.event_session?._data));
  }, [templateIds?.voucher]);

  //parking
  useEffect(() => {
    if (!templateIds?.parking) return;
    getEventSessions(templateIds.parking).then((res) => setParkingSessions(res?.body?.event_session?._data));
  }, [templateIds?.parking]);

  //parking gc
  useEffect(() => {
    if (!templateIds?.parkingGarage) return;
    getEventSessions(templateIds.parkingGarage).then((res) => setParkingGCSessions(res?.body?.event_session?._data));
  }, [templateIds?.parkingGarage]);

  //parking gg
  useEffect(() => {
    if (!templateIds?.parkingGeico) return;
    getEventSessions(templateIds.parkingGeico).then((res) => setParkingGGSessions(res?.body?.event_session?._data));
  }, [templateIds?.parkingGarage]);

  return {
    vipSessions: toTZformat(vipSessions, order),
    elfSessions: toTZformat(elfSessions, order),
    gaSessions: toTZformat(gaSessions, order),
    skatingSessions: toTZformat(skatingSessions, order),
    dinnerSessions: toTZformat(dinnerSessions, order),
    voucherSessions: toTZformat(voucherSessions, order),
    parkingSessions: toTZformat(parkingSessions, order),
    parkingGCSessions: toTZformat(parkingGCSessions, order),
    parkingGGSessions: toTZformat(parkingGGSessions, order),
  };
};
export const getBookedTemplateIds = ({order, eventTemplates}: getBookedEventsPropsT): BookedTemplateIds => {
  const templatesIds: BookedTemplateIds = {
    ga: '',
    parking: '',
    skating: '',
    dinner: '',
    voucher: '',
  };
  order?.tickets?.forEach((ticket) => {
    const template = eventTemplates?.find((el) => el?.name === ticket?.eventTemplateName);
    const fName = template?.name?.toLowerCase();
    if (fName?.includes('general') || fName?.includes('admission') || fName === 'ga') templatesIds['ga'] = template?.id;
    if (fName?.includes('skating')) templatesIds['skating'] = template?.id;
    if (fName?.includes('dinner')) templatesIds['dinner'] = template?.id;
    if (fName?.includes('voucher')) templatesIds['voucher'] = template?.id;
    if (fName?.includes('parking')) templatesIds['parking'] = template?.id;
  });
  return templatesIds;
};

const toTZformat = (sessions?: EventSessionT[] | null, order?: OrderT) => {
  if (!sessions) return sessions;
  return sessions?.map((el) => ({
    start: getTZdate(el?.start_datetime, order).format(),
    capacity: el?.capacity,
    sold: el?.sold_quantity,
    soldout: el?.sold_out,
  }));
};

export const useGetSellerData = (sellerId?: string) => {
  const [events, setEvents] = useState<EventsT>();
  const [loading, setLoading] = useState(false);
  const fetchEvents = async () => {
    if (!sellerId) return;
    setLoading(true);
    const res = await getSellerData(sellerId);
    setEvents(res.body);
    setLoading(false);
  };
  useEffect(() => {
    fetchEvents();
  }, [sellerId]);

  return {events, refetch: fetchEvents, loading};
};
