import React from 'react';

// import {InfoCircleOutlined} from '@ant-design/icons';
import {StyledDescriptionRow, StyledTicketInfo} from './styles';
import {FunnelCardProps} from './types';
import {formatCurrency} from '../../../../helpers/helpers';
import {TextBody1, TextBody2, TextSixteenBodyBold} from '../../../Typography/styles';

const VipCard: React.FC<FunnelCardProps> = ({data}) => {
  return (
    <>
      <StyledDescriptionRow>
        <TextBody2>{data.description}</TextBody2>
        {/* <StyledSvg>
          <InfoCircleOutlined />
        </StyledSvg> */}
      </StyledDescriptionRow>
      {data.ticketType.map((item) => (
        <StyledTicketInfo key={item.id} style={{margin: 0}}>
          <TextBody1>
            {item.count}x {item.type}
          </TextBody1>
          <TextSixteenBodyBold>{formatCurrency(item.price * item.count)}</TextSixteenBodyBold>
        </StyledTicketInfo>
      ))}
    </>
  );
};

export default VipCard;
