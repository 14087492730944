import React, {PropsWithChildren} from 'react';
import {ContentWrapper, PageWrapper, TabsWrapper, TitleDescr, TitleWrapper} from './styles';
import {TitleMedium} from '../styles';
import {capitalize} from '../../helpers/helpers';
import {User} from '../../types/auth';

export const ReferralsWrapper: React.FC<PropsWithChildren & {viewer: User | null}> = ({children, viewer}) => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <TitleDescr>{capitalize(`Welcome, ${viewer?.firstName?.toLocaleLowerCase()}!`)}</TitleDescr>
          <TitleMedium>Enchant Wallet</TitleMedium>
        </TitleWrapper>
        <TabsWrapper>{children}</TabsWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
};
