import React, {useCallback, useState} from 'react';
import {StyledTabsButton, TabItem, ButtonTab, TabsWrapper} from './styles';
import {TabProps, TabsPropsT, TabsButtonPropsT, TabTitlePropsT} from './types';
import {useSetRecoilState} from 'recoil';
import {selectedTabState} from '../../states/general';

const TabsButton: React.FC<TabsButtonPropsT> = ({...props}) => {
  return <StyledTabsButton {...props} />;
};

const TabTitle = (props: TabTitlePropsT) => {
  const {title, setSelectedTab, index, isActive, buttonTab} = props;

  const handleOnClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return buttonTab ? (
    <ButtonTab theme={isActive ? 'light' : ''} $isActive={isActive} onClick={handleOnClick}>
      {title}
    </ButtonTab>
  ) : (
    <TabsButton onClick={handleOnClick} variant={isActive ? 'primary' : 'secondary'}>
      {title}
    </TabsButton>
  );
};

export const Tab = ({children}: TabProps) => <TabItem>{children}</TabItem>;

export const Tabs = (props: TabsPropsT) => {
  const {children, preSelectedTabIndex} = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(preSelectedTabIndex || 0);
  const setSelectedTabState = useSetRecoilState(selectedTabState);
  const {isHeaderNav} = props;
  if (isHeaderNav) {
    setSelectedTabState(selectedTabIndex);
  }
  const handleSelect = (index: number) => () => {
    props?.onSelect && props?.onSelect(index);
  };
  return (
    <>
      <TabsWrapper>
        {Array.isArray(children) ? (
          children.map((item, index) => (
            <div key={item.props.title} onClick={handleSelect(index)}>
              <TabTitle
                title={item.props.title}
                index={index}
                buttonTab={item.props.buttonTab ? true : false}
                isActive={index === selectedTabIndex}
                setSelectedTab={setSelectedTabIndex}
              />
            </div>
          ))
        ) : (
          <div key={children.props.title} onClick={handleSelect(0)}>
            <TabTitle
              title={children.props.title}
              index={0}
              buttonTab={children.props.buttonTab ? true : false}
              isActive={true}
              setSelectedTab={setSelectedTabIndex}
            />
          </div>
        )}
      </TabsWrapper>
      {Array.isArray(children) ? children[selectedTabIndex] : children}
    </>
  );
};
