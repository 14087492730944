import React, {useEffect} from 'react';
import {useViewer} from '../../hooks/auth';
import {toPaymentFormInitial} from '../../helpers/payment';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {PaymentForm} from '../../components/PaymentForm';
import {usePayment} from '../../hooks/payment';
import {STRIPE, STRIPE_ACCOUNT} from '../../constants/env';
import {StripeConstructorOptions} from '@stripe/stripe-js';
import {useGetBalanceDue, useOrderTime} from '../../hooks/orders';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {purchaseError} from '../../states/purchase';
import {checkoutState} from '../../states/order';

export type PaymentPropsT = {
  showPayButtons?: boolean;
  setCheckPolicy?: (v: boolean) => void;
  orderCreatedAt?: Date;
  stripeAccount?: string;
  paymentSkipping?: boolean;
  order?: any;
  isAdmission?: boolean;
};

export const Payment: React.FC<PaymentPropsT> = ({
  showPayButtons,
  setCheckPolicy,
  orderCreatedAt,
  stripeAccount,
  paymentSkipping,
  order,
  isAdmission,
}) => {
  const viewer = useViewer();
  const initial = toPaymentFormInitial(viewer);
  const setCheckout = useSetRecoilState(checkoutState);
  const paymentData = usePayment();
  const {timeLeft} = useOrderTime(orderCreatedAt);
  const [error, setError] = useRecoilState(purchaseError);
  const balanceDue = useGetBalanceDue();
  useEffect(() => {
    setCheckout({cardFilled: false});
  }, []);
  const stripeAccountId = stripeAccount || STRIPE_ACCOUNT;
  const STRIPE_ACC: StripeConstructorOptions = {
    stripeAccount: stripeAccountId,
  };
  const stripePromise = STRIPE ? loadStripe(STRIPE, STRIPE_ACC) : null;
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        initial={initial}
        paymentData={paymentData}
        showPayButtons={showPayButtons}
        setCheckPolicy={setCheckPolicy}
        timeLeft={timeLeft}
        purchaseError={error}
        setPurchaseError={setError}
        setCheckout={setCheckout}
        balanceDue={balanceDue}
        order={order}
        paymentSkipping={paymentSkipping}
        isAdmission={isAdmission}
      />
    </Elements>
  );
};
