import {css} from 'styled-components';

const primaryColors = {
  midnightBlue: '#041C2C',
  darkGold: '#937E6B',
  enchantGold: '#AE9277',
  darkBlack: '#000000',
  alarmColor: '#964439',
};

const secondaryColors = {
  brightGold: '#E4D19D',
  skyBlue: '#BECDCC',
  lightRed: '#E6AFA7',
  roseRed: '#CD796E',
  lightRose: '#D6C2B0',
  lightGold: '#F3EFEA',
  seaBlue: '#1F4762',
  nightBlue: '#265574',
  yellowGold: '#ce931f',
  greyBackground: 'rgba(243, 239, 234, 0.6)',
};

const systemColors = {
  darkBlack: '#020E16',
  gray1: '#495258',
  gray2: '#9A9FA2',
  gray3: '#CCCFD0',
  gray4: '#E6E7E8',
  gray5: '#F5F5F6',
  gray6: '#FDFDFD',
  lightGray: '#C9C9C9',
  white: '#FFFFFF',
  active: '#fdeac4',
};

const stateColors = {
  red: '#964439',
  green: '#227B75',
  orange: '#BF7A38',
  gray: '#9A9FA2',
  darkGray: '#495258',
};

const Colors = {
  primary: primaryColors,
  secondary: secondaryColors,
  system: systemColors,
  state: stateColors,
};

export const whiteShadow = css`
  box-shadow: 0px 0px 20px #e7d6a4;
`;

export const noShadow = css`
  box-shadow: 0px 0px 0px #e7d6a4;
`;

export const linearStraightGradient = css`
  background-image: linear-gradient(
    90.01deg,
    #a58569 0.01%,
    #c2a06a 25.07%,
    #dfc999 51.47%,
    #c2a06a 76.74%,
    #a58569 100.23%
  );
  background-image: -o-linear-gradient(
    90.01deg,
    #a58569 0.01%,
    #c2a06a 25.07%,
    #dfc999 51.47%,
    #c2a06a 76.74%,
    #a58569 100.23%
  );
  background-image: -moz-linear-gradient(
    90.01deg,
    #a58569 0.01%,
    #c2a06a 25.07%,
    #dfc999 51.47%,
    #c2a06a 76.74%,
    #a58569 100.23%
  );
  background-image: -webkit-linear-gradient(
    90.01deg,
    #a58569 0.01%,
    #c2a06a 25.07%,
    #dfc999 51.47%,
    #c2a06a 76.74%,
    #a58569 100.23%
  );
  background-image: -ms-linear-gradient(
    90.01deg,
    #a58569 0.01%,
    #c2a06a 25.07%,
    #dfc999 51.47%,
    #c2a06a 76.74%,
    #a58569 100.23%
  );
  background-image: -webkit-gradient(
    90.01deg,
    #a58569 0.01%,
    #c2a06a 25.07%,
    #dfc999 51.47%,
    #c2a06a 76.74%,
    #a58569 100.23%
  );
`;
export const linearLeftGradient = css`
  background-image: linear-gradient(
    360deg,
    #b9976a 0%,
    #c8ab78 11.46%,
    #e4d19d 33.33%,
    #f9ecc8 52.08%,
    #e4d19d 68.23%,
    #d8c288 85.42%,
    #d4b67a 100%
  );
  background-image: -o-linear-gradient(
    360deg,
    #b9976a 0%,
    #c8ab78 11.46%,
    #e4d19d 33.33%,
    #f9ecc8 52.08%,
    #e4d19d 68.23%,
    #d8c288 85.42%,
    #d4b67a 100%
  );
  background-image: -moz-linear-gradient(
    360deg,
    #b9976a 0%,
    #c8ab78 11.46%,
    #e4d19d 33.33%,
    #f9ecc8 52.08%,
    #e4d19d 68.23%,
    #d8c288 85.42%,
    #d4b67a 100%
  );
  background-image: -webkit-linear-gradient(
    360deg,
    #b9976a 0%,
    #c8ab78 11.46%,
    #e4d19d 33.33%,
    #f9ecc8 52.08%,
    #e4d19d 68.23%,
    #d8c288 85.42%,
    #d4b67a 100%
  );
  background-image: -ms-linear-gradient(
    360deg,
    #b9976a 0%,
    #c8ab78 11.46%,
    #e4d19d 33.33%,
    #f9ecc8 52.08%,
    #e4d19d 68.23%,
    #d8c288 85.42%,
    #d4b67a 100%
  );
  background-image: -webkit-gradient(
    360deg,
    #b9976a 0%,
    #c8ab78 11.46%,
    #e4d19d 33.33%,
    #f9ecc8 52.08%,
    #e4d19d 68.23%,
    #d8c288 85.42%,
    #d4b67a 100%
  );
`;

export const undrlineGradient = css`
  border-image: linear-gradient(
      263.17deg,
      #b9976a -1.06%,
      #e4d19d 28.55%,
      #f9ecc8 47.62%,
      #e4d19d 71.7%,
      #d4b67a 95.28%
    )
    1;
`;

export const linearRightGradient = css`
  background-image: linear-gradient(
    263.17deg,
    #a58569 -1.06%,
    #c2a06a 23.03%,
    #dfc999 48.41%,
    #c2a06a 72.7%,
    #a58569 95.28%
  );
  background-image: -o-linear-gradient(
    263.17deg,
    #a58569 -1.06%,
    #c2a06a 23.03%,
    #dfc999 48.41%,
    #c2a06a 72.7%,
    #a58569 95.28%
  );
  background-image: -moz-linear-gradient(
    263.17deg,
    #a58569 -1.06%,
    #c2a06a 23.03%,
    #dfc999 48.41%,
    #c2a06a 72.7%,
    #a58569 95.28%
  );
  background-image: -webkit-linear-gradient(
    263.17deg,
    #a58569 -1.06%,
    #c2a06a 23.03%,
    #dfc999 48.41%,
    #c2a06a 72.7%,
    #a58569 95.28%
  );
  background-image: -ms-linear-gradient(
    263.17deg,
    #a58569 -1.06%,
    #c2a06a 23.03%,
    #dfc999 48.41%,
    #c2a06a 72.7%,
    #a58569 95.28%
  );
  background-image: -webkit-gradient(
    263.17deg,
    #a58569 -1.06%,
    #c2a06a 23.03%,
    #dfc999 48.41%,
    #c2a06a 72.7%,
    #a58569 95.28%
  );
`;
export const linearGoldGradient = css`
  background-image: linear-gradient(
    263.17deg,
    #b9976a -1.06%,
    #e4d19d 28.55%,
    #f9ecc8 47.62%,
    #e4d19d 71.7%,
    #d4b67a 95.28%
  );
  background-image: -o-linear-gradient(
    263.17deg,
    #b9976a -1.06%,
    #e4d19d 28.55%,
    #f9ecc8 47.62%,
    #e4d19d 71.7%,
    #d4b67a 95.28%
  );
  background-image: -moz-linear-gradient(
    263.17deg,
    #b9976a -1.06%,
    #e4d19d 28.55%,
    #f9ecc8 47.62%,
    #e4d19d 71.7%,
    #d4b67a 95.28%
  );
  background-image: -webkit-linear-gradient(
    263.17deg,
    #b9976a -1.06%,
    #e4d19d 28.55%,
    #f9ecc8 47.62%,
    #e4d19d 71.7%,
    #d4b67a 95.28%
  );
  background-image: -ms-linear-gradient(
    263.17deg,
    #b9976a -1.06%,
    #e4d19d 28.55%,
    #f9ecc8 47.62%,
    #e4d19d 71.7%,
    #d4b67a 95.28%
  );
  background-image: -webkit-gradient(
    263.17deg,
    #b9976a -1.06%,
    #e4d19d 28.55%,
    #f9ecc8 47.62%,
    #e4d19d 71.7%,
    #d4b67a 95.28%
  );
`;

export const linearWhiteGradient = css`
  background-image: linear-gradient(97.25deg, #e0dacd 1.59%, #ffffff 49.94%, #e0dacd 97.79%);
  background-image: -o-linear-gradient(97.25deg, #e0dacd 1.59%, #ffffff 49.94%, #e0dacd 97.79%);
  background-image: -moz-linear-gradient(97.25deg, #e0dacd 1.59%, #ffffff 49.94%, #e0dacd 97.79%);
  background-image: -webkit-linear-gradient(97.25deg, #e0dacd 1.59%, #ffffff 49.94%, #e0dacd 97.79%);
  background-image: -ms-linear-gradient(97.25deg, #e0dacd 1.59%, #ffffff 49.94%, #e0dacd 97.79%);
  background-image: -webkit-gradient(97.25deg, #e0dacd 1.59%, #ffffff 49.94%, #e0dacd 97.79%);
`;

// export const horizontalGradient = css`
//   linear-gradient(360deg,#b9976a 0%,#c8ab78 11.46%,#e4d19d 33.33%,#f9ecc8 52.08%,#e4d19d 68.23%,#d8c288 85.42%,#d4b67a 100%);
// `;

export const horizontalGradientBG = css`
  background-image: linear-gradient(
    360deg,
    #b9976a 0%,
    #c8ab78 11.46%,
    #e4d19d 33.33%,
    #f9ecc8 52.08%,
    #e4d19d 68.23%,
    #d8c288 85.42%,
    #d4b67a 100%
  );
  background-image: -o-linear-gradient(
    360deg,
    #b9976a 0%,
    #c8ab78 11.46%,
    #e4d19d 33.33%,
    #f9ecc8 52.08%,
    #e4d19d 68.23%,
    #d8c288 85.42%,
    #d4b67a 100%
  );
  background-image: -moz-linear-gradient(
    360deg,
    #b9976a 0%,
    #c8ab78 11.46%,
    #e4d19d 33.33%,
    #f9ecc8 52.08%,
    #e4d19d 68.23%,
    #d8c288 85.42%,
    #d4b67a 100%
  );
  background-image: -webkit-linear-gradient(
    360deg,
    #b9976a 0%,
    #c8ab78 11.46%,
    #e4d19d 33.33%,
    #f9ecc8 52.08%,
    #e4d19d 68.23%,
    #d8c288 85.42%,
    #d4b67a 100%
  );
  background-image: -ms-linear-gradient(
    360deg,
    #b9976a 0%,
    #c8ab78 11.46%,
    #e4d19d 33.33%,
    #f9ecc8 52.08%,
    #e4d19d 68.23%,
    #d8c288 85.42%,
    #d4b67a 100%
  );
  background-image: -webkit-gradient(
    360deg,
    #b9976a 0%,
    #c8ab78 11.46%,
    #e4d19d 33.33%,
    #f9ecc8 52.08%,
    #e4d19d 68.23%,
    #d8c288 85.42%,
    #d4b67a 100%
  );
`;

export const emblemGradient = css`
  background: linear-gradient(
    119.54deg,
    #a48469 -13.78%,
    #c2a06a 23.9%,
    #dec999 33.73%,
    #d3b674 46.84%,
    #a48469 71.41%,
    #c2a06a 97.63%,
    #dec999 110.74%,
    #c2a06a 123.84%,
    #a48469 146.78%
  );
  background-image: -o-linear-gradient(
    119.54deg,
    #a48469 -13.78%,
    #c2a06a 23.9%,
    #dec999 33.73%,
    #d3b674 46.84%,
    #a48469 71.41%,
    #c2a06a 97.63%,
    #dec999 110.74%,
    #c2a06a 123.84%,
    #a48469 146.78%
  );
  background-image: -moz-linear-gradient(
    119.54deg,
    #a48469 -13.78%,
    #c2a06a 23.9%,
    #dec999 33.73%,
    #d3b674 46.84%,
    #a48469 71.41%,
    #c2a06a 97.63%,
    #dec999 110.74%,
    #c2a06a 123.84%,
    #a48469 146.78%
  );
  background-image: -webkit-linear-gradient(
    119.54deg,
    #a48469 -13.78%,
    #c2a06a 23.9%,
    #dec999 33.73%,
    #d3b674 46.84%,
    #a48469 71.41%,
    #c2a06a 97.63%,
    #dec999 110.74%,
    #c2a06a 123.84%,
    #a48469 146.78%
  );
  background-image: -ms-linear-gradient(
    119.54deg,
    #a48469 -13.78%,
    #c2a06a 23.9%,
    #dec999 33.73%,
    #d3b674 46.84%,
    #a48469 71.41%,
    #c2a06a 97.63%,
    #dec999 110.74%,
    #c2a06a 123.84%,
    #a48469 146.78%
  );
  background-image: -webkit-gradient(
    119.54deg,
    #a48469 -13.78%,
    #c2a06a 23.9%,
    #dec999 33.73%,
    #d3b674 46.84%,
    #a48469 71.41%,
    #c2a06a 97.63%,
    #dec999 110.74%,
    #c2a06a 123.84%,
    #a48469 146.78%
  );
`;
export const hoverGoldGradient = css`
  background: linear-gradient(97.25deg, #d8b89c 1.59%, #ead3ae 25.64%, #eee2c6 50.98%, #e0c498 75.24%, #ddc6b3 97.79%);
`;

export const blueLinearRightGradient = css`
  background-image: linear-gradient(94.66deg, #041c2c 29.64%, #1b3e55 52.42%, #041c2c 102.93%);
  background-image: -o-linear-gradient(94.66deg, #041c2c 29.64%, #1b3e55 52.42%, #041c2c 102.93%);
  background-image: -moz-linear-gradient(94.66deg, #041c2c 29.64%, #1b3e55 52.42%, #041c2c 102.93%);
  background-image: -webkit-linear-gradient(94.66deg, #041c2c 29.64%, #1b3e55 52.42%, #041c2c 102.93%);
  background-image: -ms-linear-gradient(94.66deg, #041c2c 29.64%, #1b3e55 52.42%, #041c2c 102.93%);
  background-image: -webkit-gradient(94.66deg, #041c2c 29.64%, #1b3e55 52.42%, #041c2c 102.93%);
`;
export const blueLinearBottomGradient = css`
  background: linear-gradient(0.04deg, #041c2c 10.75%, rgba(4, 28, 44, 0) 57.44%);
  background-image: -o-linear-gradient(0.04deg, #041c2c 10.75%, rgba(4, 28, 44, 0) 57.44%);
  background-image: -moz-linear-gradient(0.04deg, #041c2c 10.75%, rgba(4, 28, 44, 0) 57.44%);
  background-image: -webkit-linear-gradient(0.04deg, #041c2c 10.75%, rgba(4, 28, 44, 0) 57.44%);
  background-image: -ms-linear-gradient(0.04deg, #041c2c 10.75%, rgba(4, 28, 44, 0) 57.44%);
  background-image: -webkit-gradient(0.04deg, #041c2c 10.75%, rgba(4, 28, 44, 0) 57.44%);
`;

export const blueLinearCenterGradient = css`
  background: linear-gradient(
    165deg,
    rgba(5, 32, 51, 1) 16%,
    rgba(18, 42, 56, 1) 33%,
    rgba(10, 41, 59, 1) 60%,
    rgba(7, 33, 51, 1) 78%
  );
  background-image: -o-linear-gradient(
    165deg,
    rgba(5, 32, 51, 1) 16%,
    rgba(18, 42, 56, 1) 33%,
    rgba(10, 41, 59, 1) 60%,
    rgba(7, 33, 51, 1) 78%
  );
  background-image: -moz-linear-gradient(
    165deg,
    rgba(5, 32, 51, 1) 16%,
    rgba(18, 42, 56, 1) 33%,
    rgba(10, 41, 59, 1) 60%,
    rgba(7, 33, 51, 1) 78%
  );
  background-image: -webkit-linear-gradient(
    165deg,
    rgba(5, 32, 51, 1) 16%,
    rgba(18, 42, 56, 1) 33%,
    rgba(10, 41, 59, 1) 60%,
    rgba(7, 33, 51, 1) 78%
  );
  background-image: -ms-linear-gradient(
    165deg,
    rgba(5, 32, 51, 1) 16%,
    rgba(18, 42, 56, 1) 33%,
    rgba(10, 41, 59, 1) 60%,
    rgba(7, 33, 51, 1) 78%
  );
  background-image: -webkit-gradient(
    165deg,
    rgba(5, 32, 51, 1) 16%,
    rgba(18, 42, 56, 1) 33%,
    rgba(10, 41, 59, 1) 60%,
    rgba(7, 33, 51, 1) 78%
  );
`;

export const soldOutGradient = css`
  background-image: linear-gradient(141.98deg, #96453a 16.17%, #c57267 84.3%);
  background-image: -o-linear-gradient(141.98deg, #96453a 16.17%, #c57267 84.3%);
  background-image: -moz-linear-gradient(141.98deg, #96453a 16.17%, #c57267 84.3%);
  background-image: -webkit-linear-gradient(141.98deg, #96453a 16.17%, #c57267 84.3%);
  background-image: -ms-linear-gradient(141.98deg, #96453a 16.17%, #c57267 84.3%);
  background-image: -webkit-gradient(141.98deg, #96453a 16.17%, #c57267 84.3%);
`;

export const sellingFastGradient = css`
  background-image: linear-gradient(310.16deg, #ce931f 10.09%, #fdeac4 119.77%);
  background-image: -o-linear-gradient(310.16deg, #ce931f 10.09%, #fdeac4 119.77%);
  background-image: -moz-linear-gradient(310.16deg, #ce931f 10.09%, #fdeac4 119.77%);
  background-image: -webkit-linear-gradient(310.16deg, #ce931f 10.09%, #fdeac4 119.77%);
  background-image: -ms-linear-gradient(310.16deg, #ce931f 10.09%, #fdeac4 119.77%);
  background-image: -webkit-gradient(310.16deg, #ce931f 10.09%, #fdeac4 119.77%);
`;

export const newSoldOutGradient = css`
  background: linear-gradient(
    97.25deg,
    #b9976a 1.59%,
    #d8c288 22.13%,
    #e4d19d 37.66%,
    #f9ecc8 54.7%,
    #e4d19d 73.23%,
    #d4b67a 97.79%
  );
`;

export const newSellingFastGradient = css`
  background: linear-gradient(97.25deg, #e0dacd 1.59%, #ffffff 41.67%, #dbc491 97.79%);
`;

export const linearMidnightBlue = css`
  background: linear-gradient(
    90deg,
    #041c2c -25.95%,
    #1b3e55 51.05%,
    rgba(63, 109, 139, 0) 93.85%,
    rgba(4, 28, 44, 0) 178.85%
  );
  background-image: -o-linear-gradient(
    90deg,
    #041c2c -25.95%,
    #1b3e55 51.05%,
    rgba(63, 109, 139, 0) 93.85%,
    rgba(4, 28, 44, 0) 178.85%
  );
  background-image: -moz-linear-gradient(
    90deg,
    #041c2c -25.95%,
    #1b3e55 51.05%,
    rgba(63, 109, 139, 0) 93.85%,
    rgba(4, 28, 44, 0) 178.85%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #041c2c -25.95%,
    #1b3e55 51.05%,
    rgba(63, 109, 139, 0) 93.85%,
    rgba(4, 28, 44, 0) 178.85%
  );
  background-image: -ms-linear-gradient(
    90deg,
    #041c2c -25.95%,
    #1b3e55 51.05%,
    rgba(63, 109, 139, 0) 93.85%,
    rgba(4, 28, 44, 0) 178.85%
  );
  background-image: -webkit-gradient(
    90deg,
    #041c2c -25.95%,
    #1b3e55 51.05%,
    rgba(63, 109, 139, 0) 93.85%,
    rgba(4, 28, 44, 0) 178.85%
  );
`;

export const vipGradient = css`
  background: linear-gradient(90.01deg, #b9976a 0.01%, #e4d19d 25.59%, #f9ecc8 49.6%, #d4b67a 100.23%);
`;

export const shadows = {
  goldGlow: '0px 0px 10px #C7B09C;',
  dark: '4px 4px 14px #000E17;',
  dark1: '0px 8px 40px #00020B;',
  dark2: '-4px -4px 14px black',
  lightGold: '0px 0px 20px #E7D6A4',
};

export const palette = Colors;
