import React, {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {creditsAppliedState, deductionAmount} from '../../../states/purchase';
import {PublicButtonStyled} from '../../AppLayout/FooterNavigation/styles';
import {useReferrals} from '../../../hooks/referral';
import {
  DetailWrapper,
  StyledCard,
  StyledText,
  StyledWrapperDescription,
  StylesDataDiscount,
  StylesRedeemCredits,
  StylesTicketType,
  StyledTotal,
  StyledBoldTotal,
  StyledBalance,
  Spacer,
  BalanceInfoText,
  PaidInfoText,
  FacilityFeeWrapper,
} from './styles';
import {OrderDetailsProps} from './types';
import {OrderDetailsHeader} from './components/Header';
import {TextBody2} from '../../Typography/styles';
import {TicketsInfoList} from './components/TicketInfo/TicketInfoWithLogic';
import {FacilityFeeTooltip} from '../../Tooltips';
import gift from '../../assets/icons/ref_gift.png';
// import PromoCode from '../../../containers/PromoCode';

export const OrderDetailsCard: React.FC<OrderDetailsProps> = ({data, paymentForm, paymentSkipping}) => {
  const [creditsRedeemed, setcreditsRedeemed] = React.useState(false);
  const creditsApplied = useRecoilValue(creditsAppliedState);
  const setCreditsApplied = useSetRecoilState(creditsAppliedState);
  const netTotal = useRecoilValue(deductionAmount);
  const setNetTotal = useSetRecoilState(deductionAmount);
  // const [netTotal, setNetTotal] = React.useState<netAmount>(data?.balance);
  const referralPoints = useReferrals();
  const remainingCredits = referralPoints?.referrals?.remaining;
  useEffect(() => {
    setCreditsApplied(creditsRedeemed);
    let deduction: any = '';
    const theTotal = paymentForm?.balance && parseFloat(paymentForm?.balance.replace('$', ''));
    if (creditsApplied && remainingCredits && theTotal) {
      deduction = (theTotal - remainingCredits).toFixed(2);
    } else {
      deduction = typeof theTotal === 'number' ? theTotal && theTotal.toFixed(2) : theTotal;
    }
    setNetTotal(`$${deduction}`);
  }, [paymentForm, creditsRedeemed, creditsApplied, deductionAmount, netTotal]);
  // const confirmedNetTotal = isPaymentSkipping ? '$0' : netTotal;
  const applyCredits = () => {
    setcreditsRedeemed(!creditsRedeemed);
  };
  return (
    <DetailWrapper>
      <OrderDetailsHeader data={data} paymentForm={paymentForm}></OrderDetailsHeader>
      {!paymentSkipping && (
        <StyledCard>
          <StyledWrapperDescription>
            <TextBody2>Ticket Type</TextBody2>
            <TextBody2>Amount</TextBody2>
          </StyledWrapperDescription>
          <TicketsInfoList items={paymentForm?.items} />
          {/*{!data.isProtection && (*/}
          {/*  <StylesDataChangeFee>*/}
          {/*    <StyledText>*/}
          {/*      Change Fee*/}
          {/*      <StyledIcon>*/}
          {/*        <InfoCircleOutlined />*/}
          {/*      </StyledIcon>*/}
          {/*    </StyledText>{' '}*/}
          {/*    <StyledText>{data.changeFee}</StyledText>*/}
          {/*  </StylesDataChangeFee>*/}
          {/*)}*/}
          {/* {showPromo && <PromoCode cartId={data?.cartId} sellerId={data?.sellerId} />} */}
          <StylesTicketType>
            {(remainingCredits as number) > 0 && (
              <StylesTicketType>
                <StylesDataDiscount>
                  <StylesRedeemCredits>
                    <PublicButtonStyled variant={'plainBanner'}>
                      <img src={gift} alt="" style={{margin: '0 12px 4px 0'}} />
                      {`Enchant Credits: $${referralPoints?.referrals?.remaining}`}
                    </PublicButtonStyled>
                    <PublicButtonStyled
                      variant={creditsRedeemed ? 'plainTrans' : 'plainTransDark'}
                      onClick={applyCredits}>
                      {creditsRedeemed ? 'Remove' : 'Apply'}
                    </PublicButtonStyled>
                  </StylesRedeemCredits>
                </StylesDataDiscount>
              </StylesTicketType>
            )}
            <StylesDataDiscount>
              <StyledText>Subtotal</StyledText> <StyledText>{paymentForm?.subTotal}</StyledText>
            </StylesDataDiscount>
            {paymentForm?.discount && (
              <StylesDataDiscount>
                <StyledText>Discount</StyledText> <StyledText>{paymentForm?.discount}</StyledText>
              </StylesDataDiscount>
            )}
            <StylesDataDiscount>
              <StyledText>Tax</StyledText> <StyledText>{paymentForm?.tax}</StyledText>
            </StylesDataDiscount>
            {/* {data?.promo && (
            <StylesDataDiscount>
              <StyledText>Promo</StyledText> <StyledText>{data.promo}</StyledText>
            </StylesDataDiscount>
          )} */}
            <StylesDataDiscount>
              <FacilityFeeWrapper>
                <StyledText>Facility Fee</StyledText>
                <FacilityFeeTooltip />
              </FacilityFeeWrapper>
              <StyledText>{paymentForm?.facilityFee}</StyledText>
            </StylesDataDiscount>
          </StylesTicketType>
          <StyledTotal>
            <StyledBoldTotal>Total</StyledBoldTotal>
            <StyledBoldTotal>{paymentForm?.total}</StyledBoldTotal>
          </StyledTotal>
          <Spacer />
          <StyledBalance>
            <PaidInfoText>Total Paid</PaidInfoText>
            <PaidInfoText>{paymentForm?.totalPaid}</PaidInfoText>
          </StyledBalance>
          {creditsRedeemed && (
            <StyledBalance>
              <PaidInfoText>Enchant Credits</PaidInfoText>
              <PaidInfoText>{`-$${Number(referralPoints?.referrals?.remaining).toFixed(2)}`}</PaidInfoText>
            </StyledBalance>
          )}
          {/* {isPaymentSkipping && (
            <StyledBalance>
              <PaidInfoText>Free Date Change</PaidInfoText>
              <PaidInfoText>{`-${netTotal}`}</PaidInfoText>
            </StyledBalance>
          )} */}
          <StyledBalance>
            <BalanceInfoText>Balance Due</BalanceInfoText>
            <BalanceInfoText>{netTotal}</BalanceInfoText>
          </StyledBalance>
        </StyledCard>
      )}
    </DetailWrapper>
  );
};
