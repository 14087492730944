import {setVipT, VipSelectedStateT} from '../../../helpers/upgradeToVip';

export interface TicketsVipPassT {
  type: string;
  price: number;
  id: string;
  count: number;
  index: string;
  name: string;
}
export type TicketType = 'VIP Pass' | 'VIP Elf Guide';

export interface TicketVipElfProps {
  type: TicketType;
  description: string;
  ticketType: TicketsVipPassT[];
  isActive: boolean;
  id: string;
  index: string;
}

export interface FunnelCardProps {
  data: TicketVipElfProps;
  setVip: setVipT;
  vipState: VipSelectedStateT;
}

export interface TypeParam {
  children: any;
  theme: any;
  type: TicketType;
}

export interface ActiveProps {
  $isActive: boolean;
}

export enum NameCard {
  vip = 'VIP Pass',
  elf = 'VIP Elf Guide',
}
