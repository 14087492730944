import styled, {css} from 'styled-components';

import {Media} from '../theme/breakpoints';
import {Text1, Heading6, SmallBold, Text2} from '../Typography';
import {TextBody1, TextBody2, TextSixteenBodyBold, TextSmallBold} from '../Typography/styles';
import {corporatedSecondaryStyles} from '../Button/styles';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  padding: 24px;
  gap: 16px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ButtonWrapper = styled.div`
  width: 161px;
  height: 43px;
`;

export const CardLabel = styled(TextBody1)`
  margin-right: ${({theme}) => theme.spacer._2};
  text-transform: capitalize;
  ${Media.down.m} {
    font-size: 14px;
  }
`;

export const CardValue = styled(TextSixteenBodyBold)`
  ${Media.down.l} {
    line-height: 19px;
  }
  ${Media.down.m} {
    font-size: 14px;
    max-width: 223px;
    word-wrap: break-word;
    text-align: right;
  }
`;

// /////
interface Props {
  $show: boolean;
}
export const showPaymentDetailPurchaise = css`
  display: flex;
  justify-content: space-between;
  ${Media.down.m} {
    flex-direction: column;
    padding: 16px 0;
  }
`;

export const notShowPaymentDetailPurchaise = css`
  display: flex;
  justify-content: flex-start;

  ${Media.down.m} {
    flex-direction: column;
    padding: 16px 0;
    margin-left: 0;
  }
`;
const showPaymentDetail = (show: boolean) => {
  switch (show) {
    case true:
      return showPaymentDetailPurchaise;
    default:
      return notShowPaymentDetailPurchaise;
  }
};

export const OrderCardContentWrapperLogic = styled.div<Props>`
  ${({$show}) => showPaymentDetail($show)};
`;

export const OrderCardWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  padding-left: 26px;
`;
export const CardInfoColumnWrapper = styled.div`
  display: flex;
`;

export const MobileOrderCardWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: ${({theme}) => theme.palette.secondary.lightGold};
  padding: 8px 8px;
`;

export const CardInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 255px;
  padding: 18px 0;
  padding-right: 30px;
  ${Media.down.xl} {
    padding-right: 40px;
  }
  ${Media.down.m} {
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    padding: 0;
  }
`;

export const CardInfoLabel = styled(Text1)`
  color: ${({theme}) => theme.palette.system.gray1};
  margin-bottom: ${({theme}) => theme.spacer._0};
`;

export const CardInfoLabelMobile = styled(TextSmallBold)`
  margin-right: 5px;
`;

export const CardInfoTitle = styled(Heading6)``;

export const MobileCardInfoTitle = styled(SmallBold)`
  color: ${({theme}) => theme.palette.system.darkBlack};
`;

export const CardInfoValue = styled(Text1)`
  margin-top: ${({theme}) => theme.spacer._0};
`;

export const MobileCardInfoValue = styled(Text2)``;

export const PaymentColumn = styled.div`
  background-color: ${({theme}) => theme.palette.secondary.lightRose};
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
`;

export const PaymentTitle = CardInfoValue;

export const PaymentValue = CardInfoTitle;

export const ValueWrapper = styled(Text1)`
  display: flex;
  flex-direction: column;
`;

export const ValueWrapperMobile = styled(TextBody2)`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
`;

// Card No Logic

export const MobileCardInfoColumn = styled.div`
  width: 100%;
`;
export const AssignCardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.secondary.lightRed};
  padding: 24px;
  gap: 24px;
  ${Media.down.l} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AssignCardTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AssignCardText = styled(Text1)`
  display: block;
  margin-left: ${({theme}) => theme.spacer._1};
  max-width: 80%;
`;

export const AssignButton = styled.a`
  ${corporatedSecondaryStyles};
  padding: 12px 24px;
  height: 43px;
  border: none;
  text-transform: uppercase;
  border-radius: 4px;
  white-space: nowrap;
  font-weight: bold;
`;
