import React from 'react';

import {NewInput, NewPublicButton, StyledWrapper, StylesTicketType} from './styles';
import {OrderProps} from './types';
import {TextSmallBold} from '../../../../Typography/styles';

const InputPromo: React.FC<OrderProps> = ({onClick, valueInput, takeInfo, available, applyAvailable}) => {
  if (!available) return null;
  return (
    <StyledWrapper>
      <TextSmallBold>Promo Code</TextSmallBold>
      <StylesTicketType>
        <NewInput placeholder="" value={valueInput} onChange={takeInfo} />
        <NewPublicButton onClick={onClick} variant={'secondary'} disabled={!applyAvailable}>
          APPLY
        </NewPublicButton>
      </StylesTicketType>
    </StyledWrapper>
  );
};

export default InputPromo;
