import React, {useState} from 'react';

import {RightSideWrapper} from './styles';
import {AddPurchaisePaymentDetailNoConfirmProps} from './types';
import {OrderDetailsCardWithLogic} from '../../ui-kit/Cards/OrderDetailsCard/OrderDetailsCardWithLogic';

export const BillingInfoWithLogic: React.FC<AddPurchaisePaymentDetailNoConfirmProps> = ({
  orderDetailsInfo,
  paymentSkipping,
}) => {
  const [valueInput, setValueInput] = useState<string>('');
  const onClickOrder = () => {
    setValueInput('');
  };

  return (
    <RightSideWrapper>
      <OrderDetailsCardWithLogic
        onClick={onClickOrder}
        data={orderDetailsInfo}
        valueInput={valueInput}
        setValueInput={setValueInput}
        paymentSkipping={paymentSkipping}
      />
    </RightSideWrapper>
  );
};
