import React from 'react';
import {
  PageWrapper,
  ContentWrapper,
  LogoSilver,
  ConfirmOrderTitle,
  ConfirmOrderDescr,
  DetailedCardWrapper,
  BackButtonStyled,
} from './styles';
import DetailedCard from '../../ui-kit/Order/DetailedCard';
import {CurrentProps} from '../../ui-kit/Order/types';
import {HomeLink} from '../../ui-kit/Button/LinkButton';
import {toUpper} from '../../helpers/helpers';

type CancelConfirmOrderPagePropsT = {
  dataOrder: CurrentProps[];
  onClickToBack: () => void;
};

export const CancelConfirmOrderPage: React.FC<CancelConfirmOrderPagePropsT> = ({dataOrder, onClickToBack}) => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <HomeLink>
          <LogoSilver />
        </HomeLink>
        <ConfirmOrderTitle>{toUpper('Your order has been cancelled')}</ConfirmOrderTitle>
        <ConfirmOrderDescr>
          You have been emailed a cancel confirmation. Refunds will take 7-10 business days to go back onto your card.
        </ConfirmOrderDescr>
        <DetailedCardWrapper>
          <DetailedCard data={dataOrder} />
        </DetailedCardWrapper>
        <BackButtonStyled variant={'tertiary'} onClick={onClickToBack}>
          {toUpper('Back to My Account')}
        </BackButtonStyled>
      </ContentWrapper>
    </PageWrapper>
  );
};
