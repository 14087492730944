import React from 'react';
import {useFetchSession} from '../hooks/auth';
import {WithChildren} from '../types/helpers';
import {PageLoader} from '../ui-kit/Loader';
import {useIntercomAnalytics} from '../hooks/customerIO';

const CurrentUser: React.FC<WithChildren> = ({children}) => {
  const session = useFetchSession();
  useIntercomAnalytics();
  if (session?.loading) return <PageLoader />;

  return children as React.ReactElement;
};

export default CurrentUser;
