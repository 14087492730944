import React from 'react';
import {
  StyledTitle,
  StyledTitleWrapper,
  StyledPageWrapper,
  StyledContentWrapper,
  ErrorLine,
  StyledDescription,
} from './styles';
import {DesktopView} from '../../ui-kit/AppLayout/View';
import {PurchaseAdmissionPagePropsT} from './types';
import {CenteredLoader} from '../../ui-kit/Loader';
import {OrderInfoComponent} from '../OrdersInfo';
import {TicketsPicker} from './TicketsPicker';
import {OrderWrapper, ReviewContentContainer} from '../UpgradeVip/styles';
import {OrderCardWithLogic} from '../../ui-kit/Order/OrderCardWithLogic';
import {Payment} from '../../containers/Payment';
import {BillingInfo} from '../UpgradeVip/Form';
import {Label} from '../../ui-kit/Label';

export const PurchaseAdmissionPage: React.FC<PurchaseAdmissionPagePropsT> = ({
  eventsLoading,
  orderLoading,
  ticketsLoading,
  tickets,
  order,
  step,
  valueState,
  changeTickets,
  paymentInfo,
  orderInfo,
  orderCreatedAt,
  paymentForm,
  dataOrderDetails,
  vipInfo,
  error,
  hasOwl,
}) => {
  const loading = eventsLoading || orderLoading || ticketsLoading;
  const skipPayment = Number(paymentForm.balance_numeric) <= 0;
  return (
    <StyledPageWrapper>
      {step === '1' && (
        <>
          <StyledContentWrapper>
            <StyledTitleWrapper>
              <StyledTitle>Please select new tickets for your order below.</StyledTitle>
            </StyledTitleWrapper>
            <DesktopView>
              <OrderInfoComponent orderInfo={order} orderLoading={orderLoading} />
            </DesktopView>
          </StyledContentWrapper>
          <OrderWrapper>
            <OrderCardWithLogic
              paymentInfo={paymentInfo}
              data={orderInfo}
              show={true}
              loading={orderLoading}
              icon="plus"
            />
          </OrderWrapper>
          {loading ? (
            <CenteredLoader variant={'light'} />
          ) : (
            <TicketsPicker
              tickets={tickets}
              valueState={valueState}
              changeTickets={changeTickets}
              vipInfo={vipInfo}
              hasOwl={hasOwl}
            />
          )}
        </>
      )}
      {step === '2' && (
        <>
          <StyledContentWrapper>
            <StyledTitleWrapper>
              {/* <StyledTitle>{capitalize('Please select new add ons for your order below.')}</StyledTitle> */}
              <StyledTitle>Review your Updated Order</StyledTitle>
              <StyledDescription>
                Please review your order to ensure all the details are correct. Visit protection will be added if you
                originally added it to your order.
              </StyledDescription>
            </StyledTitleWrapper>
            <DesktopView>
              <OrderInfoComponent orderInfo={order} orderLoading={orderLoading} />
            </DesktopView>
          </StyledContentWrapper>
          <OrderWrapper>
            {/* <OrderCardWithLogic paymentInfo={paymentInfo} data={orderInfo} show={true} loading={orderLoading} /> */}
          </OrderWrapper>
          <ReviewContentContainer>
            {paymentForm?.stripeAccountId !== undefined && (
              <Payment
                orderCreatedAt={orderCreatedAt}
                stripeAccount={paymentForm?.stripeAccountId}
                paymentSkipping={skipPayment}
              />
            )}
            <BillingInfo
              dataOrderDetails={dataOrderDetails}
              paymentForm={paymentForm}
              paymentSkipping={skipPayment}
              showFreePurchase={true}
            />
          </ReviewContentContainer>
        </>
      )}
      <ErrorLine>{error && <Label type={'error'} label={error} />}</ErrorLine>
    </StyledPageWrapper>
  );
};
