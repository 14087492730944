import {BookedSessionsT, DataTimeCard, EventTemplateT, TicketGroupT, TicketTypeT} from '../types/events';
import {EventsT, DateEventsT, CustomSessionsT, SessionsT} from '../types/events';
import {DatesTicketsCardProps, TypeVariant} from '../ui-kit/Cards/CardFromTicket/types';
import {CurrentTimeCardProps, PossibleAddonSession} from '../ui-kit/Cards/CardFromTime/types';
import {OrderT} from '../types/orders';
import {useEffect, useMemo, useState} from 'react';
import {formats, special_addons_dates, uniqArrayWithObjects} from '../helpers/helpers';
import {getTZdate, isGA, isGACat} from './orders';

const toCreatePrice = (eventStartDate?: string, eventEndDate?: string, eventsWithPrice?: DateEventsT[]) => {
  const formatStartDate = eventStartDate && new Date(eventStartDate).getTime();
  const formatEndDate = eventEndDate && new Date(eventEndDate).getTime();
  const currentOrderTime = formatStartDate && formatEndDate ? (formatStartDate + formatEndDate) / 2 : '';
  return eventsWithPrice?.reduce((total, session) => {
    const currentDay = session?.price_schedule?._data?.find((item) => {
      const eventStartDate = item ? item?.from : '';
      const eventEndDate = item ? item?.to : '';
      const formatStartDate = eventStartDate && new Date(eventStartDate).getTime();
      const formatEndDate = eventEndDate && new Date(eventEndDate).getTime();
      return currentOrderTime > formatStartDate && currentOrderTime < formatEndDate;
    });
    const amount = currentDay ? Number(currentDay.currency_amount) : 0;
    return total + amount;
  }, 0);
};

export type CD_AddonT = {
  template?: EventTemplateT;
  group?: TicketGroupT;
  ticket_type?: TicketTypeT;
} | null;
export const getPNC = (order?: OrderT, events?: EventsT): CD_AddonT => {
  const withoutSanJose = !order?.sellerName?.toLowerCase()?.includes('san jose');
  const template = events?.event_template?._data?.find((el) => el?.name?.toLowerCase()?.includes('paws'));
  const group = events?.ticket_group?._data?.find((el) => el?.name?.toLowerCase()?.includes('paws'));
  const ticket_type = events?.ticket_type?._data?.find((el) => el?.ticket_group_id === group?.id);
  if (withoutSanJose) return {template, group, ticket_type};
  return null;
};

export const toCreateDataTicketCards = (
  sessions?: SessionsT,
  eventsWithPrice?: DateEventsT[],
  order?: OrderT,
  events?: EventsT,
) => {
  const [dates_, setDates_] = useState<DatesTicketsCardProps[]>([]);
  const ticketsCategoryGA = order?.tickets?.filter((el) => isGA(el));
  const eventStartDate = ticketsCategoryGA ? ticketsCategoryGA[0]?.eventStartDate : '';
  const eventEndDate = ticketsCategoryGA ? ticketsCategoryGA[0]?.eventEndDate : '';
  const selectedTickets = order?.tickets?.filter(
    (el) =>
      el.ticketGroupName.toLowerCase().includes('admission') ||
      el.ticketGroupName.toLowerCase().includes('skating') ||
      el.ticketGroupName.toLowerCase().includes('parking'),
  );
  const hidden = events?.meta?._data?.find((el) => el?.metakey === 'hide_dates')?.value;
  useEffect(() => {
    if (eventsWithPrice?.length) {
      const pnc_event = getPNC(order, events);
      const dates: DatesTicketsCardProps[] = [];
      const currentPrice = toCreatePrice(eventStartDate, eventEndDate, eventsWithPrice);
      if (!sessions?.event_session?._data?.length) return;
      sessions?.event_session?._data.forEach((event) => {
        const eventOnDate = getTZdate(event?.start_datetime, order).format(formats.onDate);
        const currentDate = dates.find(
          (el) =>
            getTZdate(el.title, order).format(formats.onDate) ===
            getTZdate(event?.start_datetime, order).format(formats.onDate),
        );
        if (hidden && hidden?.includes(eventOnDate)) return;

        // <---- check special/theme nights (pnc, etc.)
        const dm_date = getTZdate(event?.start_datetime, order).format(formats.date5);
        const has_pnc = dm_date === special_addons_dates.pnc;
        const addons = [];
        if (has_pnc && pnc_event) addons.push(pnc_event);
        // check ---->

        if (!currentDate) {
          const datePrice = toCreatePrice(event.start_datetime, event.end_datetime, eventsWithPrice);
          dates.push({
            title: getTZdate(event?.start_datetime, order).format() || '',
            id: event?.id || '',
            capacity: event?.capacity || 0,
            soldQuantity: event?.sold_quantity || 0,
            countTickets: selectedTickets?.length || 0,
            price: 0,
            variant: 'default',
            currentPrice: currentPrice || 0,
            datePrice: datePrice || 0,
            addons,
          });
        } else {
          const newDate = {
            ...currentDate,
            capacity: (currentDate.capacity || 0) + event?.capacity,
            soldQuantity: (currentDate.soldQuantity || 0) + event?.sold_quantity,
            addons,
          };
          const currentIdx = dates.findIndex((el) => el === currentDate);
          dates.splice(currentIdx, 1, newDate);
        }
      });
      setDates_(dates);
    }
  }, [sessions?.event_session?._data?.length, eventsWithPrice?.length]);
  const dataTicketCard = useMemo(
    () =>
      dates_.map((el) => {
        const currentCount = el.capacity - el.soldQuantity;
        let currentVariant = TypeVariant.default;
        let currentDescription = '';
        const price =
          el.datePrice && el.currentPrice && el.datePrice > el.currentPrice ? el.datePrice - el.currentPrice : 0;
        if (currentCount > 0 && currentCount < 500) {
          currentVariant = TypeVariant.fast;
          currentDescription = 'Selling Fast!';
        }
        if (currentCount < el.countTickets) {
          currentVariant = TypeVariant.sold;
          currentDescription = 'Sold Out!';
        }
        return {
          ...el,
          description: currentDescription,
          warningLabel: '',
          event: '',
          variant: currentVariant,
          price: price,
        };
      }),
    [dates_?.length],
  );
  return {dataTicketCard};
};

export const getEventTemplateIdByTicketGroup = (ticketGroup: string, events?: EventsT) => {
  const eventGroup = events?.ticket_group?._data.find((el) => el.name === ticketGroup);
  return eventGroup?.event_template_id ? eventGroup?.event_template_id : '';
};

export const getEventTemplateIdByGA = (events?: EventsT) => {
  const eventGroup = events?.ticket_group?._data.find((el) => isGACat(el.name));
  return eventGroup?.event_template_id ? eventGroup?.event_template_id : '';
};

export const getDateByIdCard = (data?: DatesTicketsCardProps[], id?: string, sellerTimezone?: string) => {
  const date = data ? data.find((el) => el.id === id)?.title : '';
  return date ? getTZdate(date, {sellerTimezone}).format() : '';
};

export const toCreateDataTimeCards = (
  _sessions: CustomSessionsT[],
  events?: EventsT,
  order?: OrderT,
  selectedGA?: CurrentTimeCardProps,
) => {
  const obj: {[key: string]: CurrentTimeCardProps[]} = {};

  if (!_sessions) return;
  //filter session with purchased tickets
  const purchasedTickets = order?.tickets?.map((el) => el.ticketGroupName);
  const sessions = _sessions.filter((el) => purchasedTickets?.includes(el.name));

  sessions?.forEach((session) => {
    const normalizeName = session.name.toLowerCase().replaceAll(' ', '_');
    // const normalizeName = eventGroupName[0].toLowerCase() + '_' + eventGroupName.slice(1);
    const ticketGroup = events?.ticket_group?._data.find((el) => el.name === session.name);
    const ticketType = events?.ticket_type?._data.find((el) => el.ticket_group_id === ticketGroup?.id);
    const ticketsCurrentGroup = order?.tickets?.filter((el) => el.ticketGroupName === session.name) || [];

    const isParking = normalizeName.toLowerCase().includes('parking');
    const isGA = normalizeName.toLowerCase().includes('admission');
    const specialEvents = sessions?.filter((event) => event?.isSpecial);
    const filteredSessions = selectedGA
      ? session?.event_session?._data.filter((el) => {
          if (isGA) return true;
          const endGA = selectedGA?.end && new Date(selectedGA.end)?.getTime();
          const startGA = selectedGA?.start && new Date(selectedGA.start)?.getTime();
          const start_1 = el?.start_datetime && new Date(el?.start_datetime)?.getTime();
          const end_1 = el?.end_datetime && new Date(el?.end_datetime)?.getTime();
          if (!endGA || !startGA || !start_1 || !end_1) return false;

          if (isParking) return start_1 <= startGA && end_1 >= endGA;
          return start_1 > startGA;
        })
      : session?.event_session?._data;

    obj[normalizeName] = filteredSessions.map((event) => {
      const startTime = getTZdate(event?.start_datetime, order).format(formats.timeStart);
      const endTime = getTZdate(event?.end_datetime, order).format(formats.timeEnd);
      const parkingTime = isParking ? getTZdate(event?.start_datetime, order).format(formats.time) : '';
      const defaultTime = parkingTime || (startTime && endTime ? `${startTime} - ${endTime}` : '');

      const possibleAddons: PossibleAddonSession[] = [];
      //<-- special events
      if (isGA) {
        specialEvents?.forEach((se) =>
          se?.event_session?._data?.forEach((event_session) => {
            const es_startTime = getTZdate(event_session?.start_datetime, order).format(formats.timeStart);
            if (es_startTime === startTime) {
              possibleAddons.push({
                name: se.name,
                ...event_session,
              });
            }
          }),
        );
      }

      // -->
      const currentCount = event.capacity - event.sold_quantity;
      let currentVariant = TypeVariant.default;
      let currentDescription = '';
      if (currentCount > 0 && currentCount < 50) {
        currentVariant = TypeVariant.fast;
        currentDescription = 'Selling Fast';
      }
      if (currentCount < ticketsCurrentGroup?.length) {
        currentVariant = TypeVariant.sold;
        currentDescription = 'Sold Out';
      }
      return {
        [DataTimeCard.eventGroupName]: session.name || '',
        [DataTimeCard.ticketTypeId]: ticketType?.id || '',
        [DataTimeCard.id]: event?.id || '',
        [DataTimeCard.variant]: currentVariant,
        [DataTimeCard.time]: defaultTime,
        [DataTimeCard.description]: currentDescription,
        [DataTimeCard.warningLabel]: '',
        [DataTimeCard.start]: event?.start_datetime,
        [DataTimeCard.end]: event?.end_datetime,
        [DataTimeCard.addons]: possibleAddons,
      };
    });
  });

  return obj;
};

export const toCreateEventTemplateIds = (
  order: OrderT | undefined,
  events: EventsT | undefined,
  addons?: CD_AddonT[],
) => {
  const ticketNames = order ? order?.tickets?.map((el) => el.ticketGroupName) : [];
  addons?.forEach((el) => el?.group?.name && ticketNames?.push(el?.group?.name));
  if (!ticketNames) return;
  const uniqArray = uniqArrayWithObjects(ticketNames);
  return uniqArray?.map((el) => {
    return {
      eventGroupName: el,
      eventTemplateId: getEventTemplateIdByTicketGroup(el, events),
    };
  });
};

export const getSoldOut = (data?: DatesTicketsCardProps, bookedSessions?: BookedSessionsT, sellerTimezone?: string) => {
  const byDate = getTZdate(data?.title, {sellerTimezone}).format(formats.onDate);

  const parking = bookedSessions?.parkingSessions
    ? !bookedSessions?.parkingSessions
        ?.filter((el) => el?.start?.includes(byDate))
        ?.some((s) => !s?.soldout && s?.capacity !== 0)
    : false;

  const parkingGG = bookedSessions?.parkingGGSessions
    ? !bookedSessions?.parkingGGSessions
        ?.filter((el) => el?.start?.includes(byDate))
        ?.some((s) => !s?.soldout && s?.capacity !== 0)
    : false;

  const parkingGC = bookedSessions?.parkingGCSessions
    ? !bookedSessions?.parkingGCSessions
        ?.filter((el) => el?.start?.includes(byDate))
        ?.some((s) => !s?.soldout && s?.capacity !== 0)
    : false;

  const skating = bookedSessions?.skatingSessions
    ? !bookedSessions?.skatingSessions
        ?.filter((el) => el?.start?.includes(byDate))
        ?.some((s) => !s?.soldout && s?.capacity !== 0)
    : false;

  const ga = bookedSessions?.gaSessions
    ? !bookedSessions?.gaSessions
        ?.filter((el) => el?.start?.includes(byDate))
        ?.some((s) => !s?.soldout && s?.capacity !== 0)
    : false;

  const vip = bookedSessions?.vipSessions
    ? !bookedSessions?.vipSessions
        ?.filter((el) => el?.start?.includes(byDate))
        ?.some((s) => !s?.soldout && s?.capacity !== 0)
    : false;

  const elf = bookedSessions?.elfSessions
    ? !bookedSessions?.elfSessions
        ?.filter((el) => el?.start?.includes(byDate))
        ?.some((s) => !s?.soldout && s?.capacity !== 0)
    : false;

  return {parking, parkingGG, parkingGC, skating, ga, vip, elf};
};
