import React from 'react';
import {ChangeDatePage} from '../../components/ChangeDate';
import {useChangeDate} from '../../hooks/changeDate';
import {toCreateChangeDateOrderDetails} from '../../helpers/changeDate';
import {PageLogoLoader} from '../../ui-kit/Loader';
import {usePurchaseSteps} from '../../hooks/purchase';
import {useHideIntercomLauncher} from '../../hooks/customerIO';

export const ChangeDate = () => {
  const steps = usePurchaseSteps();
  const {
    changeDate,
    setIsDateSelected,
    setIsTimeSelected,
    order,
    dataTicketCard,
    orderData,
    setDateCardId,
    dataTimeCard,
    eventsWithPrice,
    onClickTimeCard,
    paymentForm,
    loadingSessions,
    orderCreatedAt,
    error,
    bookedSessions,
    selectedGA,
    sessions,
    showPromo,
    cartId,
    nightOwl,
    sellerHasOwl,
    hiddenSesions,
    bookedTemplates,
    orderInfoBar,
    subTotal,
    discount,
    addons,
    modals,
    specialNights,
    hasPNC,
    onDate,
    removedTickets,
  } = useChangeDate(steps);
  useHideIntercomLauncher();
  const dataOrderDetails = toCreateChangeDateOrderDetails(order, orderData, cartId, discount);
  if (!dataTicketCard.length) return <PageLogoLoader />;
  return (
    <ChangeDatePage
      changeDate={changeDate}
      setIsDateSelected={setIsDateSelected}
      // setIsConfirm={setIsConfirm}
      setIsTimeSelected={setIsTimeSelected}
      orderInfo={order}
      dataTicketCard={dataTicketCard}
      orderData={orderData}
      setDateCardId={setDateCardId}
      dataTimeCard={dataTimeCard}
      onClickTimeCard={onClickTimeCard}
      dataOrderDetails={dataOrderDetails}
      order={order}
      paymentForm={paymentForm}
      loadingSessions={loadingSessions}
      orderCreatedAt={orderCreatedAt}
      error={error}
      bookedSessions={bookedSessions}
      eventsWithPrice={eventsWithPrice}
      selectedGA={selectedGA}
      urlSteps={steps}
      sessions={sessions}
      showPromo={showPromo}
      nightOwl={nightOwl}
      sellerHasOwl={sellerHasOwl}
      hiddenSesions={hiddenSesions}
      bookedTemplates={bookedTemplates}
      orderInfoBar={orderInfoBar}
      subTotal={subTotal}
      addons={addons}
      modals={modals}
      specialNights={specialNights}
      hasPNC={hasPNC}
      onDate={onDate}
      removedTickets={removedTickets}
    />
  );
};
