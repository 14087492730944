import React from 'react';

import {
  TwitterPath,
  UsersPath,
  InstagramPath,
  LinkedinPath,
  FacebookPath,
  SparkPath,
  SparkGoldPath,
  SnowGlobePath,
  StarLightPath,
  VisaPath,
  GooglePayPath,
  ApplePayPath,
  Logout,
  Photo,
  CheckCircle,
  CloseCircle,
  UserGroup,
  Pointer,
  People,
  Gift,
  Ticket,
  CustomArrow,
  Paw,
  ChevronDown,
  ArrowRight,
  Shield,
  SparkSmall,
  ExaminationMark,
  Copy,
  ChevronRight,
  LightCalendar,
  LightChat,
  LightGift,
  LightMark,
  LightStar,
  LightTicket,
  ShortArrow,
} from './Paths';
import {StyledSvgIconProps} from './types';

export const SvgChildren: React.FC<StyledSvgIconProps> = ({type}) => {
  if (type === 'users') {
    return <UsersPath />;
  }
  if (type === 'twitter') {
    return <TwitterPath />;
  }
  if (type === 'instagram') {
    return <InstagramPath />;
  }
  if (type === 'linkedin') {
    return <LinkedinPath />;
  }
  if (type === 'facebook') {
    return <FacebookPath />;
  }
  if (type === 'spark') {
    return <SparkPath />;
  }
  if (type === 'sparkGold') {
    return <SparkGoldPath />;
  }
  if (type === 'snowGlobe') {
    return <SnowGlobePath />;
  }
  if (type === 'starLight') {
    return <StarLightPath />;
  }
  if (type === 'applePay') {
    return <ApplePayPath />;
  }
  if (type === 'googlePay') {
    return <GooglePayPath />;
  }
  if (type === 'visa') {
    return <VisaPath />;
  }
  if (type === 'logout') {
    return <Logout />;
  }
  if (type === 'photo') {
    return <Photo />;
  }
  if (type === 'check') {
    return <CheckCircle />;
  }
  if (type === 'close') {
    return <CloseCircle />;
  }
  if (type === 'userGroup') {
    return <UserGroup />;
  }
  if (type === 'pointer') {
    return <Pointer />;
  }
  if (type === 'people') {
    return <People />;
  }
  if (type === 'gift') {
    return <Gift />;
  }
  if (type === 'ticket') {
    return <Ticket />;
  }
  if (type === 'copy') {
    return <Copy />;
  }
  if (type === 'examination') {
    return <ExaminationMark />;
  }
  if (type === 'customArrow') {
    return <CustomArrow />;
  }
  if (type === 'paw') {
    return <Paw />;
  }
  if (type === 'chevronDown') {
    return <ChevronDown />;
  }
  if (type === 'arrowRight') {
    return <ArrowRight />;
  }
  if (type === 'shield') {
    return <Shield />;
  }
  if (type === 'sparkSmall') {
    return <SparkSmall />;
  }
  if (type === 'chevron') {
    return <ChevronRight />;
  }
  if (type === 'lightTicket') {
    return <LightTicket />;
  }
  if (type === 'lightMark') {
    return <LightMark />;
  }
  if (type === 'lightStar') {
    return <LightStar />;
  }
  if (type === 'lightChat') {
    return <LightChat />;
  }
  if (type === 'lightCalendar') {
    return <LightCalendar />;
  }
  if (type === 'lightGift') {
    return <LightGift />;
  }
  if (type === 'shortArrow') {
    return <ShortArrow />;
  }
  return null;
};
