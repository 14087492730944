import React from 'react';
import {useLocation} from 'react-router-dom';
import {AddOnePurchase} from './AddOnePurchase';
import {CancellationHeader} from './CancellationHeader';
import {ChangeDateHeader} from './ChangeDateHeader';
import {UpgradeVipHeader} from './UpgradeVipHeader';
import {paths} from '../../../constants/routes';
import {DashboardHeader} from './DashboardHeader';
import {TicketConfirmationHeader} from './TicketConfirmationHeader';
import {OrderNotFoundHeader} from './OrderNotFoundHeader';
import {PurchaseAdmissionHeader} from './PurchaseAdmissionHeader';

export const Header = () => {
  const {pathname} = useLocation();

  return (
    <>
      {pathname === '/' && <DashboardHeader />}
      {pathname.includes(paths.orders) && <DashboardHeader />}
      {pathname.includes(paths.referrals) && <DashboardHeader />}
      {pathname.includes(paths.profile) && <DashboardHeader />}
      {pathname.includes(paths.changeDate) && <ChangeDateHeader />}
      {pathname.includes(paths.cancellationPage) && <CancellationHeader />}
      {pathname.includes(paths.upgradeVipPage) && <UpgradeVipHeader />}
      {pathname.includes(paths.addPurchase) && <AddOnePurchase />}
      {/* {pathname.includes(paths.manageOrder) && <ManageOrderHeader />} */}
      {pathname.includes(paths.ticketConfirmationPage) && <TicketConfirmationHeader />}
      {pathname.includes(paths.orderNotFoundPage) && <OrderNotFoundHeader />}
      {pathname.includes(paths.admission) && <PurchaseAdmissionHeader />}
    </>
  );
};
