import React from 'react';
import {useLocation} from 'react-router-dom';

import {ChangeDateFooter} from './ChangeDateFooter';
import {UpgradePurchaiseFooter} from './PurchaiseFooter';
import {UpgradeVipFooter} from './UpgradeVipFooter';
import {TicketConfirmationFooter} from './TicketConfirmationFooter';
import {paths} from '../../../constants/routes';
import {PurchaseAdmissionFooter} from './PurchaseAdmissionFooter';

export const Footer = () => {
  const {pathname} = useLocation();

  return (
    <>
      {pathname.includes(paths.upgradeVipPage) && <UpgradeVipFooter />}
      {pathname.includes(paths.addPurchase) && <UpgradePurchaiseFooter />}
      {pathname.includes(paths.changeDate) && <ChangeDateFooter />}
      {pathname.includes(paths.admission) && <PurchaseAdmissionFooter />}
      {pathname.includes(paths.ticketConfirmationPage) && <TicketConfirmationFooter />}
    </>
  );
};
