import {useState, useEffect, useMemo} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {useNavigate, useParams} from 'react-router-dom';
import {changeDateState, СhangeDateStateT} from '../states/order';
import {ButtonType} from '../ui-kit/Button/types';
import {route} from '../constants/routes';
import {useGetOrderWithDetails} from './orders';
import {
  useGetEventsSessions,
  useGetAvailableEvents,
  useGetMultiplyEventsSessionsForOneDate,
  useGetAvailableEventsSessionsAndPrice,
  getBookedEvents,
  getBookedTemplateIds,
} from './events';
import {
  toCreateDataTicketCards,
  getDateByIdCard,
  toCreateDataTimeCards,
  toCreateEventTemplateIds,
  getEventTemplateIdByGA,
  getPNC,
} from '../helpers/events';
import {
  // checkIsNightOwl,
  checkSellerHasOwl,
  getChangeFeeDate,
  getExitingOrderInfoCD,
  getVipsForChangeDate,
  toCreateOrderDataChangeDate,
} from '../helpers/changeDate';
import {OrderDataT} from '../components/ChangeDate/types';
import {useChangeDateReq} from './orders';
import {userState} from '../states/session';
import {PurchaseResT} from '../types/purchase';
import {purchaseStepsStateT, useCartPurchase} from './purchase';
import {getPaymentFormInfo} from '../helpers/upgradeToVip';
import {formatHiddenSessions, getTZdate, isGACat} from '../helpers/orders';
import {PNC_date, formats, scrollUpMobile} from '../helpers/helpers';
import {CustomSessionsT, EventSessionT, EventsT} from '../types/events';
import {useOnRequestsError, useSegmentChangeDate} from './customerIO';
import {CurrentTimeCardProps} from '../ui-kit/Cards/CardFromTime/types';
import {OrderT, OrderTicketsT, SpecialNightRowT, VipSetT} from '../types/orders';
import {commonOrderInfo} from '../states/common';
import special_nights from '../assets/special_nights.json';
// import moment from 'moment-timezone';

type useChangeDateStateProps = {
  purchaseCD?: () => Promise<boolean>;
  cartPurchase: (source?: string) => Promise<false | true>;
  redirect: () => void;
  cartId?: string;
  changeDate?: СhangeDateStateT;
  templateCount?: number;
  vipsCount?: number;
  times?: string;
  subTotal?: string;
};

const useChangDateState = ({
  cartId,
  cartPurchase,
  redirect,
  purchaseCD,
  changeDate,
  templateCount,
  vipsCount,
  times,
  subTotal,
}: useChangeDateStateProps) => {
  const setChangeDate = useSetRecoilState(changeDateState);

  const setProgress = (val: number) => {
    setChangeDate((prev) => ({...prev, progress: val}));
  };
  const setIsDateSelected = (val: boolean) => {
    setChangeDate((prev) => ({...prev, isDateSelected: val, step: 2, mobileStep: 2}));
  };
  const setIsTimeSelected = (val: boolean) => {
    setChangeDate((prev) => ({...prev, isTimeSelected: val, step: 3}));
  };

  const setIsReview = (val: boolean) => {
    setChangeDate((prev) => ({...prev, isReview: val}));
  };
  const setIsConfirm = (val: boolean) => {
    setChangeDate((prev) => ({...prev, isConfirm: val}));
  };
  const setStep = (val: number) => {
    setChangeDate((prev) => ({...prev, step: val}));
  };
  const setMobileStep = (val: number) => {
    setChangeDate((prev) => ({...prev, mobileStep: val}));
  };
  const setConditionButton = (val: boolean) => {
    setChangeDate((prev) => ({...prev, conditionButton: val}));
  };
  const setConditioMobileButton = (val: boolean) => {
    setChangeDate((prev) => ({...prev, conditionMobileButton: val}));
  };

  const setButtonTitle = (val: string) => {
    setChangeDate((prev) => ({...prev, buttonTitle: val}));
  };
  const setButtonType = (val: ButtonType) => {
    setChangeDate((prev) => ({...prev, buttonType: val}));
  };
  const setOrderNumber = (val: string | undefined) => {
    setChangeDate((prev) => ({...prev, orderNumber: val}));
  };
  const setDateCardId = (val: string | undefined) => {
    setChangeDate((prev) => ({...prev, dateCardId: val}));
  };
  const setCartId = (val: string | undefined) => {
    setChangeDate((prev) => ({...prev, cartId: val}));
  };
  const setStripeAccountId = (val: string | undefined) => {
    setChangeDate((prev) => ({...prev, stripeAccountId: val}));
  };
  const setAdditionalPayment = (val?: number) => {
    setChangeDate((prev) => ({...prev, additionalPayment: val}));
  };
  const setOrderId = (val?: string) => {
    setChangeDate((prev) => ({...prev, orderId: val}));
  };
  const setOnNextClick = (func?: () => void) => {
    setChangeDate((prev) => ({...prev, onNextClick: func}));
  };

  const onClickNextStep = () => {
    setChangeDate((prev) => ({
      ...prev,
      step: prev?.step ? prev?.step + 1 : undefined,
    }));
  };

  const onPurchaseClick = async () => {
    const success = await purchaseCD?.();
    success && setStep(4);
  };

  const onCartCheckoutClick = async (source?: string) => {
    const success = await cartPurchase(source);
    success && redirect();
  };

  useEffect(() => {
    setProgress(15);
    setIsDateSelected(false);
    setIsTimeSelected(false);
    setIsReview(false);
    setIsConfirm(false);
    setStep(1);
    setMobileStep(1);
    setOnNextClick(onClickNextStep);
  }, []);

  useEffect(() => {
    if (changeDate?.step === 2) {
      setConditionButton(true);
      setProgress(35);
      setButtonType('rounded');
      setButtonTitle('next');
    } else if (changeDate?.step === 3) {
      setConditioMobileButton(true);
      setProgress(50);
    } else if (changeDate?.step === 4) {
      setProgress(85);
      setButtonTitle('Confirm Exchange');
    } else if (changeDate?.step === 5) {
      setProgress(95);
    }
  }, [changeDate?.step, changeDate?.isDateSelected, changeDate?.isTimeSelected]);

  useEffect(() => {
    if (changeDate?.step === 4) {
      //if an exchange order is LESS THAN the value of the existing order
      if (Number(subTotal) === 0) {
        setOnNextClick(() => onCartCheckoutClick('skip'));
      } else {
        setOnNextClick(onCartCheckoutClick);
      }
    }
    setCartId(cartId);
  }, [changeDate?.step, cartId, subTotal]);

  useEffect(() => {
    if (changeDate?.step === 3) {
      setOnNextClick(onPurchaseClick);
    }
  }, [changeDate?.step, vipsCount, templateCount, times]);

  return {
    changeDate,
    setProgress,
    setIsDateSelected,
    setIsTimeSelected,
    setIsReview,
    setIsConfirm,
    setStep,
    setMobileStep,
    setConditionButton,
    setConditioMobileButton,
    setButtonTitle,
    setButtonType,
    setOrderNumber,
    setDateCardId,
    setAdditionalPayment,
    setStripeAccountId,
    setOrderId,
    onClickNextStep,
  };
};

export const useChangeDate = (urlSteps: purchaseStepsStateT) => {
  const navigate = useNavigate();
  const [user] = useRecoilState(userState);
  const {id} = useParams();
  const [onDate, setOnDate] = useState('');
  // const [dateWithPriceInfo, setDateWithPriceInfo] = useState({});
  const [selectedGA, setSelectedGA] = useState<CurrentTimeCardProps>();
  const [nightOwl, setNightOwl] = useState(false);
  const [vipTicket, setVipTicket] = useState<VipSetT | undefined>();
  const [changeDate, setChangeDateState] = useRecoilState(changeDateState);
  const [purchaseCD, setPurchaseCD] = useState<PurchaseResT>();
  const {order, loading} = useGetOrderWithDetails(id);
  const {onChangeDateComplete, onChangeDateReview, toChangeDatePageClick} = useSegmentChangeDate(order);
  const [orderData, setOrderData] = useState<OrderDataT | undefined>();
  const {events} = useGetAvailableEvents(order?.sellerId);
  //<--- SPECIAL EVENTS
  const {modals, pnc, specialAddons, specialNights, hasPNC} = useEventModals(order, events);
  //SPECIAL EVENTS --->
  const {events: availableDates} = useGetEventsSessions(getEventTemplateIdByGA(events));
  const {eventsWithPrice, eventTemplateIds} = useGetAvailableEventsSessionsAndPrice(order, events);
  const {dataTicketCard} = toCreateDataTicketCards(availableDates, eventsWithPrice, order, events);
  const setOrderInfo = useSetRecoilState(commonOrderInfo);
  const {sessions, loadingSessions, voucherSession} = useGetMultiplyEventsSessionsForOneDate(
    toCreateEventTemplateIds(order, events, [pnc]),
    onDate ? getTZdate(onDate, order).format(formats.onDate) : '',
    changeDate?.dateCardId,
    [pnc],
    order?.tickets,
  );
  const bookedSessions = getBookedEvents({order, eventTemplates: events?.event_template?._data});
  const bookedTemplates = useMemo(
    () => getBookedTemplateIds({order, eventTemplates: events?.event_template?._data}),
    [!!events],
  );
  const setvipTickets = (key: 'vippass' | 'elf', event?: EventSessionT) =>
    setVipTicket((prev) => {
      if (!event) return prev;
      return {...(prev || {}), [key]: event};
    });
  const dataTimeCard = toCreateDataTimeCards(sessions, events, order, selectedGA);
  const {dataStepOne, dataStepTwo} = toCreateOrderDataChangeDate(order, changeDate?.dateCardId, dataTicketCard, events);
  const setPurchase = (values: any) => {
    setPurchaseCD({...values, created: new Date()});
    onChangeDateReview(values);
  };
  const inappropriatePncDate = !!(onDate && getTZdate(new Date(onDate), order).format(formats.date5) !== PNC_date);
  const hasVip = order?.tickets?.some((el) => el?.ticketGroupName.toLowerCase().includes('pass'));
  const orderInfoBar = getExitingOrderInfoCD({
    order,
    events,
    hasVip: !!hasVip,
    orderData,
    specialAddons,
    inappropriatePncDate,
  });
  const {onRequestError} = useOnRequestsError({order});
  const {changeDateReq, error} = useChangeDateReq(
    changeDate?.orderId,
    orderData,
    order,
    events,
    eventTemplateIds,
    setPurchase,
    vipTicket,
    onRequestError,
  );
  const cartId = purchaseCD?.cart?._data?.[0]?.id || '';
  const identityId = purchaseCD?.cart?._data?.[0]?.identity_id || '';
  const times = orderData?.info[1]?.tickets
    ?.filter((el) => !el.removed)
    ?.map((el) => (el?.isSpecial && inappropriatePncDate ? '1' : el?.time || null));

  const onPurchaseSuccess = () => onChangeDateComplete(purchaseCD);
  const {cartPurchase} = useCartPurchase(cartId, identityId, order?.sellerId, onPurchaseSuccess, onRequestError);
  const redirect = async () => changeDate?.orderId && navigate(route.confirmOrder.get({id: changeDate?.orderId}));
  const changeFee = getChangeFeeDate(purchaseCD, events, order);
  const paymentForm = getPaymentFormInfo(order, purchaseCD, events, changeFee);
  const subTotal = purchaseCD?.cart_fees?._data?.[0]?.grand_totals?.total_outstanding;
  const stripe_fees = purchaseCD?.cart_fees?._data?.find((el) => el.gateway_id === 'stripe');
  const stripeAccountId = stripe_fees?.account_id;

  const {
    setIsDateSelected,
    setIsTimeSelected,
    setIsReview,
    setIsConfirm,
    onClickNextStep,
    setOrderNumber,
    setDateCardId,
    setOrderId,
    setProgress,
    setAdditionalPayment,
    setStripeAccountId,
    setConditionButton,
    setConditioMobileButton,
  } = useChangDateState({
    purchaseCD: changeDateReq,
    changeDate,
    cartId,
    cartPurchase,
    redirect,
    templateCount: eventTemplateIds?.length,
    vipsCount: vipTicket ? Object.values(vipTicket)?.length : 0,
    times: times?.join(''),
    subTotal,
  });

  const onClickTimeCard = (id: string) => {
    if (!orderData) return;
    const timeCardsArray = dataTimeCard && Object.values(dataTimeCard).flatMap((el) => el);
    const currentTimeCard = timeCardsArray && timeCardsArray.find((el) => el.id === id);
    const currentOrderData = {...orderData};
    const newObj: Record<number, any> = {};

    //skip if soldOut card clicked
    if (currentTimeCard?.variant === 'sold') {
      setChangeDateState((prev: any) => {
        return {
          ...prev,
          canSkipTickets: [...(prev?.canSkipTickets || []), currentTimeCard?.eventGroupName],
        };
      });
    } else {
      //remove from skip tickets
      setChangeDateState((prev) => ({
        ...prev,
        canSkipTickets: prev?.canSkipTickets?.filter((el) => el !== currentTimeCard?.eventGroupName),
      }));
    }
    const isSelectedGa = isGACat(currentTimeCard?.eventGroupName);

    if (isSelectedGa) {
      setSelectedGA(currentTimeCard);
    }
    //get voucher info
    const merchandiseGroupId =
      events?.ticket_group?._data?.find((el) => el?.name?.toLowerCase()?.includes('merchandise'))?.id || null;
    const voucherTicketType = events?.ticket_type?._data?.find(
      (el) => el?.name?.toLowerCase()?.includes('voucher') || el?.ticket_group_id === merchandiseGroupId,
    );
    const voucherTime = `${getTZdate(voucherSession?.start_datetime, order).format(formats.timeStart)} - ${getTZdate(
      voucherSession?.end_datetime,
      order,
    ).format(formats.timeEnd)}`;

    currentOrderData?.info[1].tickets?.forEach((el, idx) => {
      if (el.ticketGroupName.toLowerCase()?.includes('merchandise')) {
        //set voucher info to order data
        newObj[idx] = {
          ...el,
          time: voucherTime,
          eventTypeId: voucherTicketType?.id,
          eventSessionId: voucherSession?.id,
        };
        return;
      }

      if (el.ticketGroupName !== currentTimeCard?.eventGroupName || el?.skip || el?.isSpecial) {
        return el;
      }

      if (currentOrderData?.info?.[1].tickets?.[idx]) {
        newObj[idx] = {
          ...el,
          time: currentTimeCard?.time,
          eventTypeId: currentTimeCard?.eventTypeId,
          eventSessionId: currentTimeCard?.id,
        };
      }
    });

    currentOrderData.info[1].tickets = currentOrderData.info[1].tickets?.map((el, i) => (newObj?.[i] ? newObj[i] : el));
    setOrderData(currentOrderData);
  };
  useEffect(() => {
    if (order?.tickets) {
      const hasNightOwl: boolean = order?.tickets?.some((p) => p?.ticketTypeName?.toLocaleLowerCase().includes('owl'));
      setNightOwl(hasNightOwl);
    }
  }, [order?.tickets]);
  useEffect(() => {
    if (order?.id)
      setOrderInfo({orderId: order?.id, orderNumber: order?.ticketureOrderNumber, sellerName: order?.sellerName});
  }, [order?.id]);
  useEffect(() => {
    setOrderData((prev) => {
      if (!prev) return prev;
      const newData: OrderDataT = Object.assign(prev) as OrderDataT;
      const gaTicket = newData?.info?.[1]?.tickets?.find((el: OrderTicketsT) => isGACat(el?.ticketGroupName));
      const tickets = newData?.info?.[1]?.tickets?.map((el: OrderTicketsT) => {
        if (isGACat(el?.ticketGroupName)) return el;
        if (el?.skip) return el?.noTimeRewrite ? el : {...el, time: gaTicket?.time};
        if (el?.isSpecial) {
          const addonFromGA = selectedGA?.addons?.find((a) => a?.name === el?.ticketGroupName);
          const addonInfo = specialAddons?.find((el) => el?.templateId === addonFromGA?.event_template_id);
          if (addonFromGA)
            return {...el, time: gaTicket?.time, eventTypeId: addonInfo?.eventTypeId, eventSessionId: addonFromGA?.id};
        }
        return {...el, time: '', eventTypeId: '', eventSessionId: ''};
      });
      newData.info[1].tickets = tickets;
      return {...newData};
    });
  }, [selectedGA?.id]);

  const selected_addons_count = specialAddons?.map((el) => el?.count)?.join('');
  useEffect(() => {
    setOrderData((prev) => {
      if (!prev) return prev;
      const newData: OrderDataT = Object.assign(prev) as OrderDataT;
      const noAddons = !newData?.info?.[1]?.tickets?.some((el) => el?.isSpecial);
      let tickets = [...(newData?.info?.[1]?.tickets || [])];
      if (noAddons) {
        newData.info[1].tickets = [...tickets, ...specialAddons];
        return {...newData};
      } else {
        const newAddons: OrderTicketsT[] = [];
        specialAddons?.forEach((el) => {
          const exist = tickets.find((t) => t.ticketGroupName === el?.ticketGroupName) || {};
          newAddons.push({...exist, ...el});
        });
        tickets = [...tickets?.filter((el) => !el?.isSpecial), ...newAddons];
        newData.info[1].tickets = [...tickets];
        return {...newData};
      }
    });
  }, [selected_addons_count]);

  useEffect(() => {
    if (!changeDate?.dateCardId) return;
    const date = getDateByIdCard(dataTicketCard, changeDate?.dateCardId, order?.sellerTimezone);
    setOnDate(date);
    const currentDateCard = dataTicketCard?.find((el) => el.id === changeDate?.dateCardId);
    const currentOrderData = orderData ? {...orderData} : dataStepTwo;
    currentOrderData.info[1].title = currentDateCard?.title;
    setOrderData(currentOrderData);
    setSelectedGA(undefined);
    urlSteps.step !== '2' && urlSteps.setStep2();
    scrollUpMobile();
  }, [changeDate?.dateCardId]);

  useEffect(() => {
    if (id !== undefined) {
      setOrderId(id);
    }
    setAdditionalPayment(0);
  }, []);

  // set Additional Payment
  useEffect(() => {
    subTotal && setAdditionalPayment(Number(subTotal) > 0 ? Number(subTotal) : 0);
    stripeAccountId && setStripeAccountId(stripeAccountId);
  }, [subTotal, stripeAccountId]);

  useEffect(() => {
    if (changeDate?.step === 2) {
      if (!dataStepTwo?.info[1].tickets?.length) return;
      setOrderData(dataStepTwo);
      setAdditionalPayment(dataStepTwo?.payment > 0 ? dataStepTwo?.payment : 0);
    }
  }, [changeDate?.step, changeDate?.dateCardId]);

  useEffect(() => {
    const hasVouchers = order?.tickets?.find(
      (ticket) => ticket?.eventTemplateName?.toLocaleLowerCase().includes('voucher') ?? false,
    );
    if (hasVouchers) {
      const vStart = `${getTZdate(hasVouchers?.eventStartDate, order).format(formats.timeStart)} - ${getTZdate(
        hasVouchers?.eventEndDate,
        order,
      ).format(formats.timeEnd)}`;
      if (times && times?.length > 0) {
        times[times?.length - 1] = vStart;
      }
    }
    const notAllSelected = times?.some((el) => !el) || !times?.length;
    if (changeDate?.step === 3 && !notAllSelected) {
      setConditionButton(false);
      setConditioMobileButton(false);
    }
    if (notAllSelected) {
      setConditionButton(true);
      setConditioMobileButton(true);
    }
    getVipsForChangeDate(setvipTickets, sessions, order, orderData);
  }, [changeDate?.step, times?.join('')]);

  useEffect(() => {
    setOrderNumber(order?.ticketureOrderNumber);
    setOrderData(dataStepOne);
  }, [order?.id]);

  useEffect(() => {
    order?.id && toChangeDatePageClick();
  }, [order?.id]);

  useEffect(() => {
    if (urlSteps.step === '1') {
      setDateCardId(undefined);
      setSelectedGA(undefined);
    }
    if (urlSteps.step === '2') {
      setSelectedGA(undefined);
    }
  }, [urlSteps?.step]);

  //remove soldOut tickets
  useEffect(() => {
    // if (!changeDate?.canSkipTickets?.length) return;
    setOrderData((prev: any) => {
      const withoutSold = prev?.info[1]?.tickets?.map((el: any) => ({
        ...el,
        removed: changeDate?.canSkipTickets?.includes(el?.ticketGroupName),
      }));
      return {...prev, info: [{...(prev?.info[0] || {})}, {...(prev?.info[1] || {}), tickets: withoutSold}]};
    });
  }, [changeDate?.canSkipTickets?.length, onDate]);

  const {soldOut} = useChangeDateSoldout(sessions, changeDate?.dateCardId);
  const sellerHasOwl = useMemo(() => checkSellerHasOwl(events), [!!events]);
  const hiddenSesions = useMemo(() => formatHiddenSessions(events), [!!events]);
  const {discount, discountName} = getPaymentFormInfo(order, purchaseCD);
  return {
    setProgress,
    changeDate,
    setIsDateSelected,
    setIsReview,
    setIsConfirm,
    onClickNextStep,
    setIsTimeSelected,
    order,
    dataTicketCard,
    orderData,
    eventsWithPrice,
    setDateCardId,
    dataTimeCard,
    onClickTimeCard,
    loading,
    user,
    loadingSessions,
    paymentForm,
    orderCreatedAt: purchaseCD?.created,
    soldOut,
    error,
    bookedSessions,
    selectedGA: selectedGA?.id,
    sessions,
    showPromo: !!paymentForm?.balance_numeric,
    cartId,
    nightOwl,
    sellerHasOwl,
    hiddenSesions,
    bookedTemplates,
    orderInfoBar,
    subTotal,
    discount: {name: discountName, value: discount},
    addons: {
      pnc,
    },
    modals,
    specialNights,
    hasPNC,
    onDate,
    removedTickets: changeDate?.canSkipTickets,
  };
};

export type SoldOutT = {
  vip?: boolean;
  elf?: boolean;
  skating?: boolean;
  parking?: boolean;
};

export const useChangeDateSoldout = (sessions: CustomSessionsT[], dateId?: string) => {
  const [soldOut, setSoldOut] = useState<SoldOutT>();
  useEffect(() => {
    // const vip = sessions.find((el) => el?.name.toLowerCase().includes('pass'));
    const vip = sessions.find((el) => el?.name.toLowerCase().includes('vip'));
    const elf = sessions.find((el) => el?.name.toLowerCase().includes('elf'));
    if (vip) {
      setSoldOut((prev) => ({...prev, vip: vip.event_session._data?.some((el) => el?.sold_out)}));
    }
    if (elf) {
      setSoldOut((prev) => ({...prev, elf: elf.event_session._data?.some((el) => el?.sold_out)}));
    }
  }, [dateId, sessions?.length]);

  return {soldOut};
};

export type EventsModals = {
  pnc: {
    visible: boolean;
    open: () => void;
    close: () => void;
    onAdd: (value?: any) => void;
    minValue?: number;
  };
  specialNight: {
    visible: string;
    open: (key: string) => void;
    close: () => void;
  };
};

const formatImgUrl = (url?: string) => url?.split('](')?.[0]?.replaceAll('[', '');

export const useEventModals = (order?: OrderT, events?: EventsT) => {
  const [specialAddons, setAddons] = useState<OrderTicketsT[]>([]);
  //special nights
  const specialNights: SpecialNightRowT[] = special_nights?.map((el) => ({
    ...el,
    icon: formatImgUrl(el?.icon),
    image: formatImgUrl(el?.image),
  }));
  const [snVisible, setSNVisible] = useState<string>('');
  const openSN = (key: string) => setSNVisible(key);
  const closeSN = () => setSNVisible('');
  //pnc
  const pnc = useMemo(() => getPNC(order, events), [!!order, !!events]);
  const hasPNC = useMemo(
    () => order?.tickets?.some((el) => el?.ticketGroupName.toLowerCase()?.includes('paws')),
    [!!order],
  );
  const [pncVisible, setPncVisible] = useState(false);
  const [pncInitialCount, setPncInitialCount] = useState(0);
  const openPNC = () => setPncVisible(true);
  const closePNC = () => setPncVisible(false);
  const onAddPnc = (count?: number) => {
    if (!count) return;
    if (specialAddons.find((el) => el.ticketGroupName === (pnc?.group?.name || 'Paws n Claus'))) {
      setAddons((prev) =>
        prev.map((el) => {
          if (el.ticketGroupName !== (pnc?.group?.name || 'Paws n Claus')) return el;
          return {...el, count};
        }),
      );
      return closePNC();
    }
    setAddons((prev) => [
      ...prev,
      {
        count: count,
        eventTypeId: pnc?.ticket_type?.id,
        id: pnc?.group?.event_template_id,
        ticketGroupName: pnc?.group?.name || 'Paws n Claus',
        isSpecial: true,
        ticketType: pnc?.template?.name,
        templateId: pnc?.template?.id,
        ticketName: pnc?.ticket_type?.name,
      },
    ]);
    closePNC();
  };

  useEffect(() => {
    const purchasedPNC =
      order?.tickets?.filter((el) => el?.ticketGroupName?.toLocaleLowerCase().includes('paws'))?.length || 0;
    if (purchasedPNC && pnc?.group) {
      // setAddons((prev) => [
      //   ...prev,
      //   {
      //     count: purchasedPNC,
      //     eventTypeId: pnc?.ticket_type?.id,
      //     id: pnc?.group?.event_template_id,
      //     ticketGroupName: pnc?.group?.name || 'Paws & Claus',
      //     isSpecial: true,
      //     ticketType: pnc?.template?.name,
      //     templateId: pnc?.template?.id,
      //     ticketName: pnc?.ticket_type?.name,
      //   },
      // ]);
      setPncInitialCount(purchasedPNC);
    }
  }, [!!order, !!pnc?.group]);

  return {
    modals: {
      pnc: {
        minValue: pncInitialCount,
        visible: pncVisible,
        open: openPNC,
        close: closePNC,
        onAdd: onAddPnc,
      },
      specialNight: {
        visible: snVisible,
        open: openSN,
        close: closeSN,
      },
    },
    specialNights,
    specialAddons,
    setAddons,
    pnc,
    hasPNC,
  };
};
